import React from 'react';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCog, faSave } from '@fortawesome/free-solid-svg-icons';

const CRMServerSideConfig = () => {
  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Intégrations CRM disponibles (à jour)
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Configuration du fournisseur CRM
              </h3>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Check.Label className="fs-8 fw-normal">
                  Sélectionner une solution CRM
                </Form.Check.Label>
                <InputGroup>
                  <FloatingLabel controlId="floatingSelect" label="Region">
                    <Form.Select aria-label="Floating label select example">
                      <option>Aucun</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2">
                    <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                    Ajouter
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2">
                    Montrer le modèle
                  </Button>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CRMServerSideConfig;
