import React, { useEffect, useState } from "react";

interface PasswordValidatorProps {
  password: string | null;
  setPasswordError: (error: string | null) => void;
}

const PasswordValidator: React.FC<PasswordValidatorProps> = ({ password, setPasswordError }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!password) {
      setPasswordError(null);
      return;
    }

    const errors = [];
    if (password.length < 8) errors.push("8 caractères minimum");
    if (!/\d/.test(password)) errors.push("un chiffre");
    if (!/[A-Z]/.test(password)) errors.push("une majuscule");
    if (!/[a-z]/.test(password)) errors.push("une minuscule");
    if (!/[!@#$%^&*]/.test(password)) errors.push("un caractère spécial");

    const errorString = errors.length ? errors.join(", ") : null;
    setPasswordError(errorString);
    setErrorMessage(errorString);
  }, [password, setPasswordError]);

  return (
    <div className="password-requirements fs-9" style={{ color: "red", whiteSpace: "nowrap" }}>
      {errorMessage}
    </div>
  );
};

export default PasswordValidator;
