import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import UserProfileCard from "../../../../components/cards/UserProfileCard";


const UsersProfile = () => {
  const { name } = useParams();
  return (
    <>

      <Row className="g-4 g-xl-6">
        <Col xl={5} xxl={12}>
          <div className="sticky-leads-sidebar">
            <UserProfileCard className="mb-3" name={name} />
          </div>
        </Col>
      </Row>


    </>
  );
};


export default UsersProfile;
