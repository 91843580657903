import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { TrunkData } from "hooks/api/ipbx/useTrunk";
import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import TrunkSIPForm from "./TrunkSIPForm";

const PasswordCell = ({ password }: { password: string }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(prevShow => !prevShow);
  };

  return (
    <div className="">
      <span className="me-2">{showPassword ? password : "••••••••"}</span>
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        onClick={toggleShowPassword}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export const trunksAPITableColumns: ColumnDef<TrunkData>[] = [
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "6%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <a
        href={`/ipbx/trunk-sip/details/${row.original.id}`}
        className="text-primary"
      >
        {row.original.name}
      </a>
    )
  },

  {
    accessorKey: "server",
    header: "Serveur",
    meta: {
      headerProps: { style: { width: "6%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "username",
    header: "Utilisateur",
    meta: {
      headerProps: { style: { width: "7%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "password",
    header: "Mot de passe",
    cell: ({ row: { original } }) => (
      <PasswordCell password={original.password} />
    ),
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "currentCall",
    header: "Appels simult.",
    meta: {
      headerProps: { style: { width: "7%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayNumber",
    header: "N° affichage appel",
    meta: {
      headerProps: { style: { width: "7%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayName",
    header: "Nom affichage appel",
    meta: {
      headerProps: { style: { width: "7%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    accessorKey: "ringDuration",
    header: "Sonnerie",
    meta: {
      headerProps: { style: { width: "6%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "withRegistration",
    header: "Avec REG",
    cell: ({ row: { original } }) => {
      const { withRegistration } = original;
      return (
        <Badge
          bg={withRegistration ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {withRegistration ? "Oui" : "Non"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "6%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "sdaRegistration",
    header: "SDA REG",
    meta: {
      headerProps: { style: { width: "6%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "stateRegistration",
    header: "État REGISTER",
    cell: ({ row: { original } }) => {
      const { stateRegistration } = original;
      return (
        <Badge
          bg={stateRegistration === "registered" ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {stateRegistration === "registered" ? "Enregistré" : "Non enregistré"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "8%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" },
    },
  },


  {
    accessorKey: "state",
    header: "État trunk",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "8%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    id: "actions",
    header: "Actions",
    cell: ({ row: { original } }) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [tableData, setTableData] = useState<TrunkData[]>([]); // Table data update
      const [selectedTrunk, setSelectedTrunk] = useState<
        TrunkData | undefined
      >();
      const [deleteError, setDeleteError] = useState<string | null>(null);

      const handleEditClick = () => {
        setSelectedTrunk(original); // Set the trunk for editing
        setShowEditModal(true);
      };

      const handleDeleteClick = () => {
        setSelectedTrunk(original);
        setShowDeleteModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (!selectedTrunk) return;

        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token not found");
          return;
        }

        try {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Ipbx/trunk/${selectedTrunk.id}`,
            {
              method: "DELETE",
              headers: {
                accept: "text/plain",
                Authorization: `Bearer ${token}`
              },
            }
          );

          if (response.status === 204 || response.ok) {
            setTableData((prevData) =>
              prevData.filter((trunk) => trunk.id !== selectedTrunk.id)
            );
            setShowDeleteModal(false); // Close the modal
            window.location.reload();
          } else if (response.status === 400) {
            const errorData = await response.json();
            if (errorData.message.includes("This trunk is used in table ast_groups")) {
              setDeleteError(
                "Ce trunk est utilisé dans la table ast_groups et ne peut pas être supprimé."
              );
            } else {
              setDeleteError(
                "Une erreur est survenue lors de la suppression. Veuillez vérifier que ce trunk n'est pas utilisé ailleurs et réessayez."
              );
            }
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error deleting trunk", error);
          setDeleteError(
            "Une erreur est survenue lors de la suppression. Veuillez vérifier que ce trunk n'est pas utilisé ailleurs et réessayez."
          );
        }
      };
      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={handleEditClick}>Modifier</Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteClick}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Delete Confirmation Modal */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmer la suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce trunk ?
              {deleteError && (
                <div className="alert alert-danger mt-3">
                  {deleteError}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>


          {/* Edit Form Modal */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier Trunk</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TrunkSIPForm
                tableData={tableData}
                setTableData={setTableData}
                onClose={() => setShowEditModal(false)}
                initialData={selectedTrunk} // Pass trunk data for edit mode
              />
            </Modal.Body>
          </Modal>
        </>
      );
    },
    meta: {
      headerProps: { style: { fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  }
];

const TrunksTableAPI = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9 text-center" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default TrunksTableAPI;
