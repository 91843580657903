import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import iconPlatinium from "assets/img/iper-x/ranks/icons/Platinium.png";

export const notificationsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pages',
    url: '#!'
  },
  {
    label: 'Notifications',
    active: true
  }
];

export interface Notification {
  id: number | string;
  avatar?: string;
  name: string;
  detail?: string;
  interaction: string;
  interactionIcon: string;
  ago: string;
  icon: string;
  time: string;
  date: string;
  read: boolean;
  // avatarPlaceholder?: boolean;
}

const currentDate = new Date();
const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
const timeOptions: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "numeric", hour12: false };

const formattedDate = currentDate.toLocaleDateString("fr-FR", options);
const formattedTime = currentDate.toLocaleTimeString("fr-FR", timeOptions);


export const notifications: Notification[] = [
  {
    id: '1',
    avatar: iconPlatinium,
    name: "Support iper-x",
    interactionIcon: "",
    interaction: "Bienvenu dans votre espace revendeur",
    detail: " \" \" ",
    ago: '10m',
    icon: 'clock',
    time: formattedTime + " ",
    date: formattedDate,
    read: false
  },

];
