import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import { useTrunkData } from "data/iper-x/context/TrunkContext";
import { GroupData } from "hooks/api/ipbx/useGroup";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";
import useTrunkDataID from "hooks/api/ipbx/useTrunkDataID";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, Form } from "react-bootstrap";
import GroupForm from "./GroupForm";

export const groupAPITableColumns: ColumnDef<GroupData>[] = [
  {
    accessorKey: "nameGroup",
    header: "Nom du groupe",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // {
  //   accessorFn: (row) => row.idClient,
  //   id: 'idClient',
  //   header: 'Client',
  //   cell: ({ row: { original } }) => {
  //     const clientData = useClientData();
  //     const { idClient } = original;
  //     const client = clientData.find(client => client.id === idClient);
  //     return client ? client.companyName : "Client non trouvé";
  //   },
  //   meta: {
  //     headerProps: { style: { width: '12%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  {
    accessorKey: "ringStrategy",
    header: "Stratégie de sonnerie",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "internalUsers",
    header: "Utilisateurs internes",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "externalNumber",
    header: "Numéro externe (format international 33X)",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorFn: (row) => row.idTrunkExternalNumber,
    id: "idTrunkExternalNumber",
    header: "Trunk externe",
    cell: ({ row: { original } }) => {
      const trunkData = useTrunkData();
      const { idTrunkExternalNumber } = original;
      const trunk = trunkData.find(trunk => trunk.id === idTrunkExternalNumber);
      return trunk ? trunk.name : "Trunk non trouvé";
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "priorityExternalInternal",
    header: "Priorité externe/interne",
    meta: {
      headerProps: { style: { width: "15%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "ringDuration",
    header: "Durée de sonnerie (secondes)",
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "Activé",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);
      const [formData, setFormData] = useState<GroupData | null>(null);

      const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
      const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

      const { trunkData, loading: loadingTrunks } = useTrunkDataID(clientFinalId);
      const { phoneUserData, loading: phoneUserLoading, error: phoneUserError } = usePhoneUserDataID(clientFinalId);

      const [userInput, setUserInput] = useState<string>("");
      const [userList, setUserList] = useState<{
        id: number;
        firstname: string;
        lastname: string;
        extension: string
      }[]>([]);
      const [userError, setUserError] = useState<string | null>(null);
      const [tableData, setTableData] = useState<GroupData[]>([]);
      const [showFormModal, setShowFormModal] = useState(false);
      const handleOpenEditForm = () => setShowFormModal(true);

      const addUserToList = () => {
        if (userInput) {
          const selectedUser = phoneUserData.find((user) => user.extension === userInput);
          if (!selectedUser) {
            setUserError("Utilisateur non trouvé.");
            return;
          }

          const isUserAlreadyAdded = userList.some((user) => user.extension === selectedUser.extension);
          if (isUserAlreadyAdded) {
            setUserError("Cet utilisateur a déjà été ajouté.");
            return;
          }

          setUserList((prevUserList) => [...prevUserList, selectedUser]);
          setUserInput("");
          setUserError(null);
        }
      };

      const handleDeleteUser = (userId: number) => {
        setUserList(prevUserList => prevUserList.filter(user => user.id !== userId));
      };

      const handleDeleteClick = (group: GroupData) => {
        setSelectedGroup(group);
        setShowDeleteModal(true);
      };

      const handleEditClick = (group: GroupData) => {
        setSelectedGroup(group);
        setFormData(group);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedGroup) {
          await handleDelete(selectedGroup.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token introuvable');
        }
        try {
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/group/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
              'Authorization': `Bearer ${token}`
            },
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression du groupe d'appels", error);
        }
      };

      const handleEdit = async (data: GroupData) => {
        const userRegistrationString = userList.map((user) => user.extension).join(",");

        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          // Incluez l'ID du groupe dans l'URL
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/group/${data.id}`, {
            method: 'PUT',
            headers: {
              'accept': 'text/plain',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              ...data, // Incluez toutes les données sauf l'ID, qui est dans l'URL
              internalUsers: userRegistrationString // Mettez à jour le champ internalUsers
            })
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour du groupe d'appels", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        } : null);
      };

      return (
        <>
          <RevealDropdownTrigger
          >
            <RevealDropdown
            >
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>


          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce groupe d'appels ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le groupe d'appels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <GroupForm
                initialData={original}
                tableData={tableData}
                setTableData={setTableData}
                onClose={() => setShowFormModal(false)}
              />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

const GroupTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default GroupTableAPI;
