import { createContext, useContext, ReactNode } from "react";
import { TrunkData } from "hooks/api/ipbx/useTrunk"; // Assurez-vous que le chemin est correct

// Création du contexte pour les données de trunk SIP
const TrunkContext = createContext<TrunkData[] | null>(null);

// Hook pour utiliser le contexte de trunk
export const useTrunkData = () => {
  const context = useContext(TrunkContext);
  if (!context) {
    throw new Error("useTrunkData must be used within a TrunkProvider");
  }
  return context;
};

// Fournisseur de contexte pour les données de trunk
export const TrunkProvider = ({
                                trunkData,
                                children
                              }: {
  trunkData: TrunkData[];
  children: ReactNode;
}) => {
  return (
    <TrunkContext.Provider value={trunkData}>
      {children}
    </TrunkContext.Provider>
  );
};
