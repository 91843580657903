import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useMsgVocalData } from "data/iper-x/context/MsgVocalContext";
import QueueForm from "./QueueForm";
import { QueueData } from "hooks/api/ipbx/useQueue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCircleStop } from "@fortawesome/free-solid-svg-icons";
import AvatarGroup from "../../../../../components/common/ipbx/AvatarGroup";

export const queueAPITableColumns: ColumnDef<QueueData>[] = [
  {
    accessorKey: "name",
    header: "Nom de la file d'attente",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "numero",
    header: "Numéro de la file d'attente",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorFn: row => row.idMsgVocal,
    id: "idMsgVocal",
    header: "Audio",
    cell: ({ row: { original } }) => {
      const msgVocalData = useMsgVocalData();
      const { idMsgVocal } = original;
      const msgVocal = msgVocalData.find(
        msgVocal => msgVocal.id === idMsgVocal
      );
      const [isPlaying, setIsPlaying] = useState(false);
      const audioRef = useRef<HTMLAudioElement | null>(null);

      const togglePlayback = () => {
        if (isPlaying) {
          audioRef.current?.pause();
        } else if (msgVocal?.url) {
          audioRef.current = new Audio(msgVocal.url);
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      };

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {msgVocal ? (
            <>
              <button
                onClick={togglePlayback}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer"
                }}
              >
                <FontAwesomeIcon
                  icon={isPlaying ? faCircleStop : faCirclePlay}
                  style={{ fontSize: "24px" }}
                />
              </button>
              <audio
                src={msgVocal.url}
                ref={audioRef}
                onEnded={() => setIsPlaying(false)}
              />
              <span
                style={{
                  maxWidth: "100px", // Limite de la largeur
                  whiteSpace: "nowrap", // Empêche le retour à la ligne
                  overflow: "hidden", // Masque le texte qui dépasse
                  textOverflow: "ellipsis" // Affiche des points de suspension
                }}
              >
                {msgVocal.name}
              </span>
            </>
          ) : (
            "Audio non trouvé"
          )}
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "12%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    accessorKey: "membres",
    header: "Utilisateurs",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    },

    cell: ({ row }) => {
      const membres = React.useMemo(() => {
        if (typeof row.original.membres === "string") {
          return row.original.membres.split(",").map(val => val.trim());
        }
        return [];
      }, [row.original.membres]);

      const ids = React.useMemo(() => {
        return membres
          .filter(val => /^\d+$/.test(val) && val.length <= 6)
          .map(Number); // IDs internes
      }, [membres]);

      const [phoneUsers, setPhoneUsers] = React.useState<any[]>([]);
      const [loading, setLoading] = React.useState<boolean>(true);
      const [error, setError] = React.useState<string | null>(null);

      React.useEffect(() => {
        const fetchPhoneUsers = async () => {
          if (ids.length === 0) {
            setLoading(false);
            return;
          }

          setLoading(true);
          setError(null);

          try {
            const token = localStorage.getItem("token");
            if (!token) throw new Error("Token not found");

            const responses = await Promise.all(
              ids.map(async id => {
                const res = await fetch(
                  `https://rct-backend.ipercom.io/api/Webrtc/phoneuser/${id}`,
                  {
                    headers: {
                      Accept: "text/plain",
                      Authorization: `Bearer ${token}`
                    }
                  }
                );

                if (!res.ok) {
                  if (res.status === 404)
                    throw new Error(`Utilisateur avec l'ID ${id} non trouvé.`);
                  throw new Error(`Échec de la récupération de l'utilisateur avec l'ID ${id}`);
                }
                return res.json();
              })
            );

            setPhoneUsers(responses);
          } catch (err) {
            setError((err as Error).message);
            console.error(err);
          } finally {
            setLoading(false);
          }
        };

        fetchPhoneUsers();
      }, [ids]);

      // Créer une map pour accéder rapidement aux utilisateurs internes par ID
      const phoneUsersMap = React.useMemo(() => {
        const map = new Map<number, any>();
        phoneUsers.forEach(user => map.set(user.id, user));
        return map;
      }, [phoneUsers]);

      if (loading) return <span>Loading...</span>;
      if (error) return <span>Error: {error}</span>;

      return (
        <div className="d-flex align-items-center gap-2 flex-wrap">
          {membres.map((member, index) => {
            // Vérifier si le membre est interne
            if (/^\d+$/.test(member) && member.length <= 6) {
              const id = Number(member);
              const user = phoneUsersMap.get(id);
              if (user) {
                // Pass an array containing the user object
                return <AvatarGroup key={id} users={[user]} />;
              }
              // Si l'utilisateur interne n'a pas été trouvé, afficher un placeholder ou rien
              return null;
            }
            // Vérifier si le membre est externe
            else if (/^\+?\d+$/.test(member) && member.length > 6) {
              return (
                <span
                  key={index}
                  className="badge bg-light text-dark"
                  style={{ fontSize: "12px" }}
                >
                {member}
              </span>
              );
            }
            // Optionnel : gérer les cas non conformes
            return null;
          })}
        </div>
      );
    }
  },


  {
    accessorKey: "timeout",
    header: "Temps maximal d'attente (en secondes)",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "strategy",
    header: "Stratégie",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "Activé",
    cell: ({ row: { original } }) => (
      <Badge
        bg={original.state ? "success" : "secondary"}
        variant="phoenix"
        iconPosition="end"
        className="fs-10"
      >
        {original.state ? "Actif" : "Inactif"}
      </Badge>
    ),
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);

      const handleDeleteClick = () => setShowDeleteModal(true);
      const handleEditClick = () => setShowEditModal(true);

      const handleActionConfirm = async (
        type: "delete" | "edit",
        data?: QueueData
      ) => {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");

        const headers: HeadersInit = {
          accept: "text/plain",
          Authorization: `Bearer ${token}`
        };

        const url = `https://rct-backend.ipercom.io/api/Ipbx/queue/${original.id}`;
        const method = type === "delete" ? "DELETE" : "PUT";
        if (type === "edit") headers["Content-Type"] = "application/json";

        const response = await fetch(url, {
          method,
          headers,
          body: type === "edit" && data ? JSON.stringify(data) : undefined
        });

        // if (response.ok) window.location.reload();
        // else throw new Error(`HTTP error! status: ${response.status}`);
        window.location.reload();
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={handleEditClick}>Modifier</Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteClick}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cette file d'attente ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button
                variant="danger"
                onClick={() => handleActionConfirm("delete")}
              >
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier la file d'attente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <QueueForm
                initialData={original}
                onClose={() => setShowEditModal(false)} // Ensure modal closes here
                setTableData={() => {
                }}
                tableData={[]}
              />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

const QueueTableAPI = () => {
  const [tableData, setTableData] = useState<QueueData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch(
          "https://rct-backend.ipercom.io/api/Ipbx/queue",
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (response.ok) {
          const data = await response.json();
          setTableData(data);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des files d'attente",
          error
        );
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9 text-center" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default QueueTableAPI;
