import AppProvider from './/providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from './/providers/BreakpointsProvider';
import SettingsPanelProvider from './/providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes';
import ChatWidgetProvider from './/providers/ChatWidgetProvider';


//Debut Steve 14Nov24
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4a1fedc091b484b7d532158fc8fff4e8@o4507854255816704.ingest.de.sentry.io/4508291188785232",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/dev\.iper-x\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// Fin Steve

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <SettingsPanelProvider>
        <ChatWidgetProvider>
          <BreakpointsProvider>
            <RouterProvider router={router} />
          </BreakpointsProvider>
        </ChatWidgetProvider>
      </SettingsPanelProvider>
    </AppProvider>
  </React.StrictMode>
);
