import React, { useState, ChangeEvent } from "react";
import { faFileExport, faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import ContactsTable, { contactsTableColumns } from "./ContactsTable";
import AddContactModal from "./AddContactModal";
import useContactDataID from "../../../../hooks/api/ipbx/useContactDataID";

const ContactsList = () => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;
  const { contactData: contactsTableData, setContactData: setContactsTableData } = useContactDataID(clientFinalId);

  const table = useAdvanceTable({
    data: contactsTableData,
    columns: contactsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Contacts</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1" />
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0" disabled>
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Import
              </Button>
              <Button variant="link" className="text-900 me-4 px-0" disabled>
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary" onClick={() => setOpenAddModal(true)}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un contact
              </Button>
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <ContactsTable />
        </div>
      </AdvanceTableProvider>

      <AddContactModal
        show={openAddModal}
        onHide={() => setOpenAddModal(false)}
        clientFinalId={clientFinalId}
        onAddContact={(newContact) => setContactsTableData(prevData => [...prevData, newContact])}
      />
    </div>
  );
};

export default ContactsList;
