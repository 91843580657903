import { useState, useEffect } from 'react';

export interface Thiers {
  id: number;
  name: string;
  phone: string | null;
  phone_mobile: string | null;
  email: string | null;
  ref: string;
  status: string;
  note_public: string | null;
  address: string | null;
  town: string | null;
  zip: string | null;
  url: string | null;
  tva_assuj: string | null;
  date_creation: string;
  date_modification: string;
}

export interface ThierDetails extends Thiers {
  siret: string | null; // additional field example
  capital: string | null; // additional field example
}

// Fetch a single Thier's details based on the ID
export const fetchThierDetails = async (id: number): Promise<ThierDetails | null> => {
  try {
    const response = await fetch(
      `https://erp.ipercom.io/api/index.php/thirdparties/${id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl', // your API key
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const thier = await response.json();

    const thierDetails: ThierDetails = {
      id: thier.id,
      name: thier.name || 'Unknown Name',
      phone: thier.phone || 'N/A',
      phone_mobile: thier.phone_mobile || 'N/A',
      email: thier.email || 'N/A',
      ref: thier.ref || 'N/A',
      status: thier.status === '1' ? 'Active' : 'Inactive',
      note_public: thier.note_public || 'No public notes',
      address: thier.address || 'No address available',
      town: thier.town || 'No town available',
      zip: thier.zip || 'No ZIP available',
      url: thier.url || 'No website',
      tva_assuj: thier.tva_assuj === '1' ? 'Yes' : 'No',
      date_creation: new Date(thier.date_creation * 1000).toISOString().split('T')[0],
      date_modification: new Date(thier.date_modification * 1000).toISOString().split('T')[0],
      siret: thier.idprof2 || 'N/A', // Example additional field
      capital: thier.capital || 'N/A'  // Example additional field
    };

    return thierDetails;
  } catch (error: any) {
    console.error("Erreur lors de la récupération des données Thier details:", error.message);
    return null;
  }
};

// Hook to fetch the list of all Thiers
const useThiersData = () => {
  const [data, setData] = useState<Thiers[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://erp.ipercom.io/api/index.php/thirdparties?sortfield=t.rowid&sortorder=ASC&limit=100&mode=1',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl', // your API key
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (!Array.isArray(result)) {
          throw new Error('Invalid data format from API');
        }

        const thiers = result.map((thier: any) => ({
          id: thier.id,
          name: thier.name || 'Unknown Name',
          phone: thier.phone || 'N/A',
          phone_mobile: thier.phone_mobile || 'N/A',
          email: thier.email || 'N/A',
          ref: thier.ref || 'N/A',
          status: thier.status === '1' ? 'Active' : 'Inactive',
          note_public: thier.note_public || 'No public notes',
          address: thier.address || 'No address available',
          town: thier.town || 'No town available',
          zip: thier.zip || 'No ZIP available',
          url: thier.url || 'No website',
          tva_assuj: thier.tva_assuj === '1' ? 'Yes' : 'No',
          date_creation: new Date(thier.date_creation * 1000).toISOString().split('T')[0],
          date_modification: new Date(thier.date_modification * 1000).toISOString().split('T')[0],
        }));

        setData(thiers);
      } catch (error) {
        // console.error('Erreur lors de la récupération des données Thiers data:', error.message);
        setError("Impossible de récupérer les données data. Please try again later.");
      }
    };

    fetchData();
  }, []);

  return { data, error };
};

export default useThiersData;
