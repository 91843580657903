import React from "react";
import { Button, Card, Col, FloatingLabel, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCopy, faEyeSlash, faGear, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const ParamVideo = () => {
  return (
    <>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Visioconférence
              </h3>


              <Form.Group className="mb-3" controlId="formRegion">
                <Form.Label>Sélectionner votre région</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>Europe</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Logo de l'entreprise pour le portail de visioconférence</Form.Label>
                <Form.Control type="file" />
              </Form.Group>


            </Col>

          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">


              <h3 className="fw-bolder mb-2 line-clamp-1">
                Email de conférences
              </h3>

              <Button variant="btn btn-white border border-1 px-2">
                <FontAwesomeIcon icon={faGear} className="me-2" />
                Configurer
              </Button>



            </Col>

          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">


              <h3 className="fw-bolder mb-2 line-clamp-1">
                API
              </h3>

              <InputGroup>
                <FormControl
                  placeholder="Recipient's username"
                  aria-label="Recipient's username with two button addons"
                />
                <Button variant="btn btn-white border border-1 px-2">
                  <FontAwesomeIcon icon={faCog} className="me-2" />
                  Générer
                </Button>
                <Button variant="btn btn-white border border-1 px-2">
                  <FontAwesomeIcon icon={faCopy} className="me-2" />

                </Button>
                <Button variant="btn btn-white border border-1 px-2">
                  <FontAwesomeIcon icon={faEyeSlash} className="me-2" />

                </Button>
                <Button variant="btn btn-white border border-1 px-2">
                  <FontAwesomeIcon icon={faTrash} className="me-2" />

                </Button>
              </InputGroup>


            </Col>

          </Row>
        </Card.Body>
      </Card>

    </>
  );
};

export default ParamVideo;
