import {
  Col,
  Row,
  Container,
  Button,
  Badge,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

interface SdaData {
  id: number;
  name: string;
  idClient: number;
  sda: string;
  idSchedule: number;
  destination: string;
  destinationData: string;
  ringDuration: number;
  idTrunkTransfert: number;
  nrDestination: string | null;
  nrDestinationData: string | null;
  idNrTrunkTransfert: number;
  state: boolean;
}

interface ScheduleData {
  id: number;
  idClient: number;
  name: string;
  startSlot1: string;
  endSlot1: string;
  startSlot2: string;
  endSlot2: string;
  days: string;
  state: boolean;
}

const SdaDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [sdaData, setSdaData] = useState<SdaData | null>(null);
  const [scheduleData, setScheduleData] = useState<ScheduleData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSdaData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token not found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(
          `https://rct-backend.ipercom.io/api/Ipbx/sda/${id}`,
          {
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`
            }
          }
        );
        setSdaData(response.data);
      } catch (err) {
        setError("Error fetching SDA data.");
        console.error(err);
      }
    };

    fetchSdaData();
  }, [id]);

  useEffect(() => {
    const fetchScheduleData = async (scheduleId: number) => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await axios.get(
          `https://rct-backend.ipercom.io/api/Ipbx/schedule/${scheduleId}`,
          {
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`
            }
          }
        );
        setScheduleData(response.data);
      } catch (err) {
        console.error("Error fetching schedule data:", err);
      }
    };

    if (sdaData?.idSchedule) {
      fetchScheduleData(sdaData.idSchedule);
    }
  }, [sdaData]);

  if (error)
    return (
      <Container>
        <p>{error}</p>
      </Container>
    );

  const renderTooltip = (text: string) => <Tooltip>{text}</Tooltip>;

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">

        {/* Button for going back */}
        <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
          ← Retour
        </Button>

        <h2>Détails Règles entrantes (SDA)</h2>
        <div>
          <Button variant="warning" className="me-2" disabled>
            <i className="fas fa-pen-to-square me-1"></i> Modifier
          </Button>
          <Button variant="danger" disabled>
            <i className="fas fa-trash me-1"></i> Supprimer
          </Button>
        </div>
      </div>

      {!sdaData ? (
        <p>Loading SDA data...</p>
      ) : (
        <>
          <Row className="mb-4">
            <Col lg={6}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-4">Informations Générales</h5>
                <Row>
                  <Col md={6}>
                    <strong>Nom:</strong>
                    <p>{sdaData.name}</p>
                  </Col>
                  <Col md={6}>
                    <strong>SDA Number:</strong>
                    <p>{sdaData.sda}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Destination:</strong>
                    <p>{sdaData.destination}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Destination Data:</strong>
                    <p>{sdaData.destinationData}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Trunk de Destination:</strong>
                    <p>{sdaData.idTrunkTransfert ?? "Non défini"}</p>
                  </Col>
                </Row>

                <h5 className="mb-4">Configuration et Statut</h5>
                <Row>
                  <Col md={6}>
                    <strong>Durée de Sonnerie:</strong>
                    <p>{sdaData.ringDuration} secondes</p>
                  </Col>


                  <Col md={6}>
                    <strong>Statut:</strong>
                    <Badge bg={sdaData.state ? "success" : "secondary"}>
                      {sdaData.state ? "Actif" : "Inactif"}
                    </Badge>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* Destination secondaire pendant cet horaire */}

            <Col lg={6}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-3">Destination secondaire pendant cet horaire</h5>
                <Row>
                  <Col md={6}>
                    <strong>Destination sur non réponse:</strong>
                    <p>{sdaData.nrDestination ?? "Non défini"}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Numéro de destination sur non réponse:</strong>
                    <p>{sdaData.nrDestinationData ?? "Non défini"}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Trunk de destination sur non réponse:</strong>
                    <p>{sdaData.idNrTrunkTransfert ?? "Non défini"}</p>
                  </Col>
                </Row>

              </div>
            </Col>

          </Row>


          {scheduleData && (
            <Row className="mt-4">
              <Col lg={12}>
                <div className="bg-light p-4 rounded">
                  <h5 className="mb-4">Horaires de Bureau</h5>
                  <Row>
                    <Col md={6}>
                      <strong>Nom de l'horaire:</strong>
                      <p>{scheduleData.name}</p>
                    </Col>
                    <Col md={6}>
                      <strong>Jours d'activité:</strong>
                      <p>{scheduleData.days}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <strong>Début du créneau 1:</strong>
                      <p>{scheduleData.startSlot1}</p>
                    </Col>
                    <Col md={6}>
                      <strong>Fin du créneau 1:</strong>
                      <p>{scheduleData.endSlot1}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <strong>Début du créneau 2:</strong>
                      <p>{scheduleData.startSlot2}</p>
                    </Col>
                    <Col md={6}>
                      <strong>Fin du créneau 2:</strong>
                      <p>{scheduleData.endSlot2}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <strong>Statut:</strong>
                      <Badge bg={scheduleData.state ? "success" : "secondary"}>
                        {scheduleData.state ? "Actif" : "Inactif"}
                      </Badge>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default SdaDetails;
