import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import client from "../../../../assets/img/iper-x/apps/client.png";
import appstore from "../../../../assets/img/iper-x/apps/appstore.png";
import googleplay from "../../../../assets/img/iper-x/apps/googleplay.png";
import Rating from "../../../../components/base/Rating";


const DownloadClients = () => {


  return (
    <>
      <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
        <div>
          <h2 className="mb-2">Système IPER-X et APPLICATIONS</h2>

        </div>

      </div>

      <div
        className="mx-auto px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
        <Row className="justify-content-center align-items-center w-sm-100">
          <Col xs={4}>
            <img src={client} alt="" className="w-90 w-sm-75 m-10" />
          </Col>
          <Col xs={4}>
            <div className="banner-text light text-left">
              <h3>Téléchargez notre application pour tous les appareils </h3>


              <div className="row mt-3">
                <div className="col-sm-6">
                  <p className="text-500 mb-0 me-2">App Store</p>
                  <p className="fs-9">
                    <Rating readonly initialValue={5} />
                    <span className="text-500 fw-semi-bold ms-1"></span>
                  </p>
                  <h4 className="fw-bolder mb-2 line-clamp-1">Le choix des éditeurs</h4>
                  <p className="text-500 mb-0 me-3">note 4.7, 187K+ avis</p>
                </div>
                <div className="col-sm-6 col-xxl-5">
                  <img src={appstore} alt="" className="w-30 w-sm-75 m-10" />
                </div>
              </div>


              <div className="row mt-1">
                <div className="col-sm-6">
                  <p className="text-500 mb-0 me-3">Google Play</p>
                  <p className="fs-9">
                    <Rating readonly initialValue={5} />
                    <span className="text-500 fw-semi-bold ms-1"></span>
                  </p>
                  <h4 className="fw-bolder mb-2 line-clamp-1">Application du jour</h4>
                  <p className="text-500 mb-0 me-3">note 4.7, 187K+ avis</p>
                </div>

                <div className="col-sm-6 col-xxl-5">
                  <img src={googleplay} alt="" className="w-30 w-sm-75 m-10" />
                </div>
              </div>


            </div>
          </Col>
        </Row>
      </div>


      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Kit marketing
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                  Inclut tout ce dont vous avez besoin pour promouvoir IPER-X dans vos efforts de marketing et de vente, ainsi que du matériel de formation pour votre équipe. Disponible en 7 langues, il suffit de cliquer sur le drapeau pour accéder à votre kit marketing & commercial IPER-X
                </span>

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Kit commercial
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                 Inclut tout ce dont vous avez besoin pour promouvoir IPER-X dans vos efforts de marketing et de vente, ainsi que du matériel de formation pour votre équipe. Disponible en 7 langues, il suffit de cliquer sur le drapeau pour accéder à votre kit marketing & commercial IPER-X
                </span>

            </Col>

          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default DownloadClients;

