import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import AdvanceTableProvider from '../../../../../../providers/AdvanceTableProvider';
import useAdvanceTable from '../../../../../../hooks/others/useAdvanceTable';
import { holdMusicPlaylistTableData } from '../../../../../../data/iper-x/ipbx/holdMusicPlaylist';
import CRMIntegrationsInstalledTable, {
  crmIntegrationsInstalledTableColumns
} from '../../../../../../components/tables/ipbx/CRMIntegrationsInstalledTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPlus } from '@fortawesome/free-solid-svg-icons';
import { crmIntegrationsInstalledTableData } from "../../../../../../data/iper-x/ipbx/crmIntegrationsInstalled";

const CRMClientSideConfig = () => {
  const table = useAdvanceTable({
    data: crmIntegrationsInstalledTableData,
    columns: crmIntegrationsInstalledTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Intégrations CRM disponibles (2)
              </h3>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Intégrations CRM installées
                <Button variant="btn btn-white border border-1 px-2 ms-3">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter
                </Button>
              </h3>

              <AdvanceTableProvider {...table}>
                <div className="mx-n4 pt-3 px-4 mx-lg-n6 px-lg-6 ">
                  <CRMIntegrationsInstalledTable />
                </div>
              </AdvanceTableProvider>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CRMClientSideConfig;
