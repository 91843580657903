import React, { useState } from "react";
import { Card } from "react-bootstrap";
import classNames from "classnames";
import InfoListItem from "./InfoListItem";
import { BadgeBg } from "../../../base/Badge";

interface InfoListProps {
  data: InfoItem[];
}

interface Badge {
  label: string;
  bg: BadgeBg;
}

export interface InfoItem {
  task: string;
  date: string;
  time: string;
  attachment?: number;
  badge?: Badge;
  listitems?: number;
  completed?: boolean;
}


const InfoList: React.FC<InfoListProps> = ({ data }) => {
  const [, setSelectedItem] = useState<InfoItem | null>(null);

  return (
    <Card.Body className="py-0 scrollbar to-do-list-body mt-5">
      {data.map((todo, index) => (
        <InfoListItem
          key={todo.task}
          todo={todo}
          className={classNames({
            "border-top": index === 0
          })}
          labelClassName="text-900"
          fullLayoutBreakpoints={["md", "xxl"]}
          halfLayoutBreakpoints={["xl"]}
          onClick={() => setSelectedItem(todo)}
        />
      ))}
    </Card.Body>
  );
};

export default InfoList;
