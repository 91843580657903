import {
  faCircleInfo,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupData } from "hooks/api/ipbx/useGroup";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";
import useTrunkDataID from "hooks/api/ipbx/useTrunkDataID";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";

type GroupFormProps = {
  initialData?: GroupData;
  tableData: GroupData[];
  setTableData: React.Dispatch<React.SetStateAction<GroupData[]>>;
  onClose: () => void;
};

const GroupForm: React.FC<GroupFormProps> = ({
                                               initialData,
                                               tableData,
                                               setTableData,
                                               onClose
                                             }) => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const { trunkData, loading: loadingTrunks } = useTrunkDataID(clientFinalId);
  const { phoneUserData, loading: phoneUserLoading } =
    usePhoneUserDataID(clientFinalId);

  const [newGroup, setNewGroup] = useState<GroupData>({
    id: 0,
    idClient: clientFinalId || 0,
    nameGroup: "",
    ringStrategy: "all",
    internalUsers: "",
    externalNumber: "",
    idTrunkExternalNumber: 0,
    priorityExternalInternal: "internal",
    ringDuration: 20,
    state: true
  });

  const [userInput, setUserInput] = useState<string>("");
  const [userList, setUserList] = useState<
    { id: number; firstname: string; lastname: string; extension: string }[]
  >([]);
  const [userError, setUserError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setNewGroup(initialData);
      if (phoneUserData.length > 0 && initialData.internalUsers) {
        const initialUsers = initialData.internalUsers
          .split(",")
          .map(ext => phoneUserData.find(user => user.extension === ext))
          .filter(user => user !== undefined) as typeof userList;
        setUserList(initialUsers);
      }
    }
  }, [initialData, phoneUserData]);

  const addUserToList = () => {
    if (userInput) {
      const selectedUser = phoneUserData.find(
        user => user.extension === userInput
      );
      if (!selectedUser) {
        setUserError("Utilisateur non trouvé.");
        return;
      }
      if (userList.some(user => user.extension === selectedUser.extension)) {
        setUserError("Cet utilisateur a déjà été ajouté.");
        return;
      }
      setUserList([...userList, selectedUser]);
      setUserInput("");
      setUserError(null);
    }
  };

  const handleDeleteUser = (userId: number) => {
    setUserList(prevUserList =>
      prevUserList.filter(user => user.id !== userId)
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const isCheckbox = e.target instanceof HTMLInputElement && e.target.type === "checkbox";

    setNewGroup((prevGroup) => ({
      ...prevGroup,
      [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value
    }));
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userRegistrationString = userList
      .map(user => user.extension)
      .join(",");

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      // Include ID in the data payload rather than in the URL
      const groupDataToSubmit = {
        ...newGroup,
        idClient: Number(newGroup.idClient),
        internalUsers: userRegistrationString,
        id: initialData ? newGroup.id : undefined // Include ID only for updates
      };

      const url = "https://rct-backend.ipercom.io/api/Ipbx/group"; // URL without ID
      const method = initialData ? "PUT" : "POST";

      const response = await fetch(url, {
        method,
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(groupDataToSubmit)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseText = await response.text();
      const data: GroupData | null = responseText
        ? JSON.parse(responseText)
        : null;

      if (data) {
        setTableData(prevData =>
          initialData
            ? prevData.map(item => (item.id === data.id ? data : item))
            : [...prevData, data]
        );
      } else {
        console.warn("Le serveur a renvoyé une réponse vide.");
      }

      onClose();
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la gestion du groupe d'appels", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="state" className="align-items-center mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>Définit l'état actif ou inactif du groupe</Tooltip>
          }
        >
          <Form.Label>
            État du groupe{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <div className="d-flex align-items-center">
          <Form.Switch
            name="state"
            checked={newGroup.state}
            onChange={e => handleChange(e as ChangeEvent<HTMLInputElement>)} // Cast explicitly for checkboxes
            className="me-2"
          />

          <span>{newGroup.state ? "Actif" : "Inactif"}</span>
        </div>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId="nameGroup">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Nom unique du groupe pour l'identifier dans le système.
                </Tooltip>
              }
            >
              <Form.Label>
                Nom du Groupe{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="nameGroup"
              value={newGroup.nameGroup}
              onChange={handleChange}
              required
            />


          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="ringStrategy">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Définit comment les appels sont distribués entre les
                  utilisateurs.
                </Tooltip>
              }
            >
              <Form.Label>
                Stratégie de Sonnerie{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              className="form-select"
              name="ringStrategy"
              value={newGroup.ringStrategy}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionner une stratégie</option>
              <option value="priority">Priorité</option>
              <option value="all">Tous</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="internalUsers">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Utilisateurs internes participant au groupe d'appels.
            </Tooltip>
          }
        >
          <Form.Label>
            Sélectionnez un Utilisateur{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          as="select"
          className="form-select"
          name="internalUsers"
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
          disabled={phoneUserLoading}
        >
          <option value="">Sélectionner un utilisateur</option>
          {phoneUserData.length >= 0 ? (
            phoneUserData.map(user => (
              <option key={user.id} value={user.extension}>
                {user.firstname} {user.lastname} ({user.extension})
              </option>
            ))
          ) : (
            <option disabled>Aucune donnée</option>
          )}
        </Form.Control>
        <Button
          variant="success"
          disabled={phoneUserData.length === 0}
          onClick={addUserToList}
          className="mt-2"
        >
          <FontAwesomeIcon icon={faPlus} /> Ajouter utilisateur
        </Button>
        {userError && <p className="text-danger">{userError}</p>}
      </Form.Group>

      {userList.length > 0 && (
        <div>
          <p className="fs-9 mt-1">Utilisateurs internes ajoutés :</p>
          <ul>
            {userList.map(user => (
              <li key={user.id} className="fs-9">
                {user.firstname} {user.lastname}{" "}
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleDeleteUser(user.id)}
                  style={{ cursor: "pointer" }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      <Row>
        <Col>
          <Form.Group controlId="externalNumber">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Numéro externe au format international (EX : 33518222200)
                </Tooltip>
              }
            >
              <Form.Label>
                Numéro Externe{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="externalNumber"
              value={newGroup.externalNumber}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="idTrunkExternalNumber">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Trunk utilisé pour acheminer les appels vers le numéro
                  externe.
                </Tooltip>
              }
            >
              <Form.Label>
                Trunk{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              name="idTrunkExternalNumber"
              className="form-select"
              value={newGroup.idTrunkExternalNumber}
              onChange={handleChange}
              required
              disabled={loadingTrunks}
            >
              <option value="">Sélectionner un trunk</option>
              {trunkData.length > 0 ? (
                trunkData.map(trunk => (
                  <option key={trunk.id} value={trunk.id}>
                    {trunk.name}
                  </option>
                ))
              ) : (
                <option disabled>Aucune donnée</option>
              )}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="priorityExternalInternal">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Priorité d'acheminement : interne pour les utilisateurs
                  internes, externe pour les appels externes.
                </Tooltip>
              }
            >
              <Form.Label>
                Priorité{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              className="form-select"
              name="priorityExternalInternal"
              value={newGroup.priorityExternalInternal}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionner une priorité</option>
              <option value="internal">Interne</option>
              <option value="external">Externe</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="ringDuration">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Durée de la sonnerie en secondes avant redirection vers la
                  destination secondaire.
                </Tooltip>
              }
            >
              <Form.Label>
                Durée sonneries (en secondes){" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="ringDuration"
              value={newGroup.ringDuration}
              onChange={handleChange}
              min={0}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default GroupForm;
