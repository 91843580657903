import React from 'react';
import { ProgressBar } from 'react-bootstrap';

interface RetailerRanksProgressBarProps {
  current: number; // The current revenue (chiffre d'affaires)
}

const RetailerRanksProgressBar: React.FC<RetailerRanksProgressBarProps> = ({ current }) => {
  // Define the thresholds for each rank
  const targetIvoire = 6000;
  const targetSilver = 12000;
  const targetGold = 60000;
  const targetPlatinum = 125000;

  // Calculate progress for each rank independently
  const ivoireProgress = Math.min((current / targetIvoire) * 100, 100);
  const silverProgress = current > targetIvoire ? Math.min(((current - targetIvoire) / (targetSilver - targetIvoire)) * 100, 100) : 0;
  const goldProgress = current > targetSilver ? Math.min(((current - targetSilver) / (targetGold - targetSilver)) * 100, 100) : 0;
  const platinumProgress = current > targetGold ? Math.min(((current - targetGold) / (targetPlatinum - targetGold)) * 100, 100) : 0;

  return (
    <div className="w-100">
      {/* Rank Titles */}
      <div className="d-flex justify-content-between">
        <div style={{ width: '20.72%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">IVOIRE</p>
        </div>
        <div style={{ width: '35.76%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">SILVER</p>
        </div>
        <div style={{ width: '25.38%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">GOLD</p>
        </div>
        <div style={{ width: '25.14%' }}>
          <p className="mb-2 text-700 fw-semi-bold fs-9">PLATINUM</p>
        </div>
      </div>

      {/* ProgressBar for all ranks on the same line */}
      <ProgressBar className="mb-3 rounded-3" style={{ height: '10px' }}>
        {/* Ivoire Progress */}
        <ProgressBar
          className="bg-warning border-end border-gray border-2"
          now={ivoireProgress * 20.72 / 100} // Adjust width based on percentage of Ivoire
          key={1}
        />
        {/* Silver Progress */}
        <ProgressBar
          className="bg-success border-end border-gray border-2"
          now={silverProgress * 35.76 / 100} // Adjust width based on percentage of Silver
          key={2}
        />
        {/* Gold Progress */}
        <ProgressBar
          className="bg-warning border-end border-gray border-2"
          now={goldProgress * 25.38 / 100} // Adjust width based on percentage of Gold
          key={3}
        />
        {/* Platinum Progress */}
        <ProgressBar
          className="bg-warning border border-gray border-1"
          now={platinumProgress * 25.14 / 100} // Adjust width based on percentage of Platinum
          key={4}
        />
      </ProgressBar>

      {/* Display progress details */}
      <p className="text-muted">
        {`Actuel : ${current.toFixed(2)} €`}
      </p>
    </div>
  );
};

export default RetailerRanksProgressBar;
