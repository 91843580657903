import React, { useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import useMsgVocalDataID from "hooks/api/ipbx/useMsgVocalDataID";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";
import { QueueData } from "hooks/api/ipbx/useQueue";
import useQueuedataID from "hooks/api/ipbx/useQueuedataID";
import MembersField from "../../../../../components/base/MembersField";

type QueueFormProps = {
  tableData: QueueData[];
  setTableData: React.Dispatch<React.SetStateAction<QueueData[]>>;
  onClose: () => void;
  initialData?: QueueData;
};

const QueueForm: React.FC<QueueFormProps> = ({
                                               tableData,
                                               setTableData,
                                               onClose,
                                               initialData
                                             }) => {
  const isEditMode = Boolean(initialData);

  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const { queueData, setQueueData, loading, error } = useQueuedataID(clientFinalId);
  const { msgVocalData, loading: msgVocalLoading } = useMsgVocalDataID(clientFinalId);
  const { phoneUserData, setPhoneUserData, loading: phoneUserLoading } = usePhoneUserDataID(clientFinalId);

  const [newQueue, setNewQueue] = useState<QueueData>({
    id: initialData?.id || 0,
    idClient: initialData?.idClient || clientFinalId,
    name: initialData?.name || "",
    numero: initialData?.numero || 0,
    idMsgVocal: initialData?.idMsgVocal || 0,
    membres: initialData?.membres || "",
    timeout: initialData?.timeout || 0,
    strategy: initialData?.strategy || "ringall",
    state: initialData?.state || true
  });

  const [members, setMembers] = useState<{ id: string; name: string; type: "internal" | "external" }[]>([]);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string | null }>({});

  useEffect(() => {
    if (isEditMode && initialData && !loading && !phoneUserLoading) {
      const memberIds = initialData.membres.split(",");

      // Reconstruire les membres dans l'ordre
      const reconstructedMembers = memberIds.map(id => {
        const internalUser = phoneUserData.find(u => u.id === parseInt(id, 10));
        if (internalUser) {
          return { id, name: `${internalUser.firstname} ${internalUser.lastname}`, type: "internal" as const };
        } else {
          return { id, name: id, type: "external" as const }; // Ajoute un membre externe si aucun utilisateur interne trouvé
        }
      });

      setMembers(reconstructedMembers);
    }
  }, [isEditMode, initialData, phoneUserData, loading, phoneUserLoading]);


  const handleChange = <T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
    e: React.ChangeEvent<T>
  ) => {
    const { name, value } = e.target;
    setNewQueue(prev => ({
      ...prev,
      [name]: name === "timeout" || name === "numero" ? Number(value) : value
    }));
    setFormErrors(prev => ({ ...prev, [name]: null }));
  };



  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found");
      return;
    }

    // Générez la chaîne des membres dans l'ordre actuel
    const requestData = {
      ...newQueue,
      idClient: clientFinalId,
      membres: members.map(m => m.id).join(",") // L'ordre est conservé ici
    };

    const url = `https://rct-backend.ipercom.io/api/Ipbx/queue`;
    const method = isEditMode ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error("Server response was not ok");
      }

      if (response.status === 204) {
        console.log("No content returned from the server.");
        window.location.reload();
        return;
      }

      const data: QueueData = await response.json();
      setTableData(prev =>
        isEditMode
          ? prev.map(queue => (queue.id === data.id ? data : queue))
          : [...prev, data]
      );
      onClose();
    } catch (error) {
      console.error("Error while adding or updating the queue", error);
    }
  };


  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (!newQueue.name) errors.name = "Le nom est requis";
    if (!newQueue.numero) {
      errors.numero = "Le numéro est requis";
    } else {
      const isValidNumero = /^\d{1,6}$/.test(newQueue.numero.toString()) &&
        (newQueue.numero === 0 || newQueue.numero.toString().charAt(0) !== "0");
      if (!isValidNumero) {
        errors.numero = "Le numéro doit être un nombre de 1 à 6 chiffres, sans commencer par 0";
      } else {
        const isNumeroInUse = queueData.some(
          queue => queue.numero === newQueue.numero && queue.id !== newQueue.id
        );
        if (isNumeroInUse) {
          errors.numero = "Ce numéro de file est déjà utilisé.";
        }
      }
    }

    if (members.length === 0) {
      errors.membres = "Ajoutez au moins un membre à la file d'attente.";
    }
    if (!newQueue.timeout) errors.timeout = "Le temps d'attente est requis";
    if (!newQueue.strategy) errors.strategy = "La stratégie est requise";
    if (!newQueue.idMsgVocal) errors.idMsgVocal = "L'audio est requis";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="state" className="align-items-center mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={<Tooltip>Définit l'état actif ou inactif de la file d'attente.</Tooltip>}
        >
          <Form.Label>
            ÉtatÉtat de la file d'attente{" "}
            <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer", marginLeft: "4px" }} />
          </Form.Label>
        </OverlayTrigger>
        <div className="d-flex align-items-center">
          <Form.Switch
            name="state"
            checked={newQueue.state}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewQueue({ ...newQueue, state: e.target.checked })
            }
            className="me-2"
          />
          <span>{newQueue.state ? "Actif" : "Inactif"}</span>
        </div>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId="name" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip>Nom unique pour identifier la file d'attente.</Tooltip>}
            >
              <Form.Label>
                Nom de la file d'attente{" "}
                <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer", marginLeft: "4px" }} />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="name"
              value={newQueue.name}
              onChange={handleChange}
              isInvalid={!!formErrors.name}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="numero" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip>Numéro à 6 chiffres pour identifier la file d'attente (ex: 123456).</Tooltip>}
            >
              <Form.Label>
                Numéro de la file d'attente{" "}
                <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer", marginLeft: "4px" }} />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="numero"
              value={newQueue.numero}
              onChange={handleChange}
              isInvalid={!!formErrors.numero}
              placeholder="Ex: 123456"
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.numero}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="timeout" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip>Durée maximale d'attente en secondes avant la redirection.</Tooltip>}
            >
              <Form.Label>
                Temps maximal d'attente (en secondes){" "}
                <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer", marginLeft: "4px" }} />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="timeout"
              value={newQueue.timeout}
              onChange={handleChange}
              min={0}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="strategy" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip>Stratégie de répartition des appels entrants.</Tooltip>}
            >
              <Form.Label>
                Stratégie{" "}
                <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer", marginLeft: "4px" }} />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              name="strategy"
              value={newQueue.strategy}
              onChange={handleChange}
              required
            >
              <option value="ringall">Sonner tous</option>
              <option value="leastrecent">Le moins récent</option>
              <option value="fewestcalls">Le moins d'appels</option>
              <option value="random">Aléatoire</option>
              <option value="rrmemory">Mémoire</option>
              <option value="linear">Linéaire</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <MembersField
        members={members}
        setMembers={setMembers}
        phoneUserData={phoneUserData}
        isEditMode={isEditMode}
      />

      <Form.Group controlId="idMsgVocal" className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={<Tooltip>Message vocal joué pour les appelants en attente.</Tooltip>}
        >
          <Form.Label>
            Audio{" "}
            <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer", marginLeft: "4px" }} />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          as="select"
          name="idMsgVocal"
          value={newQueue.idMsgVocal}
          onChange={handleChange}
          required
          disabled={msgVocalLoading}
        >
          <option value="">Sélectionner un audio</option>
          {msgVocalData.map(msgVocal => (
            <option key={msgVocal.id} value={msgVocal.id}>
              {msgVocal.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        {isEditMode ? "Modifier" : "Ajouter"}
      </Button>
    </Form>
  );
};

export default QueueForm;

