import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger
} from "components/base/RevealDropdown";
import { useScheduleData } from "data/iper-x/context/ScheduleContext";
import React, { useState, useEffect } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";
import SdaForm from "./SdaForm";
import { SdaData } from "hooks/api/ipbx/useSda";

export const sdaAPITableColumns: ColumnDef<SdaData>[] = [
  {
    accessorKey: "name",
    header: "Nom de la règle",
    meta: {
      headerProps: { style: { width: "6%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <a
        href={`/ipbx/gestion-des-appels/regles-entrantes/details/${row.original.id}`}
        className="text-primary"
      >
        {row.getValue("name")}
      </a>
    )
  },
  {
    accessorKey: "sda",
    header: "SDA",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorFn: row => row.idSchedule,
    id: "idSchedule",
    header: "Horaires de bureau",
    cell: ({ row: { original } }) => {
      const scheduleData = useScheduleData();
      const schedule = scheduleData.find(
        schedule => schedule.id === original.idSchedule
      );
      return schedule ? schedule.name : "Horaires de bureau non trouvé";
    },
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "destination",
    header: "Destination Principale",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "destinationData",
    header: "Numéro de destination",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "ringDuration",
    header: "Nombre de sonneries",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "nrDestination",
    header: "Dest sur non réponse",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "nrDestinationData",
    header: "nr dest sur non réponse",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "Activé",
    cell: ({ row: { original } }) => (
      <Badge
        bg={original.state ? "success" : "secondary"}
        variant="phoenix"
        iconPosition="end"
        className="fs-10"
      >
        {original.state ? "Actif" : "Inactif"}
      </Badge>
    ),
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      // Définition des états pour la modal d'édition et de suppression
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);

      // Fonctions pour gérer les clics des actions
      const handleDeleteClick = () => setShowDeleteModal(true);
      const handleEditClick = () => setShowEditModal(true);

      const handleActionConfirm = async (type: "delete" | "edit") => {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");

        const headers: HeadersInit = {
          accept: "text/plain",
          Authorization: `Bearer ${token}`
        };

        // Add 'Content-Type' header only if `type` is 'edit'
        if (type === "edit") {
          headers["Content-Type"] = "application/json";
        }

        const response = await fetch(
          `https://rct-backend.ipercom.io/api/Ipbx/sda/${original.id}`,
          {
            method: type === "delete" ? "DELETE" : "PUT",
            headers,
            body: type === "edit" ? JSON.stringify(original) : undefined
          }
        );

        if (response.ok) window.location.reload();
        else throw new Error(`HTTP error! status: ${response.status}`);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={handleEditClick}>Modifier</Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteClick}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Modal de confirmation de suppression */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cette règle entrante ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button
                variant="danger"
                onClick={() => handleActionConfirm("delete")}
              >
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal d'édition */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier la règle SDA</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SdaForm
                initialData={original} // Données de la règle sélectionnée
                tableData={[]} // Mettez à jour cette prop si nécessaire
                setTableData={() => {
                }} // Fonction de mise à jour des données
                onClose={() => setShowEditModal(false)}
              />
            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*  <Button variant="secondary" onClick={() => setShowEditModal(false)}>Annuler</Button>*/}
            {/*</Modal.Footer>*/}
          </Modal>
        </>
      );
    }
  }
];

const SdaTableAPI = () => (
  <div>
    <AdvanceTable
      tableProps={{ className: "phoenix-table fs-9 text-center" }}
    />
    <AdvanceTableFooter pagination />
  </div>
);

export default SdaTableAPI;
