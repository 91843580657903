import { Button, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilm,
  faCog,
  faKeyboard,
  faEnvelope,
  faMusic,
  faMicrophone
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const ParamSystem = () => {
  return (
    <>
      <div className="mb-5">
        <div className="g-3 row">
          <div className="col-xl-12 gy-3">
            <Nav className="" style={{ width: '90%' }}>
              <div className="d-flex flex-wrap">
                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/parametres/call-parking-config"
                >
                  <FontAwesomeIcon
                    icon={faFilm}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Parking d’appel
                  </span>
                </Button>

                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/parametres/conference"
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Conférence
                  </span>
                </Button>

                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/parametres/shortcut-codes"
                >
                  <FontAwesomeIcon
                    icon={faKeyboard}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Code de raccourcis
                  </span>
                </Button>

                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/parametres/email"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Email
                  </span>
                </Button>

                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/parametres/general"
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Général
                  </span>
                </Button>

                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/parametres/hold-music"
                >
                  <FontAwesomeIcon
                    icon={faMusic}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Musique d’attente
                  </span>
                </Button>

                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/parametres/voice-instructions"
                >
                  <FontAwesomeIcon
                    icon={faMicrophone}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Directives vocales
                  </span>
                </Button>

                <Button
                  className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link me-3 mb-3"
                  href="/ipbx/gestion-des-appels/musique-d-attente"
                >
                  <FontAwesomeIcon
                    icon={faMicrophone}
                    className="category-icon text-800 fs-6"
                  />
                  <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                    Messagerie vocale
                  </span>
                </Button>
              </div>
            </Nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParamSystem;
