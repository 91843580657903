import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "providers/AuthProvider";
import { Spinner } from "react-bootstrap";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRoles }) => {
  const { isAuthenticated, config, loading } = useAuthContext();
  const [delayedRedirect, setDelayedRedirect] = useState(false);

  // Démarre un timer pour un délai avant la redirection vers Access Denied
  useEffect(() => {
    if (config.role && !requiredRoles.includes(config.role)) {
      const timer = setTimeout(() => {
        setDelayedRedirect(true);
      }, 1000); // 1 seconde de délai avant la redirection

      return () => clearTimeout(timer);
    }
  }, [config.role, requiredRoles]);

  // Affiche un spinner pendant le chargement
  if (loading || !config.role) {
    return <Spinner animation="border" />;
  }

  // Redirige vers la page de connexion si l'utilisateur n'est pas authentifié
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Vérifie si l'utilisateur a les rôles nécessaires pour accéder à cette page
  const userHasRequiredRole = requiredRoles.includes(config.role);

  // Si l'utilisateur n'a pas les droits nécessaires, attend avant la redirection
  if (!userHasRequiredRole) {
    if (!delayedRedirect) {
      return <Spinner animation="border" />; // Affiche un spinner pendant le délai
    }
    return <Navigate to="/accessDenied" replace />;
  }

  // Si tout est correct, rend le composant enfant
  return <>{children}</>;
};

export default ProtectedRoute;
