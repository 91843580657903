import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
// import { UserData } from 'hooks/api/security/useEditorUser';
import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal
} from "react-bootstrap";
import UserEditForm from "../../editor/user/UserEditForm";
import axios from "axios";
import { UserData } from "../../../../../types/user";

export const userAPITableColumns: ColumnDef<UserData>[] = [
  {
    id: "name",
    header: "Nom & Prénom",
    cell: ({ row: { original } }) => {
      const { firstname, lastname } = original;
      const avatarUrl = `https://ui-avatars.com/api/?name=${firstname}+${lastname}`;
      return (
        <div className="d-flex align-items-center">
          <Avatar src={avatarUrl} size="m" className="me-3" />
          <span>
            {firstname} {lastname}
          </span>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9 d-flex align-items-center" }
    }
  },
  {
    accessorKey: "mail",
    header: "Email",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "phone",
    header: "Téléphone",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "userRole.name",
    header: "Statut",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "company.name",
    header: "Société",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedUser, setSelectedUser] = useState<UserData | null>(null); // Fix the selectedUser state

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (user: UserData) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
      };

      const handleEditClick = (user: UserData) => {
        setSelectedUser(user);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedUser) {
          await handleDelete(selectedUser.idUsers);
          setShowDeleteModal(false);
        }
      };

      const handleEditSave = async (updatedUser: UserData) => {
        try {
          const token = localStorage.getItem("token"); // Use token from local storage

          // Prepare the user data for the PUT request
          const userPayload = {
            idUsers: updatedUser.idUsers,
            firstname: updatedUser.firstname || "", // Default to empty string if null
            lastname: updatedUser.lastname || "", // Default to empty string if null
            mail: updatedUser.mail || "", // Ensure email is non-null
            phone: updatedUser.phone || "", // Default to empty string
            mobile: updatedUser.mobile || "", // Default to empty string
            avatar: updatedUser.avatar || "", // Default to empty string
            password: updatedUser.password || "", // Default to empty string
            // idUsersRoles: updatedUser.idUsersRoles || null, // Ensure a valid role id is provided
            // idCompanies: updatedUser.idCompanies || null, // Default to company id 1 if not provided
            erpThiersId: updatedUser.erpThiersId || null, // Default to 0 if not provided
            erpUserId: updatedUser.erpUserId || null, // Default to 0 if not provided
            erpUserToken: updatedUser.erpUserToken || null, // Default to empty string
            idInstance: updatedUser.idInstance || null, // Default to instance id 1
            status: updatedUser.status || "active", // Default status to active if not provided
            placeholder: updatedUser.placeholder || "", // Default to empty string
            moduleAccess: updatedUser.moduleAccess.length > 0 ? updatedUser.moduleAccess : [], // Ensure moduleAccess is not null
            features: updatedUser.features.length > 0 ? updatedUser.features : [] // Ensure features is not null
          };

          // Make the PUT request with the full user data in the body
          const response = await axios.put(
            `https://rct-backend.ipercom.io/api/User`,
            userPayload,
            {
              headers: {
                accept: "text/plain",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}` // Use token for authorization
              }
            }
          );

          if (response.status === 200 || response.status === 204) {
            console.log("User updated successfully.");
            window.location.reload(); // Reload page after success
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error updating user:", error);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/User/${id}`,
            {
              method: "DELETE",
              headers: {
                accept: "text/plain"
              }
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la suppression de l'utilisateur",
            error
          );
        }
      };

      return (
        <>
          <Dropdown
            show={dropdownOpen}
            onToggle={toggle}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <DropdownToggle
              as="div"
              onClick={toggle}
              style={{ cursor: "pointer" }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(original)}>
                Modifier
              </DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(original)}>
                Supprimer
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cet utilisateur ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedUser && (
                <UserEditForm
                  user={selectedUser} // Pass the selected user to the form
                  onSave={handleEditSave}
                  onCancel={() => setShowEditModal(false)}
                />
              )}
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

const RetailerUserTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9 text-center" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RetailerUserTable;
