import useFinalClient from "hooks/api/ipbx/useFinalClient";
import { MsgVocalData } from "hooks/api/ipbx/useMsgVocal";
import React, { ChangeEvent, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

type MsgVocalFormProps = {
  tableData: MsgVocalData[];
  setTableData: React.Dispatch<React.SetStateAction<MsgVocalData[]>>;
  onClose: () => void;
};

const AddMsgVocalForm: React.FC<MsgVocalFormProps> = ({ tableData, setTableData, onClose }) => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const [newMsgVocal, setNewMsgVocal] = useState<MsgVocalData>({
    id: 0,
    idClient: clientFinalId,
    name: "",
    state: true
  });

  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const parsedValue = name === "idClient" ? parseInt(value, 10) : value;

    setNewMsgVocal(prevMsgVocal => ({
      ...prevMsgVocal,
      [name]: parsedValue
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (file) {
      if (file.size > maxSize) {
        setFileError("La taille du fichier doit être inférieure à 5 Mo.");
        setAudioFile(null);
      } else if (!file.name.endsWith(".wav")) {
        setFileError("Seuls les fichiers .wav sont autorisés.");
        setAudioFile(null);
      } else {
        setFileError(null);
        setAudioFile(file);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (fileError || !audioFile) {
      setFileError("Veuillez sélectionner un fichier valide avant de soumettre.");
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error("Token non trouvé");
      }

      const formData = new FormData();
      formData.append("Id", newMsgVocal.id.toString());
      formData.append("IdClient", newMsgVocal.idClient.toString());
      formData.append("Name", newMsgVocal.name);
      formData.append("State", newMsgVocal.state.toString());
      formData.append("File", audioFile);

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/msgvocal', {
        method: 'POST',
        headers: {
          'accept': 'text/plain',
          "Authorization": `Bearer ${token}`
        },
        body: formData
      });

      if (response.ok) {
        const data: MsgVocalData = await response.json();
        setTableData([...tableData, data]);
        onClose();
      } else {
        throw new Error(`Erreur HTTP ! statut : ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un message vocal", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="state" className="align-items-center mb-2">
        <Form.Label>État de l'audio</Form.Label>
        <div className="d-flex align-items-center">
          <Form.Switch
            name="state"
            checked={newMsgVocal.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewMsgVocal({
                ...newMsgVocal,
                state: e.target.checked
              })
            }
            className="me-2"
          />
          <span>{newMsgVocal.state ? "Actif" : "Inactif"}</span>
        </div>
      </Form.Group>

      <Form.Group controlId="name" className="mb-3">
        <Form.Label>Nom de l'audio</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newMsgVocal.name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group controlId="url" className="mb-3">
        <Form.Label>
          Télécharger (format RIFF .wav linéaire 16 bits 8kHz mono) <Link to="https://online-audio-converter.com">Convertir
          ici</Link>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Uploader un fichier audio en .wav, max 5 Mo, format 16 bits 8kHz mono.</Tooltip>}
          >
            <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer", marginLeft: "4px" }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="file"
          accept=".wav"
          onChange={handleFileChange}
          required
        />
        {fileError && <div className="text-danger">{fileError}</div>}
      </Form.Group>

      <Button variant="primary" type="submit" disabled={!audioFile || !!fileError}>
        Ajouter
      </Button>
    </Form>
  );
};

export default AddMsgVocalForm;
