import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import axios from 'axios';
import iconSilver from 'assets/img/iper-x/ranks/icons/Silver.png';
import iconGold from 'assets/img/iper-x/ranks/icons/Gold.png';
import iconPlatinium from 'assets/img/iper-x/ranks/icons/Platinium.png';

// Define the AppAccess interface with an additional link property
interface AppAccess {
  img: string;
  title: string;
  width?: string;
  link: string; // Add link property
}

// Fallback data with unique links for each application
const fallbackApps: AppAccess[] = [
  { img: iconPlatinium, title: "Ipercom", width: "30", link: "https://ipercom.com/" },
  {
    img: iconPlatinium,
    title: "Ipos",
    width: "30",
    link: "https://ipercom.com/services/services-proposes/logiciel-et-application-de-caisse"
  },
  {
    img: iconPlatinium,
    title: "Iper-cloud",
    width: "30",
    link: "https://ipercom.com/services/services-proposes/solution-iper-cloud"
  },
  { img: iconPlatinium, title: "Webrtc", width: "30", link: "https://phone.iper-x.com/" },
  { img: iconPlatinium, title: "Iper-print", width: "30", link: "https://iperprint.vercel.app/login" },
  { img: iconPlatinium, title: "Iper-call", width: "30", link: "https://ipercall.vercel.app/login" }
];

const NineDotMenu: React.FC = () => {
  const [apps, setApps] = useState<AppAccess[]>(fallbackApps);
  const [error, setError] = useState<boolean>(false);

  // Uncomment and modify this section if API integration is needed
  /*
  useEffect(() => {
    axios.get('/api/AppsAccess')
      .then(response => {
        const fetchedApps: AppAccess[] = response.data.map((app: any) => ({
          img: getAppIcon(app.title),
          title: app.title,
          width: '0',
          link: `/app/${app.title.toLowerCase()}` // Generate a link based on app title or adjust as needed
        }));
        setApps(fetchedApps);
        setError(false);
      })
      .catch(() => {
        setApps(fallbackApps);
        setError(true);
      });
  }, []);
  */

  // Function to determine the appropriate icon based on app title
  const getAppIcon = (title: string): string => {
    switch (title) {
      case 'Ipercom':
        return iconPlatinium;
      case 'Iperx':
        return iconGold;
      case 'Ipos':
        return iconSilver;
      case 'Iper-cloud':
        return iconPlatinium;
      case 'Webrtc':
        return iconPlatinium;
      case "Iper-print":
        return iconPlatinium;
      case "Iper-call":
        return iconPlatinium;
      default:
        return iconPlatinium;
    }
  };

  return (
    <Dropdown.Menu
      align="end"
      className="navbar-dropdown-caret py-0 dropdown-nine-dots shadow border border-300"
    >
      <Card
        className="position-relative border-0"
        style={{ height: '20rem', minWidth: 244 }}
      >
        <Scrollbar>
          <Card.Body className="pt-3 px-3 pb-0">
            <Row className="text-center align-items-center g-0">
              {apps.map(app => (
                <Col xs={4} key={app.title}>
                  <Link
                    to={app.link} // Use each app's unique link here
                    className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3"
                  >
                    <img
                      src={app.img}
                      alt={app.title}
                      width={app.width}
                    />
                    <p className="mb-0 text-black text-truncate fs-10 mt-1 pt-1">
                      {app.title}
                    </p>
                  </Link>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Scrollbar>
      </Card>
    </Dropdown.Menu>
  );
};

export default NineDotMenu;
