import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import SortableItem from "../../pages/apps/ipbx/gestion-des-appels/queue/SortableItem";

import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";


type Member = {
  id: string;
  name: string;
  type: "internal" | "external";
};

type PhoneUser = {
  id: number;
  firstname: string;
  lastname: string;
};

type MembersFieldProps = {
  members: Member[];
  setMembers: React.Dispatch<React.SetStateAction<Member[]>>;
  phoneUserData: PhoneUser[];
  isEditMode: boolean;
};

const MembersField: React.FC<MembersFieldProps> = ({
                                                     members,
                                                     setMembers,
                                                     phoneUserData,
                                                     isEditMode
                                                   }) => {
  const [externalInput, setExternalInput] = useState("");
  const [availablePhoneUsers, setAvailablePhoneUsers] = useState<PhoneUser[]>(
    []
  );

  useEffect(() => {
    setAvailablePhoneUsers(
      phoneUserData.filter(
        user => !members.some(member => member.id === user.id.toString())
      )
    );
  }, [phoneUserData, members]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = members.findIndex(member => member.id === active.id);
      const newIndex = members.findIndex(member => member.id === over.id);
      setMembers(arrayMove(members, oldIndex, newIndex));
    }
  };

  const handleAddInternalUser = (userId: number) => {
    const selectedUser = availablePhoneUsers.find(user => user.id === userId);
    if (!selectedUser) return;

    const newInternalMember: Member = {
      id: userId.toString(),
      name: `${selectedUser.firstname} ${selectedUser.lastname}`,
      type: "internal"
    };

    setMembers(prevMembers => [...prevMembers, newInternalMember]);
    setAvailablePhoneUsers(prev => prev.filter(user => user.id !== userId));
  };

  const handleAddExternal = () => {
    if (!externalInput || externalInput.trim() === "") {
      alert("Le numéro externe ne peut pas être vide.");
      return;
    }

    const exists = members.some(member => member.id === externalInput);
    if (exists) {
      alert("Ce numéro est déjà ajouté.");
      return;
    }

    const newExternalMember: Member = {
      id: externalInput,
      name: externalInput,
      type: "external"
    };

    setMembers(prevMembers => [...prevMembers, newExternalMember]);
    setExternalInput("");
  };

  const handleDeleteMember = (memberId: string) => {
    const member = members.find(m => m.id === memberId);
    if (!member) return;

    if (member.type === "internal") {
      const removedUser = phoneUserData.find(
        user => user.id.toString() === memberId
      );
      if (removedUser) {
        setAvailablePhoneUsers(prev => [...prev, removedUser]);
      }
    }

    setMembers(prevMembers => prevMembers.filter(m => m.id !== memberId));
  };

  return (
    <div>
      <Form.Group controlId="internalUsers">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Ajoutez des utilisateurs internes ou des numéros externes à la
              liste.
            </Tooltip>
          }
        >
          <Form.Label>
            Membres de la file d'attente{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Row className="align-items-end">
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Utilisateur interne</Form.Label>
              <Form.Control
                as="select"
                onChange={e => handleAddInternalUser(Number(e.target.value))}
                value=""
              >
                <option value="">Sélectionner un utilisateur interne</option>
                {availablePhoneUsers.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.firstname} {user.lastname}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Numéro externe</Form.Label>
              <InputGroup>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Entrez un numéro externe d'au moins 10 chiffres sans `+`.
                    </Tooltip>
                  }
                >
                  <Form.Control
                    type="text"
                    placeholder="33518222200"
                    value={externalInput}
                    onChange={e => setExternalInput(e.target.value)}
                    minLength={10}
                    maxLength={15}
                  />
                </OverlayTrigger>
                <Button
                  variant="outline-success"
                  onClick={handleAddExternal}
                  disabled={!externalInput.trim()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Form.Group>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={members.map(m => m.id)}>
          <ul className="list-group mt-3">
            {members.length === 0 ? (
              <p className="text-muted">Aucun membre ajouté.</p>
            ) : (
              members.map(member => (
                <SortableItem
                  key={member.id}
                  id={member.id}
                  name={member.name}
                  onDelete={handleDeleteMember}
                />
              ))
            )}
          </ul>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default MembersField;
