import React, { ChangeEvent, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import RetailerUsersTable, { RetailerUsersColumn } from "pages/apps/editor/retailer/RetailerUsersTable";
import useRetailerGroupMembers from "../../../../hooks/dolibarr/useRetailerGroupMembers";

// Assuming the Member interface expects the 'stage' property to be of type { label: string; value: number }
const getStageValue = (stage: string): { label: string; value: number } => {
  // You can create a mapping of stages to corresponding numeric values
  const stages: { [key: string]: number } = {
    "Stage 1": 1,
    "Stage 2": 2,
    "Stage 3": 3
    // Add more stages as needed
  };

  return {
    label: stage,
    value: stages[stage] || 0 // Default to 0 if stage doesn't match
  };
};

const RetailerUsersList: React.FC = () => {
  const { group, loading, error } = useRetailerGroupMembers();

  // Ensure data is mapped correctly from GroupMember to Member format
  const memberData = useMemo(() => {
    return group?.members.map((member) => ({
      ...member,
      mobile: member.mobile || "Unknown Mobile", // Ensure mobile is always a string or number
      stage: getStageValue(member.stage || "Unknown Stage"), // Convert stage into the correct format
      revenue: member.revenue?.toString() || "0" // Convert revenue to a string
    })) || [];
  }, [group]);

  const table = useAdvanceTable({
    data: memberData,
    columns: RetailerUsersColumn,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [showAddRetailerModal, setShowAddRetailerModal] = useState(false);

  const [newRetailer, setNewRetailer] = useState({
    COMPAGNIE: '',
    EMAIL: '',
    'NUMÉRO DE TÉLÉPHONE': '',
    VILLE: '',
    SIRET: ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewRetailer(prevRetailer => ({
      ...prevRetailer,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNewRetailer({
      COMPAGNIE: '',
      EMAIL: '',
      'NUMÉRO DE TÉLÉPHONE': '',
      VILLE: '',
      SIRET: ''
    });
    setShowAddRetailerModal(false);
  };

  return (
    <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
      <div>
        <div className="mb-1">
          <AdvanceTableProvider {...table}>
            <h2 className="mb-3 mt-3">Revendeur</h2>
            <div className="mb-4">
              <Row className="g-3">
                <Col xs="auto">
                  <SearchBox
                    placeholder="Rechercher par nom"
                    onChange={handleSearchInputChange}
                  />
                </Col>
                <Col
                  xs="auto"
                  className="scrollbar overflow-hidden-y flex-grow-1"
                ></Col>
                <Col xs="auto">
                  <Button variant="link" className="text-900 me-4 px-0">
                    <FontAwesomeIcon
                      icon={faFileExport}
                      className="fs-9 me-2"
                    />
                    Export
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => setShowAddRetailerModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Nouveau revendeur
                  </Button>
                </Col>
              </Row>
            </div>

            {/* Conditionally show loading, error, or the table */}
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {!loading && !error && <RetailerUsersTable />}
          </AdvanceTableProvider>
        </div>
      </div>

      {/* Modal pour ajouter un nouveau revendeur */}
      <Modal
        show={showAddRetailerModal}
        onHide={() => setShowAddRetailerModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nouveau revendeur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Form.Group controlId="COMPAGNIE">
              <Form.Label>COMPAGNIE</Form.Label>
              <Form.Control
                type="text"
                name="COMPAGNIE"
                value={newRetailer.COMPAGNIE}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="EMAIL">
              <Form.Label>EMAIL</Form.Label>
              <Form.Control
                type="email"
                name="EMAIL"
                value={newRetailer.EMAIL}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="NUMÉRO DE TÉLÉPHONE">
              <Form.Label>NUMÉRO DE TÉLÉPHONE</Form.Label>
              <Form.Control
                type="tel"
                name="NUMÉRO DE TÉLÉPHONE"
                value={newRetailer['NUMÉRO DE TÉLÉPHONE']}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="VILLE">
              <Form.Label>VILLE</Form.Label>
              <Form.Control
                type="text"
                name="VILLE"
                value={newRetailer.VILLE}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="SIRET">
              <Form.Label>SIRET</Form.Label>
              <Form.Control
                type="text"
                name="SIRET"
                value={newRetailer['SIRET']}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-4 mb-4">
              Ajouter
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RetailerUsersList;
