import { useEffect, useState } from "react";

interface GroupMember {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  name: string;
  city: string;
  mobile: string | number;
  stage: string;
  revenue: number;
  joined: string;
}

interface GroupInfo {
  id: number;
  name: string;
  members: GroupMember[];
}

interface ApiGroupMember {
  id: number;
  firstname: string;
  lastname: string;
  email?: string;
}

interface ApiGroupInfo {
  id: number;
  nom: string;
  members: ApiGroupMember[];
}

const useRetailerGroupMembers = () => {
  const [group, setGroup] = useState<GroupInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

    if (!DOLAPIKEY) {
      setError('API key not found');
      return;
    }

    const fetchGroupMembers = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          'https://erp.ipercom.io/api/index.php/users/groups/5',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: DOLAPIKEY || '', // Ensure we don't pass null
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status}`);
        }

        const groupData: ApiGroupInfo = await response.json();

        // Check if the response contains members array
        if (groupData && Array.isArray(groupData.members)) {
          const formattedMembers = groupData.members.map((member: ApiGroupMember) => ({
            id: member.id,
            firstname: member.firstname,
            lastname: member.lastname,
            email: member.email || 'No email provided',
            name: `${member.firstname} ${member.lastname}`,
            city: "Unknown City", // Default value
            mobile: "Unknown Mobile", // Default value, should match the `string | number` type
            stage: "Unknown Stage", // Default value
            revenue: 0, // Default value
            joined: "N/A" // Default value
          }));

          setGroup({
            id: groupData.id,
            name: groupData.nom,
            members: formattedMembers,
          });
        } else {
          throw new Error('Invalid group data format');
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchGroupMembers();
  }, []);

  return { group, loading, error };
};

export default useRetailerGroupMembers;
