import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger
} from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import { ScheduleData } from "hooks/api/ipbx/useSchedule";
import React, { ChangeEvent, useState } from "react";
import {
  Dropdown,
  Button,
  Modal,
  Form,
  Col,
  Row,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import TimeSelector from "./TimeSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export const scheduleAPITableColumns: ColumnDef<ScheduleData>[] = [
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // {
  //   accessorFn: (row) => row.idClient,
  //   id: 'idClient',
  //   header: 'Client',
  //   cell: ({ row: { original } }) => {
  //     const clientData = useClientData();
  //     const { idClient } = original;
  //     const client = clientData.find(client => client.id === idClient);
  //     return client ? client.companyName : "Client non trouvé";
  //   },
  //   meta: {
  //     headerProps: { style: { width: "12%", fontSize: "11px", fontWeight: "normal" } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  {
    accessorKey: "startSlot1",
    header: "matin ouverture",
    meta: {
      headerProps: {
        style: { width: "20%", fontSize: "12px", fontWeight: "normal" }
      },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: "endSlot1",
    header: "matin fermeture",
    meta: {
      headerProps: {
        style: { width: "20%", fontSize: "12px", fontWeight: "normal" }
      },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "startSlot2",
    header: "après-midi ouverture",
    meta: {
      headerProps: {
        style: { width: "20%", fontSize: "12px", fontWeight: "normal" }
      },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "endSlot2",
    header: "après-midi fermeture",
    meta: {
      headerProps: {
        style: { width: "20%", fontSize: "12px", fontWeight: "normal" }
      },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "days",
    header: "Jours",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "Activé",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedSchedule, setSelectedSchedule] =
        useState<ScheduleData | null>(null);
      const [formData, setFormData] = useState<ScheduleData | null>(null);
      const [selectedDays, setSelectedDays] = useState<string[]>([]);
      const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"));
      const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));


      const handleSaveChanges = async () => {
        if (formData) {
          const updatedFormData = {
            ...formData,
            days: selectedDays.join(",") // Convert selected days to a string
          };

          try {
            const token = localStorage.getItem("token");
            if (!token) {
              throw new Error("Token not found");
            }
            const response = await fetch(
              "https://rct-backend.ipercom.io/api/Ipbx/schedule",
              {
                method: "PUT",
                headers: {
                  accept: "text/plain",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(updatedFormData)
              }
            );

            if (response.ok) {
              setShowEditModal(false);
              window.location.reload(); // Optionally reload or update the UI as needed
            } else {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          } catch (error) {
            console.error("Error saving schedule data", error);
          }
        }
      };

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (schedule: ScheduleData) => {
        setSelectedSchedule(schedule);
        setShowDeleteModal(true);
      };

      const handleEditClick = (schedule: ScheduleData) => {
        setSelectedSchedule(schedule);
        setFormData(schedule);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedSchedule) {
          await handleDelete(selectedSchedule.id);
          setShowDeleteModal(false);
        }
      };

      const daysOfWeek = [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche"
      ];

      const handleDaysChange = (day: string) => {
        setSelectedDays(prevDays => {
          if (prevDays.includes(day)) {
            return prevDays.filter(d => d !== day);
          } else {
            return [...prevDays, day];
          }
        });
      };

      const isDaySelected = (day: string) => selectedDays.includes(day);

      // Remplir selectedDays avec les jours déjà sélectionnés lors de l'édition
      React.useEffect(() => {
        if (formData) {
          setSelectedDays(formData.days ? formData.days.split(',') : []);
        }
      }, [formData]);

      const handleEditConfirm = async () => {
        if (formData) {
          const updatedFormData = {
            ...formData,
            days: formData.days || "" // Provide a default empty string if days is undefined
          };

          await handleEdit(updatedFormData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Ipbx/schedule/${id}`,
            {
              method: "DELETE",
              headers: {
                accept: "text/plain",
                Authorization: `Bearer ${token}`
              }
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la suppression de l'horaire de bureau",
            error
          );
        }
      };

      const handleEdit = async (data: ScheduleData) => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const { id, ...dataWithoutId } = data;
          const response = await fetch(
            "https://rct-backend.ipercom.io/api/Ipbx/schedule",
            {
              method: "PUT",
              headers: {
                accept: "text/plain",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify(data)
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la mise à jour de l'horaire de bureau",
            error
          );
        }
      };

      const formatTime = (time: string) => {
        const parts = time.split(":");
        if (parts.length === 3) {
          return time;
        } else if (parts.length === 2) {
          return `${time}:00`;
        }
        return time;
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const formattedValue =
          name.startsWith("startSlot") || name.startsWith("endSlot")
            ? formatTime(value)
            : value;

        setFormData(prevData =>
          prevData
            ? {
              ...prevData,
              [name]: type === "checkbox" ? checked : formattedValue
            }
            : null
        );
      };
      const handleTimeChange = (name: string, value: string) => {
        setFormData(prevData =>
          prevData
            ? {
              ...prevData,
              [name]: formatTime(value)
            }
            : null
        );
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer l'horaire ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modal pour modifier l'horaire */}

          {/* Edit Modal with identical layout to AddScheduleForm */}
          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'horaire</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* Schedule State Toggle */}
                <Form.Group
                  controlId="state"
                  className="align-items-center mb-2"
                >
                  <Form.Label>État du horaire :</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Switch
                      name="state"
                      checked={formData?.state || false}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFormData(prev => prev ? { ...prev, state: e.target.checked } : null)
                      }
                      className="me-2"
                    />
                    <span>{formData?.state ? "Actif" : "Inactif"}</span>
                  </div>
                </Form.Group>

                {/* Name Input */}
                <Form.Group controlId="name" className="mb-2">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData?.name || ""}
                    onChange={e =>
                      setFormData(prev => prev ? { ...prev, name: e.target.value } : null)
                    }
                    required
                  />
                </Form.Group>

                {/* Morning Schedule */}
                <Row className="mb-3">
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip>
                        Sélectionnez les heures d'ouverture et de fermeture pour
                        le matin
                      </Tooltip>
                    }
                  >
                    <h5 className="m-1 fs-9 text-uppercase">
                      Matin{" "}
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ cursor: "pointer", marginLeft: "4px" }}
                      />
                    </h5>
                  </OverlayTrigger>
                  <Col>
                    <Form.Group controlId="startSlot1">
                      <Form.Label>Ouverture</Form.Label>
                      <div className="d-flex">
                        <Form.Select
                          value={formData?.startSlot1.split(":")[0] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "startSlot1",
                              `${e.target.value}:${formData?.startSlot1.split(":")[1] || "00"}`
                            )
                          }
                          required
                        >
                          <option value="">Heure</option>
                          {hours.map(hour => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={formData?.startSlot1.split(":")[1] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "startSlot1",
                              `${formData?.startSlot1.split(":")[0] || "00"}:${e.target.value}`
                            )
                          }
                          required
                        >
                          <option value="">Minute</option>
                          {minutes.map(minute => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="endSlot1">
                      <Form.Label>Fermeture</Form.Label>
                      <div className="d-flex">
                        <Form.Select
                          value={formData?.endSlot1.split(":")[0] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "endSlot1",
                              `${e.target.value}:${formData?.endSlot1.split(":")[1] || "00"}`
                            )
                          }
                          required
                        >
                          <option value="">Heure</option>
                          {hours.map(hour => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={formData?.endSlot1.split(":")[1] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "endSlot1",
                              `${formData?.endSlot1.split(":")[0] || "00"}:${e.target.value}`
                            )
                          }
                          required
                        >
                          <option value="">Minute</option>
                          {minutes.map(minute => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Afternoon Schedule */}
                <Row className="mb-3">
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip>
                        Sélectionnez les heures d'ouverture et de fermeture pour
                        l'après-midi
                      </Tooltip>
                    }
                  >
                    <h5 className="m-1 fs-9 text-uppercase">
                      Après-midi{" "}
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ cursor: "pointer", marginLeft: "4px" }}
                      />
                    </h5>
                  </OverlayTrigger>
                  <Col>
                    <Form.Group controlId="startSlot2">
                      <Form.Label>Ouverture</Form.Label>
                      <div className="d-flex">
                        <Form.Select
                          value={formData?.startSlot2.split(":")[0] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "startSlot2",
                              `${e.target.value}:${formData?.startSlot2.split(":")[1] || "00"}`
                            )
                          }
                          required
                        >
                          <option value="">Heure</option>
                          {hours.map(hour => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={formData?.startSlot2.split(":")[1] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "startSlot2",
                              `${formData?.startSlot2.split(":")[0] || "00"}:${e.target.value}`
                            )
                          }
                          required
                        >
                          <option value="">Minute</option>
                          {minutes.map(minute => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="endSlot2">
                      <Form.Label>Fermeture</Form.Label>
                      <div className="d-flex">
                        <Form.Select
                          value={formData?.endSlot2.split(":")[0] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "endSlot2",
                              `${e.target.value}:${formData?.endSlot2.split(":")[1] || "00"}`
                            )
                          }
                          required
                        >
                          <option value="">Heure</option>
                          {hours.map(hour => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Select
                          value={formData?.endSlot2.split(":")[1] || ""}
                          onChange={e =>
                            handleTimeChange(
                              "endSlot2",
                              `${formData?.endSlot2.split(":")[0] || "00"}:${e.target.value}`
                            )
                          }
                          required
                        >
                          <option value="">Minute</option>
                          {minutes.map(minute => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Day Selector */}
                <h5 className="m-1 fs-9 text-uppercase">Jour</h5>
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip>
                      Sélectionnez les jours pour lesquels cet horaire est actif
                    </Tooltip>
                  }
                >
                  <div className="d-flex mb-3">
                    {[
                      "Lundi",
                      "Mardi",
                      "Mercredi",
                      "Jeudi",
                      "Vendredi",
                      "Samedi",
                      "Dimanche"
                    ].map(day => (
                      <div
                        key={day}
                        onClick={() => handleDaysChange(day)}
                        style={{
                          width: "36px",
                          height: "36px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "0 4px",
                          cursor: "pointer",
                          backgroundColor: selectedDays.includes(day)
                            ? "#007bff"
                            : "#f8f9fa",
                          color: selectedDays.includes(day) ? "#fff" : "#000",
                          border: "1px solid #ddd"
                        }}
                      >
                        {day.slice(0, 1)}
                      </div>
                    ))}
                  </div>
                </OverlayTrigger>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Fermer
              </Button>
              <Button variant="primary" onClick={handleSaveChanges}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const ScheduleTableAPI = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9 text-center" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ScheduleTableAPI;
