import React, { ChangeEvent, useState } from "react";
import {
  Button,
  Col,
  Row,
  Modal
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import DevicesTable, { devicesTableColumns } from "./DevicesTable";
import useAdvanceTable from 'hooks/others/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import usePhoneDevicesDataID from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";
import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";
import DevicesForm from "./DevicesForm";

const DevicesList = () => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const { phoneDevicesData, setPhoneDevicesData, loading, error } = usePhoneDevicesDataID(clientFinalId);
  const table = useAdvanceTable({
    data: phoneDevicesData,
    columns: devicesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState<PhoneDevicesData | undefined>(undefined);


  // Handle opening the form for adding or editing
  const handleOpenForm = (device?: PhoneDevicesData) => {
    setCurrentDevice(device || undefined);
    setOpenFormModal(true);
  };

  const handleCloseForm = () => {
    setOpenFormModal(false);
    setCurrentDevice(undefined);
  };


  // Handle form submission
  const handleSaveDevice = (device: PhoneDevicesData) => {
    if (device.id) {
      // Editing existing device
      setPhoneDevicesData(prev =>
        prev.map(d => (d.id === device.id ? device : d))
      );
    } else {
      // Adding a new device
      setPhoneDevicesData(prev => [...prev, { ...device, id: Date.now() }]); // Mock ID for new device
    }
    setOpenFormModal(false);
  };


  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Téléphones</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <input
                type="text"
                className="form-control w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="primary" onClick={() => handleOpenForm()}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un téléphone
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <DevicesTable />
        </div>
      </AdvanceTableProvider>

      {/* DevicesForm Modal */}
      <Modal show={openFormModal} onHide={handleCloseForm} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentDevice ? "Modifier Appareil" : "Ajouter Appareil"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DevicesForm
            currentDevice={currentDevice}
            onSave={handleSaveDevice}
            onClose={handleCloseForm}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DevicesList;
