import React, { ChangeEvent, useState, useMemo } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport, faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import useSda from "hooks/api/ipbx/useSda";
import SdaTableAPI, { sdaAPITableColumns } from "./SdaTableAPI";
import LeadsFilterModal from "components/modals/LeadsFilterModal";
import SdaForm from "./SdaForm";
import { ClientProvider } from "data/iper-x/context/ClientContext";
import useFinalClient from "hooks/api/ipbx/useFinalClient";
import { ScheduleProvider } from "data/iper-x/context/ScheduleContext";
import useSchedule from "hooks/api/ipbx/useSchedule";
import useTrunk from "hooks/api/ipbx/useTrunk";
import { TrunkProvider } from "data/iper-x/context/TrunkContext";
import useSdaDataID from "hooks/api/ipbx/useSdaDataID";

const SdaListAPI = () => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;
  const { sdaData, setSdaData, loading, error } = useSdaDataID(clientFinalId);
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();
  const { scheduleData, loading: scheduleLoading, error: scheduleError } = useSchedule();
  const { trunkData, loading: trunkLoading, error: trunkError } = useTrunk();

  const [openFilterModal, setOpenFilterModal] = useState(false);

  // Initialize advance table hook with filtered SDA data
  const table = useAdvanceTable({
    data: sdaData,
    columns: sdaAPITableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <TrunkProvider trunkData={trunkData}>
      <ScheduleProvider scheduleData={scheduleData}>
        <ClientProvider clientData={clientData}>
          <div>
            <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
              <h2 className="mb-0">
                <span className="me-3">Règles entrantes</span>
              </h2>
            </div>
            <div className="mb-4">
              <Row className="g-3">
                <Col xs="auto">
                  <SearchBox
                    className="w-300"
                    placeholder="Rechercher"
                    onChange={handleSearchInputChange}
                  />
                </Col>
                <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
                <Col xs="auto">
                  <Button variant="primary" onClick={() => setShowAddClientModal(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Ajouter une règle entrante
                  </Button>
                  <LeadsFilterModal
                    show={openFilterModal}
                    handleClose={() => setOpenFilterModal(false)}
                  />
                </Col>
              </Row>
            </div>
            {loading && <p>Chargement des données...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {!loading && (
              <AdvanceTableProvider {...table}>
                <div
                  className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                  <SdaTableAPI />
                </div>
              </AdvanceTableProvider>
            )}
            <Modal show={showAddClientModal} onHide={() => setShowAddClientModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Ajouter une règle entrante</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <SdaForm
                  tableData={sdaData}
                  setTableData={setSdaData}
                  onClose={() => setShowAddClientModal(false)}
                />
              </Modal.Body>
            </Modal>
          </div>
        </ClientProvider>
      </ScheduleProvider>
    </TrunkProvider>
  );
};

export default SdaListAPI;
