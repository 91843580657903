import React, { useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Order } from "../../../../../../types/types";
import ClientModal from "./ClientModal"; // Import du nouveau composant

interface LicenseTableProps {
  order: Order;
  setOrder: (order: Order) => void;
}

const LicenseTable: React.FC<LicenseTableProps> = ({ order, setOrder }) => {
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState<
    number | null
  >(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState<
    number | null
  >(null);
  const [showClientModal, setShowClientModal] = useState(false);

  const handleAssignClient = (productIndex: number, licenseIndex: number) => {
    setSelectedProductIndex(productIndex);
    setSelectedLicenseIndex(licenseIndex);
    setShowClientModal(true);
  };

  const removeLicense = (licenseIndex: number, productIndex: number) => {
    const updatedItems = [...order.items];

    // Supprimer la licence sélectionnée
    const licenses = updatedItems[productIndex].licenses.filter(
      (_, i) => i !== licenseIndex
    );

    // Mettre à jour les licences du produit
    updatedItems[productIndex].licenses = licenses;

    // Mettre à jour la quantité du produit en fonction du nombre de licences restantes
    updatedItems[productIndex].qty = licenses.length.toString(); // Mettez à jour la quantité

    // Si toutes les licences sont supprimées, supprimez le produit
    if (licenses.length === 0) {
      updatedItems.splice(productIndex, 1); // Supprimer le produit si aucune licence n'est restante
    }

    // Mettre à jour la commande
    setOrder({ ...order, items: updatedItems });
  };


  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <h5>Récapitulatif de vos Licences et attribution Clients</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Ref</th>
              <th>Licence</th>
              <th>Nom</th>
              <th>Rôle</th>
              <th>Email</th>
              <th>Adresse</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {order.items.map((item, productIndex) =>
              item.licenses.map((license, licenseIndex) => (
                <tr key={license.id_license}>
                  <td>{license.id_license}</td>
                  <td>{license.license_key || "N/A"}</td>
                  <td>{license.client_final?.name || "N/A"}</td>
                  <td>{license.client_final?.position || "N/A"}</td>
                  <td>{license.client_final?.email || "N/A"}</td>
                  <td>{license.client_final?.address || "N/A"}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() =>
                        handleAssignClient(productIndex, licenseIndex)
                      }
                    >
                      Attribuer un client
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() =>
                        removeLicense(licenseIndex, productIndex)
                      }
                    >
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))
            )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {showClientModal &&
        selectedLicenseIndex !== null &&
        selectedProductIndex !== null && (
          <ClientModal
            order={order}
            setOrder={setOrder}
            selectedLicenseIndex={selectedLicenseIndex}
            selectedProductIndex={selectedProductIndex}
            onClose={() => setShowClientModal(false)}
          />
        )}
    </>
  );
};

export default LicenseTable;
