import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Button, Form, Modal, Alert, Col, Row } from 'react-bootstrap';

interface Order {
  socid: string;
  date: string;
  services: OrderLine[];
}

interface OrderLine {
  fk_product: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  duration?: string;
}

interface DictionaryItem {
  id: string;
  label: string;
}

interface Product {
  id: string;
  label: string;
  price: string;
  type: 'product' | 'service';
}

interface Category {
  id: string;
  label: string;
}

interface OrdersFormProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (order: Order) => void;
}

const OrdersForm: React.FC<OrdersFormProps> = ({
  show,
  handleClose,
  handleSubmit
}) => {
  const [order, setOrder] = useState<Order>({
    socid: '',
    date: new Date().toISOString().split('T')[0], // Initialize with current date
    services: [
      {
        fk_product: '',
        qty: '1',
        subprice: '',
        tva_tx: '20'
      }
    ]
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [thirdParties, setThirdParties] = useState<DictionaryItem[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [family, setFamily] = useState<string>('IPERX');
  const [simultaneousCalls, setSimultaneousCalls] = useState<string>('4AS');
  const [years, setYears] = useState<string>('1');
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  useEffect(() => {
    fetchThirdParties();
    fetchCategories();
  }, []);

  useEffect(() => {
    filterProducts();
  }, [family, simultaneousCalls, years, categories]);

  const fetchThirdParties = async () => {
    try {
      const response = await fetch(
        'https://erp.ipercom.io/api/index.php/thirdparties',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl'
          }
        }
      );
      const data = await response.json();
      setThirdParties(
        data.map((party: any) => ({ id: party.id, label: party.name }))
      );
    } catch (error) {
      console.error("Erreur lors de la récupération des données third parties:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        'https://erp.ipercom.io/api/index.php/categories',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl'
          }
        }
      );
      const data = await response.json();
      setCategories(
        data.map((category: any) => ({
          id: category.id,
          label: category.label
        }))
      );
    } catch (error) {
      console.error("Erreur lors de la récupération des données categories:", error);
    }
  };

  const fetchProducts = async (categoryId: string) => {
    try {
      const response = await fetch(
        `https://erp.ipercom.io/api/index.php/products?sortfield=t.ref&sortorder=ASC&limit=100&mode=2&category=${categoryId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl'
          }
        }
      );
      const data = await response.json();
      const fetchedProducts = data.map((product: any) => ({
        id: product.id,
        label: product.label,
        price: product.price,
        type: product.type
      }));
      setFilteredProducts(fetchedProducts);

      if (fetchedProducts.length === 1) {
        setOrder(prevOrder => ({
          ...prevOrder,
          services: [
            {
              ...prevOrder.services[0],
              fk_product: fetchedProducts[0].id,
              subprice: fetchedProducts[0].price
            }
          ]
        }));
      } else {
        setOrder(prevOrder => ({
          ...prevOrder,
          services: [
            {
              ...prevOrder.services[0],
              fk_product: '',
              subprice: ''
            }
          ]
        }));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données products:", error);
    }
  };

  const filterProducts = () => {
    const categoryFilter = `${family}${simultaneousCalls}${years}`;
    const category = categories.find(cat => cat.label.includes(categoryFilter));
    if (category) {
      fetchProducts(category.id);
    } else {
      setFilteredProducts([]);
      setOrder(prevOrder => ({
        ...prevOrder,
        services: [
          {
            ...prevOrder.services[0],
            fk_product: '',
            subprice: ''
          }
        ]
      }));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const { name, value } = e.target;
    if (name === 'socid' || name === 'date') {
      setOrder(prevOrder => ({
        ...prevOrder,
        [name]: value
      }));
    } else if (index !== undefined) {
      const updatedServices = [...order.services];
      if (name === 'fk_product') {
        const selectedProduct = products.find(product => product.id === value);
        if (selectedProduct) {
          updatedServices[index] = {
            ...updatedServices[index],
            fk_product: value,
            subprice: selectedProduct.price,
            tva_tx: '20'
          };
        }
      } else {
        updatedServices[index] = { ...updatedServices[index], [name]: value };
      }
      setOrder(prevOrder => ({
        ...prevOrder,
        services: updatedServices
      }));
    }
  };

  const handleFamilyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFamily(e.target.value);
  };

  const handleSimultaneousCallsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSimultaneousCalls(e.target.value);
  };

  const handleYearsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setYears(e.target.value);
  };

  const handleAcceptTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAcceptedTerms(e.target.checked);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!acceptedTerms) {
      setError('Vous devez accepter les conditions générales de vente.');
      return;
    }

    try {
      const response = await fetch(
        'https://erp.ipercom.io/api/index.php/orders',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            socid: order.socid,
            date: new Date(order.date).getTime() / 1000, // Convert to timestamp
            lines: order.services
          })
        }
      );

      if (response.ok) {
        setSuccess('Commande créée avec succès.');
        setError(null);
        setOrder({
          socid: '',
          date: new Date().toISOString().split('T')[0], // Reset to current date
          services: [
            {
              fk_product: '',
              qty: '1',
              subprice: '',
              tva_tx: '20'
            }
          ]
        });
        setAcceptedTerms(false);
        handleSubmit(order);
      } else {
        const errorResponse = await response.json();
        setError(
          `Erreur: ${errorResponse.error || "Une erreur s'est produite."}`
        );
        setSuccess(null);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(`Erreur: ${err.message}`);
      } else {
        setError('Erreur inconnue');
      }
      setSuccess(null);
    }
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter une commande</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="socid">
                <Form.Label>Tiers</Form.Label>
                <Form.Control
                  as="select"
                  name="socid"
                  value={order.socid}
                  onChange={handleChange}
                  required
                >
                  <option value="">Sélectionner un tiers</option>
                  {thirdParties.map(tp => (
                    <option key={tp.id} value={tp.id}>
                      {tp.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="date">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={order.date}
                  onChange={handleChange}
                  required
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group controlId="family">
                <Form.Label>Famille</Form.Label>
                <Form.Control
                  as="select"
                  name="family"
                  value={family}
                  // onChange={handleFamilyChange}
                >
                  <option value="IPERX">IPERX</option>
                  <option value="IPERCLOUD" disabled>
                    IPERCLOUD
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="simultaneousCalls">
                <Form.Label>Appels Simultanés</Form.Label>
                <Form.Control
                  as="select"
                  name="simultaneousCalls"
                  value={simultaneousCalls}
                  // onChange={handleSimultaneousCallsChange}
                >
                  {[
                    '4',
                    '8',
                    '16',
                    '24',
                    '32',
                    '40',
                    '48',
                    '50',
                    '100',
                    '150',
                    '200',
                    '250',
                    '300',
                    '350',
                    '400',
                    '450',
                    '500',
                    '550',
                    '600',
                    '650',
                    '700',
                    '750',
                    '800',
                    '850',
                    '900',
                    '950',
                    '1000'
                  ].map(call => (
                    <option key={call} value={`${call}AS`}>
                      {call} accès simultané
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="years">
                <Form.Label>Années</Form.Label>
                <Form.Control
                  as="select"
                  name="years"
                  value={years}
                  // onChange={handleYearsChange}
                >
                  <option value="1">1 an</option>
                  <option value="2">2 ans</option>
                  <option value="3">3 ans</option>
                  <option value="4">4 ans</option>
                  <option value="5">5 ans</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="fk_product">
            <Form.Label>Service</Form.Label>
            <Form.Control
              as="select"
              name="fk_product"
              value={order.services[0].fk_product}
              onChange={e => handleChange(e, 0)}
              required
              disabled={true}
            >
              <option value="">Sélectionner un service</option>
              {filteredProducts.map(product => (
                <option key={product.id} value={product.id}>
                  {product.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Row>
            <Col md={4}>
              <Form.Group controlId="qty">
                <Form.Label>Quantité</Form.Label>
                <Form.Control
                  type="number"
                  name="qty"
                  value={order.services[0].qty}
                  onChange={e => handleChange(e, 0)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="subprice">
                <Form.Label>Prix unitaire</Form.Label>
                <Form.Control
                  type="number"
                  name="subprice"
                  value={order.services[0].subprice}
                  onChange={e => handleChange(e, 0)}
                  required
                  disabled
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="tva_tx">
                <Form.Label>TVA (%)</Form.Label>
                <Form.Control
                  type="number"
                  name="tva_tx"
                  value={order.services[0].tva_tx}
                  onChange={e => handleChange(e, 0)}
                  required
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="acceptedTerms">
            <Form.Check
              type="checkbox"
              label="Accepter les conditions générales de vente"
              checked={acceptedTerms}
              onChange={handleAcceptTermsChange}
              required
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            className="mt-4"
            disabled={!acceptedTerms}
          >
            Valider
          </Button>
        </Form>
        <hr />
        <h5>Récapitulatif de la commande</h5>
        <Row>
          <Col>Tiers:</Col>
          <Col>
            {thirdParties.find(tp => tp.id === order.socid)?.label || 'N/A'}
          </Col>
        </Row>
        <Row>
          <Col>Date:</Col>
          <Col>{order.date}</Col>
        </Row>
        <Row>
          <Col>Service:</Col>
          <Col>
            {filteredProducts.find(p => p.id === order.services[0].fk_product)
              ?.label || 'N/A'}
          </Col>
        </Row>
        <Row>
          <Col>Quantité:</Col>
          <Col>{order.services[0].qty}</Col>
        </Row>
        <Row>
          <Col>Prix unitaire:</Col>
          <Col>{order.services[0].subprice}</Col>
        </Row>
        <Row>
          <Col>TVA (%):</Col>
          <Col>{order.services[0].tva_tx}</Col>
        </Row>
        <Row>
          <Col>Total HT:</Col>
          <Col>
            {(
              parseFloat(order.services[0].subprice) *
              parseFloat(order.services[0].qty)
            ).toFixed(2)}
          </Col>
        </Row>
        <Row>
          <Col>Total TTC:</Col>
          <Col>
            {(
              parseFloat(order.services[0].subprice) *
              parseFloat(order.services[0].qty) *
              (1 + parseFloat(order.services[0].tva_tx) / 100)
            ).toFixed(2)}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default OrdersForm;
