import { useState, useEffect } from 'react';

export interface Assignee {
  id: number;
  name: string;
  avatar?: string;
}

export interface Order {
  id: number;
  ref: string;
  tiers: string;
  date_commande: number;
  date_livraison: string;
  total_ht: number;
  author: string;
  status: string;
}

const useOrdersData = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

    const fetchOrders = async () => {
      try {
        const response = await fetch(
          'https://erp.ipercom.io/api/index.php/orders?sortfield=t.rowid&sortorder=ASC&limit=100',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: DOLAPIKEY || '',
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Orders API Response:', result);

        if (!Array.isArray(result)) {
          throw new Error('Invalid data format from API');
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Erreur lors de la récupération des données orders:", error.message);
          throw error;
        } else {
          console.error('Unexpected error:', error);
          throw new Error("Unexpected Erreur lors de la récupération des données orders");
        }
      }
    };

    const fetchUserById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/users/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const user = await response.json();
        console.log(`User ${id} API Response:`, user);
        return { id: user.rowid, name: `${user.firstname} ${user.lastname}`, avatar: user.avatar };
      } catch (error) {
        console.error(`Erreur lors de la récupération des données user ${id}:`, error);
        throw error;
      }
    };

    const fetchThirdPartyById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/thirdparties/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const thirdParty = await response.json();
        console.log(`Third Party ${id} API Response:`, thirdParty);
        return { id: thirdParty.id, name: thirdParty.name };
      } catch (error) {
        console.error(`Erreur lors de la récupération des données third party ${id}:`, error);
        throw error;
      }
    };

    const fetchData = async () => {
      try {
        const orders = await fetchOrders();
        const usersMap: { [key: number]: Assignee } = {};
        const thirdPartiesMap: { [key: number]: string } = {};

        for (const order of orders) {
          if (order.user_creation_id && !usersMap[order.user_creation_id]) {
            usersMap[order.user_creation_id] = await fetchUserById(order.user_creation_id);
          }
          if (order.socid && !thirdPartiesMap[order.socid]) {
            thirdPartiesMap[order.socid] = (await fetchThirdPartyById(order.socid)).name;
          }
        }

        console.log('User Map:', usersMap);
        console.log('Third Parties Map:', thirdPartiesMap);

        const ordersWithDetails: Order[] = orders.map((order: any) => {
          return {
            id: Number(order.id),
            ref: order.ref,
            tiers: thirdPartiesMap[order.socid] || 'Unknown Company',
            date_commande: order.date_commande,
            date_livraison: order.delivery_date,
            total_ht: parseFloat(order.total_ht),
            author: usersMap[order.user_creation_id]?.name || 'Unknown Author',
            status: order.statut
          };
        });

        setOrders(ordersWithDetails);
      } catch (error) {
        setError("Impossible de récupérer les données data. Please try again later.");
      }
    };

    fetchData();
  }, []);

  return { orders, error };
};

export default useOrdersData;
