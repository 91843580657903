import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge, { BadgeBg } from "components/base/Badge";
import { PhoneUserData } from "hooks/api/ipbx/usePhoneUser";
import React, { useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { formatDistanceToNow, isBefore, subMinutes } from "date-fns";
import { fr } from "date-fns/locale";
import Avatar from "components/base/Avatar";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import { useServerConfigData } from "data/iper-x/context/ServerConfigurationContext";
import { Link } from "react-router-dom";
import PhoneUserForm from "./PhoneUserForm";

export const phoneUserTableColumns: ColumnDef<PhoneUserData>[] = [
  {
    accessorFn: ({ lastActivity }) => lastActivity,
    id: 'status',
    header: 'Utilisateur',
    cell: ({ row: { original } }) => {
      const { lastActivity, firstname, lastname, extension, id } = original;

      const isOnline = isBefore(
        subMinutes(new Date(), 5),
        new Date(lastActivity ?? Date.now())
      );

      const avatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(firstname)}+${encodeURIComponent(lastname)}&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center ms-3">
          <Avatar
            src={avatar}
            size="m"
            className="me-1"
            status={isOnline ? "online" : "offline"}
          />
          <div className="d-flex flex-column ms-1">
            <Link to={`/ipbx/utilisateurs/details/${id}`} className="text-dark">
              <p className="mb-0 fw-bold">
                {firstname} {lastname}
              </p>
            </Link>
            {/*<p className="mb-0 fw-bold text-dark fs-8">*/}
            {/*  {firstname} {lastname}*/}
            {/*</p>*/}
            {/*<small className="text-muted">*/}
            {/*  {extension}*/}
            {/*</small>*/}
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "10%", fontSize: "11px" }
      },
      cellProps: { className: "ps-2" }
    }
  },
  // {
  //   accessorFn: (row) => row.idClient,
  //   id: 'idClient',
  //   header: 'Client',
  //   cell: ({ row: { original } }) => {
  //     const clientData = useClientData();
  //     const { idClient } = original;
  //     const client = clientData.find(client => client.id === idClient);
  //
  //     return client && client.companyName ? client.companyName : "Nom à renseigner";
  //   },
  //   meta: {
  //     headerProps: { style: { width: '8%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  {
    accessorKey: "extension",
    header: "Poste interne",
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayNumber",
    header: "Numéro Affiché",
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayName",
    header: "Nom Affiché",
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "userFunction",
    header: "Fonction",
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },

  {
    accessorKey: 'email',
    header: 'Email',
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'mobile',
    header: 'Mobile',
    cell: ({ getValue }) => {
      const mobile = getValue() as string | null;
      return mobile ? <a href={`tel:${mobile}`}>{mobile}</a> : null;
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'fixe',
    header: 'Fixe',
    cell: ({ getValue }) => {
      const fixe = getValue() as string | null;
      return fixe ? <a href={`tel:${fixe}`}>{fixe}</a> : null;
    },
    meta: {
      headerProps: { style: { width: '8%', fontSize: '11px' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  // {
  //   accessorKey: 'lastActivity',
  //   header: 'Activité',
  //   cell: ({ getValue }) => {
  //     const lastActivity = getValue() as string | number;
  //     const formattedTimeAgo = formatDistanceToNow(new Date(lastActivity), { addSuffix: true, locale: fr });
  //
  //     return <span>{formattedTimeAgo}</span>;
  //   },
  //   meta: {
  //     headerProps: { style: { width: '8%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  // {
  //   accessorKey: 'callStatus',
  //   header: 'Statut D’appel',
  //   cell: ({ row: { original } }) => {
  //     const { callStatus } = original;
  //     const statusCallMapping = {
  //       online: { color: "success", label: "En ligne" },
  //       waiting: { color: "warning", label: "En attente" },
  //       unregistered: { color: "danger", label: "Non inscrit" }
  //     };
  //
  //     const { color, label } = statusCallMapping[callStatus as keyof typeof statusCallMapping] ?? {
  //       color: "secondary",
  //       label: "Inconnu"
  //     };
  //
  //     return <Badge bg={color as BadgeBg} className="text-uppercase">{label}</Badge>;
  //   },
  //   meta: {
  //     headerProps: { style: { width: '8%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  // {
  //   accessorFn: (row) => row.idClient,
  //   id: 'idPhoneConfig',
  //   header: 'Sous domaine instance',
  //   cell: ({ row: { original } }) => {
  //     const clientData = useClientData();
  //     const { idClient } = original;
  //
  //     const client = clientData.find(client => client.id === idClient);
  //     if (client) {
  //       const serverConfigData = useServerConfigData();
  //       const server = serverConfigData.find(server => server.id === client.idInstance);
  //       return server ? server.subdomain : "Sous domaine non trouvé";
  //     }
  //     return "Client non trouvé";
  //   },
  //   meta: {
  //     headerProps: { style: { width: '8%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  // {
  //   accessorKey: 'userStatus',
  //   header: 'Statut Utilisateur',
  //   cell: ({ row: { original } }) => {
  //     const { userStatus } = original;
  //     const statusUserMapping = {
  //       actif: { color: 'success', label: 'Actif' },
  //       absent: { color: 'danger', label: 'Absent' },
  //       dnd: { color: 'dark', label: 'Ne pas déranger' },
  //       communication_trip: { color: 'info', label: 'En communication' },
  //       conference: { color: 'warning', label: 'En conférence' },
  //       occupe: { color: 'primary', label: 'Occupé' }
  //     };
  //     const { color, label } = statusUserMapping[userStatus as keyof typeof statusUserMapping] || { color: 'secondary', label: 'Inconnu' };
  //
  //     return <Badge bg={color as BadgeBg} className="text-uppercase">{label}</Badge>;
  //   },
  //   meta: {
  //     headerProps: { style: { width: '8%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },

  {
    id: "actions",
    header: "Actions",
    meta: {
      headerProps: { style: { width: "8%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [tableData, setTableData] = useState<PhoneUserData[]>([]);

      const handleDeleteClick = () => setShowDeleteModal(true);
      const handleEditClick = () => setShowEditModal(true);

      const handleActionConfirm = async (type: "delete" | "edit") => {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token not found");

        const headers: HeadersInit = {
          accept: "text/plain",
          Authorization: `Bearer ${token}`
        };

        if (type === "edit") {
          headers["Content-Type"] = "application/json";
        }

        const response = await fetch(
          `https://rct-backend.ipercom.io/api/Webrtc/phoneuser/${original.id}`,
          {
            method: type === "delete" ? "DELETE" : "PUT",
            headers,
            body: type === "edit" ? JSON.stringify(original) : undefined
          }
        );

        if (response.ok) window.location.reload();
        else throw new Error(`HTTP error! status: ${response.status}`);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={handleEditClick}>Modifier</Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteClick}>Supprimer</Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={() => handleActionConfirm("delete")}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PhoneUserForm
                initialData={original}
                onClose={() => setShowEditModal(false)}
                setTableData={setTableData}
                tableData={tableData}
              />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

const UserPhoneTableAPI = () => (
  <div>
    <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
    <AdvanceTableFooter pagination />
  </div>
);

export default UserPhoneTableAPI;
