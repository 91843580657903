import React, { ChangeEvent, useState, useEffect, useMemo } from "react";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/others/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import UserPhoneTableAPI, { phoneUserTableColumns } from './UserPhoneTableAPI';
import PhoneUserForm from "./PhoneUserForm";
import useFinalClient from 'hooks/api/ipbx/useFinalClient';
import { ClientProvider } from 'data/iper-x/context/ClientContext';
import useServerConfig from "hooks/api/ipbx/useServerConfig";
import { ServerConfigProvider } from "data/iper-x/context/ServerConfigurationContext";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";

const UserPhoneListAPI = () => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  // Use the new hook with clientFinalId
  const { phoneUserData, setPhoneUserData, loading, error } = usePhoneUserDataID(clientFinalId);
  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();
  const { serverConfigData, serverConfigLoading, serverConfigError } = useServerConfig();

  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const table = useAdvanceTable({
    data: phoneUserData, 
    columns: phoneUserTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <ClientProvider clientData={clientData}>
      <ServerConfigProvider serverConfigData={serverConfigData}>
        <div>
          <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
            <h2 className="mb-0">
              <span className="me-3">Liste des utilisateurs</span>
            </h2>
          </div>

          {/* Search and Filter */}
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  className="w-300"
                  placeholder="Rechercher"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
              <Col xs="auto">
                <Button
                  variant="primary"
                  onClick={() => setShowAddClientModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter un utilisateur
                </Button>
                <LeadsFilterModal
                  show={openFilterModal}
                  handleClose={() => setOpenFilterModal(false)}
                />
              </Col>
            </Row>
          </div>

          {loading && clientLoading && serverConfigLoading && <p>Chargement des données...</p>}
          {(error || clientError || serverConfigError) && (
            <p style={{ color: "red" }}>{error || clientError || serverConfigError}</p>
          )}

          {!loading && !clientLoading && !serverConfigLoading && (
            <AdvanceTableProvider {...table}>
              <div className="mx-n4 px-2 mx-lg-n6 bg-white border-top border-bottom border-200 position-relative top-1">
                <UserPhoneTableAPI />
              </div>
            </AdvanceTableProvider>
          )}

          {/* Add Phone User Modal */}
          <Modal
            show={showAddClientModal}
            onHide={() => setShowAddClientModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Ajouter un utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PhoneUserForm
                tableData={phoneUserData}
                setTableData={setPhoneUserData}
                onClose={() => setShowAddClientModal(false)}
              />
            </Modal.Body>
          </Modal>
        </div>
      </ServerConfigProvider>
    </ClientProvider>
  );
};

export default UserPhoneListAPI;
