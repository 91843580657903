import React from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableProvider from '../../../../../../providers/AdvanceTableProvider';
import useAdvanceTable from '../../../../../../hooks/others/useAdvanceTable';
import Microsoft365IntegrationsTable, {
  microsoft365IntegrationsInstalledTableColumns
} from '../../../../../../components/tables/ipbx/Microsoft365IntegrationsTable';
import { microsoft365IntegrationsTableData } from '../../../../../../data/iper-x/ipbx/microsoft365Integrations';

const CalendarSyncConfig = () => {
  const table = useAdvanceTable({
    data: microsoft365IntegrationsTableData,
    columns: microsoft365IntegrationsInstalledTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <>
      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Synchronisation Calendrier vers Statut
                  </h3>
                </Form.Check.Label>
              </Form.Check>
              <span className="me-3">
                Mettre à jour le statut d'extension (Présence) en fonction du
                statut "Afficher comme" de l'uilisateur dans le calendrier
                Outlook en fonction de cette correspondance :
              </span>

              <Table bordered hover size="sm  w-50">
                <thead>
                  <tr>
                    <th>
                      <b>Outlook - Afficher comme</b>
                    </th>
                    <th>
                      <b>IPER-X - Statut extension</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Occupé</td>
                    <td>Ne pas déranger</td>
                  </tr>
                  <tr>
                    <td>Hors du bureau / Absent</td>
                    <td>Absent</td>
                  </tr>
                  <tr>
                    <td>Travaille ailleurs</td>
                    <td>Ne pas déranger</td>
                  </tr>
                  <tr>
                    <td>Provisoire</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <Button variant="btn btn-white border border-1 px-2 pt-3 mb-3">
                <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                Ajouter des utilisateurs
              </Button>
              <Button variant="btn btn-white border border-1 px-2 ms-2 mb-3">
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Supprimer
              </Button>
              <br />
              <span className="me-3">Nombre : 0, Sélectionné : 0</span>

              <AdvanceTableProvider {...table}>
                <div className="mx-n4 pt-3 px-4 mx-lg-n6 px-lg-6 ">
                  <Microsoft365IntegrationsTable />
                </div>
              </AdvanceTableProvider>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CalendarSyncConfig;
