import { ServerConfigData } from "hooks/api/ipbx/useServerConfig";
import React, { createContext, useContext, ReactNode } from "react";

// Création du contexte pour les données de configuration du serveur
const ServerConfigurationContext = createContext<ServerConfigData[] | null>(null);

// Hook pour utiliser le contexte de configuration du serveur
export const useServerConfigData = () => {
  const context = useContext(ServerConfigurationContext);
  if (!context) {
    throw new Error("useServerConfigData must be used within a ServerConfigProvider");
  }
  return context;
};

// Fournisseur de contexte pour les données de configuration du serveur
export const ServerConfigProvider = ({
                                       serverConfigData,
                                       children
                                     }: {
  serverConfigData: ServerConfigData[];
  children: ReactNode;
}) => {
  return (
    <ServerConfigurationContext.Provider value={serverConfigData}>
      {children}
    </ServerConfigurationContext.Provider>
  );
};
