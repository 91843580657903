import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableProvider from '../../../../../../providers/AdvanceTableProvider';
import useAdvanceTable from '../../../../../../hooks/others/useAdvanceTable';
import Microsoft365IntegrationsTable, {
  microsoft365IntegrationsInstalledTableColumns
} from '../../../../../../components/tables/ipbx/Microsoft365IntegrationsTable';
import { microsoft365IntegrationsTableData } from '../../../../../../data/iper-x/ipbx/microsoft365Integrations';

const UserSyncConfig = () => {
  const table = useAdvanceTable({
    data: microsoft365IntegrationsTableData,
    columns: microsoft365IntegrationsInstalledTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Synchroniser les utilisateurs Microsoft 365 avec les
                    extensions iperx
                  </h3>
                </Form.Check.Label>
              </Form.Check>
              <span className="me-3">
                Créer automatiquement une nouvelle extension pour chaque nouvel
                utilisateur Microsoft 365 et synchroniser l'extension avec les
                informations mises à jour depuis les utilisateurs Microsoft 365.
                Les extensions ne sont pas affectées quand des utilisateurs
                Microsoft 365 sont supprimés. alertes.
              </span>

              <Form.Check
                type="checkbox"
                id="transferToExternalNumbers"
                className="mt-3"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Autoriser le transfert vers numéros externes à partir des
                  groupes d'appels et files
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="checkbox" id="playBusyTone" className="mt-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Jouer la tonalité Occupé lorsque l'extension est occupée
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="limitInterception"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Limiter l'interception aux appels reçus dans son groupe
                  d'extension
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="allowOutgoingCalls"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Permettre les appels sortants à partir de la messagerie vocale
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="radio" id="allUsers" className="mb-0 pt-3">
                <Form.Check.Input
                  type="radio"
                  name="userAccess"
                  value="allUsers"
                />
                <Form.Check.Label className="fs-8 fw-normal" htmlFor="allUsers">
                  Tous les utilisateurs
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="radio" id="allUsersExcept" className="mb-0">
                <Form.Check.Input
                  type="radio"
                  name="userAccess"
                  value="allUsersExcept"
                />
                <Form.Check.Label
                  className="fs-8 fw-normal"
                  htmlFor="allUsersExcept"
                >
                  Tous les utilisateurs sauf ceux-ci :
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="radio" id="onlyTheseUsers" className="mb-3">
                <Form.Check.Input
                  type="radio"
                  name="userAccess"
                  value="onlyTheseUsers"
                />
                <Form.Check.Label
                  className="fs-8 fw-normal"
                  htmlFor="onlyTheseUsers"
                >
                  Seulement ces utilisateurs :
                </Form.Check.Label>
              </Form.Check>

              <Button variant="btn btn-white border border-1 px-2 pt-3 mb-3">
                <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
                Ajouter
              </Button>
              <Button variant="btn btn-white border border-1 px-2 ms-2 mb-3">
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Supprimer
              </Button>
              <br />
              <span className="me-3">Nombre : 0, Sélectionné : 0</span>

              <AdvanceTableProvider {...table}>
                <div className="mx-n4 pt-3 px-4 mx-lg-n6 px-lg-6 ">
                  <Microsoft365IntegrationsTable />
                </div>
              </AdvanceTableProvider>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserSyncConfig;
