import {
  Button,
  ButtonGroup,
  Col,
  Form,
  OverlayTrigger,
  Row,
  ToggleButton,
  Tooltip
} from "react-bootstrap";
import { faCircleInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useEffect, useState } from "react";
import useMsgVocalDataID from "hooks/api/ipbx/useMsgVocalDataID";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";
import { QueueData } from "hooks/api/ipbx/useQueue";
import useQueuedataID from "../../../../../hooks/api/ipbx/useQueuedataID";

type QueueFormProps = {
  tableData: QueueData[];
  setTableData: React.Dispatch<React.SetStateAction<QueueData[]>>;
  onClose: () => void;
  initialData?: QueueData; // File d'attente à modifier, si en mode édition
};

const QueueForm: React.FC<QueueFormProps> = ({
                                               tableData,
                                               setTableData,
                                               onClose,
                                               initialData
                                             }) => {
  const isEditMode = Boolean(initialData);

  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const { queueData, setQueueData, loading, error } =
    useQueuedataID(clientFinalId);

  const [newQueue, setNewQueue] = useState<QueueData>({
    id: initialData?.id || 0,
    idClient: initialData?.idClient || clientFinalId,
    name: initialData?.name || "",
    numero: initialData?.numero || 0,
    idMsgVocal: initialData?.idMsgVocal || 0,
    membres: initialData?.membres || "",
    timeout: initialData?.timeout || 0,
    strategy: initialData?.strategy || "ringall",
    state: initialData?.state || true
  });

  const [memberType, setMemberType] = useState<string>("internal");
  const [userInput, setUserInput] = useState<string>("");
  const [userList, setUserList] = useState<
    { id: number; firstname: string; lastname: string }[]
  >([]);
  const [userError, setUserError] = useState<string | null>(null);

  const [externalPhoneNumberInput, setExternalPhoneNumberInput] = useState("");
  const [externalPhoneNumbers, setExternalPhoneNumbers] = useState<string[]>(
    []
  );
  const [phoneError, setPhoneError] = useState("");
  const [numeroError, setNumeroError] = useState("");

  const handleNumeroChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Keep only numeric characters
    if (value.length > 6) value = value.slice(0, 6); // Limit to 6 digits

    const numero = Number(value);
    setNewQueue(prevQueue => ({ ...prevQueue, numero }));
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    const regex = /^\+?\d{11,15}$/;
    return regex.test(phoneNumber);
  };

  const handleAddExternalPhoneNumber = () => {
    if (!isValidPhoneNumber(externalPhoneNumberInput)) {
      setPhoneError("Numéro invalide. Format attendu : +33518222200");
      return;
    }

    if (externalPhoneNumbers.includes(externalPhoneNumberInput)) {
      setPhoneError("Ce numéro est déjà dans la liste.");
      return;
    }

    setExternalPhoneNumbers([
      ...externalPhoneNumbers,
      externalPhoneNumberInput
    ]);
    setExternalPhoneNumberInput(""); // Réinitialiser le champ d'entrée
    setPhoneError("");
  };

  const handleDeleteExternalPhoneNumber = (index: number) => {
    setExternalPhoneNumbers(externalPhoneNumbers.filter((_, i) => i !== index));
  };

  const { msgVocalData, loading: msgVocalLoading } =
    useMsgVocalDataID(clientFinalId);
  const { phoneUserData, loading: phoneUserLoading } =
    usePhoneUserDataID(clientFinalId);
  const [formErrors, setFormErrors] = useState<{
    [key: string]: string | null;
  }>({
    name: null,
    numero: null,
    timeout: null,
    strategy: null,
    idMsgVocal: null
  });

  useEffect(() => {
    if (isEditMode && initialData) {
      const initialUsers = initialData.membres
        .split(",")
        .map(id => phoneUserData.find(user => user.id === Number(id)))
        .filter(user => user !== undefined) as {
        id: number;
        firstname: string;
        lastname: string;
      }[];
      setUserList(initialUsers);
    }
  }, [initialData, isEditMode, phoneUserData]);

  const handleChange = (e: React.FormEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;

    setNewQueue(prevQueue => ({
      ...prevQueue,
      [name]: name === "timeout" || name === "numero" ? Number(value) : value
    }));

    if (name === "numero") {
      setFormErrors(prevErrors => ({ ...prevErrors, numero: null }));
    }
  };

  const handleMemberTypeChange = (type: string) => {
    setMemberType(type);
    setUserList([]);
  };

  const addUserToList = () => {
    if (userInput) {
      const selectedUser = phoneUserData.find(
        user => user.extension === userInput
      );
      if (!selectedUser) {
        setUserError("Utilisateur non trouvé.");
        return;
      }
      const isUserAlreadyAdded = userList.some(
        user => user.id === selectedUser.id
      );
      if (isUserAlreadyAdded) {
        setUserError("Cet utilisateur a déjà été ajouté.");
        return;
      }
      const updatedUserList = [...userList, selectedUser];
      setUserList(updatedUserList);
      setNewQueue(prevQueue => ({
        ...prevQueue,
        membres: updatedUserList.map(user => user.id).join(",")
      }));
      setUserInput("");
      setUserError(null);
    }
  };

  const handleDeleteUser = (userId: number) => {
    const updatedUserList = userList.filter(user => user.id !== userId);
    setUserList(updatedUserList);
    setNewQueue(prevQueue => ({
      ...prevQueue,
      membres: updatedUserList.map(user => user.id).join(",")
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm() || userError || phoneError || numeroError) return;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found");
      return;
    }

    const requestData = {
      ...newQueue,
      idClient: clientFinalId
    };

    const url = `https://rct-backend.ipercom.io/api/Ipbx/queue`;
    const method = isEditMode ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestData)
      });

      if (response.status === 204) {
        // Handle no content response
        console.log("No content returned from the server.");
        window.location.reload();
        return;
      }

      const data: QueueData = await response.json();
      setTableData(prevTableData =>
        isEditMode
          ? prevTableData.map(queue => (queue.id === data.id ? data : queue))
          : [...prevTableData, data]
      );
      onClose();
      window.location.reload();
    } catch (error) {
      console.error("Error while adding or updating the queue", error);
    }
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    // Validation du nom
    if (!newQueue.name) errors.name = "Le nom est requis";

    // Validation du numéro de file d'attente
    if (!newQueue.numero) {
      errors.numero = "Le numéro est requis";
    } else {
      const isValidNumero =
        /^\d{1,6}$/.test(newQueue.numero.toString()) &&
        (newQueue.numero === 0 || newQueue.numero.toString().charAt(0) !== "0");

      if (!isValidNumero) {
        errors.numero =
          "Le numéro doit être un nombre de 1 à 6 chiffres, sans commencer par 0";
      } else {
        // Vérifie si le numéro existe déjà dans `queueData`, sauf pour l'ID actuel en mode édition
        const isNumeroInUse = queueData.some(
          queue => queue.numero === newQueue.numero && queue.id !== newQueue.id
        );

        if (isNumeroInUse) {
          errors.numero = "Ce numéro de file est déjà utilisé.";
        }
      }
    }

    // Validation du timeout
    if (!newQueue.timeout) errors.timeout = "Le temps d'attente est requis";

    // Validation de la stratégie
    if (!newQueue.strategy) errors.strategy = "La stratégie est requise";

    // Validation de l'audio
    if (!newQueue.idMsgVocal) errors.idMsgVocal = "L'audio est requis";

    // Mise à jour des erreurs dans l'état
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Retourne `true` si aucun champ n'a d'erreur
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* État */}
      <Form.Group controlId="state" className="align-items-center mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Définit l'état actif ou inactif de la file d'attente.
            </Tooltip>
          }
        >
          <Form.Label>
            État de la file d'attente{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <div className="d-flex align-items-center">
          <Form.Switch
            name="state"
            checked={newQueue.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewQueue({ ...newQueue, state: e.target.checked })
            }
            className="me-2"
          />
          <span>{newQueue.state ? "Actif" : "Inactif"}</span>
        </div>
      </Form.Group>

      <Row>
        <Col>
          {/* Nom de la file d'attente */}
          <Form.Group controlId="name" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>Nom unique pour identifier la file d'attente.</Tooltip>
              }
            >
              <Form.Label>
                Nom de la file d'attente{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="name"
              value={newQueue.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
              isInvalid={!!formErrors.name}
              required
            />

            <Form.Control.Feedback type="invalid">
              {formErrors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          {/* Numéro de la file d'attente */}
          <Form.Group controlId="numero" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Numéro à 6 chiffres pour identifier la file d'attente (ex:
                  123456).
                </Tooltip>
              }
            >
              <Form.Label>
                Numéro de la file d'attente{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="numero"
              value={newQueue.numero}
              onChange={handleNumeroChange}
              isInvalid={!!numeroError || !!formErrors.numero}
              placeholder="Ex: 123456"
              max={999999}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.numero}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          {/* Timeout */}
          <Form.Group controlId="timeout" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Durée maximale d'attente en secondes avant la redirection.
                </Tooltip>
              }
            >
              <Form.Label>
                Temps maximal d'attente (en secondes){" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{
                    cursor: "pointer",
                    marginLeft: "4px"
                  }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="timeout"
              value={newQueue.timeout}
              onChange={e => handleChange(e as ChangeEvent<HTMLInputElement>)}
              min={0}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          {/* Stratégie */}
          <Form.Group controlId="strategy" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>Stratégie de répartition des appels entrants.</Tooltip>
              }
            >
              <Form.Label>
                Stratégie{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              className="form-select"
              name="strategy"
              value={newQueue.strategy}
              onChange={(e) => handleChange(e as unknown as React.FormEvent<HTMLSelectElement>)}
              required
            >
              <option value="ringall">Sonner tous</option>
              <option value="leastrecent">Le moins récent</option>
              <option value="fewestcalls">Le moins d'appels</option>
              <option value="random">Aléatoire</option>
              <option value="rrmemory">Mémoire</option>
              <option value="linear">Linéaire</option>
            </Form.Control>

          </Form.Group>
        </Col>
      </Row>

      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip>
            Ajoutez des utilisateurs internes et/ou des numéros externes à la
            file d'attente.
          </Tooltip>
        }
      >
        <h5 className="mt-3">
          Ajouter des utilisateurs{" "}
          <FontAwesomeIcon
            icon={faCircleInfo}
            style={{ cursor: "pointer", marginLeft: "4px" }}
          />
        </h5>
      </OverlayTrigger>

      <Row>
        <Col>
          {/* Section Utilisateur Interne */}
          <Form.Group controlId="internalUsers" className="mt-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Ajoutez des utilisateurs internes à la file d'attente.
                </Tooltip>
              }
            >
              <Form.Label>
                Utilisateur interne{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              as="select"
              className="form-select"
              name="internalUsers"
              value={userInput}
              onChange={e => setUserInput(e.target.value)}
              disabled={phoneUserLoading}
            >
              <option value="">Sélectionner un utilisateur</option>
              {phoneUserData.length > 0 ? (
                phoneUserData.map(user => (
                  <option key={user.id} value={user.extension}>
                    {user.firstname} {user.lastname}
                  </option>
                ))
              ) : (
                <option disabled>Aucune donnée</option>
              )}
            </Form.Control>
            <Button
              variant="success"
              disabled={phoneUserData.length === 0}
              onClick={addUserToList}
              className="mt-2"
            >
              Ajouter utilisateur interne
            </Button>
            {userError && <p className="text-danger">{userError}</p>}
          </Form.Group>

          {/* Liste des utilisateurs internes ajoutés */}
          {userList.length > 0 && (
            <div>
              <p className="fs-9 mt-3">Utilisateurs internes ajoutés :</p>
              <ul>
                {userList.map(user => (
                  <li key={user.id} className="fs-9">
                    {user.firstname} {user.lastname}{" "}
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleDeleteUser(user.id)}
                      style={{ cursor: "pointer" }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Col>

        <Col>
          {/* Section Numéro Externe */}
          <Form.Group controlId="externalPhoneNumber" className="mt-4">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Numéro externe au format international (ex: +33518222200)
                </Tooltip>
              }
            >
              <Form.Label>
                Numéro externe{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="text"
              name="externalPhoneNumber"
              maxLength={15}
              value={externalPhoneNumberInput}
              onChange={e => setExternalPhoneNumberInput(e.target.value)}
              placeholder="+33518222200"
            />
            <Button
              variant="success"
              className="mt-2"
              onClick={handleAddExternalPhoneNumber}
              disabled={!isValidPhoneNumber(externalPhoneNumberInput)}
            >
              Ajouter numéro externe
            </Button>
            {phoneError && <p className="text-danger">{phoneError}</p>}
          </Form.Group>

          {/* Liste des numéros externes ajoutés */}
          {externalPhoneNumbers.length > 0 && (
            <div>
              <p className="fs-9 mt-3">Numéros externes ajoutés :</p>
              <ul>
                {externalPhoneNumbers.map((number, index) => (
                  <li key={index} className="fs-9">
                    {number}{" "}
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => handleDeleteExternalPhoneNumber(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Col>
      </Row>

      {/* Audio */}
      <Form.Group controlId="idMsgVocal" className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>Message vocal joué pour les appelants en attente.</Tooltip>
          }
        >
          <Form.Label>
            Audio{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          as="select"
          name="idMsgVocal"
          className="form-select"
          value={newQueue.idMsgVocal}
          onChange={(e) => handleChange(e as unknown as React.FormEvent<HTMLSelectElement>)}
          required
          disabled={msgVocalLoading}
        >
          <option value="">Sélectionner un audio</option>
          {msgVocalData.map((msgVocal) => (
            <option key={msgVocal.id} value={msgVocal.id}>
              {msgVocal.name}
            </option>
          ))}
        </Form.Control>

      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        {isEditMode ? "Modifier" : "Ajouter"}
      </Button>
    </Form>
  );
};

export default QueueForm;
