import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ClientFinal, Order } from "../../../../../../types/types";

interface ClientModalProps {
  order: Order;
  setOrder: (order: Order) => void;
  selectedLicenseIndex: number;
  selectedProductIndex: number;
  onClose: () => void;
}

const ClientModal: React.FC<ClientModalProps> = ({
                                                   order,
                                                   setOrder,
                                                   selectedLicenseIndex,
                                                   selectedProductIndex,
                                                   onClose
                                                 }) => {
  const [step, setStep] = useState<"choice" | "create" | "select">("choice");
  const [existingClients, setExistingClients] = useState<ClientFinal[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);

  // Fields for creating a new client
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPosition, setClientPosition] = useState("");
  const [clientAddress, setClientAddress] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (step === "select") {
      fetchClients();
    }
  }, [step]);

  const fetchClients = async () => {
    try {
      const response = await fetch("https://rct-backend.ipercom.io/api/Ipbx/clientfinal", {
        method: "GET",
        headers: {
          Accept: "text/plain",
          Authorization: `Bearer ${token}`
        }
      });
      const data = await response.json();
      setExistingClients(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données clients:", error);
    }
  };

  const saveNewClient = async () => {
    try {
      const response = await fetch("https://rct-backend.ipercom.io/api/Ipbx/clientfinal", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          idRevendeur: 6, // Vous pouvez ajuster ces valeurs si nécessaire
          createdBy: 10, // Assurez-vous que `createdBy` est défini correctement
          idInstance: 1, // L'instance à laquelle le client est associé
          name: clientName,
          position: clientPosition,
          email: clientEmail,
          address: clientAddress
        })
      });

      if (response.ok) {
        const newClient = await response.json();

        const updatedItems = [...order.items];
        const updatedLicenses = [...updatedItems[selectedProductIndex].licenses];
        updatedLicenses[selectedLicenseIndex] = {
          ...updatedLicenses[selectedLicenseIndex],
          client_final: {
            id: newClient.id,
            name: newClient.name,
            email: newClient.email,
            position: newClient.position,
            address: newClient.address
          }
        };
        updatedItems[selectedProductIndex].licenses = updatedLicenses;
        setOrder({ ...order, items: updatedItems });

        onClose(); // Fermer le modal après la sauvegarde
      } else {
        console.error("Erreur lors de la création du client");
      }
    } catch (error) {
      console.error("Erreur lors de la requête de création du client:", error);
    }
  };

  const assignExistingClient = () => {
    if (selectedClientId) {
      const selectedClient = existingClients.find(client => client.id.toString() === selectedClientId);
      if (selectedClient) {
        const updatedItems = [...order.items];
        const updatedLicenses = [...updatedItems[selectedProductIndex].licenses];
        updatedLicenses[selectedLicenseIndex] = {
          ...updatedLicenses[selectedLicenseIndex],
          client_final: {
            id: selectedClient.id,
            name: selectedClient.name,
            email: selectedClient.email,
            position: selectedClient.position,
            address: selectedClient.address
          }
        };
        updatedItems[selectedProductIndex].licenses = updatedLicenses;
        setOrder({ ...order, items: updatedItems });
        onClose();
      }
    }
  };


  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Attribuer ou créer un client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === "choice" && (
          <div className="text-center">
            <Button variant="outline-primary" className="w-100 mb-3" onClick={() => setStep("select")}>
              Sélectionner un client existant
            </Button>
            <Button variant="outline-success" className="w-100" onClick={() => setStep("create")}>
              Créer un nouveau client
            </Button>
          </div>
        )}

        {step === "create" && (
          <>
            <Form.Group controlId="clientName">
              <Form.Label>Nom du client</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrer le nom du client"
                value={clientName}
                onChange={e => setClientName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="clientEmail">
              <Form.Label>Email du client</Form.Label>
              <Form.Control
                type="email"
                placeholder="Entrer l'email du client"
                value={clientEmail}
                onChange={e => setClientEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="clientPosition">
              <Form.Label>Rôle du client</Form.Label>
              <Form.Control
                as="select"
                value={clientPosition}
                onChange={e => setClientPosition(e.target.value)}
              >
                <option value="">Sélectionner un poste</option>
                <option value="PDG">PDG (Président-Directeur Général)</option>
                <option value="DG">DG (Directeur Général)</option>
                <option value="CTO">CTO (Directeur Technique)</option>
                <option value="CFO">CFO (Directeur Financier)</option>
                <option value="COO">COO (Directeur des Opérations)</option>
                <option value="Responsable">Responsable</option>
                <option value="Chef d'Équipe">Chef d'Équipe</option>
                <option value="ADV">ADV (Administration des Ventes)</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="clientAddress">
              <Form.Label>Adresse du client</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrer l'adresse du client"
                value={clientAddress}
                onChange={e => setClientAddress(e.target.value)}
              />
            </Form.Group>
          </>
        )}

        {step === "select" && (
          <Form.Group controlId="existingClients">
            <Form.Label>Choisir un client</Form.Label>
            <Form.Control
              as="select"
              value={selectedClientId || ""}
              onChange={e => setSelectedClientId(e.target.value)}
            >
              <option value="">Sélectionner un client</option>
              {existingClients.map(client => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fermer
        </Button>
        {step === "create" && (
          <Button variant="success" onClick={saveNewClient}>
            Créer le client
          </Button>
        )}
        {step === "select" && (
          <Button variant="primary" onClick={assignExistingClient}>
            Attribuer le client
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ClientModal;
