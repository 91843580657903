import { useState, useEffect, useCallback } from "react";

export interface GroupData {
  id: number;
  idClient: number;
  nameGroup: string;
  ringStrategy: string;
  internalUsers: string;
  externalNumber: string;
  idTrunkExternalNumber: number;
  priorityExternalInternal: string;
  ringDuration: number;
  state: boolean;
}


const useGroupDataID = (id: number) => {
  const [groupData, setGroupData] = useState<GroupData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchGroupData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/group/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setGroupData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données group data", error);
      setError("Impossible de récupérer les données group data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchGroupData();
  }, [fetchGroupData]);

  return {
    groupData,
    setGroupData,
    loading,
    error
  };
};

export default useGroupDataID;
