import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, Badge, ProgressBar, Form, Modal, Card } from "react-bootstrap";
import useTicketsData, { Ticket } from '../../../../hooks/dolibarr/useTicketsData'; // Adjust the import path as needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage, faEdit, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";

const TicketDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, refetch } = useTicketsData();
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [error, setError] = useState<string | null>(null);
  const DOLAPIKEY = localStorage.getItem("DOLAPIKEY");
  const navigate = useNavigate();

  useEffect(() => {
    const foundTicket = data.find(ticket => ticket.id === Number(id));
    setTicket(foundTicket || null);
  }, [data, id]);

  const handleAddMessage = async () => {
    if (!newMessage.trim()) return;
    try {
      const response = await fetch("https://erp.ipercom.io/api/index.php/tickets/newmessage", {
        method: "POST",
        headers: {
          Accept: "application/json",
          DOLAPIKEY: DOLAPIKEY || ""
        },
        body: JSON.stringify({
          track_id: ticket?.ref,
          message: newMessage
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert("Message ajouté avec succès");
      setShowMessageModal(false);
      refetch(); // Reload tickets after adding a message
    } catch (error) {
      setError("Erreur lors de l'ajout du message");
    }
  };

  const handleCloseTicket = async () => {
    try {
      const response = await fetch(`https://erp.ipercom.io/api/index.php/tickets/${ticket?.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          DOLAPIKEY: DOLAPIKEY || ""
        },
        body: JSON.stringify({ status: "closed" })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert("Ticket fermé avec succès");
      refetch(); // Reload tickets after closing
    } catch (error) {
      setError("Erreur lors de la fermeture du ticket");
    }
  };

  if (!ticket) {
    return <div>Loading ticket details...</div>;
  }

  return (
    <div className="container my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">

        {/* Button for going back */}
        <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
          ← Retour
        </Button>

        <h2>Détails du Ticket {ticket.ref}</h2>
        <div>
          <Button variant="primary" className="me-2" disabled>
            <FontAwesomeIcon icon={faEnvelope} /> Envoyer Email
          </Button>
          <Button variant="secondary" className="me-2" onClick={() => setShowMessageModal(true)} disabled>
            <FontAwesomeIcon icon={faMessage} /> Ajouter un message privé
          </Button>
          <Button variant="success" className="me-2" onClick={handleCloseTicket} disabled>
            <FontAwesomeIcon icon={faCheck} /> Fermer | Résoudre
          </Button>
          <Button variant="warning" className="me-2" disabled>
            <FontAwesomeIcon icon={faEdit} /> Modifier
          </Button>
          <Button variant="danger" disabled>
            <FontAwesomeIcon icon={faTrash} /> Supprimer
          </Button>
        </div>
      </div>

      {/* Main Ticket Information */}
      <Row className="mb-4">
        <Col lg={8}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Informations sur le Ticket</h5>
            <Row className="mb-3">
              <Col md={6}>
                <strong>Référence :</strong>
                <p>{ticket.ref}</p>
              </Col>
              <Col md={6}>
                <strong>Sujet :</strong>
                <p>{ticket.subject}</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <strong>Assigné à :</strong>
                <p>{ticket.assignee}</p>
              </Col>
              <Col md={6}>
                <strong>Progression :</strong>
                <ProgressBar now={parseInt(ticket.progress)} label={`${ticket.progress}%`} />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <strong>Date de création :</strong>
                <p>{ticket.date_creation}</p>
              </Col>
              <Col md={6}>
                <strong>Date de modification :</strong>
                <p>{ticket.date_modification}</p>
              </Col>
            </Row>
            {ticket.date_cloture && (
              <Row className="mb-3">
                <Col md={6}>
                  <strong>Date de clôture :</strong>
                  <p>{ticket.date_cloture}</p>
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              <Col md={6}>
                <strong>Statut :</strong>
                <Badge pill bg={ticket.status === "0" ? "secondary" : ticket.status === "1" ? "info" : "success"}>
                  {ticket.status === "0" ? "Non Lu" : ticket.status === "1" ? "Lu" : "En Cours"}
                </Badge>
              </Col>
              <Col md={6}>
                <strong>Gravité :</strong>
                <p>{ticket.severity}</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <strong>Message :</strong>
                <p>{ticket.message}</p>
              </Col>
            </Row>
          </div>
        </Col>

        {/* Sidebar for Related Information */}
        <Col lg={4}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Informations supplémentaires</h5>
            <p><strong>Entreprise :</strong> {ticket.company}</p>
            <p><strong>Catégorie :</strong> {ticket.category_code}</p>
            <p><strong>Type :</strong> {ticket.type_code}</p>
            <p><strong>Priorité :</strong> {ticket.priority}</p>
          </div>


          <div className="mt-4 bg-light p-4 rounded">
            <h5 className="mb-4">Autres : </h5>
            <p>Aucune information disponible</p>
          </div>
        </Col>


        <Col className="mt-4" lg={12}>
          <div className="bg-light p-4 rounded">
            <h5 className="mb-4">Les 10 derniers événements liés</h5>
            <p>Aucun événement disponible</p> {/* Display the events if available */}
          </div>
        </Col>
        {/* Last 10 events */}

      </Row>

      {/* Message Modal */}
      <Modal show={showMessageModal} onHide={() => setShowMessageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un message privé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form>
            <Form.Group controlId="newMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} value={newMessage} onChange={e => setNewMessage(e.target.value)} />
            </Form.Group>
            <Button variant="primary" className="mt-3" onClick={handleAddMessage}>Ajouter Message</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Email Modal */}
      <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Envoyer un email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="emailSubject">
              <Form.Label>Sujet</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group controlId="emailMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={5} />
            </Form.Group>
            <Button variant="primary" className="mt-3" onClick={() => alert("Email envoyé")}>Envoyer</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TicketDetail;
