import {
  faArchive,
  faBox,
  faDownload,
  faFileExport,
  faFilter,
  faFolderTree,
  faTrash,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import RecordsTable, { recordsTableColumns } from "../../../../components/tables/ipbx/RecordsTable";
import { recordsTableData } from "../../../../data/iper-x/ipbx/records";

const RecordsList = () => {
  const table = useAdvanceTable({
    data: recordsTableData,
    columns: recordsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Enregistrements</span>
          </h2>


          {/*<Link className="btn btn-white border border-2 px-2" to="/">*/}
          {/*  <FontAwesomeIcon icon={faFilter} className="me-2" />*/}
          {/*  Filtre*/}
          {/*</Link>*/}
          <Link className="btn btn-phoenix-secondary d-flex flex-center" to="/">
            <FontAwesomeIcon icon={faFolderTree} className="me-2" />
            Emplacement
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faDownload} className="me-2" />
            Télécharger
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faArchive} className="me-2" />
            Archive
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
            Supprimer
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faBox} className="me-2" />
            Quota
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faTrash} className="me-2" />
            Purger
          </Link>


        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                         placeholder="Rechercher"
                         onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary">
                <FontAwesomeIcon icon={faFilter} />
              </Button>


            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <RecordsTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default RecordsList;

