import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordCell = ({ password }: { password: string }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  return (
    <div className="">
      <span className="me-2">
        {showPassword ? password : "••••••••"}
      </span>
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        onClick={toggleShowPassword}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default PasswordCell;
