import React, { useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import ProgressBar from "./ProgressBar";
import { faStopCircle, faPlayCircle, faVolumeUp, faMusic, faComment, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { audioFiles, getMessageExample } from "./dataMessage";

const MessageOneClic: React.FC = () => {
  const [step, setStep] = useState(1);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedVoiceFiles, setSelectedVoiceFiles] = useState<number[]>([]);
  const [selectedMusicFiles, setSelectedMusicFiles] = useState<number[]>([]);
  const [isPlaying, setIsPlaying] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [savedAudio, setSavedAudio] = useState<{
    name: string;
    language: string;
    src?: string;
    type: string;
    categories?: string[]
  }[]>([]);
  const [selectedMessageType, setSelectedMessageType] = useState<string[]>([]);
  const [voiceCount, setVoiceCount] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(["Pop rock"]);

  const audioRefs = useRef<{ [key: string]: HTMLAudioElement }>({});
  const totalSteps = 3;

  const nextStep = () => step < totalSteps && setStep(step + 1);
  const beforeStep = () => step > 1 && setStep(step - 1);

  const togglePlayPause = (audioName: string) => {
    if (isPlaying && isPlaying !== audioName) {
      const currentAudio = audioRefs.current[isPlaying];
      currentAudio?.pause();
    }

    const audio = audioRefs.current[audioName];
    if (audio) {
      audio.paused ? audio.play() : audio.pause();
      setIsPlaying(audio.paused ? null : audioName);
    }
  };

  const handleLanguageFilterChange = (lang: string) => {
    setSelectedLanguages(prev =>
      prev.includes(lang) ? prev.filter(language => language !== lang) : [...prev, lang]
    );
  };

  const handleSelectAudioOrMusicChange = (audioId: number, genre: "voice" | "music") => {
    const currentSelection = genre === "voice" ? selectedVoiceFiles : selectedMusicFiles;
    const setter = genre === "voice" ? setSelectedVoiceFiles : setSelectedMusicFiles;

    let newSelectionUpdated: number[];

    if (genre === "music") {
      newSelectionUpdated = selectedMusicFiles.includes(audioId)
        ? []
        : [audioId];

      setter(newSelectionUpdated);

      const selectedAudio = audioFiles.find(audio => audio.id === audioId && audio.genre === genre);

      if (selectedAudio) {
        setSavedAudio(prev => {
          const updatedAudio = prev.filter(item => item.type !== "Music");
          return [...updatedAudio, {
            name: selectedAudio.name,
            language: selectedAudio.languages || "",
            src: selectedAudio.src,
            type: "Music"
          }];
        });
      }
    } else {
      if (selectedVoiceFiles.length >= 5 && !currentSelection.includes(audioId)) {
        alert("Vous ne pouvez sélectionner que 5 voix maximum.");
        return;
      }

      newSelectionUpdated = currentSelection.includes(audioId)
        ? currentSelection.filter(id => id !== audioId)
        : [...currentSelection, audioId];

      setter(newSelectionUpdated);

      const selectedAudio = audioFiles.find(audio => audio.id === audioId && audio.genre === genre);

      if (selectedAudio) {
        setSavedAudio(prev => {
          const updatedAudio = prev.filter(item => item.name !== selectedAudio.name);

          if (newSelectionUpdated.includes(audioId)) {
            setVoiceCount(newSelectionUpdated.length * 79);
            return [...updatedAudio, {
              name: selectedAudio.name,
              language: selectedAudio.languages || "",
              type: "Voice",
              price: 79
            }];
          } else {
            setVoiceCount(newSelectionUpdated.length * 79);
            return updatedAudio;
          }
        });
      }
    }

    setter(newSelectionUpdated);
  };


  const toggleCategorySelection = (category: string) => {
    setSelectedCategories(prev => prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]);
  };

  const toggleMessageTypeSelection = (type: string) => {
    setSelectedMessageType([type]);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    setSavedAudio(prev => {
      const existingMessageIndex = prev.findIndex(audio => audio.type === "Message");
      if (existingMessageIndex !== -1) {
        prev[existingMessageIndex].name = newMessage;
        return [...prev];
      }
      return [...prev, { name: newMessage, language: "", type: "Message", categories: selectedCategories }];
    });
  };

  const filteredAudio = (genre: "voice" | "music") => {
    const selectedData = genre === "voice" ? selectedLanguages : selectedCategories;
    return audioFiles.filter(audio => audio.genre === genre && (selectedData.length === 0 || selectedData.includes(audio.languages || audio.type || "")));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found");
      return;
    }

    const requestData = {
      additionalDetails: JSON.stringify(savedAudio),
      companyName: "",
      email: "",
      firstName: "",
      idProductService: 1,
      lastName: "",
      siretNumber: ""
    };

    const method = "POST";
    const url = `https://rct-backend.ipercom.io/api/CSoonRetailer`;

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestData)
      });

      if (response.status === 200) {
        setShowModal(true);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      alert("Erreur lors de l'envoi du formulaire. Veuillez réessayer.");
    }
  };

  const handleRemoveAudio = (audioName: string) => {
    setSavedAudio(prev => {
      const updatedAudio = prev.filter(audio => audio.name !== audioName);
      const removedAudio = prev.find(audio => audio.name === audioName);

      if (removedAudio) {
        if (removedAudio.type === "Voice") {
          setSelectedVoiceFiles(prevVoiceFiles =>
            prevVoiceFiles.filter(id => audioFiles.find(audio => audio.name === audioName && audio.id === id) === undefined)
          );
          const remainingVoices = updatedAudio.filter(audio => audio.type === "Voice");
          setVoiceCount(remainingVoices.length * 79);
        }

        if (removedAudio.type === "Music") {
          setSelectedMusicFiles(prevMusicFiles =>
            prevMusicFiles.filter(id => audioFiles.find(audio => audio.name === audioName && audio.id === id) === undefined)
          );
        }
      }

      return updatedAudio;
    });
  };

  return (
    <div>
      <ProgressBar step={step} totalSteps={totalSteps} />
      <h2 className="py-2">Votre message en un clic</h2>
      <p className="fs-9">Votre message téléphonique à partir de 79 € HT</p>

      {step === 1 && (
        <div className="mb-1">
          <Row>
            <h3>Choix des voix et langues</h3>
            <Col lg={4} xs={6} sm={6} md={12} className="align-content-center my-2">
              <div className="d-flex gap-4">
                {["française", "anglaise", "espagnole", "allemande", "italienne"].map(lang => (
                  <Button
                    key={lang}
                    variant={selectedLanguages.includes(lang) ? "primary" : "light"}
                    onClick={() => handleLanguageFilterChange(lang)}
                    className="btn-sm border-secondary"
                    style={{
                      backgroundColor: selectedLanguages.includes(lang) ? "#3874FF" : "",
                      color: selectedLanguages.includes(lang) ? "white" : ""
                    }}
                  >
                    {lang}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>

          <Row className="g-3 mt-3">
            {filteredAudio("voice").map(audio => (
              <Col key={audio.id} lg={1} xs={6} sm={6} md={4}>
                <Card
                  className={`overflow-hidden text-center`}
                  onClick={() => handleSelectAudioOrMusicChange(audio.id, "voice")}
                  style={{
                    cursor: "pointer",
                    borderColor: selectedVoiceFiles.includes(audio.id) ? "#3874FF" : "",
                    borderWidth: selectedVoiceFiles.includes(audio.id) ? "3px" : ""
                  }}
                >
                  <div className="position-relative">
                    <img src={audio.avatar} alt={audio.name} className="w-100 rounded-circle" />
                  </div>
                  <Card.Body className="p-1">
                    <h6 className="fs-9">{audio.name}</h6>
                    <div>
                      <FontAwesomeIcon
                        icon={isPlaying === audio.name ? faStopCircle : faPlayCircle}
                        onClick={() => togglePlayPause(audio.name)}
                        size="2x"
                        style={{ color: "#F02488", cursor: "pointer" }}
                      />
                      <audio
                        ref={el => (audioRefs.current[audio.name] = el!)}
                        id={audio.name}
                        src={audio.src}
                        controls
                        style={{ display: "none" }}
                      />
                    </div>
                    <p className="fs-9" style={{ marginTop: "8px" }}>Sélectionner</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}

      {step === 2 && (
        <div className="mb-2">
          <h3>Sélectionnez votre musique</h3>
          <div className="d-flex flex-wrap gap-4 my-2">
            {["Pop rock", "Electro", "Soft"].map(category => (
              <Button
                key={category}
                variant={selectedCategories.includes(category) ? "primary" : "light"}
                onClick={() => toggleCategorySelection(category)}
                className="btn-sm border-secondary"
                style={{
                  backgroundColor: selectedCategories.includes(category) ? "#3874FF" : "",
                  color: selectedCategories.includes(category) ? "white" : ""
                }}
              >
                {category}
              </Button>
            ))}
          </div>

          {selectedCategories.length > 0 && (
            <Row className="g-3">
              {filteredAudio("music").map(audio => (
                <Col key={audio.id} lg={1} xs={6} sm={4}>
                  <Card
                    className={`overflow-hidden text-center`}
                    onClick={() => handleSelectAudioOrMusicChange(audio.id, "music")}
                    style={{
                      cursor: "pointer",
                      borderColor: selectedMusicFiles.includes(audio.id) ? "#3874FF" : "",
                      borderWidth: selectedMusicFiles.includes(audio.id) ? "3px" : ""
                    }}
                  >
                    <Card.Body className="p-2">
                      <h6 className="fs-9">{audio.name}</h6>
                      <div>
                        <FontAwesomeIcon icon={isPlaying === audio.name ? faStopCircle : faPlayCircle}
                                         onClick={() => togglePlayPause(audio.name)} size="2x"
                                         style={{ color: "#F02488", cursor: "pointer" }} />
                        <audio ref={el => (audioRefs.current[audio.name] = el!)} id={audio.name} src={audio.src}
                               controls style={{ display: "none" }} />
                      </div>
                      <p className="fs-9" style={{ marginTop: "8px" }}>Sélectionner</p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </div>
      )}

      {step === 3 && (
        <div>
          <h3>Quel type de message attendez-vous ?</h3>
          <div className="d-flex flex-wrap gap-3 my-2">
            {["Répondeur", "SVI", "Prédécroché", "Message d'attente"].map(type => (
              <Button
                key={type}
                variant={selectedMessageType.includes(type) ? "primary" : "#FFFFFF"}
                onClick={() => toggleMessageTypeSelection(type)}
                className="btn-sm border-secondary"
                style={{ backgroundColor: selectedMessageType.includes(type) ? "#3874FF" : "" }}
              >
                {type}
              </Button>
            ))}
          </div>

          <Form>
            <Form.Group controlId="customMessage">
              <Form.Label>Votre message personnalisé</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Tapez votre message ici..."
                value={savedAudio.find(audio => audio.type === "Message")?.name || ""}
                onChange={handleMessageChange}
              />
            </Form.Group>
          </Form>

          <div className="mt-3">
            <h5 style={{ whiteSpace: "pre-line" }}>Exemple de message</h5>
            <p className="fs-9">{getMessageExample(selectedMessageType[0])}</p>
          </div>
        </div>
      )}

      {savedAudio.length > 0 && (
        <div className="mt-4">
          <h3 className="mb-3">Sélection de votre message</h3>
          <p>Nombre de voix sélectionnées: {selectedVoiceFiles.length} <br />
            Tarrification totale : {voiceCount} € HT + TVA</p>

          <div className="d-flex flex-wrap gap-3">
            {savedAudio.map((audio, index) => (
              <div key={index} className="d-flex align-items-center p-3 border rounded-3 mb-2">
                <div className="me-3">
                  {audio.type === "Voice" && <FontAwesomeIcon icon={faVolumeUp} size="2x" />}
                  {audio.type === "Music" && <FontAwesomeIcon icon={faMusic} size="2x" />}
                  {audio.type !== "Voice" && audio.type !== "Music" && <FontAwesomeIcon icon={faComment} size="2x" />}
                </div>
                <div className="d-flex flex-column">
                  <span className="fw-bold">
                    {audio.type === "Voice" ? `Voix de : ${audio.name}` :
                      audio.type === "Music" ? `Musique de : ${audio.name}` :
                        `Votre Message de ${selectedMessageType[0]} : ${audio.name}`}
                  </span>
                  {audio.type === "Voice" && <span>Langue : {audio.language}</span>}
                </div>

                {(audio.type === "Voice" || audio.type === "Music") && (
                  <FontAwesomeIcon
                    icon={isPlaying === audio.name ? faStopCircle : faPlayCircle}
                    onClick={() => togglePlayPause(audio.name)}
                    size="2x"
                    style={{ cursor: "pointer" }}
                    className="px-3"
                  />

                )}

                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleRemoveAudio(audio.name)}
                  size="1x"
                  style={{ cursor: "pointer", color: "red" }}
                  className="ms-3"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <Row className="justify-content-start">
        {step > 1 && <Col xs={12} sm={6} md={4} lg={2} className="d-flex justify-content-center mb-2">
          <Button className="mx-4 w-100" onClick={beforeStep}>Retour</Button>
        </Col>}
        {step < totalSteps && <Col xs={12} sm={6} md={4} lg={2} className="d-flex justify-content-center mb-2">
          <Button onClick={nextStep} variant="phoenix-primary" className="w-100">Étape suivante</Button>
        </Col>}
      </Row>

      {step === 3 &&
        <Col xs={12} sm={6} md={4} lg={2} className="d-flex justify-content-center mb-2" style={{ margin: "auto" }}
             onClick={handleSubmit}>
          <Button variant="success" className="w-100">Commander</Button>
        </Col>}

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ajout d'un message</Modal.Title>
        </Modal.Header>
        <Modal.Body>Votre formulaire d'ajout de message a été envoyé avec succès. Nous vous contacterons sous
          peu.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MessageOneClic;
