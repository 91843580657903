import { useState, useCallback, useEffect } from "react";

export interface ScheduleData {
  id: number;
  idClient: number;
  name: string;
  startSlot1: string;
  endSlot1: string;
  startSlot2: string;
  endSlot2: string;
  days: string;
  state: boolean;
}

// Fallback data in case the API fails
const fallBackScheduleData: ScheduleData[] = [
  {
    id: 1,
    idClient: 9,
    name: "Afternoon Schedule",
    startSlot1: "08:00:00",
    endSlot1: "12:00:00",
    startSlot2: "13:00:00",
    endSlot2: "17:00:00",
    days: "Lundi-Vendredi",
    state: true
  },
];

// Default empty schedule data
const scheduleTableData: ScheduleData[] = [];

/**
 * **Plans d'appel**:
 * A custom hook that manages schedule data for call plans, including loading and error states.
 * It fetches schedule data (such as start and end slots, days, and state) from an external API, using token-based authentication stored in localStorage.
 *
 * @returns {Object} - The schedule data, loading state, error message, and a setter for schedule data.
 *
 * @property {ScheduleData[]} scheduleData - Schedule data fetched from the API or fallback data in case of errors.
 * @property {Function} setScheduleData - Function to manually set the schedule data.
 * @property {boolean} loading - Indicates whether data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * // Example usage of useSchedule in a component
 * const { scheduleData, loading, error } = useSchedule();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ScheduleList data={scheduleData} />;
 */
const useSchedule = () => {
  const [scheduleData, setScheduleData] = useState<ScheduleData[]>(scheduleTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/schedule', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setScheduleData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      // setScheduleData(fallBackScheduleData); // Use fallback data in case of error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    scheduleData,
    setScheduleData,
    loading,
    error
  };
};

export default useSchedule;
