import React, { CSSProperties, useEffect, useState } from "react";
import CoverUpload from "components/common/CoverUpload";
import AvatarUpload from "components/common/AvatarUpload";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import coverImage from "assets/img/iper-x/profile-banner/2.png";
import { Status } from "../base/Avatar";

interface SocialProfileCardProps {
  showAbout?: boolean;
  avatar: string;
  className?: string;
}

interface CompanyData {
  name: string;
  code_client: string;
  address: string;
  status_prospect_label: string;
  siret: string;
  presidentName: string;
  representativeName: string;
  representativeTitle: string;
  representativeEmail: string;
  representativePhone: string;
  phone?: string;
  website?: string;
  description?: string;
  retailerRank?: string;
}

const SocialProfileCard = ({
  showAbout,
  avatar,
  className
}: SocialProfileCardProps) => {
  const [show, setShow] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const DOLID = localStorage.getItem('DOLID');
    const companyID = localStorage.getItem('companyID');
    const token = localStorage.getItem('token');

    const fetchCompanyData = async () => {
      try {
        // First API Call (ERP)
        const companyResponse = await fetch(
          'https://erp.ipercom.io/api/index.php/thirdparties/' + DOLID,
          {
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl'
            }
          }
        );

        let erpCompanyData = null;
        if (companyResponse.ok) {
          erpCompanyData = await companyResponse.json();
        }

        // Second API Call (Company Data API)
        const companyAPICall = await fetch(
          `https://rct-backend.ipercom.io/api/Company/${companyID}`,
          {
            headers: {
              accept: 'text/plain',
              Authorization: `Bearer ${token}`
            }
          }
        );

        let fallbackCompanyData = null;
        if (companyAPICall.ok) {
          fallbackCompanyData = await companyAPICall.json();
        }

        // Default representative data
        let representativeData = {
          name: 'Benoit LEFEVRE',
          title: 'Directeur Commercial',
          email: "blefevre@ipercom.com",
          phone: '0644390949'
        };

        // Fallback logic: Use representative from ERP if available, else fallback to default
        if (erpCompanyData) {
          const representativeResponse = await fetch(
            'https://erp.ipercom.io/api/index.php/thirdparties/' +
              DOLID +
              '/representatives',
            {
              headers: {
                Accept: 'application/json',
                DOLAPIKEY: 'VPnBn591lOztE1g8H7p06aHR2Akn1ABl'
              }
            }
          );

          if (representativeResponse.ok) {
            const representativeArray = await representativeResponse.json();
            const representative = representativeArray[0]; // Use the first representative if multiple exist

            if (representative) {
              representativeData = {
                name: `${representative.lastname} ${representative.firstname}`,
                title: representative.statutLabel || 'Directeur Commercial',
                email: representative.email || "blefevre@ipercom.com",
                phone: representative.phone_mobile || '0644390949'
              };
            }
          }
        }

        // Fallback logic: Use data from ERP if available, else fallback to secondary API
        const mergedCompanyData = {
          name: erpCompanyData?.name || fallbackCompanyData?.name,
          code_client:
            erpCompanyData?.code_client || fallbackCompanyData?.idCompanies,
          address: fallbackCompanyData?.address || erpCompanyData?.address,
          status_prospect_label: erpCompanyData?.status_prospect_label || 'N/A',
          siret: erpCompanyData?.siret || fallbackCompanyData?.siret,
          presidentName:
            erpCompanyData?.presidentName || fallbackCompanyData?.ceoName,
          representativeName: representativeData.name,
          representativeTitle: representativeData.title,
          representativeEmail: representativeData.email,
          representativePhone: representativeData.phone,
          phone: fallbackCompanyData?.phone,
          website: fallbackCompanyData?.website,
          description: fallbackCompanyData?.description,
          retailerRank: fallbackCompanyData?.retailerRank?.name
        };

        setCompanyData(mergedCompanyData);
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    fetchCompanyData();
  }, []);

  return (
    <Card className={className}>
      <Card.Header
        className="position-relative mb-6"
        style={{ minHeight: '130px' }}
      >
        <CoverUpload
          src={coverImage}
          gradient={
            'linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%)' as CSSProperties
          }
        />
        <AvatarUpload
          size="4xl"
          src={companyData?.description || ''}
          status={
            (companyData?.retailerRank?.toLowerCase() as Status) || 'ivoire'
          }
          thumbnail
          className="feed-avatar-profile"
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-wrap mb-2 align-items-center">
              <div className="d-flex align-items-center mb-3">
                <h3 className="fw-bolder mb-2">
                  {companyData ? companyData.name : ''}
                </h3>
                {/*<Button variant="link" className="px-3" onClick={handleShow}>*/}
                {/*  Modifier*/}
                {/*</Button>*/}
              </div>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="companyName">
                    <Form.Label>Nom de l'entreprise</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={companyData?.name || ''}
                      value={companyData?.name || ''}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="ceoName">
                    <Form.Label>PDG</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={companyData?.presidentName || ''}
                      value={companyData?.presidentName || ''}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="companySiret">
                    <Form.Label>Siret de l'entreprise</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={companyData?.siret || ''}
                      value={companyData?.siret || ''}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="companyAddress">
                    <Form.Label>Adresse de l'entreprise</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={companyData?.address || ''}
                      value={companyData?.siret || ''}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="certificationLevel">
                    <Form.Label>Niveau de certification</Form.Label>
                    <Form.Control type="text" placeholder="Avancé" />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Annuler
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Sauvegarder
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="mt-0">
              <p className="mb-0">PDG : {companyData?.presidentName || ''}</p>
              <p className="mb-0">Siret : {companyData?.siret || ''}</p>
              <p className="mb-0">Adresse : {companyData?.address || ''}</p>
            </div>

            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>ID partenaire :</b>{' '}
                {companyData?.code_client || 'pas defini'}
              </p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Niveau de certification :</b> {'En attente'}
              </p>
            </div>

            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Votre distributeur</b>
              </p>
              <p className="mb-0">ipercom</p>
              <p className="mb-0">Service Commercial</p>
              <p className="mb-0">+33 5 18 22 22 00</p>
              <p className="mb-0">adv@ipercom.com</p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Votre représentant commercial</b>
              </p>
              <p className="text-700 mb-0">
                <p className="mb-0">{companyData?.representativeName || ''}</p>
                <p className="mb-0">{companyData?.representativeTitle || ''}</p>
                <p className="mb-0">{companyData?.representativeEmail || ''}</p>
                <p className="mb-0">{companyData?.representativePhone || ''}</p>
              </p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Conditions de règlement</b>
              </p>
              <p className="text-700 mb-0">
                <p className="mb-0">Prélèvement automatique</p>
              </p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SocialProfileCard;
