import React from 'react';
import { Button, Nav } from 'react-bootstrap';
import { faFilm, faClock, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ParamAdvance = () => {
  return (
    <>
      <div className="mb-5">
        <div className="g-3 row">
          <div className="col-xl-12 gy-3">
            <Nav className="" style={{ width: '90%' }}>
              <Button
                className="btn bg-white px-10 pt-4 pb-3 fs-8  nav-link "
                href="/ipbx/parametres/replacement-e164"
              >
                <FontAwesomeIcon
                  icon={faFilm}
                  className="category-icon text-800 fs-6"
                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  Remplacement E164
                </span>
              </Button>

              <Button
                className="btn bg-white px-10 ms-5 pt-4 pb-3 fs-8  nav-link "
                href="/ipbx/parametres/language-office-hours"
              >
                <FontAwesomeIcon
                  icon={faClock}
                  className="category-icon text-800 fs-6"
                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  Langue et fuseau horaire
                </span>
              </Button>

              <Button
                className="btn bg-white px-10 ms-5 pt-4 pb-3 fs-8  nav-link "
                href="/ipbx/parametres/update"
              >
                <FontAwesomeIcon
                  icon={faSync}
                  className="category-icon text-800 fs-6"
                />
                <span className="d-block fs-12 fw-bolder lh-1 text-900 mt-3 mb-2">
                  Mise à jour
                </span>
              </Button>
            </Nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParamAdvance;
