import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import useRetailerUsers, { UserData } from 'hooks/dolibarr/useRetailerUsers';
import AdvanceTable from '../../../../components/base/AdvanceTable';
import AdvanceTableFooter from '../../../../components/base/AdvanceTableFooter';

// Define the table columns, including necessary fields
export const userAPITableColumns: ColumnDef<UserData>[] = [
  {
    accessorFn: ({ id, firstname, lastname, login }) => ({ id, firstname, lastname, login }),
    id: 'nameLogin',
    header: 'Utilisateur',
    cell: ({ row: { original } }) => {
      const { id, firstname, lastname, login } = original;

      const avatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(firstname)}+${encodeURIComponent(lastname)}&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center">
          <Avatar
            src={avatar}
            size="m"
            className="me-2"
          />
          <div className="d-flex flex-column ms-2">
            {/* Ajout du lien autour du nom et prénom */}
            <Link to={`/portail-revendeur/utilisateurs/details/${id}`} className="text-dark">
              <p className="mb-0 fw-bold">
                {firstname} {lastname}
              </p>
            </Link>
            {/* Affichage du login aligné à gauche */}
            <small className="text-muted" style={{ textAlign: "left", display: "block" }}>
              {login ?? 'N/A'}
            </small>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "15%", minWidth: 250 }
      },
      cellProps: { className: "ps-3" }
    }
  },




  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => original?.email ?? 'N/A',
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'office_phone',
    header: 'Téléphone',
    cell: ({ row: { original } }) => original?.office_phone ?? 'N/A',
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'user_mobile',
    header: 'Mobile',
    cell: ({ row: { original } }) => original?.user_mobile ?? 'N/A',
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },


  {
    accessorKey: 'datelastlogin',
    header: 'Dernière connexion',
    cell: ({ row: { original } }) => {
      const lastLogin = original?.datelastlogin ? new Date(original.datelastlogin * 1000).toLocaleString() : 'Jamais';
      return lastLogin;
    },
    meta: {
      headerProps: { style: { width: '15%', fontSize: '12px' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
];

const UserTableAPIRetailer = () => {
  const { usersData, loading, error } = useRetailerUsers(); // Fetching data using your hook

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div>
      <AdvanceTable
        // tableProps={{ className: 'phoenix-table fs-9 text-center' }}
        // columns={userAPITableColumns} // Updated columns with fields
        // data={usersData} // Passing the actual user data from your API
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UserTableAPIRetailer;
