import React, { useState, useEffect, ChangeEvent } from "react";
import { Form, Button, Row, Col, Accordion, InputGroup } from "react-bootstrap";
import useAppModules from "hooks/api/security/useAppModules";
import useModuleFeatures from "hooks/api/security/useModuleFeatures";
import axios from "axios";
import { UserData } from "../../../../../types/user";

// Define the types
type Feature = {
  idFeature: number;
  idModule: number;
  name: string;
  slug: string;
};

type Module = {
  idModule: number;
  idApps: number;
  name: string;
  slug: string;
};

type UserEditFormProps = {
  user?: UserData;
  onSave: (updatedUser: UserData) => void;
  onCancel: () => void;
};

const templates = {
  "Utilisateur simple": {
    modules: [1, 5, 6, 7],
    features: [1, 2, 3]
  },
  Revendeur: {
    modules: [1, 2, 3, 4, 5, 6, 7],
    features: [1, 2, 3, 4, 5, 6, 7, 8]
  },
  Administrateur: {
    modules: [1, 2, 3, 4, 5, 6, 7, 40, 47, 45, 46],
    features: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19
    ]
  }
};

const UserEditForm: React.FC<UserEditFormProps> = ({
                                                     user,
                                                     onSave,
                                                     onCancel
                                                   }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [permissionMode, setPermissionMode] = useState<"template" | "custom">(
    "template"
  );
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const {
    appModules,
    loading: loadingModules,
    error: errorModules
  } = useAppModules();
  const {
    moduleFeatures,
    loading: loadingFeatures,
    error: errorFeatures
  } = useModuleFeatures();

  const toggleShowPassword = () => {
    setShowPassword(prevShow => !prevShow);
  };

  // Sync the form when user prop changes
  useEffect(() => {
    if (user) {
      setUserData({
        ...user,
        moduleAccess: user.moduleAccess || [],
        features: user.features || []
      });
    }
  }, [user]);

  // Fetch UserAccess and UserFeature from API and merge into userData
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        if (user && user.idUsers) {
          const token = localStorage.getItem("token");

          const [accessResponse, featureResponse] = await Promise.all([
            axios.get(
              `https://rct-backend.ipercom.io/api/UserAccess/${user.idUsers}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  accept: "text/plain"
                }
              }
            ),
            axios.get(
              `https://rct-backend.ipercom.io/api/UserFeature/${user.idUsers}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  accept: "text/plain"
                }
              }
            )
          ]);

          setUserData(prevUserData =>
            prevUserData
              ? {
                ...prevUserData,
                moduleAccess: accessResponse.data,
                features: featureResponse.data
              }
              : null
          );
        }
        setLoadingPermissions(false);
      } catch (err) {
        setError("Failed to load user permissions.");
        setLoadingPermissions(false);
      }
    };

    fetchUserPermissions();
  }, [user]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (userData) {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };

  const handleTemplateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const template = e.target.value as keyof typeof templates;
    setSelectedTemplate(template);

    if (templates[template] && userData) {
      const selectedTemplateData = templates[template];
      const newModuleAccess = selectedTemplateData.modules.map(idModule => ({
        idModule,
        hasAccess: true
      }));
      const newFeatures = selectedTemplateData.features.map(idFeature => ({
        idFeature,
        canDo: true
      }));

      setUserData(prevData => ({
        ...prevData!,
        moduleAccess: newModuleAccess,
        features: newFeatures
      }));
    }
  };

  const handleModuleAccessChange = (idModule: number, hasAccess: boolean) => {
    if (userData) {
      const updatedModuleAccess = userData.moduleAccess.filter(
        m => m.idModule !== idModule
      );
      if (hasAccess) {
        updatedModuleAccess.push({ idModule, hasAccess });
      }
      setUserData({ ...userData, moduleAccess: updatedModuleAccess });
    }
  };

  const handleFeatureChange = (idFeature: number, canDo: boolean) => {
    if (userData) {
      const updatedFeatures = userData.features.filter(
        f => f.idFeature !== idFeature
      );
      if (canDo) {
        updatedFeatures.push({ idFeature, canDo });
      }
      setUserData({ ...userData, features: updatedFeatures });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (userData) {
      onSave(userData);
    }
  };

  if (loadingPermissions || !userData || loadingModules || loadingFeatures) {
    return <p>Loading...</p>;
  }

  if (error || errorModules || errorFeatures) {
    return <p>{error || "Error loading data."}</p>;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="firstname">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={userData.firstname}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastname">
            <Form.Label>Nom de famille</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={userData.lastname}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="mail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              name="mail"
              value={userData.mail}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="phone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={userData.phone}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              name="mobile"
              value={userData.mobile}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <h3>Autorisations utilisateur</h3>
      <Form.Group controlId="permissionMode" className="mb-3">
        <Form.Label>Mode d'autorisation</Form.Label>
        <Form.Check
          type="radio"
          label="Utiliser un modèle"
          name="permissionMode"
          value="template"
          checked={permissionMode === "template"}
          onChange={() => setPermissionMode("template")}
        />
        <Form.Check
          type="radio"
          label="Personnaliser les autorisations"
          name="permissionMode"
          value="custom"
          checked={permissionMode === "custom"}
          onChange={() => setPermissionMode("custom")}
        />
      </Form.Group>

      {permissionMode === "template" && (
        <Form.Group controlId="template" className="mb-3">
          <Form.Label>Sélectionnez un modèle</Form.Label>
          <Form.Select value={selectedTemplate} onChange={handleTemplateChange}>
            <option value="">Sélectionnez un modèle</option>
            <option value="Utilisateur simple">Utilisateur simple</option>
            <option value="Revendeur">Revendeur</option>
            <option value="Administrateur">Administrateur</option>
          </Form.Select>
        </Form.Group>
      )}

      <Accordion>
        {appModules
          .filter(module => module.idApps === 1)
          .map(module => (
            <Accordion.Item
              eventKey={`module-${module.idModule}`}
              key={module.idModule}
            >
              <Accordion.Header>{module.name}</Accordion.Header>
              <Accordion.Body>
                <Form.Check
                  type="checkbox"
                  label={`Accès au ${module.name}`}
                  checked={userData.moduleAccess.some(
                    m => m.idModule === module.idModule && m.hasAccess
                  )}
                  onChange={e =>
                    handleModuleAccessChange(module.idModule, e.target.checked)
                  }
                />
                {moduleFeatures
                  .filter(feature => feature.idModule === module.idModule)
                  .map(feature => (
                    <div key={feature.idFeature} className="ms-3">
                      <Form.Check
                        type="checkbox"
                        label={feature.name}
                        checked={userData.features.some(
                          f => f.idFeature === feature.idFeature && f.canDo
                        )}
                        onChange={e =>
                          handleFeatureChange(
                            feature.idFeature,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>

      <Button variant="primary" type="submit" className="mt-5">
        Enregistrer les modifications
      </Button>
      <Button variant="secondary" onClick={onCancel} className="mt-5 ms-2">
        Annuler
      </Button>
    </Form>
  );
};

export default UserEditForm;
