import { Button, Col, Row } from 'react-bootstrap';

import React, { ChangeEvent, useState } from 'react';
import { PageBreadcrumbItem } from '../../../../../../components/common/PageBreadcrumb';
import AdvanceTableProvider from '../../../../../../providers/AdvanceTableProvider';
import SearchBox from '../../../../../../components/common/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faFileImport,
  faFilter,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import LeadsFilterModal from '../../../../../../components/modals/LeadsFilterModal';
import useAdvanceTable from '../../../../../../hooks/others/useAdvanceTable';
import { holdMusicPlaylistTableData } from "../../../../../../data/iper-x/ipbx/holdMusicPlaylist";
import HoldMusicPlaylistTable, { holdMusicPlaylistTableColumns } from "../../../../../../components/tables/ipbx/HoldMusicPlaylistTable";

export const generalBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Systéme',
    url: '/apps/ipbx/parametres?selected=system'
  },
  {
    label: 'Général',
    url: '/apps/ipbx/parametres/general'
  }
];

const PlaylistConfig = () => {
  const table = useAdvanceTable({
    data: holdMusicPlaylistTableData,
    columns: holdMusicPlaylistTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <>
      <div className="mb-4">
        <Row className="g-3">
          <Col xs="auto">
            <SearchBox
              className="w-300"
              placeholder="Rechercher"
              onChange={handleSearchInputChange}
            />
          </Col>
          <Col
            xs="auto"
            className="scrollbar overflow-hidden-y flex-grow-1"
          ></Col>
          <Col xs="auto">
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
              Importer
            </Button>
            <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Exporter
            </Button>
            <Button variant="primary">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter une playlist
            </Button>
            <Button
              variant="phoenix-secondary"
              className="px-3 ms-2"
              onClick={() => setOpenFilterModal(true)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                transform="down-3"
                className="text-primary"
              />
            </Button>
            <LeadsFilterModal
              show={openFilterModal}
              handleClose={() => setOpenFilterModal(false)}
            />
          </Col>
        </Row>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mx-n4 pt-5 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <HoldMusicPlaylistTable />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default PlaylistConfig;
