import React, { createContext, useContext, ReactNode } from "react";
import useSda, { SdaData } from "hooks/api/ipbx/useSda"; // Ensure the path is correct

// Create the context for SDA data
const SdaContext = createContext<SdaData[] | null>(null);

// Custom hook to use the SdaContext
export const useSdaData = () => {
  const context = useContext(SdaContext);
  if (!context) {
    throw new Error("useSdaData must be used within a SdaProvider");
  }
  return context;
};

// Provider component for the SdaContext
export const SdaProvider = ({
                              sdaData,
                              children
                            }: {
  sdaData: SdaData[];
  children: ReactNode;
}) => {
  return (
    <SdaContext.Provider value={sdaData}>
      {children}
    </SdaContext.Provider>
  );
};
