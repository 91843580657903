import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
  Alert
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";

type DevicesFormProps = {
  currentDevice?: PhoneDevicesData; // Pass null for creation
  onSave: (device: PhoneDevicesData) => void;
  onClose: () => void;
};

const DevicesForm: React.FC<DevicesFormProps> = ({
                                                   currentDevice,
                                                   onSave,
                                                   onClose
                                                 }) => {
  const predefinedLocations = ["Bureau", "Salle de réunion", "Stock", "Accueil"];

  const [deviceData, setDeviceData] = useState<PhoneDevicesData>({
    id: currentDevice?.id || 0,
    idClient: currentDevice?.idClient || 0,
    idPhoneUser: currentDevice?.idPhoneUser || 0,
    brand: currentDevice?.brand || "",
    deviceModel: currentDevice?.deviceModel || "",
    firmwareVersion: currentDevice?.firmwareVersion || "",
    macAddress: currentDevice?.macAddress || "",
    machineId: currentDevice?.machineId || "",
    privateIpAddress: currentDevice?.privateIpAddress || "",
    publicIpAddress: currentDevice?.publicIpAddress || "",
    location: currentDevice?.location || "",
    deviceType: currentDevice?.deviceType || "",
    provisioningStatus: currentDevice?.provisioningStatus || "Non provisionné",
    deviceStatus: currentDevice?.deviceStatus || "Inactif",
    createdAt: currentDevice?.createdAt || new Date().toISOString(),
    name: "",
    description: "",
    serialNumber: ""
  });
  const [error, setError] = useState<string | null>(null);

  const handleChange = (field: keyof PhoneDevicesData, value: string) => {
    setDeviceData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!deviceData.name || !deviceData.brand || !deviceData.serialNumber || !deviceData.macAddress) {
      setError("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    setError(null);
    onSave(deviceData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Nom */}
      <Form.Group controlId="name" className="mb-3">
        <Form.Label>
          Nom
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Nom de l'appareil. Ce champ est obligatoire.</Tooltip>}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="ms-2 text-info" />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="text"
          value={deviceData.name}
          onChange={e => handleChange("name", e.target.value)}
          placeholder="Entrer le nom de l'appareil"
          required
        />
      </Form.Group>

      {/* Description */}
      <Form.Group controlId="description" className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={deviceData.description}
          onChange={e => handleChange("description", e.target.value)}
          placeholder="Ajouter une description de l'appareil (facultatif)"
        />
      </Form.Group>

      <Row className="mb-3">
        {/* Constructeur */}
        <Col>
          <Form.Group controlId="brand">
            <Form.Label>
              Constructeur
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Sélectionnez le constructeur de l'appareil. Ce champ est obligatoire.</Tooltip>}
              >
                <FontAwesomeIcon icon={faCircleInfo} className="ms-2 text-info" />
              </OverlayTrigger>
            </Form.Label>
            <Form.Select
              value={deviceData.brand}
              onChange={e => handleChange("brand", e.target.value)}
              required
            >
              <option value="">Sélectionnez un constructeur</option>
              <option value="Yealink">Yealink</option>
              <option value="Fanvil">Fanvil</option>
              <option value="Grandstream">Grandstream</option>
              <option value="Cisco">Cisco</option>
              <option value="Polycom">Polycom</option>
              <option value="Avaya">Avaya</option>
            </Form.Select>
          </Form.Group>
        </Col>

        {/* Modèle */}
        <Col>
          <Form.Group controlId="deviceModel">
            <Form.Label>Modèle</Form.Label>
            <Form.Select
              value={deviceData.deviceModel}
              onChange={e => handleChange("deviceModel", e.target.value)}
              disabled={!deviceData.brand}
            >
              <option value="">Sélectionnez un modèle (optionnel)</option>
              {deviceData.brand === "Yealink" && (
                <>
                  <option value="T31P">T31P</option>
                  <option value="T33G">T33G</option>
                  <option value="W73P">W73P</option>
                </>
              )}
              {/* Ajoutez d'autres options pour les marques */}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        {/* MAC Address */}
        <Col>
          <Form.Group controlId="macAddress">
            <Form.Label>
              Adresse MAC
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Entrez l'adresse MAC de l'appareil. Ce champ est obligatoire.</Tooltip>}
              >
                <FontAwesomeIcon icon={faCircleInfo} className="ms-2 text-info" />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type="text"
              value={deviceData.macAddress}
              onChange={e => handleChange("macAddress", e.target.value)}
              placeholder="Entrer l'adresse MAC"
              required
            />
          </Form.Group>
        </Col>

        {/* Numéro de Série */}
        <Col>
          <Form.Group controlId="serialNumber">
            <Form.Label>
              Numéro de série (SN)
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Numéro de série unique de l'appareil. Ce champ est obligatoire.</Tooltip>}
              >
                <FontAwesomeIcon icon={faCircleInfo} className="ms-2 text-info" />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type="text"
              value={deviceData.serialNumber}
              onChange={e => handleChange("serialNumber", e.target.value)}
              placeholder="Entrer le numéro de série"
              required
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Emplacement */}
      <Form.Group controlId="location" className="mb-3">
        <Form.Label>
          Emplacement
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Sélectionnez un emplacement prédéfini ou entrez un nouvel emplacement.</Tooltip>}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="ms-2 text-info" />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          list="predefinedLocations"
          value={deviceData.location}
          onChange={e => handleChange("location", e.target.value)}
          placeholder="Sélectionnez ou entrez un emplacement"
        />
        <datalist id="predefinedLocations">
          {predefinedLocations.map(location => (
            <option key={location} value={location} />
          ))}
        </datalist>
      </Form.Group>

      <div className="d-flex justify-content-end">
        <Button variant="secondary" className="me-2" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="primary" type="submit">
          {currentDevice ? "Modifier" : "Ajouter"}
        </Button>
      </div>
    </Form>
  );
};

export default DevicesForm;
