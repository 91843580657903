import { createContext, useContext, ReactNode } from "react";
import { MsgVocalData } from "hooks/api/ipbx/useMsgVocal"; // Assurez-vous que le chemin est correct

// Création du contexte pour les données de messagerie vocale
const MsgVocalContext = createContext<MsgVocalData[] | null>(null);

// Hook pour utiliser le contexte de messagerie vocale
export const useMsgVocalData = () => {
  const context = useContext(MsgVocalContext);
  if (!context) {
    throw new Error("useMsgVocalData must be used within a MsgVocalProvider");
  }
  return context;
};

// Fournisseur de contexte pour les données de messagerie vocale
export const MsgVocalProvider = ({
                                   msgVocalData,
                                   children
                                 }: {
  msgVocalData: MsgVocalData[];
  children: ReactNode;
}) => {
  return (
    <MsgVocalContext.Provider value={msgVocalData}>
      {children}
    </MsgVocalContext.Provider>
  );
};
