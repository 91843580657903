import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';

interface InvoiceCreateModalProps {
  show: boolean;
  handleClose: () => void;
}

interface OrderLine {
  fk_product: number;
  qty: number;
  subprice: number;
  tva_tx?: number; // Assuming it's a number, you can adjust the type if needed
  options_licences?: string; // Assuming it's a string, you can adjust the type if needed
}

interface Order {
  id: string;
  ref: string;
  total_ht: string;
  socid: string;
  date_commande: string;
  status: string;
  lines: OrderLine[];
  billed: string;
}


const InvoiceCreateModal: React.FC<InvoiceCreateModalProps> = ({ show, handleClose }) => {
  const [orderId, setOrderId] = useState('');
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
      try {
        const response = await fetch('https://erp.ipercom.io/api/index.php/orders?sortfield=t.rowid&sortorder=ASC&limit=100', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || ''
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        // Filter orders that are not already invoiced or in draft status
      //  const filteredOrders = result.filter((order: Order) => order.billed === '0' && order.status !== '0');
        setOrders(result);
      } catch (error) {
        console.error("Erreur lors de la récupération des données orders:", error);
      }
    };

    fetchOrders();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const selectedOrder = orders.find(o => o.id === orderId);
    if (!selectedOrder) return;

    const invoiceData = {
      socid: selectedOrder.socid,
      date: Math.floor(Date.now() / 1000), // Current timestamp in seconds
      type: 0, // Assuming 0 is the type for normal invoices
      lines: selectedOrder.lines.map(line => ({
        fk_product: line.fk_product,
        qty: line.qty,
        subprice: line.subprice,
        tva_tx: line.tva_tx,
        array_options: {
          options_licences: line.options_licences
        }
      })),
      array_options: {
        options_ref_commande: selectedOrder.ref
      }
    };

    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
    try {
      const response = await fetch('https://erp.ipercom.io/api/index.php/invoices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'DOLAPIKEY': DOLAPIKEY || ''
        },
        body: JSON.stringify(invoiceData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Invoice created:', result);
      handleClose();
    } catch (error) {
      console.error('Error creating invoice:', error);
    }
  };


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Créer une facture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="ORDER_ID">
            <Form.Label>Commande</Form.Label>
            <Form.Control as="select" value={orderId} onChange={(e) => setOrderId(e.target.value)} required>
              <option value="">Sélectionner une commande</option>
              {orders.map((order) => (
                <option key={order.id} value={order.id}>
                  {order.ref} - {order.total_ht} EUR
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit" className='mt-4 mb-4'>
            Créer
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceCreateModal;
