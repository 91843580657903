// src/components/AvatarGroup.tsx

import React from "react";
import AvatarDropdown, { PhoneUser } from "./AvatarDropdown";

// import { PhoneUser } from "../hooks/usePhoneUsers";

interface AvatarGroupProps {
  users: PhoneUser[];
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({ users }) => {
  return (
    <div className="d-flex">
      {users.map(user => (
        <AvatarDropdown key={user.id} user={user} />
      ))}
    </div>
  );
};

export default AvatarGroup;
