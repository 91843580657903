import React from 'react';
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faDownload,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import AdvanceTableProvider from '../../../../../../providers/AdvanceTableProvider';
import useAdvanceTable from '../../../../../../hooks/others/useAdvanceTable';
import Microsoft365IntegrationsTable, {
  microsoft365IntegrationsInstalledTableColumns
} from '../../../../../../components/tables/ipbx/Microsoft365IntegrationsTable';
import { microsoft365IntegrationsTableData } from '../../../../../../data/iper-x/ipbx/microsoft365Integrations';

const TeamsRoutingConfig = () => {
  const table = useAdvanceTable({
    data: microsoft365IntegrationsTableData,
    columns: microsoft365IntegrationsInstalledTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <>
      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Activer le routage direct Microsoft Teams
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Check.Label className="fs-9 fw-bolder">
                  Étape 1 : Spécifiez votre FQDN Teams
                </Form.Check.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="Nombre">
                  <Form.Control
                    type="text"
                    value="Le FQDN de votre SBC teams doit correspondre à l’email de domaine de MS365"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Check.Label className="fs-9 fw-bolder">
                Étape 1 : Spécifiez votre FQDN Teams
              </Form.Check.Label>
              <br />
              <span className="me-3">
                Microsoft nécessite un certificat de l'un des fournisseurs
                suivants .
              </span>
              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Check.Label className="fs-9 fw-bolder">
                  Certificat
                </Form.Check.Label>
                <InputGroup className="mb-3">
                  <FloatingLabel
                    controlId="floatingInputAuthMax"
                    label="Nombre"
                  >
                    <Form.Control type="text" value="" />
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2 pt-3 ">
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    Uploader
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2 pt-3">
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                    Supprimer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Check.Label className="fs-9 fw-bolder">
                  Clé privée
                </Form.Check.Label>
                <InputGroup className="mb-3">
                  <FloatingLabel
                    controlId="floatingInputAuthMax"
                    label="Nombre"
                  >
                    <Form.Control type="text" value="" />
                  </FloatingLabel>
                  <Button variant="btn btn-white border border-1 px-2 pt-3 ">
                    <FontAwesomeIcon icon={faDownload} className="me-2" />
                    Uploader
                  </Button>
                  <Button variant="btn btn-white border border-1 px-2 pt-3">
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                    Supprimer
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Check.Label className="fs-9 fw-bolder">
                Étape 3 : Téléchargez et exécutez le script de plan de
                numérotation
              </Form.Check.Label>
              <br />
              <span className="me-3">
                Sélectionner votre pays pour ajuster le script du plan de
                numérotation. Pour plus d'informations, consultez ce guide.
              </span>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Pays</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>United States</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Indicatif régional</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>Indicatif régional</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Button variant="btn btn-primary  px-2 pt-3 ">
                Créer un script de plan de numérotation
              </Button>
              <br />
              <Form.Check.Label className="fs-9 fw-bolder">
                Étape 4 : Téléchargez et exécutez le script des utilisateurs
              </Form.Check.Label>
              <br />
              <span className="me-3">
                Ce script configure les utilisateurs Teams avec iperx et doit être
                exécuté chaque fois qu’un nouvel utilisateur est ajouté ou
                supprimé.
                <br />
                Note : Microsoft Teams peut nécessiter jusqu’à 24 heures pour
                pouvoir utiliser les utilisateurs configurés.
              </span>

              <br />
              <Button variant="btn btn-primary  px-2 pt-3 ">
                Générer un script d’utilisateurs
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </>
  );
};

export default TeamsRoutingConfig;
