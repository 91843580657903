import { useState, useEffect, useCallback } from "react";

// Define the structure for ScheduleData
export interface ScheduleData {
  id: number;
  idClient: number;
  name: string;
  startSlot1: string;
  endSlot1: string;
  startSlot2: string;
  endSlot2: string;
  days: string;
  state: boolean;
}

/**
 * **useScheduleDataID**:
 * A custom hook for managing schedule data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch schedule data for.
 * @returns {Object} - The schedule data, loading state, errors, and a setter for the data.
 *
 * @property {ScheduleData[]} scheduleData - The data retrieved from the API or a fallback value.
 * @property {Function} setScheduleData - Function to manually set schedule data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { scheduleData, loading, error } = useScheduleDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ScheduleList data={scheduleData} />;
 */
const useScheduleDataID = (id: number) => {
  const [scheduleData, setScheduleData] = useState<ScheduleData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchScheduleData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/schedule/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setScheduleData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données schedule data", error);
      setError("Impossible de récupérer les données schedule data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchScheduleData();
  }, [fetchScheduleData]);

  return {
    scheduleData,
    setScheduleData,
    loading,
    error
  };
};

export default useScheduleDataID;
