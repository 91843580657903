import React, { ChangeEvent, useState, useMemo } from "react";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faFileImport,
  faFilter,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/others/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import TrunksTableAPI, { trunksAPITableColumns } from './TrunksTableAPI';
import useTrunk from 'hooks/api/ipbx/useTrunk';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import TrunkSIPForm from "./TrunkSIPForm";
import useFinalClient from 'hooks/api/ipbx/useFinalClient';
import useServerConfig from "hooks/api/ipbx/useServerConfig";
import { ServerConfigProvider } from "data/iper-x/context/ServerConfigurationContext";
import { ClientProvider } from "data/iper-x/context/ClientContext";
import useTrunkDataID from "hooks/api/ipbx/useTrunkDataID";

const TrunkListAPI = () => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;
  const { trunkData, setTrunkData, loading, error } =
    useTrunkDataID(clientFinalId);
  const {
    clientData,
    loading: clientLoading,
    error: clientError
  } = useFinalClient();
  const { serverConfigData, serverConfigLoading, serverConfigError } =
    useServerConfig();

  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  // Initialize advance table hook with filtered trunk data
  const table = useAdvanceTable({
    data: trunkData,
    columns: trunksAPITableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <ServerConfigProvider serverConfigData={serverConfigData}>
      <ClientProvider clientData={clientData}>
        <div>
          <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
            <h2 className="mb-0">
              <span className="me-3">Trunk SIP</span>
            </h2>
          </div>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  className="w-300"
                  placeholder="Rechercher"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button
                  variant="primary"
                  onClick={() => setShowAddClientModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter un trunk
                </Button>
                <LeadsFilterModal
                  show={openFilterModal}
                  handleClose={() => setOpenFilterModal(false)}
                />
              </Col>
            </Row>
          </div>
          {loading && <p>Chargement des données...</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
          {!loading && (
            <AdvanceTableProvider {...table}>
              <div className="mx-n4 px-4 mx-lg-n6 bg-white border-top border-bottom border-200 position-relative top-1">
                <TrunksTableAPI />
              </div>
            </AdvanceTableProvider>
          )}
          <Modal
            show={showAddClientModal}
            onHide={() => setShowAddClientModal(false)}
            // size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Ajouter un trunk</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TrunkSIPForm
                tableData={trunkData}
                setTableData={setTrunkData}
                onClose={() => setShowAddClientModal(false)}
              />
            </Modal.Body>
          </Modal>
        </div>
      </ClientProvider>
    </ServerConfigProvider>
  );
};

export default TrunkListAPI;
