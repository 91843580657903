import { FinalClientData } from 'hooks/api/ipbx/useFinalClient';
import React, { createContext, useContext, ReactNode } from 'react';


// Création du contexte pour les données clients
const ClientContext = createContext<FinalClientData[] | null>(null);

// Hook pour utiliser le contexte client
export const useClientData = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error('useClientData must be used within a ClientProvider');
  }
  return context;
};

// Fournisseur de contexte pour les données clients
export const ClientProvider = ({
  clientData,
  children,
}: {
  clientData: FinalClientData[];
  children: ReactNode;
}) => {
  return (
    <ClientContext.Provider value={clientData}>
      {children}
    </ClientContext.Provider>
  );
};
