import {
  faFileExport, faFileImport, faFilter, faPencil, faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import React, { ChangeEvent, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import { trunksTableData } from "../../../../../data/iper-x/ipbx/trunks";

import LeadsFilterModal from "../../../../../components/modals/LeadsFilterModal";
import VoicemailTable, { voicemailTableColumns } from "../../../../../components/tables/ipbx/VoicemailTable";
import { voicemailTableData } from "../../../../../data/iper-x/ipbx/voicemail";
import PageBreadcrumb, { PageBreadcrumbItem } from "../../../../../components/common/PageBreadcrumb";
import { Link } from "react-router-dom";

export const codecsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: "/apps/ipbx/parametres"
  },
  {
    label: 'Apps',
    url: "/apps/ipbx/parametres?selected=apps"
  },
  {
    label: 'Codecs',
    url: "/apps/ipbx/parametres/codecs"
  },
];

const CodecsList = () => {
  const table = useAdvanceTable({
    data: voicemailTableData,
    columns: voicemailTableColumns,
    pageSize: 5,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={codecsBreadcrumbItems} />

      <AdvanceTableProvider {...table}>
        <Col md={9} xs="auto">
          <h3 className="mb-5">
            <span className="me-3">Ordre des codecs pour les appareils connectés au LAN ou les applications connectées au WiFi</span>
          </h3>
        </Col>


        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                         placeholder="Rechercher"
                         onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un codec
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <VoicemailTable />
        </div>
      </AdvanceTableProvider>


      <AdvanceTableProvider {...table}>
        <Col md={9} xs="auto">
          <h3 className="mb-5 mt-5">
            <span className="me-3">Ordre des codecs pour les appareils connectés au WAN ou les applications connectées en LTE/3G</span>
          </h3>
        </Col>


        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                         placeholder="Rechercher"
                         onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un codec
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <VoicemailTable />
        </div>
      </AdvanceTableProvider>

    </div>
  );
};

export default CodecsList;

