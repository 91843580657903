import { faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import { faCircleStop, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, {
  RevealDropdownTrigger
} from "components/base/RevealDropdown";
import { MsgVocalData } from "hooks/api/ipbx/useMsgVocal";
import React, { ChangeEvent, useRef, useState } from "react";
import {
  Dropdown,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Row,
  Col
} from "react-bootstrap";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const msgVocalAPITableColumns: ColumnDef<MsgVocalData>[] = [
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    id: "url",
    header: "Lire",
    cell: ({ row: { original } }) => {
      const audioUrl = original.url?.startsWith("http://")
        ? original.url.replace("http://", "https://")
        : original.url;

      const fileName = audioUrl?.split("/").pop() || "audio.wav"; // Extracts the file name from the URL
      const [isPlaying, setIsPlaying] = useState(false);
      const audioRef = useRef<HTMLAudioElement | null>(null);

      const togglePlayback = () => {
        if (isPlaying) {
          audioRef.current?.pause();
        } else {
          audioRef.current = new Audio(audioUrl);
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      };

      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{fileName}</Tooltip>}
          >
            <button
              onClick={togglePlayback}
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={isPlaying ? faCircleStop : faCirclePlay}
                style={{ fontSize: "24px" }}
              />
            </button>
          </OverlayTrigger>
          <audio
            src={audioUrl}
            ref={audioRef}
            onEnded={() => setIsPlaying(false)}
          />
          {/*<a*/}
          {/*  href={audioUrl}*/}
          {/*  download={fileName}*/}
          {/*  style={{ marginLeft: "10px", color: "inherit", textDecoration: "none" }}*/}
          {/*  title="Télécharger l'audio"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={faDownload} style={{ fontSize: "18px" }} />*/}
          {/*</a>*/}
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    accessorKey: "date",
    header: "Date",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "Activé",
    cell: ({ row: { original } }) => (
      <Badge
        bg={original.state ? "success" : "secondary"}
        variant="phoenix"
        className="fs-10"
      >
        {original.state ? "Actif" : "Inactif"}
      </Badge>
    ),
    meta: {
      headerProps: { style: { width: "10%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: "actions",
    header: "Actions",
    cell: ({ row: { original } }) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [formData, setFormData] = useState<MsgVocalData | null>(null);
      const [fileError, setFileError] = useState<string | null>(null);
      const [audioFile, setAudioFile] = useState<File | null>(null);

      const handleEditClick = (msgVocal: MsgVocalData) => {
        setFormData(msgVocal);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            throw new Error("Token not found");
          }

          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Ipbx/msgvocal/${original.id}`, // Use the id of the item to delete
            {
              method: "DELETE",
              headers: {
                accept: "text/plain",
                Authorization: `Bearer ${token}`
              }
            }
          );

          if (response.ok) {
            setShowDeleteModal(false);
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression du message vocal", error);
        }
      };

      const handleEditConfirm = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }

          const updatedFormData = new FormData();
          // Add all required parameters to the FormData
          if (formData?.id) updatedFormData.append("Id", formData.id.toString());
          if (formData?.idClient)
            updatedFormData.append("IdClient", formData.idClient.toString());
          if (formData?.name) updatedFormData.append("Name", formData.name);
          updatedFormData.append("State", formData?.state ? "true" : "false");

          // Only append the file if a new one has been selected and no error
          if (audioFile && fileError === null) {
            updatedFormData.append("File", audioFile);
          }

          const response = await fetch("https://rct-backend.ipercom.io/api/Ipbx/msgvocal", {
            method: "PUT",
            headers: {
              accept: "text/plain",
              Authorization: `Bearer ${token}`
            },
            body: updatedFormData
          });

          if (response.ok) {
            setShowEditModal(false);
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour du message vocal", error);
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData =>
          prevData
            ? { ...prevData, [name]: type === "checkbox" ? checked : value }
            : null
        );
      };

      const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        const maxSize = 5 * 1024 * 1024; // 5MB

        if (file) {
          if (file.size > maxSize) {
            setFileError("La taille du fichier doit être inférieure à 5 Mo.");
            setAudioFile(null); // Clear audioFile if invalid
          } else if (!file.name.endsWith(".wav")) {
            setFileError("Seuls les fichiers .wav sont autorisés.");
            setAudioFile(null); // Clear audioFile if invalid
          } else {
            setFileError(null);
            setAudioFile(file); // Set audioFile if valid
          }
        }
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Edit Modal */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'audio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  controlId="state"
                  className="align-items-center mb-2"
                >
                  <Form.Label>État de l'audio :</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Switch
                      name="state"
                      checked={formData?.state || false}
                      onChange={handleChange}
                    />
                    <span>{formData?.state ? "Actif" : "Inactif"}</span>
                  </div>
                </Form.Group>

                <Form.Group controlId="name">
                  <Form.Label>Nom de l'audio :</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData?.name || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="url" className="mb-3">
                  <Form.Label>
                    Télécharger (format RIFF .wav linéaire 16 bits 8kHz mono){" "}
                    <Link to="https://online-audio-converter.com">
                      Convertir ici
                    </Link>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Uploader un fichier audio en .wav, max 5 Mo, format 16
                          bits 8kHz mono.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        style={{ cursor: "pointer", marginLeft: "4px" }}
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept=".wav"
                    onChange={handleFileChange}
                  />
                  {fileError && <div className="text-danger">{fileError}</div>}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Annuler
              </Button>
              <Button
                variant="primary"
                onClick={handleEditConfirm}
                disabled={!!fileError}
              >
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delete Modal */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cet audio ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    },
    meta: {
      headerProps: { style: { fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  }
];

const MsgVocalTableAPI = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9 text-center" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default MsgVocalTableAPI;
