import { useState, useEffect, useCallback } from "react";

// Define the structure for VoicemailData
export interface VoicemailData {
  id: number;
  idClient: number;
  nameVoicemail: string;
  idMsgVocal: number;
  emailUser: string;
  password: string;
  voicemailNumber: number;
  state: boolean;
}

/**
 * **useVoicemailDataID**:
 * A custom hook for managing voicemail data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch voicemail data for.
 * @returns {Object} - The voicemail data, loading state, errors, and a setter for the data.
 *
 * @property {VoicemailData[]} voicemailData - The data retrieved from the API or a fallback value.
 * @property {Function} setVoicemailData - Function to manually set voicemail data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { voicemailData, loading, error } = useVoicemailDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <VoicemailList data={voicemailData} />;
 */
const useVoicemailDataID = (id: number) => {
  const [voicemailData, setVoicemailData] = useState<VoicemailData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchVoicemailData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/voicemail/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setVoicemailData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données voicemail data", error);
      setError("Impossible de récupérer les données voicemail data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchVoicemailData();
  }, [fetchVoicemailData]);

  return {
    voicemailData,
    setVoicemailData,
    loading,
    error
  };
};

export default useVoicemailDataID;
