import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import ParamApps from "./apps/ParamApps";
import ParamSystem from "./system/ParamSystem";
import ParamAdvance from "./advance/ParamAdvance";
import ParamIntegration from "./integration/ParamIntegration";

const Settings = () => {
  const [selectedComponent, setSelectedComponent] = useState<string>("apps");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedComponentParam = searchParams.get("selected");
    if (selectedComponentParam) {
      setSelectedComponent(selectedComponentParam);
    }
  }, [location]);

  const handleItemClick = (component: string) => {
    setSelectedComponent(component);
    // Update the URL with the selected component
    const searchParams = new URLSearchParams({ selected: component });
    window.history.pushState({}, '', `${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <div>
      <Nav
        variant="underline"
        className="mb-2 mb-3 md-5 pb-3 pt-2 w-100 w-sm-75 w-md-100 mx-auto bg-soft"
      >
        <Nav.Item>
          <Nav.Link
            className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === "apps" ? "active" : ""}`}
            onClick={() => handleItemClick("apps")}
          >
            Apps
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === "system" ? "active" : ""}`}
            onClick={() => handleItemClick("system")}
          >
            Système
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === "integration" ? "active" : ""}`}
            onClick={() => handleItemClick("integration")}
          >
            Intégration
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            className={`fw-semi-bold me-3 fs-8 pe-2 text-center ${selectedComponent === "advance" ? "active" : ""}`}
            onClick={() => handleItemClick("advance")}
          >
            Avancés
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <hr />

      {selectedComponent === "apps" && <ParamApps />}
      {selectedComponent === "system" && <ParamSystem />}
      {selectedComponent === "integration" && <ParamIntegration />}
      {selectedComponent === "advance" && <ParamAdvance />}
    </div>

  );
};

export default Settings;
