"use client"; // Enables client-side rendering in Next.js

import React, { useState, ChangeEvent } from "react";
import { Form, Button, Row, Col, Card, Accordion, InputGroup } from "react-bootstrap";
import useAppModules from "hooks/api/security/useAppModules";
import useModuleFeatures from "hooks/api/security/useModuleFeatures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordValidator from "../../ipbx/restriction/PasswordValidator";
import { useNavigate } from "react-router-dom";

// Define the types
type Feature = {
  idFeature: number;
  idModule: number;
  name: string;
  slug: string;
};

type Module = {
  idModule: number;
  idApps: number;
  name: string;
  slug: string;
};

type UserData = {
  idUsers: number;
  firstname: string;
  lastname: string;
  password: string;
  mail: string;
  phone: string;
  mobile: string;
  avatar: string;
  idUsersRoles: number;
  idCompanies: number;
  erpThiersId: number;
  erpUserId: number;
  erpUserToken: string;
  idInstance: number;
  status: string;
  placeholder: string;
  moduleAccess: { idModule: number; hasAccess: boolean }[];
  features: { idFeature: number; canDo: boolean }[];
};

// Define templates for different roles
const templates = {
  "Utilisateur de base": {
    modules: [1, 6, 7], // List of module IDs
    features: [1, 2, 3] // List of feature IDs
  },
  "Responsable": {
    modules: [1, 2, 3, 4, 5, 6, 7],
    features: [1, 2, 3, 4, 5, 6, 7, 8]
  },
  "Administrateur": {
    modules: [1, 2, 3, 4, 5, 6, 7, 8],
    features: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  }
};

// Main Component
const UserCreationForm: React.FC<{ onSave: (user: UserData) => void; onCancel: () => void; }> = ({
                                                                                                   onSave,
                                                                                                   onCancel
                                                                                                 }) => {
  const navigate = useNavigate();
  const dolToken = localStorage.getItem("DOLAPIKEY");
  const companyID = localStorage.getItem("companyID");
  const dolUserID = localStorage.getItem("DOLUSERID");
  const dolThierID = localStorage.getItem("DOLID");
  const [userData, setUserData] = useState<UserData>({
    idUsers: 0,
    firstname: "",
    lastname: "",
    password: "",
    mail: "",
    phone: "",
    mobile: "",
    avatar: "",
    idUsersRoles: 14,//pas secure d'etre la
    idCompanies: companyID ? Number(companyID) : 0, // Ensure it's a number or fallback to 0
    erpThiersId: dolUserID ? Number(dolThierID) : 0, // a remplir apres la creation de l'utilistaerusr sur l'erp
    erpUserId: dolUserID ? Number(dolUserID) : 0, // a remplir apres la creation de l'utilistaerusr sur l'erp
    erpUserToken: dolToken ? String(dolToken) : "", // a remplir apres la creation de l'utilistaerusr sur l'erp
    idInstance: 1,
    status: "created",
    placeholder: "",
    moduleAccess: [],//bloquer la posibilter d'ajouter des modules qui ne sont pas de l'app 1
    features: [] //bloquer la posibilter d'ajouter des fonctionalites qui ne sont pas de l'app 1
  });

  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [permissionMode, setPermissionMode] = useState<"template" | "custom">("template");

  // Use custom hooks to fetch modules and features
  const { appModules, loading: loadingModules, error: errorModules } = useAppModules();
  const { moduleFeatures, loading: loadingFeatures, error: errorFeatures } = useModuleFeatures();

  // Filter modules where idApps === 1
  const filteredModules = appModules.filter((module: Module) => module.idApps === 1);


  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPhonePassword, setShowPhonePassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(prevShow => !prevShow);
  };


  // Handle input changes
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const templates: { [key in "Basic User" | "Manager" | "Admin"]: { modules: number[], features: number[] } } = {
    "Basic User": { modules: [1, 6, 7], features: [1, 2, 3] },
    "Manager": { modules: [1, 2, 3, 4, 5, 6, 7], features: [1, 2, 3, 4, 5, 6, 7, 8] },
    "Admin": { modules: [1, 2, 3, 4, 5, 6, 7, 8], features: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] }
  };

// Ensure the template value is of the correct type
  const handleTemplateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const template = e.target.value as keyof typeof templates;
    setSelectedTemplate(template);

    if (templates[template]) {
      const selectedTemplateData = templates[template];
      const newModuleAccess = selectedTemplateData.modules.map((idModule) => ({ idModule, hasAccess: true }));
      const newFeatures = selectedTemplateData.features.map((idFeature) => ({ idFeature, canDo: true }));

      setUserData({
        ...userData,
        moduleAccess: newModuleAccess,
        features: newFeatures
      });
    }
  };

  // Handle module access change
  const handleModuleAccessChange = (idModule: number, hasAccess: boolean) => {
    const updatedModuleAccess = userData.moduleAccess.filter((m) => m.idModule !== idModule);
    if (hasAccess) {
      updatedModuleAccess.push({ idModule, hasAccess });
    }
    setUserData({ ...userData, moduleAccess: updatedModuleAccess });
  };

  // Handle feature permission change
  const handleFeatureChange = (idFeature: number, canDo: boolean) => {
    const updatedFeatures = userData.features.filter((f) => f.idFeature !== idFeature);
    if (canDo) {
      updatedFeatures.push({ idFeature, canDo });
    }
    setUserData({ ...userData, features: updatedFeatures });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem("token"); // Retrieve token from local storage

    if (!token) {
      console.error("Token not found");
      return;
    }

    const userWithPermissions = {
      ...userData,
      moduleAccess: userData.moduleAccess,
      features: userData.features
    };

    try {
      const response = await fetch("https://rct-backend.ipercom.io/api/User", {
        method: "POST",
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` // Send the token in the Authorization header
        },
        body: JSON.stringify(userWithPermissions)
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      console.log("User created:", result);
      // navigate('/portail-revendeur/utilisateurs');
      onSave(result);
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  if (loadingModules || loadingFeatures) return <p>Loading...</p>;
  if (errorModules || errorFeatures) return <p>Error loading data.</p>;

  return (
    <Form onSubmit={handleSubmit}>

      <h3>Créer un nouvel utilisateur</h3>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="firstname">
              <Form.Label>Prénom</Form.Label>
              <Form.Control type="text" name="firstname" value={userData.firstname} onChange={handleInputChange}
                            required />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="lastname">
              <Form.Label>Nom de famille</Form.Label>
              <Form.Control type="text" name="lastname" value={userData.lastname} onChange={handleInputChange}
                            required />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="mail">
              <Form.Label>E-mail</Form.Label>
              <Form.Control type="email" name="mail" value={userData.mail} onChange={handleInputChange} required />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="password">
              <Form.Label>Mot de passe</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={userData.password}
                  onChange={handleInputChange}
                  isInvalid={!!passwordError}
                  required
                />
                <InputGroup.Text onClick={toggleShowPassword} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {passwordError}
              </Form.Control.Feedback>
              <PasswordValidator password={userData.password} setPasswordError={setPasswordError} />
            </Form.Group>
          </Col>
        </Row>


        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="phone">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control type="text" name="phone" value={userData.phone} onChange={handleInputChange} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="mobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="text" name="mobile" value={userData.mobile} onChange={handleInputChange} />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="avatar">
          <Form.Label>Avatar du profil</Form.Label>
          <Form.Control
            type="file"
            name="avatar"
            value={userData.avatar}
            onChange={handleInputChange}
          />
        </Form.Group>



        <h3>Autorisations utilisateur</h3>
        <Form.Group controlId="permissionMode" className="mb-3">
          <Form.Label>Mode d'autorisation</Form.Label>
          <Form.Check
            type="radio"
            label="Utiliser un modèle"
            name="permissionMode"
            value="template"
            checked={permissionMode === "template"}
            onChange={() => setPermissionMode("template")}
          />
          <Form.Check
            type="radio"
            label="Personnaliser les autorisations"
            name="permissionMode"
            value="custom"
            checked={permissionMode === "custom"}
            onChange={() => setPermissionMode("custom")}
          />
        </Form.Group>

        {/* Select Template when "Use Template" is selected */}
        {permissionMode === "template" && (
          <Form.Group controlId="template" className="mb-3">
            <Form.Label>Sélectionnez un modèle</Form.Label>
            <Form.Select value={selectedTemplate} onChange={handleTemplateChange}>
              <option value="">Sélectionnez un modèle</option>
              <option value="Basic User">Basic User</option>
              <option value="Manager">Manager</option>
              <option value="Admin">Admin</option>
            </Form.Select>
          </Form.Group>
        )}

        {/* Always show the Accordion with automatic selection for Template and Custom Permissions */}
        <Accordion>
          {filteredModules.map((module) => (
            <Accordion.Item eventKey={`module-${module.idModule}`} key={module.idModule}>
              <Accordion.Header>{module.name}</Accordion.Header>
              <Accordion.Body>
                <Form.Check
                  type="checkbox"
                  label={`Accès au ${module.name}`}
                  checked={userData.moduleAccess.some((m) => m.idModule === module.idModule && m.hasAccess)}
                  onChange={(e) => handleModuleAccessChange(module.idModule, e.target.checked)}
                />
                {moduleFeatures
                  .filter((feature) => feature.idModule === module.idModule)
                  .map((feature) => (
                    <div key={feature.idFeature} className="ms-3">
                      <Form.Check
                        type="checkbox"
                        label={feature.name}
                        checked={userData.features.some((f) => f.idFeature === feature.idFeature && f.canDo)}
                        onChange={(e) => handleFeatureChange(feature.idFeature, e.target.checked)}
                      />
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>


      <Button variant="primary" type="submit" className="mb-5">
        Créer un utilisateur
      </Button>
    </Form>
  );
};

export default UserCreationForm;
