import React, { useState, useEffect, ChangeEvent } from "react";
import { Form, Button, OverlayTrigger, Tooltip, InputGroup, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { VoicemailData } from "hooks/api/ipbx/useVoicemail";
import useMsgVocalDataID from "hooks/api/ipbx/useMsgVocalDataID";

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

type VoicemailFormProps = {
  initialData?: VoicemailData;
  tableData: VoicemailData[];
  setTableData: React.Dispatch<React.SetStateAction<VoicemailData[]>>;
  onClose: () => void;
};

const VoicemailForm: React.FC<VoicemailFormProps> = ({ initialData, tableData, setTableData, onClose }) => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  const { msgVocalData, loading: msgVocalLoading } = useMsgVocalDataID(clientFinalId);

  const [newVoicemail, setNewVoicemail] = useState<VoicemailData>(
    initialData || {
      id: 0,
      idClient: clientFinalId || 0,
      voicemailNumber: 0,
      idMsgVocal: 0,
      emailUser: "",
      password: "",
      nameVoicemail: "",
      state: true
    }
  );

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [phonePasswordError, setPhonePasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (initialData) {
      setNewVoicemail(initialData);
    }
  }, [initialData]);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const validatePassword = (password: string) => {
    if (!/^\d{4}$/.test(password)) {
      setPasswordError("Le mot de passe doit être exactement 4 chiffres.");
      return false;
    }
    setPasswordError(null);
    return true;
  };

  const validateVoicemailNumber = (number: number) => {
    if (number < 10000 || number > 99999) {
      setPhonePasswordError("Le numéro de la boîte vocale doit être de 5 chiffres.");
      return false;
    }
    setPhonePasswordError(null);
    return true;
  };

  const handleChange = (e: ChangeEvent<FormControlElement>) => {
    const { name, value, type } = e.target as HTMLInputElement;
    const checked = type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;

    setNewVoicemail(prevVoicemail => ({
      ...prevVoicemail,
      [name]: type === "checkbox" ? checked : value
    }));

    if (name === "password") {
      validatePassword(value);
    } else if (name === "voicemailNumber") {
      validateVoicemailNumber(Number(value));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (passwordError || phonePasswordError) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const method = initialData ? "PUT" : "POST";
      const response = await fetch('https://rct-backend.ipercom.io/api/Ipbx/voicemail', {
        method,
        headers: {
          'accept': 'text/plain',
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newVoicemail)
      });

      if (response.ok) {
        const responseText = await response.text();
        const data: VoicemailData | null = responseText ? JSON.parse(responseText) : null;

        if (data) {
          if (initialData) {
            setTableData(prevData =>
              prevData.map(item => (item.id === data.id ? data : item))
            );
          } else {
            setTableData(prevData => [...prevData, data]);
          }
        }

        onClose();
        if (response.ok) window.location.reload();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de la gestion du répondeur numérique", error);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <h5>Paramètres Généraux</h5>
      <Form.Group controlId="state" className="align-items-center mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>Activer ou désactiver le répondeur numérique.</Tooltip>
          }
        >
          <Form.Label>
            État du répondeur{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <div className="d-flex align-items-center">
          <Form.Switch
            name="state"
            checked={newVoicemail.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            className="me-2"
          />
          <span>{newVoicemail.state ? "Actif" : "Inactif"}</span>
        </div>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId="nameVoicemail" className="mb-3">
            <Form.Label>Nom du répondeur numérique</Form.Label>
            <Form.Control
              type="text"
              name="nameVoicemail"
              value={newVoicemail.nameVoicemail}
              onChange={
                handleChange as React.ChangeEventHandler<FormControlElement>
              }
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="voicemailNumber" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Numéro unique de la boîte vocale (5 chiffres maximum).
                  <br />
                  Les appelants composent ce numéro pour accéder au répondeur et
                  laisser un message vocal.
                  <br />
                  Exemple : Lorsque ce numéro est appelé, l'appel est redirigé
                  vers le message d'accueil et permet à l'appelant de déposer un
                  message.
                </Tooltip>
              }
            >
              <Form.Label>
                Numéro de la boîte vocale{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="voicemailNumber"
              value={newVoicemail.voicemailNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (/^\d{0,5}$/.test(e.target.value)) handleChange(e);
              }}
              isInvalid={!!phonePasswordError}
              maxLength={5}
              required
            />
            <Form.Control.Feedback type="invalid">
              {phonePasswordError}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <h5>Audio du répondeur</h5>
      <Form.Group controlId="idMsgVocal" className="mb-3">
        <Form.Label>Audio</Form.Label>
        <Form.Control
          as="select"
          name="idMsgVocal"
          className="form-select"
          value={newVoicemail.idMsgVocal}
          onChange={handleChange}
          required
          disabled={msgVocalLoading}
        >
          <option value="">Sélectionner un audio</option>
          {msgVocalData.length > 0 ? (
            msgVocalData.map(msgVocal => (
              <option key={msgVocal.id} value={msgVocal.id}>
                {msgVocal.name}
              </option>
            ))
          ) : (
            <option disabled>Aucune donnée</option>
          )}
        </Form.Control>
      </Form.Group>

      <Row>
        <Col>
          <Form.Group controlId="emailUser" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Adresse e-mail où seront envoyés les messages vocaux reçus.
                  <br />
                  Lorsqu'un appelant laisse un message, un e-mail contenant
                  l'audio du message est envoyé à cette adresse pour permettre
                  une consultation rapide.
                </Tooltip>
              }
            >
              <Form.Label>
                E-mail de réception des messages vocaux{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="email"
              name="emailUser"
              value={newVoicemail.emailUser}
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="password" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  Mot de passe à 4 chiffres utilisé pour sécuriser l'accès à la
                  boîte vocale.
                  <br />
                  Ce code est requis pour écouter ou gérer les messages vocaux
                  enregistrés.
                </Tooltip>
              }
            >
              <Form.Label>
                Mot de passe (4 chiffres uniquement){" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                value={newVoicemail.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (/^\d{0,4}$/.test(e.target.value)) handleChange(e);
                }}
                isInvalid={!!passwordError}
                maxLength={4}
                required
              />
              <InputGroup.Text
                onClick={toggleShowPassword}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {passwordError}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Button variant="primary" type="submit" className="mt-2">
        {initialData ? "Enregistrer les modifications" : "Ajouter"}
      </Button>
    </Form>
  );
};

export default VoicemailForm;
