import React, { useState } from "react";
import { Alert, Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";
import usePhoneUserDataID from "../../../../hooks/api/ipbx/usePhoneUserDataID";

type DevicesFormProps = {
  currentDevice?: PhoneDevicesData; // Pass null for creation
  mode: "add" | "edit" | "delete"; // Mode determines the form behavior
  onSave?: (device: PhoneDevicesData) => void; // Callback for saving
  onDelete?: (id: number) => void; // Callback for deletion
  onClose: () => void; // Callback for closing the form
};

const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
const clientFinalId =
  ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

const DevicesForm: React.FC<DevicesFormProps> = ({
                                                   currentDevice,
                                                   mode,
                                                   onSave,
                                                   onDelete,
                                                   onClose
                                                 }) => {
  const predefinedLocations = [
    "Bureau",
    "Salle de réunion",
    "Stock",
    "Accueil"
  ];

  const [deviceData, setDeviceData] = useState<Partial<PhoneDevicesData>>({
    idClient: currentDevice?.idClient || clientFinalId,
    name: currentDevice?.name || "",
    description: currentDevice?.description || "",
    brand: currentDevice?.brand || "",
    deviceModel: currentDevice?.deviceModel || "",
    firmwareVersion: currentDevice?.firmwareVersion || "",
    macAddress: currentDevice?.macAddress || "",
    machineId: currentDevice?.machineId || "",
    privateIpAddress: currentDevice?.privateIpAddress || "",
    publicIpAddress: currentDevice?.publicIpAddress || "",
    location: currentDevice?.location || "",
    deviceType: currentDevice?.deviceType || "Téléphone de bureau",
    provisioningStatus: currentDevice?.provisioningStatus || "Non provisionné",
    deviceStatus: currentDevice?.deviceStatus || "Offline",
    deviceIdProv: currentDevice?.deviceIdProv || ""
  });

  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const [userList, setUserList] = useState<
    { id: number; firstname: string; lastname: string; extension: string }[]
  >([]);
  const [userInput, setUserInput] = useState<string>("");
  const [userError, setUserError] = useState<string | null>(null);
  const { phoneUserData, loading: phoneUserLoading } =
    usePhoneUserDataID(clientFinalId);

  const handleChange = (field: keyof PhoneDevicesData, value: string) => {
    setDeviceData(prev => ({
      ...prev,
      [field]: value
    }));
  };
  const handleSelectUser = (index: number, extension: string) => {
    const selectedUser = phoneUserData.find(
      user => user.extension === extension
    );
    if (!selectedUser) {
      setUserError("Utilisateur non trouvé.");
      return;
    }
    const isUserAlreadyAdded = userList.some(
      user => user.id === selectedUser.id
    );
    if (isUserAlreadyAdded) {
      setUserError("Cet utilisateur a déjà été ajouté.");
      return;
    }
    const updatedUserList = [...userList];
    updatedUserList[index] = selectedUser;
    setUserList(updatedUserList);
    setUserError(null);
  };

  const handleDeleteUser = (userId: number) => {
    const updatedUserList = userList.filter(user => user.id !== userId);
    setUserList(updatedUserList);
  };

  const addUserToList = () => {
    if (userList.length >= 2) {
      setUserError("Vous ne pouvez ajouter que deux utilisateurs maximum.");
      return;
    }
    if (userInput) {
      const selectedUser = phoneUserData.find(
        user => user.extension === userInput
      );
      if (!selectedUser) {
        setUserError("Utilisateur non trouvé.");
        return;
      }
      const isUserAlreadyAdded = userList.some(
        user => user.id === selectedUser.id
      );
      if (isUserAlreadyAdded) {
        setUserError("Cet utilisateur a déjà été ajouté.");
        return;
      }
      setUserList(prev => [...prev, selectedUser]);
      setUserInput("");
      setUserError(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (submitting) return;
    setSubmitting(true);

    if ((mode === "add" || mode === "edit") && onSave) {
      const updatedDeviceData = {
        ...deviceData,
        internalUsers: userList.map(user => user.id) // Inclure les IDs des utilisateurs internes
      };

      if (!deviceData.name || !deviceData.brand || !deviceData.macAddress) {
        setError("Veuillez remplir tous les champs obligatoires.");
        setSubmitting(false);
        return;
      }

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token introuvable dans le stockage local.");
        }

        const url = "https://rct-backend.ipercom.io/api/Ipbx/device";
        const response = await fetch(url, {
          method: mode === "add" ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            ...updatedDeviceData,
            id: currentDevice?.id || undefined
          })
        });

        if (response.status === 204 || response.ok) {
          const updatedDevice =
            mode === "edit"
              ? { ...updatedDeviceData, id: currentDevice?.id }
              : await response.json();
          onSave?.(updatedDevice);
          window.location.reload();
        } else {
          const message = await response.text();
          throw new Error(
            `Erreur lors de l'envoi des données : ${response.status} - ${message}`
          );
        }
      } catch (error) {
        setError(
          error instanceof Error
            ? error.message
            : "Erreur inconnue lors de l'envoi."
        );
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleDelete = async () => {
    if (!currentDevice || !currentDevice.id) {
      setError("Impossible de supprimer l'appareil. Identifiant manquant.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token introuvable dans le stockage local.");
      }

      const response = await fetch(
        `https://rct-backend.ipercom.io/api/Ipbx/device/${currentDevice.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status === 204) {
        onDelete?.(currentDevice.id); // Notify parent about the deleted device
        onClose(); // Close the modal after successful deletion
        // Rafraîchir la page après la soumission réussie
        window.location.reload();
      } else {
        const message = await response.text();
        throw new Error(
          `Erreur lors de la suppression : ${response.status} - ${message}`
        );
      }
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : "Erreur inconnue lors de la suppression."
      );
    } finally {
      onClose(); // Ensure the modal is closed even in case of errors
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      {mode === "delete" ? (
        <>
          <p>Voulez-vous vraiment supprimer cet appareil ?</p>
          <div className="d-flex justify-content-end">
            <Button variant="secondary" className="me-2" onClick={onClose}>
              Annuler
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Supprimer
            </Button>
          </div>
        </>
      ) : (
        <>
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>
              Nom
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Nom de l'appareil. Ce champ est obligatoire.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="ms-2 text-info"
                />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type="text"
              value={deviceData.name}
              onChange={e => handleChange("name", e.target.value)}
              placeholder="Entrer le nom de l'appareil"
              required
            />
          </Form.Group>

          {/* Description */}
          <Form.Group controlId="description" className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={deviceData.description}
              onChange={e => handleChange("description", e.target.value)}
              placeholder="Ajouter une description de l'appareil (facultatif)"
            />
          </Form.Group>

          <Row className="mb-3">
            {/* Constructeur */}
            <Col>
              <Form.Group controlId="brand">
                <Form.Label>
                  Constructeur
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Sélectionnez le constructeur de l'appareil. Ce champ est
                        obligatoire.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2 text-info"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Select
                  value={deviceData.brand}
                  onChange={e => handleChange("brand", e.target.value)}
                  required
                >
                  <option value="">Sélectionnez un constructeur</option>
                  <option value="Yealink">Yealink</option>
                  <option value="Fanvil">Fanvil</option>
                  <option value="Grandstream">Grandstream</option>
                  <option value="Cisco">Cisco</option>
                  <option value="Polycom">Polycom</option>
                  <option value="Avaya">Avaya</option>
                </Form.Select>
              </Form.Group>
            </Col>

            {/* Modèle */}
            <Col>
              <Form.Group controlId="deviceModel">
                <Form.Label>Modèle</Form.Label>
                <Form.Select
                  value={deviceData.deviceModel}
                  onChange={e => handleChange("deviceModel", e.target.value)}
                  disabled={!deviceData.brand}
                >
                  <option value="">Sélectionnez un modèle (optionnel)</option>
                  {deviceData.brand === "Yealink" && (
                    <>
                      <option value="T31P">T31P</option>
                      <option value="T33G">T33G</option>
                      <option value="W73P">W73P</option>
                    </>
                  )}
                  {/* Ajoutez d'autres options pour les marques */}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            {/* MAC Address */}
            <Col>
              <Form.Group controlId="macAddress">
                <Form.Label>
                  Adresse MAC
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Entrez l'adresse MAC de l'appareil. Ce champ est
                        obligatoire.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2 text-info"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={deviceData.macAddress}
                  onChange={e => handleChange("macAddress", e.target.value)}
                  placeholder="Entrer l'adresse MAC"
                  required
                />
              </Form.Group>
            </Col>

            {/* Numéro de Série */}
            <Col>
              <Form.Group controlId="machineId">
                <Form.Label>
                  Numéro de série (SN)
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        Numéro de série unique de l'appareil. Ce champ est
                        obligatoire.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2 text-info"
                    />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={deviceData.machineId}
                  onChange={e => handleChange("machineId", e.target.value)}
                  placeholder="Entrer le numéro de série"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Emplacement */}
          <Form.Group controlId="location" className="mb-3">
            <Form.Label>
              Emplacement
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Sélectionnez un emplacement prédéfini ou entrez un nouvel
                    emplacement.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="ms-2 text-info"
                />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              list="predefinedLocations"
              value={deviceData.location}
              onChange={e => handleChange("location", e.target.value)}
              placeholder="Sélectionnez ou entrez un emplacement"
            />
            <datalist id="predefinedLocations">
              {predefinedLocations.map(location => (
                <option key={location} value={location} />
              ))}
            </datalist>
          </Form.Group>

          {/* Gestion des utilisateurs à provisionner */}
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="provisionedUsers">
                <OverlayTrigger
                  placement="auto"
                  overlay={
                    <Tooltip>
                      Sélectionnez les utilisateurs à provisionner sur ce
                      téléphone.
                    </Tooltip>
                  }
                >
                  <Form.Label>
                    Utilisateurs à provisionner{" "}
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      style={{ cursor: "pointer", marginLeft: "4px" }}
                    />
                  </Form.Label>
                </OverlayTrigger>
              </Form.Group>

              <Row>
                {/* Emplacement pour le premier utilisateur */}
                <Col md={6}>
                  <Form.Group controlId="provisionedUser1" className="mb-3">
                    <Form.Label>Utilisateur 1 (principal)</Form.Label>
                    <Form.Control
                      as="select"
                      value={userList[0]?.extension || ""}
                      onChange={e => handleSelectUser(0, e.target.value)}
                      disabled={
                        phoneUserLoading ||
                        (userList.length >= 2 && !userList[0])
                      }
                    >
                      <option value="">Sélectionner un utilisateur</option>
                      {phoneUserData.map(user => (
                        <option key={user.id} value={user.extension}>
                          {user.firstname} {user.lastname} ({user.extension})
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                {/* Emplacement pour le second utilisateur */}
                <Col md={6}>
                  <Form.Group controlId="provisionedUser2" className="mb-3">
                    <Form.Label>Utilisateur 2 (secondaire)</Form.Label>
                    <Form.Control
                      as="select"
                      value={userList[1]?.extension || ""}
                      onChange={e => handleSelectUser(1, e.target.value)}
                      disabled={
                        phoneUserLoading ||
                        (userList.length >= 2 && !userList[1])
                      }
                    >
                      <option value="">Sélectionner un utilisateur</option>
                      {phoneUserData.map(user => (
                        <option key={user.id} value={user.extension}>
                          {user.firstname} {user.lastname} ({user.extension})
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              {/* Erreur de sélection */}
              {userError && <p className="text-danger">{userError}</p>}

              {/* Liste des utilisateurs sélectionnés */}
              {userList.length > 0 && (
                <div className="mt-3">
                  <p className="fw-bold">
                    Liste des utilisateurs à provisionner :
                  </p>
                  <ul>
                    {userList.map((user, index) => (
                      <li key={user.id}>
                        {index + 1}. {user.firstname} {user.lastname} (
                        {user.extension}){" "}
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => handleDeleteUser(user.id)}
                          style={{ cursor: "pointer", marginLeft: "8px" }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button variant="secondary" className="me-2" onClick={onClose}>
              Annuler
            </Button>
            <Button variant="primary" type="submit" disabled={submitting}>
              {submitting
                ? "En cours..."
                : mode === "edit"
                  ? "Modifier"
                  : "Ajouter"}
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default DevicesForm;
