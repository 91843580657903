import React, { ChangeEvent } from "react";
import { Col, Row } from "react-bootstrap";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import SearchBox from "components/common/SearchBox";
import useRetailerClients from "hooks/api/retailer/useRetailerClients";
import RetailerClientsTable, {
  retailerClientsTableColumns
} from "../../../../components/tables/iper-x/retailer/RetailerClientsTable";
import useAdvanceTable from "../../../../hooks/others/useAdvanceTable";

const RetailerClients = () => {
  const { tableData, setTableData, loading, error } = useRetailerClients();

  // Handle search input to filter client names
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  // Configure the advanced table
  const table = useAdvanceTable({
    data: tableData,
    columns: retailerClientsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  return (

    <div>
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Vos clients</span>
        </h2>
      </div>

      {/* Search and Filter */}
      <div className="mb-4">
        <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
          <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
        </Row>
      </div>

      {/* Gestion du chargement et des erreurs */}
      {loading && <p>Chargement des données...</p>}
      {error && (
        <p style={{ color: "red" }}>{error}</p>
      )}
      {/* Contenu du tableau */}
      {!loading && (
        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-2 mx-lg-n6 bg-white border-top border-bottom border-200 position-relative top-1">
            <RetailerClientsTable />
          </div>
        </AdvanceTableProvider>
      )}
    </div>
  );
};

export default RetailerClients;
