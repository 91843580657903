import React, { useState } from "react";
import { Modal, Card, Form, FormGroup, Button, Col } from "react-bootstrap";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import IperxParamTAexceptionsTable, { usersIperxParamTAexceptionsColumn } from "components/tables/iper-x/IperxParamTAexceptionsTable";
import { usersIperxParamTAexceptionsTableData } from "data/iper-x/ipbx/usersIperx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const IperxParamTAexceptions = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCloseModal();
  };

  const openModalNewUser = () => {
    handleShowModal();
  }

  const table = useAdvanceTable({
    data: usersIperxParamTAexceptionsTableData,
    columns: usersIperxParamTAexceptionsColumn,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Exception à la règle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>Nom de la personne</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>Extention</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="transfert">
                  <Form.Label>Statue de traitement</Form.Label>
                  <Form.Select aria-label="Default select example" className="mb-3">
                    <option>Transférer</option>
                    <option value="1">Valider</option>
                  </Form.Select>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Soumettre
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="fs-7">Exceptions</h2>
        </div>
        <Col xs="auto" className="mb-3">
          <Button variant="primary" onClick={openModalNewUser}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Ajouter une exception
          </Button>
        </Col>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <IperxParamTAexceptionsTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default IperxParamTAexceptions;
