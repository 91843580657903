import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';

import React, { ChangeEvent, useState } from 'react';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../../components/common/PageBreadcrumb';
import AdvanceTableProvider from '../../../../../../providers/AdvanceTableProvider';
import SearchBox from '../../../../../../components/common/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp, faCogs,
  faEdit,
  faFileExport,
  faFileImport,
  faFilter,
  faPlus, faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import LeadsFilterModal from '../../../../../../components/modals/LeadsFilterModal';
import VoicemailTable from '../../../../../../components/tables/ipbx/VoicemailTable';
import useAdvanceTable from '../../../../../../hooks/others/useAdvanceTable';
import { outboundRulesTableData } from '../../../../../../data/iper-x/ipbx/outboundRules';
import { outboundRulesTableColumns } from '../../../../../../components/tables/ipbx/OutboundRulesTable';
import EmergencyNumTable, {
  emergencyNumRulesTableColumns
} from "../../../../../../components/tables/ipbx/EmergencyNumTable";

export const generalBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Systéme',
    url: '/apps/ipbx/parametres?selected=system'
  },
  {
    label: 'Général',
    url: '/apps/ipbx/parametres/general'
  }
];

const GeneralConfig = () => {
  const table = useAdvanceTable({
    data: outboundRulesTableData,
    columns: emergencyNumRulesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <>
      <PageBreadcrumb items={generalBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Paramètres généraux</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Extension opérateur
              </h3>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  Les appelants reroutés hors messagerie vocale ou autres menus
                  seront transférés ici
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="Nombre">
                  <Form.Control type="text" value="10" />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Options générales</h3>

              <Form.Check
                type="checkbox"
                id="trunkFailoverMaxCalls"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Un failover de trunks a eu lieu ou le nombre max d'appels
                  disponibles sur un trunk a été dépassé
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="externalTransfer"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Autoriser le transfert vers numéros externes à partir des
                  groupes d'appels et files
                </Form.Check.Label>
              </Form.Check>

              <Form.Check type="checkbox" id="busyTone" className="mt-2">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Jouer la tonalité Occupé lorsque l'extension est occupée
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="limitInterception"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Limiter l'interception aux appels reçus dans son groupe
                  d'extension
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="outgoingCallsFromVoicemail"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Permettre les appels sortants à partir de la messagerie vocale
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                type="checkbox"
                id="disableOutgoingCallsOutsideBusinessHours"
                className="mt-2"
              >
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Désactiver automatiquement les appels sortants sur tous les
                  ports en dehors des heures de bureau
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5 mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Récupérer l'appel si Occupé
              </h3>
              <span className="me-3">
                Transfère automatiquement les rappels à la personne qui a
                initialement transféré l'appel si la destination est occupée
                (fonctionne seulement avec les transferts aveugles)
              </span>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  Raccourci (exemple *3*) utilisé automatiquement par ces
                  transferts aveugles
                </Form.Label>
                <FloatingLabel
                  controlId="floatingInputAuthMax"
                  label="racourcis"
                >
                  <Form.Control type="text" value="10" />
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  Timeout avant que l'appelant récupère l'appel
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="Nombre">
                  <Form.Control type="text" value="10" />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AdvanceTableProvider {...table}>
        <div
          className="mx-n4 pt-5 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <h3 className="fw-bolder mb-2 line-clamp-1">
            Numéros d'urgence
          </h3>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  className="w-300"
                  placeholder="Rechercher"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faArrowUp} className="me-2" />
                  Monter
                </Button>
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faArrowDown} className="me-2" />
                  Descendre
                </Button>
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faCogs} className="me-2" />
                  Modéle
                </Button>
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                  Importer
                </Button>
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter un trunck
                </Button>
                <Button
                  variant="phoenix-secondary"
                  className="px-3 ms-2"
                  onClick={() => setOpenFilterModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    transform="down-3"
                    className="text-primary"
                  />
                </Button>
                <LeadsFilterModal
                  show={openFilterModal}
                  handleClose={() => setOpenFilterModal(false)}
                />
              </Col>
            </Row>
          </div>
          <EmergencyNumTable />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default GeneralConfig;
