import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export interface Certification {
  id: number;
  datePassage: string;
  status: boolean;
  statusUpdate: string;
  pointScore: number;
  diplomPath: string;
  user: {
    id: number;
    firstname: string;
    lastname: string;
    avatar: string;
  };
  certification: {
    id: number;
    name: string;
  };
  certificationTypeGroup: {
    id: number;
    name: string;
  };
  certificationType: {
    id: number;
    name: string;
  };
}

// Données anonymes par défaut pour les certifications
const fallBackCertifications: Certification[] = [
  {
    id: 1,
    datePassage: '2024-06-15T10:30:00',
    status: true,
    statusUpdate: 'Completed',
    pointScore: 95,
    diplomPath: 'path/to/diploma',
    user: {
      id: 1,
      firstname: 'Anonymous',
      lastname: 'User',
      avatar: 'https://ui-avatars.com/api/?name=Anonymous+User'
    },
    certification: {
      id: 1,
      name: 'Certification A'
    },
    certificationTypeGroup: {
      id: 1,
      name: 'Group Editeur'
    },
    certificationType: {
      id: 1,
      name: 'Type Technicien'
    }
  }
];

// Données anonymes par défaut pour les certifications
const defaultCertifications: Certification[] = [

];



const certificationsData: Certification[] = [];

/**
 * **Certification**:
 * A custom hook that manages certification data, including loading and error states.
 * It fetches certification data from an external API, using token-based authentication stored in localStorage.
 *
 * @returns {Object} - The certification data, loading state, error message, and fallback data.
 *
 * @property {Certification[]} certifications - Certification data fetched from the API or fallback data in case of errors.
 * @property {Function} setCertifications - Function to manually set the certification data.
 * @property {boolean} loading - Indicates whether the certification data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 * @property {Certification[]} fallBackCertifications - The fallback certification data in case the API call fails.
 *
 * @example
 * // Example usage of useRetailerCertifications in a component
 * const { certifications, loading, error } = useRetailerCertifications();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <CertificationList data={certifications} />;
 */
const useRetailerCertifications = () => {
  const [certifications, setCertifications] =
    useState<Certification[]>(certificationsData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchCertifications = useCallback(async () => {
    try {
      // Récupérer le token depuis le localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token non disponible');
      }

      // Ajouter le token dans les headers de la requête
      const response = await axios.get<Certification[]>(
        'https://rct-backend.ipercom.io/api/Certification/user/me',
        {
          headers: {
            accept: 'text/plain',
            Authorization: `Bearer ${token}`
          }
        }
      );

      setCertifications(response.data);
      setError(null); // Réinitialiser l'erreur en cas de succès
    } catch (err) {
      console.error("Erreur lors de la récupération des données de l'API", err);
      setError('Impossible de récupérer les données.');
      // setCertifications(fallBackCertifications); // Utiliser les données par défaut en cas d'erreur
    } finally {
      setLoading(false); // Arrêter le chargement dans tous les cas
    }
  }, []);

  useEffect(() => {
    fetchCertifications();
  }, [fetchCertifications]);

  return {
    certifications,
    loading,
    error,
    fallBackCertifications
  };
};

export default useRetailerCertifications;
