import {
  faCog,
  faDollarSign,
  faEdit,
  faFileExport,
  faFilter,
  faPhone,
  faPlus,
  faTrash,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReportsTable, { reprtsTableColumns } from "../../../../components/tables/ipbx/ReportsTable";
import { reportsTableData } from "../../../../data/iper-x/ipbx/reports";
import ReportsDownloadTable, {
  reprtsDownloadTableColumns
} from "../../../../components/tables/ipbx/ReportsDownloadTable";
import { reportsDownloadTableData } from "../../../../data/iper-x/ipbx/reportsDownload";

const ReportsList = () => {


  const table2 = useAdvanceTable({
    data: reportsDownloadTableData,
    columns: reprtsDownloadTableColumns,
    pageSize: 5,
    pagination: true,
    sortable: true,
    selection: true
  });

  const table = useAdvanceTable({
    data: reportsTableData,
    columns: reprtsTableColumns,
    pageSize: 5,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <>

      <div>
        {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
        <AdvanceTableProvider {...table}>
          <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
            <h2 className="mb-0">
              <span className="me-3">Rapports</span>
            </h2>

            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faEdit} className="me-2" />
              Modifier
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
              Supprimer
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faTrash} className="me-2" />
              Purger
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faCog} className="me-2" />
              Paramètres
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faPhone} className="me-2" />
              Types d’appels
            </Link>
            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faDollarSign} className="me-2" />
              Coût des appels
            </Link>

          </div>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox className="w-300"
                           placeholder="Rechercher"
                           onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faFilter} />
                </Button>


              </Col>
            </Row>
          </div>
          <div
            className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <ReportsTable />
          </div>
        </AdvanceTableProvider>
      </div>


      <div className="mt-5">
        {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
        <AdvanceTableProvider {...table2}>
          <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
            <h2 className="mb-0">
              <span className="me-3">Télécharger</span>
            </h2>

            <Link className="btn btn-white border border-2 px-2" to="/">
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
              Supprimer tout
            </Link>


          </div>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox className="w-300"
                           placeholder="Rechercher"
                           onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faFilter} />
                </Button>


              </Col>
            </Row>
          </div>
          <div
            className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <ReportsDownloadTable />
          </div>
        </AdvanceTableProvider>
      </div>

    </>
  );
};

export default ReportsList;

