import { useState, useCallback, useEffect } from "react";

export interface FinalClientData {
  id: number;
  idRevendeur: number;
  createdBy: number;
  idInstance: number;
  firstName: string;
  lastName: string;
  position: string | null;
  email: string | null;
  address: string | null;
  dateCreated: string | null;
  phoneNumber: string | null;
  companyName: string | null;
  status: string | null;
  lastUpdated: string | null;
  licences: any[];
  siret: string | null;
}

// Données initiales vides
const initialClientData: FinalClientData | null = null;

/**
 * **useFinalClientByID**:
 * Un hook personnalisé pour gérer les données d'un client final spécifique, l'état de chargement et les erreurs.
 * Il récupère les données du client via une API externe et gère l'authentification via un token stocké dans le localStorage.
 *
 * @param {number} id - L'identifiant du client final à récupérer.
 * @returns {Object} - Les données du client final, l'état de chargement, le message d'erreur et le setter des données client.
 *
 * @property {FinalClientData | null} clientData - Données du client final récupérées via l'API ou null si non disponible.
 * @property {Function} setClientData - La fonction pour définir manuellement les données du client final.
 * @property {boolean} loading - Indique si les données sont en cours de récupération.
 * @property {string | null} error - Le message d'erreur ou null s'il n'y a pas d'erreur.
 *
 * @example
 * const { clientData, loading, error } = useFinalClientByID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ClientDetail data={clientData} />;
 */
const useFinalClientByID = (id: number) => {
  const [clientData, setClientData] = useState<FinalClientData | null>(initialClientData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Token not available");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/ipbx/clientfinal/${id}`, {
        method: "GET",
        headers: {
          "accept": "text/plain",
          "Authorization": `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setClientData(data);

      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError("Impossible de récupérer les données.");
      setClientData(null); // Ou définir ici des données de secours si nécessaire
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    clientData,
    setClientData,
    loading,
    error
  };
};

export default useFinalClientByID;
