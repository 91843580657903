import useFinalClient from "hooks/api/ipbx/useFinalClient";
import { ScheduleData } from "hooks/api/ipbx/useSchedule";
import React, { ChangeEvent, useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

type ScheduleFormProps = {
  tableData: ScheduleData[];
  setTableData: React.Dispatch<React.SetStateAction<ScheduleData[]>>;
  onClose: () => void;
};

const AddScheduleForm: React.FC<ScheduleFormProps> = ({
                                                        tableData,
                                                        setTableData,
                                                        onClose
                                                      }) => {
  const [newSchedule, setNewSchedule] = useState<ScheduleData>({
    id: 0,
    idClient: 0,
    name: "",
    startSlot1: "00:00:00",
    endSlot1: "00:00:00",
    startSlot2: "00:00:00",
    endSlot2: "00:00:00",
    days: "",
    state: true
  });

  const hours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );

  const handleTimeChange = (field: string, value: string) => {
    const [hour, minute] = value.split(":");
    const timeWithSeconds = `${hour}:${minute}:00`; // Append ":00" as seconds
    setNewSchedule(prev => ({
      ...prev,
      [field]: timeWithSeconds
    }));
  };

  const handleDaysChange = (day: string) => {
    const currentDaysArray = newSchedule.days.split(",").filter(Boolean);
    const updatedDaysArray = currentDaysArray.includes(day)
      ? currentDaysArray.filter(d => d !== day)
      : [...currentDaysArray, day];

    setNewSchedule(prev => ({
      ...prev,
      days: updatedDaysArray.join(",")
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }

    const storedIpbxAccess = localStorage.getItem("ipbx_access");
    let clientFinalId = 0;

    if (storedIpbxAccess) {
      const ipbxAccess = JSON.parse(storedIpbxAccess);
      if (ipbxAccess.length > 0) {
        clientFinalId = ipbxAccess[0].id_client_final;
      }
    }

    const scheduleToSubmit = {
      ...newSchedule,
      idClient: clientFinalId,
      startSlot1: newSchedule.startSlot1, // Ensure this is in the correct format
      endSlot1: newSchedule.endSlot1, // Ensure this is in the correct format
      startSlot2: newSchedule.startSlot2, // Ensure this is in the correct format
      endSlot2: newSchedule.endSlot2, // Ensure this is in the correct format
      days: newSchedule.days
        .split(",")
        .map(day => day.trim())
        .join(",") // Ensure the days are formatted properly
    };

    try {
      const response = await fetch(
        "https://rct-backend.ipercom.io/api/Ipbx/schedule",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(scheduleToSubmit)
        }
      );

      if (response.ok) {
        window.location.reload();
      } else {
        const errorData = await response.json();
        console.error("Error details:", errorData.errors);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un l'horaire de bureau", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* State Toggle */}
      <Form.Group controlId="state" className="align-items-center mb-2">
        <Form.Label>État de l'horaire :</Form.Label>
        <div className="d-flex align-items-center">
          <Form.Switch
            name="state"
            checked={newSchedule.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewSchedule({
                ...newSchedule,
                state: e.target.checked
              })
            }
            className="me-2"
          />
          <span>{newSchedule.state ? "Actif" : "Inactif"}</span>
        </div>
      </Form.Group>

      {/* Name Input */}
      <Form.Group controlId="name" className="mb-2">
        <Form.Label>Nom</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={newSchedule.name}
          onChange={e =>
            setNewSchedule(prev => ({
              ...prev,
              name: e.target.value
            }))
          }
          required
        />
      </Form.Group>

      {/* Morning Schedule */}
      <Row className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Sélectionnez les heures d'ouverture et de fermeture pour le matin
            </Tooltip>
          }
        >
          <h5 className="m-1 fs-9 text-uppercase">
            Matin{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </h5>
        </OverlayTrigger>
        <Col>
          <Form.Group controlId="startSlot1">
            <Form.Label>Ouverture</Form.Label>
            <div className="d-flex">
              <Form.Select
                value={newSchedule.startSlot1.split(":")[0] || ""}
                onChange={e =>
                  handleTimeChange(
                    "startSlot1",
                    `${e.target.value}:${newSchedule.startSlot1.split(":")[1] || "00"}`
                  )
                }
                required
              >
                <option value="">Heure</option>
                {hours.map(hour => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                value={newSchedule.startSlot1.split(":")[1] || ""}
                onChange={e =>
                  handleTimeChange(
                    "startSlot1",
                    `${newSchedule.startSlot1.split(":")[0] || "00"}:${e.target.value}`
                  )
                }
                required
              >
                <option value="">Minute</option>
                {minutes.map(minute => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="endSlot1">
            <Form.Label>Fermeture</Form.Label>
            <div className="d-flex">
              <Form.Select
                value={newSchedule.endSlot1.split(":")[0] || ""}
                onChange={e =>
                  handleTimeChange(
                    "endSlot1",
                    `${e.target.value}:${newSchedule.endSlot1.split(":")[1] || "00"}`
                  )
                }
                required
              >
                <option value="">Heure</option>
                {hours.map(hour => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                value={newSchedule.endSlot1.split(":")[1] || ""}
                onChange={e =>
                  handleTimeChange(
                    "endSlot1",
                    `${newSchedule.endSlot1.split(":")[0] || "00"}:${e.target.value}`
                  )
                }
                required
              >
                <option value="">Minute</option>
                {minutes.map(minute => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Form.Group>
        </Col>
      </Row>

      {/* Afternoon Schedule */}
      <Row className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Sélectionnez les heures d'ouverture et de fermeture pour
              l'après-midi
            </Tooltip>
          }
        >
          <h5 className="m-1 fs-9 text-uppercase">
            Après-midi{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </h5>
        </OverlayTrigger>
        <Col>
          <Form.Group controlId="startSlot2">
            <Form.Label>Ouverture</Form.Label>
            <div className="d-flex">
              <Form.Select
                value={newSchedule.startSlot2.split(":")[0] || ""}
                onChange={e =>
                  handleTimeChange(
                    "startSlot2",
                    `${e.target.value}:${newSchedule.startSlot2.split(":")[1] || "00"}`
                  )
                }
                required
              >
                <option value="">Heure</option>
                {hours.map(hour => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                value={newSchedule.startSlot2.split(":")[1] || ""}
                onChange={e =>
                  handleTimeChange(
                    "startSlot2",
                    `${newSchedule.startSlot2.split(":")[0] || "00"}:${e.target.value}`
                  )
                }
                required
              >
                <option value="">Minute</option>
                {minutes.map(minute => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="endSlot2">
            <Form.Label>Fermeture</Form.Label>
            <div className="d-flex">
              <Form.Select
                value={newSchedule.endSlot2.split(":")[0] || ""}
                onChange={e =>
                  handleTimeChange(
                    "endSlot2",
                    `${e.target.value}:${newSchedule.endSlot2.split(":")[1] || "00"}`
                  )
                }
                required
              >
                <option value="">Heure</option>
                {hours.map(hour => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                value={newSchedule.endSlot2.split(":")[1] || ""}
                onChange={e =>
                  handleTimeChange(
                    "endSlot2",
                    `${newSchedule.endSlot2.split(":")[0] || "00"}:${e.target.value}`
                  )
                }
                required
              >
                <option value="">Minute</option>
                {minutes.map(minute => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Form.Group>
        </Col>
      </Row>

      {/* Day Selector */}
      <h5 className="m-1 fs-9 text-uppercase">Jour</h5>
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip>
            Sélectionnez les jours pour lesquels cet horaire est actif
          </Tooltip>
        }
      >
        <div className="d-flex mb-3">
          {[
            "Lundi",
            "Mardi",
            "Mercredi",
            "Jeudi",
            "Vendredi",
            "Samedi",
            "Dimanche"
          ].map(day => (
            <div
              key={day}
              onClick={() => handleDaysChange(day)}
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 4px",
                cursor: "pointer",
                backgroundColor: newSchedule.days.includes(day)
                  ? "#007bff"
                  : "#f8f9fa",
                color: newSchedule.days.includes(day) ? "#fff" : "#000",
                border: "1px solid #ddd"
              }}
            >
              {day.slice(0, 1)}
            </div>
          ))}
        </div>
      </OverlayTrigger>

      <Button variant="primary" type="submit" className="mt-2">
        Ajouter
      </Button>
    </Form>
  );
};

export default AddScheduleForm;
