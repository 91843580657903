import { createContext, useContext, ReactNode } from "react";
import { ScheduleData } from "hooks/api/ipbx/useSchedule";

// Création du contexte pour les données de l'emploi du temps
const ScheduleContext = createContext<ScheduleData[] | null>(null);

// Hook pour utiliser le contexte de l'emploi du temps
export const useScheduleData = () => {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error("useScheduleData must be used within a ScheduleProvider");
  }
  return context;
};

// Fournisseur de contexte pour les données de l'emploi du temps
export const ScheduleProvider = ({
                                   scheduleData,
                                   children
                                 }: {
  scheduleData: ScheduleData[];
  children: ReactNode;
}) => {
  return (
    <ScheduleContext.Provider value={scheduleData}>
      {children}
    </ScheduleContext.Provider>
  );
};
