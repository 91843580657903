import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import useFinalClientByID from "hooks/api/ipbx/useFinalClientByID";
import IpbxPrintingDimensionsCard from "components/cards/ipbx/IpbxPrintingDimensionsCard";
import { Stat } from "data/iper-x/ipbx/ipbxDetailsInfo";
import classNames from "classnames";
import AdClicksChart from "components/charts/e-charts/AdClicksChart";
import ContactsCreatedChart from "components/charts/e-charts/ContactsCreatedChart";
import InfoList from "components/modules/ipbx/info-list/InfoList";
import useServerByID from "hooks/api/ipbx/useServerByID";

export type BadgeBg =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info";

type InfoItem = {
  task: string;
  badge: {
    label: string;
    bg: BadgeBg;
  };
  attachment: number;
  date: string;
  time: string;
};

export const stats: Stat[] = [
  {
    id: 1,
    title: "Contact",
    value: "0 inscrits/ 0 non inscrits",
    icon: "phone",
    color: "success"
  },
  {
    id: 2,
    title: "Appareils SIP",
    value: "0 inscrits/ 0 non inscrits",
    icon: "phone",
    color: "info"
  },
  {
    id: 3,
    title: "Appel actif",
    value: "0",
    icon: "headphones",
    color: "primary"
  }
];
export const statusList: InfoItem[] = [];

export const infoListTemplate: InfoItem[] = [];

const DashboardIpbx = () => {
  const [showModal, setShowModal] = useState(false);
  const [clientName, setClientName] = useState("");
  const [systemStats, setSystemStats] = useState({
    cpu_used: 0,
    ram_used: 0,
    ram_total: 0,
    disk_used: 0,
    disk_total: 0
  });

  const [infoList, setInfoList] = useState(infoListTemplate);
  // Get the license ID from localStorage
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const idLicence = ipbxAccess.length > 0 ? ipbxAccess[0].id_licence : null;
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;
  const token = localStorage.getItem("token");

  const { clientData } = useFinalClientByID(clientFinalId);
  const serverId = clientData?.idInstance;

  const { serverByIDData } = useServerByID(serverId as number);

  // Fetch license data using idLicence
  useEffect(() => {
    const fetchLicenceData = async () => {
      try {
        if (idLicence) {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Licence/${idLicence}`,
            {
              headers: {
                accept: "text/plain",
                Authorization: `Bearer ${token}`
              }
            }
          );
          const data = await response.json();
          updateInfoList(data); // Update infoList after fetching license data
        }
      } catch (error) {
        console.error("Failed to fetch licence data", error);
      }
    };

    fetchLicenceData();
  }, [idLicence]);

  type LicenseDataType = {
    key: string;
    activationDate: string;
    expirationDate: string;
    simultaneousCalls?: number;
    maxUsers?: number;
    status?: string;
  };

  const updateInfoList = (licenseData: LicenseDataType) => {
    const updatedInfoList: InfoItem[] = [
      {
        task: "Numéro de licence",
        badge: {
          label: licenseData.key || "N/A",
          bg: "warning" as BadgeBg // Explicitly cast to BadgeBg
        },
        attachment: 2,
        date: new Date(licenseData.activationDate).toLocaleDateString(),
        time: new Date(licenseData.activationDate).toLocaleTimeString()
      },
      {
        task: "Date d'expiration",
        badge: {
          label: licenseData.expirationDate
            ? new Date(licenseData.expirationDate).toLocaleDateString()
            : "N/A",
          bg: "warning" as BadgeBg // Explicitly cast to BadgeBg
        },
        attachment: 2,
        date: new Date(licenseData.expirationDate).toLocaleDateString(),
        time: new Date(licenseData.expirationDate).toLocaleTimeString()
      },
      {
        task: "Appels simultanés",
        badge: {
          label: licenseData.simultaneousCalls?.toString() || "N/A",
          bg: "info" as BadgeBg // Explicitly cast to BadgeBg
        },
        attachment: 2,
        date: "N/A",
        time: "N/A"
      },
      {
        task: "Nombre d’utilisateurs maximal",
        badge: {
          label: licenseData.maxUsers?.toString() || "N/A",
          bg: "success" as BadgeBg // Explicitly cast to BadgeBg
        },
        attachment: 2,
        date: "N/A",
        time: "N/A"
      },
      {
        task: "Statut de la licence",
        badge: {
          label: licenseData.status || "N/A",
          bg:
            licenseData.status === "active"
              ? ("success" as BadgeBg)
              : ("danger" as BadgeBg) // Cast as BadgeBg
        },
        attachment: 2,
        date: "N/A",
        time: "N/A"
      }
    ];

    // Set the updated list
    setInfoList(updatedInfoList);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    position: "",
    email: "",
    address: "",
    siret: "",
    dateCreated: "",
    phoneNumber: "",
    companyName: "",
    status: "",
    lastUpdated: ""
  });

  useEffect(() => {
    if (clientData) {
      setFormData({
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        companyName: clientData.companyName || "",
        position: clientData.position || "",
        email: clientData.email || "",
        address: clientData.address || "",
        siret: clientData.siret || "",
        dateCreated: clientData.dateCreated || "",
        phoneNumber: clientData.phoneNumber || "",
        status: clientData.status || "",
        lastUpdated: clientData.lastUpdated || ""
      });
      setClientName(clientData.companyName || "");
    }
  }, [clientData]);

  // Function to fetch system stats
  const fetchSystemStats = async () => {
    try {
      const response = await fetch("https://ipbx-e9212.iper-x.com/sys_stats.txt");
      const data = await response.text();
      const stats = parseSystemStats(data);
      setSystemStats(stats);
    } catch (error) {
      console.error("Failed to load system stats", error);
    }
  };

  // Parse system stats from text
  const parseSystemStats = (
    data: string
  ): {
    cpu_used: number;
    ram_used: number;
    ram_total: number;
    disk_used: number;
    disk_total: number;
  } => {
    const stats: {
      cpu_used: number;
      ram_used: number;
      ram_total: number;
      disk_used: number;
      disk_total: number;
    } = {
      cpu_used: 0,
      ram_used: 0,
      ram_total: 0,
      disk_used: 0,
      disk_total: 0
    };
    const lines = data.split("\n");
    lines.forEach(line => {
      const [key, value] = line.split("=");
      if (key && value) {
        switch (key.trim()) {
          case "cpu_used":
            stats.cpu_used = parseFloat(value);
            break;
          case "ram_used":
            stats.ram_used = parseFloat(value);
            break;
          case "ram_total":
            stats.ram_total = parseFloat(value);
            break;
          case "disk_used":
            stats.disk_used = parseFloat(value);
            break;
          case "disk_total":
            stats.disk_total = parseFloat(value);
            break;
          default:
            break;
        }
      }
    });
    return stats;
  };

  // UseEffect to load system stats every 2 minutes
  useEffect(() => {
    fetchSystemStats(); // Initial fetch
    const intervalId = setInterval(fetchSystemStats, 120000); // Fetch every 2 minutes (120000 ms)

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value || ""
    }));
  };

  const handleEdit = async () => {
    try {
      if (!clientData) {
        console.error("clientData is null");
        return;
      }

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(
        "https://rct-backend.ipercom.io/api/Ipbx/clientfinal",
        {
          method: "PUT",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            id: clientData.id,
            idRevendeur: clientData.idRevendeur,
            createdBy: clientData.createdBy,
            idInstance: clientData.idInstance,
            ...formData
          })
        }
      );

      if (response.ok) {
        window.location.reload(); // Refresh page to see updated data
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du client final", error);
    }
  };

  return (
    <>
      <Col md={9} xs="auto">
        <h3 className="fw-bolder mb-2">
          {clientName || "Nom à renseigner"}
          <FontAwesomeIcon
            icon={faPencil}
            className="fs-10 text-500 ms-3"
            onClick={handleOpenModal}
            style={{ cursor: "pointer" }}
          />
        </h3>
        {clientData && (
          <div className=" mb-3">
            <span className="me-3">
              <strong>Poste:</strong> {clientData.position || "non défini"}
            </span>
            <span className="mx-2">|</span>
            <span className="me-3">
              <strong>Email:</strong> {clientData.email || "non défini"}
            </span>
            <span className="mx-2">|</span>
            <span className="me-3">
              <strong>Adresse:</strong> {clientData.address || "non défini"}
            </span>
            <span className="mx-2">|</span>
            <span>
              <strong>Siret:</strong> {clientData.siret || "non défini"}
            </span>
          </div>
        )}
      </Col>

      <div className="mb-9" data-bs-spy="scroll">
        <div className="mt-3 mx-lg-n4">
          <IpbxPrintingDimensionsCard stats={stats} className="mb-9" />

          <div className="mb-9">
            <Row className="g-3 mt-n7">
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Statut système</h3>
                    <div className="mt-5">
                      <AdClicksChart style={{ height: 270, width: "100%" }} />
                    </div>
                    <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between">
                      <Col key={1} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            " ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">
                              Usage du disque
                            </h4>
                            <p className="fw-bold mb-1">
                              {systemStats.disk_used}% utilisé (
                              {systemStats.disk_total - systemStats.disk_used}{" "}
                              GB restant)
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col key={2} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Mémoire</h4>
                            <p className="fw-bold mb-1">
                              {systemStats.ram_used}% utilisé,{" "}
                              {systemStats.ram_total - systemStats.ram_used} MB
                              restant
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col key={3} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5 mx-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">CPU</h4>
                            <p className="fw-bold mb-1">
                              {systemStats.cpu_used}/100
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Trafic du jour</h3>
                    <div className="mt-5 mb-5">
                      <ContactsCreatedChart
                        style={{ height: 270, width: "100%" }}
                      />
                    </div>
                    <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between">
                      <Col key={1} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            " ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Appels entrants</h4>
                            <p className="fw-bold mb-1">0</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={2} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Appels sortants</h4>
                            <p className="fw-bold mb-1">0 inscrits/ 0 non inscrits</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={3} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Interne</h4>
                            <p className="fw-bold mb-1">0</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={4} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >
                          <div>
                            <h4 className="fw-bolder text-nowrap">Transit</h4>
                            <p className="fw-bold mb-1">0</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <div className="mb-9">
            <Row className="g-3 mt-n7">
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Statut PBX</h3>
                    <InfoList data={statusList} />
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Informations</h3>
                    <InfoList data={infoList} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* Modal for editing client information */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier les informations du client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nom de la société</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPosition">
              <Form.Label>Poste</Form.Label>
              <Form.Control
                as="select"
                name="position"
                className="form-select"
                value={formData.position}
                onChange={handleChange}
              >
                <option value="">Sélectionner un poste</option>
                <option value="pdg">PDG</option>
                <option value="directeur_commercial">
                  Directeur commercial
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Adresse</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Format: N° rue ville code postal pays"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="siret">
              <Form.Label>Siret</Form.Label>
              <Form.Control
                type="text"
                name="siret"
                value={formData.siret}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formDateCreated">
              <Form.Label>Date de création</Form.Label>
              <Form.Control
                type="text"
                name="dateCreated"
                value={formData.dateCreated}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Numéro de téléphone</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCompanyName">
              <Form.Label>Nom de l'entreprise</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formStatus">
              <Form.Label>Statut</Form.Label>
              <Form.Control
                type="text"
                name="status"
                value={formData.status}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastUpdated">
              <Form.Label>Dernière mise à jour</Form.Label>
              <Form.Control
                type="text"
                name="lastUpdated"
                value={formData.lastUpdated}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Enregistrer les modifications
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardIpbx;
