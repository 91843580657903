import { useState, useEffect, useCallback } from "react";

// Define the structure for PhoneUserData
export interface PhoneUserData {
  id: number;
  idClient: number;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  // phoneUsername: string;
  phonePassword: string;
  avatar: string;
  extension: string;
  mobile: string;
  fixe: string;
  lastActivity?: string;
  callStatus: string;
  userStatus: string;
  userFunction: string;
  displayNumber: string;
  displayName: string;
  infoOrigineTel?: string;
  infoMarqueTel?: string;
  infoModeleTel?: string;
  infoVersion?: string;
  infoIP?: string;
  // postParam: number;
}

/**
 * **usePhoneUserDataID**:
 * A custom hook for managing phone user data from the WebRTC API.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch phone user data for.
 * @returns {Object} - The phone user data, loading state, errors, and a setter for the data.
 *
 * @property {PhoneUserData[]} phoneUserData - The data retrieved from the API or a fallback value.
 * @property {Function} setPhoneUserData - Function to manually set phone user data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { phoneUserData, loading, error } = usePhoneUserDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <PhoneUserList data={phoneUserData} />;
 */
const usePhoneUserDataID = (id: number) => {
  const [phoneUserData, setPhoneUserData] = useState<PhoneUserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPhoneUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Webrtc/phoneuser/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setPhoneUserData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données phone user data", error);
      setError("Impossible de récupérer les données phone user data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchPhoneUserData();
  }, [fetchPhoneUserData]);

  return {
    phoneUserData,
    setPhoneUserData,
    loading,
    error
  };
};

export default usePhoneUserDataID;
