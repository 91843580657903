import React, { ChangeEvent } from 'react';
import { Nav, Tab, Col, Row } from 'react-bootstrap';
import SearchBox from 'components/common/SearchBox';
import FinalClientTable, { FinalClientcolumns } from 'pages/apps/editor/detail_retailer/FinalClientTable';
import useAdvanceTable from 'hooks/others/useAdvanceTable';
import { finalClientTableData } from 'data/crm/leadsData';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Users from './Users';

const FinalClientInDetailEditor = () => {

  const table = useAdvanceTable({
    data: finalClientTableData,
    columns: FinalClientcolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  interface TabItem {
    name: string;
    content: JSX.Element;
  }

  const tabItems: TabItem[] = [
    {
      name: 'Détail des clients finaux',
      content: (
        <div>
          <Row className="g-3 justify-content-between mb-4 mt-4">
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Rechercher par nom"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          <FinalClientTable />
        </div>
      )
    },
    {
      name: 'Utilisateurs',
      content: (
        <div>
          <Row className="g-3 justify-content-between mb-4 mt-4">
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Rechercher par nom"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          <Users />
        </div>
      )
    },

  ];

  return (
    <AdvanceTableProvider {...table}>
      <div className="mb-2 mt-2" style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Tab.Container defaultActiveKey={tabItems[0].name.toLowerCase()}>
          <Nav
            variant="underline"
            className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-2 justify-content-around"
          >
            {tabItems.map(item => (
              <Nav.Item key={item.name} className="me-2 text-nowrap">
                <Nav.Link eventKey={item.name.toLowerCase()}>
                 <h3>{item.name}</h3> 
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          
          <Tab.Content>
            {tabItems.map(item => (
              <Tab.Pane key={item.name} eventKey={item.name.toLowerCase()}>
                {item.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
    </AdvanceTableProvider>
  );
};

export default FinalClientInDetailEditor;
