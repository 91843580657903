import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState
} from "react";
import {
  Button,
  Form,
  InputGroup,
  Row,
  Col,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import { PhoneUserData } from "hooks/api/ipbx/usePhoneUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faEye,
  faEyeSlash,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import PasswordValidator from "../restriction/PasswordValidator";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";
import useSdaDataID from "hooks/api/ipbx/useSdaDataID";

type PhoneUserFormProps = {
  tableData: PhoneUserData[];
  setTableData: React.Dispatch<React.SetStateAction<PhoneUserData[]>>;
  onClose: () => void;
  initialData?: PhoneUserData;
};

const generateStrongPassword = () => {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";

  const isValidPassword = (password: string) => (password: string) =>
    password.length >= 8 &&
    /\d/.test(password) &&
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    /[!@#$%^&*]/.test(password);

  do {
    password = Array.from({ length: 16 }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join("");
  } while (!isValidPassword(password));

  return password;
};


const PhoneUserForm: React.FC<PhoneUserFormProps> = ({
                                                       tableData,
                                                       setTableData,
                                                       onClose,
                                                       initialData
                                                     }) => {
  const clientFinalId =
    JSON.parse(localStorage.getItem("ipbx_access") || "[]")[0]
      ?.id_client_final || null;
  const { phoneUserData } = usePhoneUserDataID(clientFinalId);
  const { sdaData, loading: sdaLoading } = useSdaDataID(clientFinalId);

  const [newUser, setNewUser] = useState<PhoneUserData>(
    initialData || {
      id: 0,
      idClient: clientFinalId || 0,
      firstname: "",
      lastname: "",
      email: "",
      password: generateStrongPassword(),
      phonePassword: generateStrongPassword(),
      avatar: "",
      extension: "",
      mobile: "",
      fixe: "",
      userStatus: "actif",
      userFunction: "",
      displayNumber: "",
      displayName: "",
      callStatus: ""
    }
  );

  useEffect(() => {
    if (initialData) {
      setNewUser(initialData);
    }
  }, [initialData]);

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [extensionError, setExtensionError] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<{
    [key: string]: string | null;
  }>({
    firstname: null,
    lastname: null,
    email: null,
    extension: null,
    displayNumber: null,
    userFunction: null
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Validation des numéros de téléphone (uniquement chiffres et longueur de 10 à 15 caractères)
    if ((name === "mobile" || name === "fixe") && !/^\d{0,15}$/.test(value)) {
      return; // Ignore the input if it's not numeric or exceeds the length
    }

    setNewUser(prevUser => ({ ...prevUser, [name]: value }));

    // Validate required fields in real-time
    if (name in formErrors) {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [name]: value ? null : "Ce champ est requis"
      }));
    }
  };

  const handleExtensionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      setExtensionError("Ce champ est requis");
      setNewUser(prevUser => ({ ...prevUser, extension: value }));
      return;
    }

    const numericValue = value.replace(/[^0-9]/g, "");

    // Check if the extension is already in use, excluding the current user
    const isExtensionInUse = phoneUserData.some(
      user => user.extension === numericValue && user.id !== newUser.id
    );

    if (isExtensionInUse) {
      setExtensionError("Ce numéro de poste est déjà utilisé.");
    } else {
      // Validate format and range
      const isValidExtension =
        /^\d{1,5}$/.test(numericValue) &&
        Number(numericValue) >= 1 &&
        Number(numericValue) <= 99999 &&
        numericValue.charAt(0) !== "0";

      if (isValidExtension) {
        setExtensionError(null);
      } else {
        setExtensionError("L'extension doit être un nombre entre 1 à 5 chiffres, sans commencer par 0");
      }
    }

    // Update state with the numeric value
    setNewUser(prevUser => ({ ...prevUser, extension: numericValue }));
  };

  const toggleShowPassword = () => setShowPassword(prevShow => !prevShow);

  const validateForm = () => {
    const errors: { [key: string]: string | null } = {};

    if (!newUser.firstname) errors.firstname = "Le prénom est requis";
    if (!newUser.lastname) errors.lastname = "Le nom est requis";
    if (!newUser.email) errors.email = "L'email est requis";
    if (!newUser.extension) errors.extension = "L'extension est requise";
    if (!newUser.displayNumber) errors.displayNumber = "Le numéro SDA est requis";
    if (!newUser.userFunction) errors.userFunction = "La fonction est requise";

    // Check if extension is already in use, excluding the current user's extension
    if (phoneUserData.some(user => user.extension === newUser.extension && user.id !== newUser.id)) {
      errors.extension = "Ce numéro de poste est déjà utilisé.";
    }

    setFormErrors(errors);

    return Object.values(errors).every(error => error === null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm() || passwordError || extensionError) return;

    const token = localStorage.getItem('token');
    if (!token) throw new Error("Token not found");

    try {
      const method = initialData ? "PUT" : "POST";
      const url = "https://rct-backend.ipercom.io/api/Webrtc/phoneuser";

      const response = await fetch(url, {
        method,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(newUser)
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      // Handle response based on status
      let data: PhoneUserData | null = null;
      if (response.status !== 204) { // Only parse JSON if it's not a 204 status
        data = await response.json();
      }

      // Update table data
      setTableData(prevData =>
        initialData && data
          ? prevData.map(item => (item.id === data.id ? data : item))
          : data
            ? [...prevData, data]
            : prevData
      );

      onClose(); // Close the modal on successful submission
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de l'ajout/mise à jour de l'utilisateur", error);
    }
  };


  const renderPasswordInfo = (props: any) => (
    <Tooltip id="password-tooltip" {...props}>
      Ce mot de passe est généré automatiquement. Vous pouvez le changer si
      nécessaire.
    </Tooltip>
  );
  const renderLoginInfo = (props: any) => (
    <Tooltip id="login-tooltip" {...props}>
      Ces informations servent à se connecter au softphone Iper-X.
    </Tooltip>
  );
  const renderAvatarInfo = (props: any) => (
    <Tooltip id="avatar-tooltip" {...props}>
      La personnalisation de l'avatar est désactivée pour le moment.
    </Tooltip>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <h5>Informations personnelles</h5>
      <Row>
        <Col md={6}>
          <Form.Group controlId="firstname">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={newUser.firstname}
              onChange={handleChange}
              isInvalid={!!formErrors.firstname}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.firstname}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastname">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={newUser.lastname}
              onChange={handleChange}
              isInvalid={!!formErrors.lastname}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.lastname}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="userFunction" className="mt-2">
        <Form.Label>Fonction</Form.Label>
        <Form.Control
          as="select"
          name="userFunction"
          className="form-select"
          value={newUser.userFunction}
          onChange={handleChange}
          isInvalid={!!formErrors.userFunction}
          required
        >
          <option value="">Sélectionner une fonction</option>
          <option value="PDG">PDG (Président-Directeur Général)</option>
          <option value="DG">DG (Directeur Général)</option>
          <option value="CTO">CTO (Directeur Technique)</option>
          <option value="CFO">CFO (Directeur Financier)</option>
          <option value="COO">COO (Directeur des Opérations)</option>
          <option value="Responsable">Responsable</option>
          <option value="Chef d'Équipe">Chef d'Équipe</option>
          <option value="ADV">ADV (Administration des Ventes)</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {formErrors.userFunction}
        </Form.Control.Feedback>
      </Form.Group>

      <h5 className="mt-3">Contact</h5>
      <Row>
        <Col md={6}>
          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              name="mobile"
              value={newUser.mobile}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="fixe">
            <Form.Label>Fixe</Form.Label>
            <Form.Control
              type="text"
              name="fixe"
              value={newUser.fixe}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group controlId="avatar" className="mt-2">
        <Form.Label>
          Avatar{" "}
          <OverlayTrigger placement="right" overlay={renderAvatarInfo}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              style={{ cursor: "pointer" }}
            />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type="file"
          name="avatar"
          onChange={handleChange}
          disabled
        />
      </Form.Group>

      <h5 className="mt-3">
        Sécurité{" "}
        <OverlayTrigger placement="right" overlay={renderLoginInfo}>
          <FontAwesomeIcon icon={faCircleInfo} style={{ cursor: "pointer" }} />
        </OverlayTrigger>
      </h5>
      <Row>
        <Col md={6}>
          <Form.Group controlId="email" className="mt-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleChange}
              isInvalid={!!formErrors.email}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </Form.Group>

        </Col>
        <Col md={6}>

          <Form.Group controlId="password" className="mt-2">
            <Form.Label>
              Mot de passe{" "}
              <OverlayTrigger placement="right" overlay={renderPasswordInfo}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ cursor: "pointer" }}
                />
              </OverlayTrigger>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                value={newUser.password}
                onChange={handleChange}
                isInvalid={!!passwordError}
              />
              <InputGroup.Text
                onClick={toggleShowPassword}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
            <PasswordValidator
              password={newUser.password}
              setPasswordError={setPasswordError}
            />
          </Form.Group>

        </Col>
      </Row>

      <h5 className="mt-3">Configuration</h5>

      <Form.Group controlId="extension" className="mt-2">
        <Form.Label>Numéro de Poste interne</Form.Label>
        <Form.Control
          type="text"
          name="extension"
          placeholder="Entrez un nombre de 1 à 5 chiffres"
          value={newUser.extension}
          max={99999}
          onChange={handleExtensionChange}
          isInvalid={!!extensionError || !!formErrors.extension}
          required
        />
        <Form.Control.Feedback type="invalid">
          {extensionError || formErrors.extension}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="displayNumber" className="mt-2">
        <Form.Label>Numéro SDA attribué à l'utilisateur</Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="displayNumber"
          value={newUser.displayNumber}
          onChange={handleChange}
          isInvalid={!!formErrors.displayNumber}
          required
          disabled={sdaLoading}
        >
          <option value="">Sélectionner un SDA</option>
          {sdaData.map(sda => (
            <option key={sda.id} value={sda.sda}>
              {sda.sda}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {formErrors.displayNumber}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="displayName" className="mt-2">
        <Form.Label>Nom affiché lors d'un appel</Form.Label>
        <Form.Control
          type="text"
          name="displayName"
          value={newUser.displayName}
          onChange={handleChange}
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-3 w-100">
        {initialData ? "Enregistrer les modifications" : "Ajouter l'utilisateur"}
      </Button>
    </Form>
  );
};

export default PhoneUserForm;
