import { useState, useEffect } from 'react';
import { BadgeBg } from "../../components/base/Badge";

export interface Assignee {
  id: number;
  name: string;
  avatar?: string;
}

export interface Invoice {
  id: number;
  ref: string;
  total_ht: number;
  array_options: { options_ref_commande?: string };
  total_ttc: number;
  date_commande: number;
  date_facturation: number;
  date_livraison: number;
  date_creation: number;
  date_validation: number;
  date_modification: number;
  user_creation_id: number;
  user_validation_id: number;
  fulfilment_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };
  payment_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };
  socid: number;
  tiers: string;
  author: string;
  status: string;
}


const useInvoicesData = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');

    const fetchInvoices = async () => {
      try {
        const response = await fetch(
          'https://erp.ipercom.io/api/index.php/invoices?sortfield=t.rowid&sortorder=ASC&limit=100',
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              DOLAPIKEY: DOLAPIKEY || ''
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Invoices API Response:', result);

        if (!Array.isArray(result)) {
          throw new Error('Invalid data format from API');
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Erreur lors de la récupération des données invoices:", error.message);
          throw error;
        } else {
          console.error('Unexpected error:', error);
          throw new Error("Unexpected Erreur lors de la récupération des données invoices");
        }
      }
    };

    const fetchUserById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/users/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const user = await response.json();
        console.log(`User ${id} API Response:`, user);
        return { id: user.rowid, name: `${user.firstname} ${user.lastname}`, avatar: user.avatar };
      } catch (error) {
        console.error(`Erreur lors de la récupération des données user ${id}:`, error);
        throw error;
      }
    };

    const fetchThirdPartyById = async (id: number) => {
      try {
        const response = await fetch(`https://erp.ipercom.io/api/index.php/thirdparties/${id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            DOLAPIKEY: DOLAPIKEY || '',
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const thirdParty = await response.json();
        console.log(`Third Party ${id} API Response:`, thirdParty);
        return { id: thirdParty.id, name: thirdParty.name };
      } catch (error) {
        console.error(`Erreur lors de la récupération des données third party ${id}:`, error);
        throw error;
      }
    };

    const fetchData = async () => {
      try {
        const invoices = await fetchInvoices();
        const usersMap: { [key: number]: Assignee } = {};
        const thirdPartiesMap: { [key: number]: string } = {};

        for (const invoice of invoices) {
          if (invoice.user_creation_id && !usersMap[invoice.user_creation_id]) {
            usersMap[invoice.user_creation_id] = await fetchUserById(invoice.user_creation_id);
          }
          if (invoice.socid && !thirdPartiesMap[invoice.socid]) {
            thirdPartiesMap[invoice.socid] = (await fetchThirdPartyById(invoice.socid)).name;
          }
        }

        console.log('User Map:', usersMap);
        console.log('Third Parties Map:', thirdPartiesMap);

        const invoicesWithDetails: Invoice[] = invoices.map((invoice: any) => {
          return {
            id: Number(invoice.id),
            ref: invoice.ref,
            total_ht: invoice.total_ht,
            total_ttc: invoice.total_ttc,
            array_options: invoice.array_options,
            date_creation: invoice.date_creation,
            date_validation: invoice.date_validation,
            date_modification: invoice.date_modification,
            user_creation_id: invoice.user_creation_id,
            user_validation_id: invoice.user_validation_id,
            socid: invoice.socid,
            tiers: invoice.tiers || 'Unknown Tier', // Default value if `tiers` is missing
            author: invoice.author || 'Unknown Author', // Default value if `author` is missing
            status: invoice.status,

            // Provide default values for the missing properties
            date_commande: invoice.date_commande || null,
            date_facturation: invoice.date_facturation || null,
            date_livraison: invoice.date_livraison || null,
            fulfilment_status: invoice.fulfilment_status || 'pending',
            payment_status: invoice.payment_status || 'unpaid',
          };
        });

        setInvoices(invoicesWithDetails);
      } catch (error) {
        setError("Impossible de récupérer les données data. Please try again later.");
      }
    };

    fetchData();
  }, []);

  return { invoices, error };
};

export default useInvoicesData;
