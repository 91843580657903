import { useState, useEffect, useCallback } from "react";

// Define the structure for MsgVocalData
export interface MsgVocalData {
  id: number;
  idClient: number;
  name: string;
  url?: string;
  date?: string;
  state: boolean;
}


/**
 * **useMsgVocalDataID**:
 * A custom hook for managing voicemail data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch voicemail data for.
 * @returns {Object} - The voicemail data, loading state, errors, and a setter for the data.
 *
 * @property {MsgVocalData[]} msgVocalData - The data retrieved from the API or a fallback value.
 * @property {Function} setMsgVocalData - Function to manually set voicemail data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { msgVocalData, loading, error } = useMsgVocalDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <VoicemailList data={msgVocalData} />;
 */
const useMsgVocalDataID = (id: number) => {
  const [msgVocalData, setMsgVocalData] = useState<MsgVocalData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchMsgVocalData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/msgvocal/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setMsgVocalData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données voicemail data", error);
      setError("Impossible de récupérer les données voicemail data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchMsgVocalData();
  }, [fetchMsgVocalData]);

  return {
    msgVocalData,
    setMsgVocalData,
    loading,
    error
  };
};

export default useMsgVocalDataID;
