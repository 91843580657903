import {
  faFileExport,
  faFileImport, faFilter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import LeadsFilterModal from "../../../components/modals/LeadsFilterModal";
import { usersIperxTableColumns } from "components/tables/ipbx/IperxUsersTable";
import { usersIperxHistoryCallTableData, usersIperxTableData } from "data/iper-x/ipbx/usersIperx";
import UsersTable from "components/tables/ipbx/UsersTable";
import IperxUserHistoryCallTable, { usersIperxHistoryCallColumn } from "components/tables/iper-x/IperxUserHistoryCallTable";

const IperxHistoryUserCalls = () => {
  const table = useAdvanceTable({
    data: usersIperxHistoryCallTableData,
    columns: usersIperxHistoryCallColumn,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });


  const [openFilterModal, setOpenFilterModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Historique d'appel</span>
          </h2>
        </div>
        <div className="mb-4 m-2">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            >
            </Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              {/* <Button variant="primary">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un utilisateur
              </Button> */}
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <IperxUserHistoryCallTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default IperxHistoryUserCalls;







