import { Row, Col, Form, Card, FormControl, InputGroup } from "react-bootstrap";

const IperxParamTAonline = () => {
  return (
    <>
      <h2 className="fs-7 mb-3">Disponible</h2>
      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Equipe</h2>
            <Form.Group className="mb-3" controlId="firstName">
              <Form.Label>Message personnalisé de statut</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Appels de non réponse</h2>
          <Form.Label>Délai de non réponse</Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="20"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <InputGroup.Text id="basic-addon2">en seconde</InputGroup.Text>
          </InputGroup>
          <Col>
            <Form.Group className="mb-3" controlId="audioSettings">
              <Form.Label>Transférer les appels externes à</Form.Label>
              <Form.Select defaultValue="Messagerie vocal">
                <option>Messagerie vocal</option>
              </Form.Select>
            </Form.Group>
          </Col><Col>
            <Form.Group className="mb-3" controlId="audioSettings">
              <Form.Label>Transférer les appels interne à</Form.Label>
              <Form.Select defaultValue="Messagerie vocal">
                <option>Messagerie vocal</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Occupé ou non enregistré</h2>
          <Col>
            <Form.Group className="mb-3" controlId="audioSettings">
              <Form.Label>Transférer les appels externes à</Form.Label>
              <Form.Select defaultValue="Messagerie vocal">
                <option>Messagerie vocal</option>
              </Form.Select>
            </Form.Group>
          </Col><Col>
            <Form.Group className="mb-3" controlId="audioSettings">
              <Form.Label>Transférer les appels interne à</Form.Label>
              <Form.Select defaultValue="Messagerie vocal">
                <option>Messagerie vocal</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Appels actifs</h2>
          <Row>
            <Col>
              <Form.Label>Sélectionner les types d'appels que vous souhaitez voir apparaître dans la section des appels
                actifs.</Form.Label>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Faire sonner mon mobile simultanément'
              />
              <Form.Check
                type='checkbox'
                id='checkedCheckbox'
                label='Accepter plusieurs appels simultanément'
              />
              <Form.Check
                type='checkbox'
                id='disabledCheckbox'
                label='Accepter les notifications Push'
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default IperxParamTAonline;
