import React from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Order } from "../../../../../../types/types";

interface OrderTableProps {
  order: Order;
  setOrder: (order: (prevOrder: Order) => Order) => void;
}

const OrderTable: React.FC<OrderTableProps> = ({ order, setOrder }) => {
  const removeItem = (index: number) => {
    setOrder((prevOrder: Order) => {
      const updatedItems = prevOrder.items.filter((_: any, i: number) => i !== index);
      return { ...prevOrder, items: updatedItems };
    });
  };

  const formatPrice = (price: string) => {
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? "0.00" : parsedPrice.toFixed(2);
  };

  const calculateTotal = (subprice: string, qty: string) => {
    const parsedPrice = parseFloat(subprice);
    const parsedQty = parseFloat(qty);
    if (isNaN(parsedPrice) || isNaN(parsedQty)) return "0.00";
    return (parsedPrice * parsedQty).toFixed(2);
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5>Services ou Produits ajoutés</h5>
      </Card.Header>
      <Card.Body>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Ref</th>
            <th>Produit/Service</th>
            <th>Quantité</th>
            <th>Prix unitaire</th>
            <th>Total HT</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {order.items.map((item, index) => (
            <tr key={index}>
              <td>{item.fk_product}</td>
              <td>{item.product_name}</td>
              <td>{item.qty}</td>
              <td>{formatPrice(item.subprice)}</td>
              <td>{calculateTotal(item.subprice, item.qty)}</td>
              <td>
                <Button variant="danger" onClick={() => removeItem(index)}>
                  Supprimer
                </Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default OrderTable;
