import { Row, Col, Form, Card } from "react-bootstrap";

const IperxParamVisualiser = () => {
  return (
    <>
      <h2 className="fs-6 mb-3">Visualiser</h2>

      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Equipe</h2>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="callSettings">
                <Form.Check type="checkbox" label="Afficher les extension non enregistrées" />
                <Form.Check type="checkbox" label="Trier les extensions par numéro" />
              </Form.Group>
              <p className="fs-9">Vous trouverez ci-dessous une liste de tous les groupes disponibles dans votre
                organisation, locaux ou distants. Sélectionner les groupes qui présentent le plus d'intérêt pour vous.
                Ils seront affichés sur l'écran de présence et le switchboard.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="cameraSettings">
                <Form.Label>PBX local</Form.Label>
                <Form.Select defaultValue="Facetime">
                  <option>Default</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <h2 className="fs-6 mb-3">Appels actifs</h2>
       
            <Col>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Afficher les appels externes en cours de sonnerie'
              />
            </Col>
            <Col>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Afficher les appels parqués'
              />
            </Col>
            <Col>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Afficher les appels externes connectés'
              />
            </Col><Col>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Afficher les appels internes connectés'
              />
            </Col><Col>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Afficher les appels IVR'
              />
            </Col><Col>
              <Form.Check
                type='checkbox'
                id='defaultCheckbox'
                label='Afficher les appels en fille d’attente'
              />
            </Col>
          
        </Card.Body>
      </Card>
    </>
  );
};

export default IperxParamVisualiser;
