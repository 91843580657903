import React, { ChangeEvent, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Category, Order, OrderLine, Product } from "../../../../../../types/types";

interface OrderFormProps {
  order: Order;
  setOrder: (order: (prevOrder: Order) => { date: string; socid: string; items: OrderLine[] }) => void;
  setError: (error: string | null) => void;
}

const OrderForm: React.FC<OrderFormProps> = ({ order, setOrder, setError }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [family, setFamily] = useState<string>("IPERX");
  const [simultaneousCalls, setSimultaneousCalls] = useState<string>("4AS");
  const [years, setYears] = useState<string>("5");
  const [itemType, setItemType] = useState<"product" | "service">("service");
  const [currentItem, setCurrentItem] = useState<OrderLine>({
    fk_product: "",
    product_name: "",
    qty: "1",
    subprice: "",
    tva_tx: "20",
    licenses: []
  });
  const [success, setSuccess] = useState<string | null>(null);

  const DOLAPIKEY = localStorage.getItem("DOLAPIKEY");
  const token = localStorage.getItem("token");
  const DOLID = localStorage.getItem("DOLID") || "";
  const companyID = localStorage.getItem("companyID") || "23";
  const userId = localStorage.getItem("userId") || "26";

  // Fetch categories on component mount
  useEffect(() => {
    fetchCategories();
  }, []);

  // Filter items when categories, family, simultaneousCalls, or years change
  useEffect(() => {
    if (categories.length > 0) {
      filterItems();
    }
  }, [categories, family, simultaneousCalls, years]);

  // Fetch categories from API
  const fetchCategories = async () => {
    try {
      const response = await fetch("https://erp.ipercom.io/api/index.php/categories", {
        method: "GET",
        headers: {
          Accept: "application/json",
          DOLAPIKEY: DOLAPIKEY || ""
        }
      });
      const data = await response.json();
      setCategories(
        data.map((category: any) => ({
          id: category.id,
          label: category.label
        }))
      );
    } catch (error) {
      console.error("Erreur lors de la récupération des données categories:", error);
    }
  };

  // Fetch products from the selected category
  const fetchProducts = async (categoryId: string) => {
    try {
      const response = await fetch(
        `https://erp.ipercom.io/api/index.php/products?sortfield=t.ref&sortorder=ASC&limit=100&mode=2&category=${categoryId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          }
        }
      );
      const data = await response.json();
      const fetchedProducts = data.map((product: any) => ({
        id: product.id,
        label: product.label,
        price: product.price,
        type: product.type
      }));
      setProducts(fetchedProducts);

      // If only one product, set it as the selected product
      if (fetchedProducts.length === 1) {
        setCurrentItem((prevItem: OrderLine) => ({
          ...prevItem,
          fk_product: fetchedProducts[0].id,
          product_name: fetchedProducts[0].label,
          subprice: fetchedProducts[0].price
        }));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données products:", error);
    }
  };

  // Filter products based on selected family, simultaneous calls, and years
  const filterItems = () => {
    const categoryFilter = `${family}${simultaneousCalls}${years}`;
    const category = categories.find(cat => cat.label.includes(categoryFilter));
    if (category) {
      fetchProducts(category.id);
    } else {
      setProducts([]);
      setCurrentItem((prevItem: OrderLine) => ({
        ...prevItem,
        fk_product: "",
        product_name: "",
        subprice: ""
      }));
    }
  };

  // Handle input changes for the form
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "fk_product") {
      const selectedProduct = products.find(product => product.id === value);
      if (selectedProduct) {
        setCurrentItem((prevItem: OrderLine) => ({
          ...prevItem,
          fk_product: selectedProduct.id,
          product_name: selectedProduct.label,
          subprice: selectedProduct.price
        }));
      }
    } else {
      setCurrentItem((prevItem: OrderLine) => ({ ...prevItem, [name]: value }));
    }
  };

  // Extract max users from product name
  const extractMaxUsers = (label: string): number => {
    const match = label.match(/(\d+)\s*utilisateurs/);
    return match ? parseInt(match[1], 10) : 0;
  };

  // Generate licenses based on the quantity of products/services
  const generateLicenses = async (quantity: number) => {
    const maxUsers = extractMaxUsers(currentItem.product_name);

    const body = {
      createdBy: userId,
      idCompanies: companyID,
      idLicencesTypesHost: 1,
      erpThiersRef: DOLID,
      erpOrderRef: 0,
      erpProductRef: currentItem.fk_product,
      maxUsers: maxUsers,
      simultaneousCalls: parseInt(simultaneousCalls.replace("AS", "")),
      subDuration: parseInt(years),
      quantity: quantity
    };

    try {
      const response = await fetch("https://rct-backend.ipercom.io/api/Licence/gen_license", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        setError("Erreur lors de la génération des licences.");
        return [];
      }

      const data = await response.json();

      // Initialize `client_final` for each license
      const licenses = data.map((license: any) => ({
        id_license: license.idLicences,
        license_key: license.key,
        client_final: { id: "", name: "", position: "", email: "", address: "" }
      }));

      return licenses;
    } catch (error) {
      console.error("Error generating licenses:", error);
      setError("Une erreur s'est produite lors de la génération des licences.");
      return [];
    }
  };

  const addItemToOrder = async () => {
    if (!currentItem.fk_product) {
      setError("Vous devez sélectionner un Produit/Service avant de l’ajouter.");
      return;
    }

    if (parseInt(currentItem.qty) <= 0) {
      setError("La quantité doit être supérieure à 0 pour ajouter un élément.");
      return;
    }

    // Generate licenses
    const licenses = await generateLicenses(parseInt(currentItem.qty));

    // Check if the product already exists in the order
    const existingProductIndex = order.items.findIndex(
      (item: OrderLine) => item.fk_product === currentItem.fk_product
    );

    if (existingProductIndex !== -1) {
      // Product exists, update its quantity and licenses
      const updatedItems = [...order.items];
      const existingProduct = updatedItems[existingProductIndex];
      const newQuantity = parseInt(existingProduct.qty) + parseInt(currentItem.qty);

      // Add new licenses to the existing product
      const updatedLicenses = [...existingProduct.licenses, ...licenses];

      updatedItems[existingProductIndex] = {
        ...existingProduct,
        qty: newQuantity.toString(),
        licenses: updatedLicenses
      };

      setOrder((prevOrder: Order) => ({
        ...prevOrder,
        items: updatedItems
      }));
    } else {

      setOrder((prevOrder: Order) => ({
        ...prevOrder,
        items: [...prevOrder.items, { ...currentItem, licenses }]
      }));
    }

    setError(null);
    setSuccess("Service ou produit ajouté avec succès.");
  };


  return (
    <Card className="my-3">
      <Card.Header>
        <h5>Ajouter un élément</h5>
      </Card.Header>
      <Card.Body>
        {success && <Alert variant="success">{success}</Alert>} {/* Success message */}

        <Form.Group controlId="itemType">
          <Form.Label>Type d'élément</Form.Label>
          <Form.Control
            as="select"
            name="itemType"
            value={itemType}
            onChange={e => setItemType(e.target.value as "product" | "service")}
          >
            <option value="service">Service</option>
            <option value="product" disabled>
              Produit
            </option>
          </Form.Control>
        </Form.Group>

        {itemType === "service" && (
          <>
            <Row>
              <Col md={4}>
                <Form.Group controlId="family">
                  <Form.Label>Famille</Form.Label>
                  <Form.Control
                    as="select"
                    name="family"
                    value={family}
                    onChange={e => setFamily(e.target.value)}
                  >
                    <option value="IPERX">IPERX</option>
                    <option value="IPERCLOUD" disabled>
                      IPERCLOUD
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="simultaneousCalls">
                  <Form.Label>Appels Simultanés</Form.Label>
                  <Form.Control
                    as="select"
                    name="simultaneousCalls"
                    value={simultaneousCalls}
                    onChange={e => setSimultaneousCalls(e.target.value)}
                  >
                    {["4", "8", "16", "24", "32", "40", "48", "50", "100"].map(
                      call => (
                        <option key={call} value={`${call}AS`}>
                          {call} accès simultanés
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="years">
                  <Form.Label>Années</Form.Label>
                  <Form.Control
                    as="select"
                    name="years"
                    value={years}
                    onChange={e => setYears(e.target.value)}
                  >
                    <option value="1">1 an</option>
                    <option value="2">2 ans</option>
                    <option value="3">3 ans</option>
                    <option value="4">4 ans</option>
                    <option value="5">5 ans</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}

        <Form.Group controlId="fk_product">
          <Form.Label>Produit/Service</Form.Label>
          <Form.Control
            as="select"
            name="fk_product"
            value={currentItem.fk_product}
            onChange={handleChange}
            required
            disabled
          >
            <option value="">Sélectionner un produit/service</option>
            {products.map(product => (
              <option key={product.id} value={product.id}>
                {product.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Row>
          <Col md={4}>
            <Form.Group controlId="qty">
              <Form.Label>Quantité</Form.Label>
              <Form.Control
                type="number"
                name="qty"
                value={currentItem.qty}
                onChange={e =>
                  setCurrentItem((prevItem: OrderLine) => ({
                    ...prevItem,
                    qty: e.target.value
                  }))
                }
                min="1"
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="subprice">
              <Form.Label>Prix unitaire hors taxe</Form.Label>
              <Form.Control
                type="number"
                name="subprice"
                value={parseFloat(currentItem.subprice).toFixed(2)}
                onChange={handleChange}
                required
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="tva_tx">
              <Form.Label>TVA (%)</Form.Label>
              <Form.Control
                type="number"
                name="tva_tx"
                value={currentItem.tva_tx}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="success" onClick={addItemToOrder} className="mt-3">
          Ajouter un service ou produit
        </Button>
      </Card.Body>
    </Card>
  );
};

export default OrderForm;
