import React, { FormEvent, useEffect, useState } from "react";
import { Alert, Button, Container, Form, Modal, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OrderForm from "./OrderForm";
import OrderTable from "./OrderTable";
import LicenseTable from "./LicenseTable";
import { Order } from "../../../../../../types/types";

const OrderCreateClient2: React.FC = () => {
  const navigate = useNavigate();
  const DOLID = localStorage.getItem("DOLID"); // Ensure DOLID is retrieved from localStorage
  const DOLAPIKEY = localStorage.getItem("DOLAPIKEY");
  const token = localStorage.getItem("token"); // Get the token from local storage

  const [order, setOrder] = useState<Order>({
    socid: DOLID || "", // Ensure DOLID is used if present
    date: new Date().toISOString().split("T")[0],
    items: []
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [idClientFinal, setIdClientFinal] = useState<number>(0); // Track idClientFinal for license activation

  // Vérification des valeurs dans le localStorage
  useEffect(() => {
    if (!DOLID) {
      console.error("DOLID is missing from localStorage.");
    }
    if (!token) {
      console.error("Token is missing from localStorage.");
    }
  }, [DOLID, token]);

  // Function to fetch idClientFinal after client creation or selection
  const fetchIdClientFinal = async () => {
    console.log("Fetching idClientFinal...");
    try {
      const response = await fetch(
        "https://your-api-endpoint/api/clientFinal",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}` // Use the token for authenticated request
          }
        }
      );

      const data = await response.json();
      if (data && data.idClientFinal) {
        setIdClientFinal(data.idClientFinal);
        console.log("idClientFinal set to:", data.idClientFinal);
      } else {
        throw new Error("Failed to fetch idClientFinal.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données idClientFinal:", error);
      setError(
        "Une erreur est survenue lors de la récupération du client final."
      );
    }
  };

  const createOrder = async () => {
    console.log("Creating order...");
    try {
      setProgress(33);
      const updatedLines = order.items.map(item => ({
        ...item,
        array_options: {
          // Ensure each license is a string and join them with commas
          options_licences: item.licenses
            .map(license =>
              typeof license === "object" ? license.license_key : license
            )
            .join(", ")
        }
      }));

      const response = await fetch(
        "https://erp.ipercom.io/api/index.php/orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          },
          body: JSON.stringify({
            socid: order.socid,
            date: Math.floor(new Date(order.date).getTime() / 1000),
            lines: updatedLines,
            status: "1"
          })
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Error creating order:", errorResponse);
        throw new Error(
          errorResponse.error || "Erreur lors de la création de la commande."
        );
      }

      const data = await response.json();
      console.log("Order created successfully:", data);
      return data; // Return the order ID
    } catch (error) {
      console.error("Error creating order:", error);
      setError("Erreur lors de la création de la commande.");
      setLoading(false);
    }
  };

  const validateOrder = async (orderId: string) => {
    console.log("Validating order with ID:", orderId);
    try {
      setProgress(66);
      const response = await fetch(
        `https://erp.ipercom.io/api/index.php/orders/${orderId}/validate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          }
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Error validating order:", errorResponse);
        throw new Error(
          errorResponse.error || "Erreur lors de la validation de la commande."
        );
      }

      console.log("Order validated successfully");
    } catch (error) {
      console.error("Error validating order:", error);
      setError("Erreur lors de la validation de la commande.");
      setLoading(false);
    }
  };

  const createInvoice = async (orderId: string) => {
    console.log("Creating invoice for order ID:", orderId);
    try {
      setProgress(100);
      const invoiceData = {
        socid: order.socid,
        date: Math.floor(Date.now() / 1000),
        type: 0,
        lines: order.items,
        array_options: {
          options_ref_commande: orderId
        }
      };

      const response = await fetch(
        "https://erp.ipercom.io/api/index.php/invoices",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          },
          body: JSON.stringify(invoiceData)
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Error creating invoice:", errorResponse);
        throw new Error(
          errorResponse.error || "Erreur de création de la facture."
        );
      }

      const data = await response.json();
      console.log("Invoice created successfully:", data);
      return data; // Return the invoice ID
    } catch (error) {
      console.error("Error creating invoice:", error);
      setError("Erreur lors de la création de la facture.");
      setLoading(false);
    }
  };

  const validateInvoice = async (invoiceId: string) => {
    console.log("Validating invoice with ID:", invoiceId);
    try {
      const response = await fetch(
        `https://erp.ipercom.io/api/index.php/invoices/${invoiceId}/validate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          }
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Error validating invoice:", errorResponse);
        throw new Error(
          errorResponse.error || "Erreur de validation de la facture."
        );
      }

      console.log("Invoice validated successfully");
    } catch (error) {
      console.error("Error validating invoice:", error);
      setError("Erreur lors de la validation de la facture.");
      setLoading(false);
    }
  };

  // Updated activateLicenses function with logging
  const activateLicenses = async (
    orderId: string,
    licensesList: { idLicense: number; idClientFinal: number }[]
  ) => {
    console.log("Activating licenses for order ID:", orderId);
    console.log("Licenses to activate:", licensesList);
    try {
      const licenses = licensesList.map(license => ({
        idLicense: license.idLicense,
        idClientFinal: license.idClientFinal,
        status: "active"
      }));

      const licenseActivationPayload = {
        erpThiersRef: Number(DOLID), // From localStorage
        erpOrderRef: Number(orderId), // From created order
        licences: licenses
      };

      console.log("License activation payload:", licenseActivationPayload);

      const response = await fetch(
        "https://rct-backend.ipercom.io/api/Licence/active_license",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}` // Use the token from local storage
          },
          body: JSON.stringify(licenseActivationPayload)
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        console.error("Error activating licenses:", errorResponse);
        throw new Error(
          errorResponse.error || "Erreur lors de l'activation des licences."
        );
      }

      const data = await response.json();
      console.log("Licenses activated successfully:", data);
      return data;
    } catch (error) {
      console.error("Error activating licenses:", error);
      setError("Erreur lors de l'activation des licences.");
    }
  };

  const runWorkflow = async () => {
    setShowModal(true);
    setProgress(0);
    setLoading(true);
    try {
      console.log("Running workflow...");
      const orderId = await createOrder();
      await validateOrder(orderId);
      const invoiceId = await createInvoice(orderId);
      await validateInvoice(invoiceId);

      // Ensure that `idLicense` and `idClientFinal` are properly extracted
      const licensesList = order.items.flatMap(item => {
        if (!item.licenses || item.licenses.length === 0) {
          console.error(`Item ${JSON.stringify(item)} does not have any licenses.`);
          return [];
        }

        // Log the licenses for each item
        item.licenses.forEach(license => {
          console.log(`Processing license: ${JSON.stringify(license)}`);
        });

        return item.licenses.map(license => ({
          idLicense: Number(license.id_license) || 0, // Convert `idLicense` to a number
          idClientFinal: Number(license.client_final?.id) || 0 // Ensure `idClientFinal` is always a number
        }));
      });


      // Log the final licensesList to be sent for activation
      console.log("Final licenses list for activation:", licensesList);

      // Check if idLicense or idClientFinal is 0
      if (
        licensesList.some(
          license => license.idLicense === 0 || license.idClientFinal === 0
        )
      ) {
        console.error(
          "Error: One or more licenses have missing data (idLicense or idClientFinal)."
        );
        throw new Error(
          "Les licences ou clients ne sont pas correctement configurés."
        );
      }

      console.log("Activating licenses after invoice creation...");
      await activateLicenses(orderId, licensesList);

      setSuccess("Commande, facture créées et licences activées avec succès.");
      setShowSuccessMessage(true);

      // Wait 2 seconds before redirecting
      setTimeout(() => {
        setShowSuccessMessage(false);
        setShowModal(false);
        navigate("/portail-revendeur/commandes-et-factures");
      }, 2000);
    } catch (error) {
      console.error("Error during workflow:", error);
      setError("Une erreur s'est produite lors du processus de commande.");
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!acceptedTerms) {
      setError("Vous devez accepter les conditions générales de vente.");
      return;
    }

    if (order.items.length === 0) {
      setError(
        "Vous devez ajouter au moins un élément avant de valider la commande."
      );
      return;
    }

    await runWorkflow();
  };

  return (
    <Container className="mt-5">
      <h2>Ajouter une commande</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      {showSuccessMessage && (
        <Alert variant="success">
          Commande bien passée. Redirection en cours...
        </Alert>
      )}

      <Form onSubmit={onSubmit}>
        <OrderForm order={order} setOrder={setOrder} setError={setError} />
        <OrderTable order={order} setOrder={setOrder} />
        <LicenseTable order={order} setOrder={setOrder} />

        <Form.Group controlId="acceptedTerms">
          <Form.Check
            type="checkbox"
            label="Accepter les conditions générales de vente (Obligatoire)"
            checked={acceptedTerms}
            onChange={e => setAcceptedTerms(e.target.checked)}
            required
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="my-4"
          disabled={!acceptedTerms || loading || order.items.length === 0}
        >
          Valider
        </Button>
      </Form>

      {/* Modal for workflow progress */}
      <Modal show={showModal} centered>
        <Modal.Header>
          <Modal.Title>Progression de la création</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            La commande, la facture et les licences sont en cours de création.
          </p>
          <ProgressBar now={progress} label={`${progress}%`} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OrderCreateClient2;
