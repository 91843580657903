
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Certification } from '../../../../data/iper-x/ipbx/certificationsHistory';
import Avatar from "../../../../components/base/Avatar";

export const certificationsTableColumns: ColumnDef<Certification>[] = [
  {
    accessorKey: 'user.firstname',
    header: 'Nom',
    cell: ({ row: { original } }) => {
      const { user } = original;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src="" size="m" className="me-3" />
          <span>{`${user.firstname} ${user.lastname}`}</span>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '20%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'certification.name',
    header: 'Certification',
    meta: {
      headerProps: { style: { width: '20%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'datePassage',
    header: 'Date de Passage',
    cell: ({ getValue }) => {
      const value = getValue<string>();
      return new Date(value).toLocaleDateString();
    },
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'statusUpdate',
    header: 'Status',
    cell: ({ row: { original } }) => {
      const { statusUpdate } = original;
      return (
        <span className={`badge bg-${statusUpdate === 'Completed' ? 'success' : 'danger'}`}>
          {statusUpdate}
        </span>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'pointScore',
    header: 'Score',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'certificationTypeGroup.name',
    header: 'Groupe',
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'certificationType.name',
    header: 'Type',
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorKey: 'diplomPath',
    header: 'Diplôme',
    cell: ({ row: { original } }) => {
      const { diplomPath } = original;
      return (
        <a href={diplomPath} target="_blank" rel="noopener noreferrer">
          Voir le diplôme
        </a>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  }
];

const CertificationsTable: React.FC = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default CertificationsTable;
