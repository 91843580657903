import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCogs, faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

interface DeviceData {
  id: number;
  name: string;
  brand: string;
  deviceModel: string;
  firmwareVersion: string;
  macAddress: string;
  machineId: string;
  privateIpAddress: string;
  publicIpAddress: string;
  location: string;
  deviceType: string;
  provisioningStatus: string;
  deviceStatus: string;
  createdAt: string;
  additionalInfo?: Record<string, string>; // Champs spécifiques par marque
}

const DeviceDetails: React.FC = () => {
  const { id } = useParams(); // Get device ID from URL params
  const navigate = useNavigate();
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDeviceData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token not found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(
          `https://rct-backend.ipercom.io/api/Ipbx/device/${id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`
            },
          }
        );
        setDeviceData(response.data);
      } catch (err) {
        setError("Error fetching device details.");
        console.error(err);
      }
    };

    fetchDeviceData();
  }, [id]);

  const handleAction = (action: string) => {
    alert(`Action '${action}' triggered for device ${deviceData?.name}`);
  };

  if (error) {
    return (
      <Container>
        <p>{error}</p>
      </Container>
    );
  }

  if (!deviceData) {
    return (
      <Container>
        <p>Loading device details...</p>
      </Container>
    );
  }

  const renderTooltip = (text: string) => <Tooltip>{text}</Tooltip>;

  const renderBrandSpecificInfo = () => {
    if (deviceData.brand === "Yealink") {
      return (
        <Row>
          <Col md={6}>
            <strong>Yealink Option 1:</strong>
            <p>{deviceData.additionalInfo?.["YealinkOption1"] || "N/A"}</p>
          </Col>
          <Col md={6}>
            <strong>Yealink Option 2:</strong>
            <p>{deviceData.additionalInfo?.["YealinkOption2"] || "N/A"}</p>
          </Col>
        </Row>
      );
    } else if (deviceData.brand === "Fanvil") {
      return (
        <Row>
          <Col md={6}>
            <strong>Fanvil Specific Info:</strong>
            <p>{deviceData.additionalInfo?.["FanvilInfo"] || "N/A"}</p>
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Button variant="primary" onClick={() => navigate(-1)}>
          ← Retour
        </Button>
        <h2>Détails de l'Appareil</h2>
        <div>
          <Button variant="warning" className="me-2">
            Modifier
          </Button>
          <Button variant="danger">Supprimer</Button>
        </div>
      </div>

      <Row>
        <Col lg={6}>
          <div className="bg-light p-4 rounded mb-4">
            <h5 className="mb-4">Informations Générales</h5>
            <Row>
              <Col md={6}>
                <strong>
                  Nom:
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Nom de l'appareil")}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2 text-info"
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                </strong>
                <p>{deviceData.name || "Non défini"}</p>
              </Col>
              <Col md={6}>
                <strong>
                  Constructeur:
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Constructeur de l'appareil")}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2 text-info"
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                </strong>
                <p>{deviceData.brand}</p>
              </Col>
            </Row>
            {renderBrandSpecificInfo()}
          </div>
        </Col>

        <Col lg={6}>
          <div className="bg-light p-4 rounded mb-4">
            <h5 className="mb-4">Gestion</h5>
            <Row className="mb-3">
              <Col md={4}>
                <Button
                  variant="secondary"
                  onClick={() => handleAction("Reboot")}
                  className="w-100"
                >
                  <FontAwesomeIcon icon={faSync} className="me-2" />
                  Redémarrer
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  variant="secondary"
                  onClick={() => handleAction("Update Firmware")}
                  className="w-100"
                >
                  <FontAwesomeIcon icon={faDownload} className="me-2" />
                  Mettre à jour le Firmware
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  variant="secondary"
                  onClick={() => handleAction("Configure")}
                  className="w-100"
                >
                  <FontAwesomeIcon icon={faCogs} className="me-2" />
                  Configurer
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <div className="bg-light p-4 rounded mb-4">
            <h5 className="mb-4">Statut</h5>
            <Row>
              <Col md={6}>
                <strong>Type d'Appareil:</strong>
                <p>{deviceData.deviceType}</p>
              </Col>
              <Col md={6}>
                <strong>Statut de Provisioning:</strong>
                <Badge
                  bg={
                    deviceData.provisioningStatus === "Registered"
                      ? "success"
                      : "secondary"
                  }
                >
                  {deviceData.provisioningStatus}
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <strong>Statut de l'Appareil:</strong>
                <Badge
                  bg={
                    deviceData.deviceStatus === "Actif"
                      ? "success"
                      : "secondary"
                  }
                >
                  {deviceData.deviceStatus}
                </Badge>
              </Col>
              <Col md={6}>
                <strong>Date de Création:</strong>
                <p>{new Date(deviceData.createdAt).toLocaleDateString()}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DeviceDetails;
