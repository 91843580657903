import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import { PhoneBook } from '../../../../data/iper-x/ipbx/phoneBook';

export const phoneBookColumns: ColumnDef<PhoneBook>[] = [
  {
    id: 'user',
    accessorFn: ({ user }) => user.name,
    header: 'Nom d’utilisateur',
    cell: ({ row: { original } }) => {
      const { name, avatar } = original.user;
      return (
        <Link
          to="/apps/e-commerce/admin/customer-details"
          className="d-flex align-items-center"
        >
          <Avatar src={avatar} size="m" />
          <p className="mb-0 ms-3 text-1100 fw-bold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '18%', minWidth: 250 },
        className: 'ps-8'
      },
      cellProps: { className: 'ps-8 py-0 white-space-nowrap' }
    }
  },

  {
    accessorKey: 'email',
    header: 'Compagnie',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },

  {
    accessorKey: 'mobile',
    header: 'Mobile',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },

  {
    accessorKey: 'email',
    header: 'Email',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },

  {
    accessorKey: 'fix',
    header: 'Fix',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' }
    }
  }
];

const PhoneBookTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default PhoneBookTable;
