import React, { ChangeEvent, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { retailerOrdersAndInvoicesBreadcrumbItems } from "data/breadcrumbItem";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import InvoicesTable, { invoicesTableColumns } from "components/tables/iper-x/retailer/InvoicesTable";

import { exportOptionsInitialState } from "./OrderListUtils"; // Adjusted to keep only export-related logic
import ExportModal from "./ExportModal";
import useInvoicesData from "hooks/dolibarr/useInvoicesData";
import InvoiceCreateModal from "./InvoiceCreateModal"; // Assuming Invoice creation is relevant here

const OrderInvoicesList: React.FC = () => {
  const { invoices, error } = useInvoicesData();

// Define your filtering logic to exclude the invoice with id "69"
  const filteredInvoices = invoices.filter((invoice) => {
    // Ensure we are comparing id properly as either string or number
    return invoice.id !== 69 && invoice.id !== 69;
  });

// Use the filteredInvoices in the table
  const table = useAdvanceTable({
    data: filteredInvoices, // Pass the filtered data
    columns: invoicesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });


  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);  // Filter invoices by search input
  };

  const [showExportModal, setShowExportModal] = useState(false);
  const toggleExportModal = () => setShowExportModal(!showExportModal);

  const [exportOptions, setExportOptions] = useState(exportOptionsInitialState);

  const handleExport = () => {
    toggleExportModal();  // Show export modal when exporting invoices
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setExportOptions(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
  const toggleAddInvoiceModal = () => setShowAddInvoiceModal(!showAddInvoiceModal);

  return (
    <>
      <div>
        <Row className="gy-3 mb-6 justify-content-between">
          <Col md={9} xs="auto">
            <PageBreadcrumb items={retailerOrdersAndInvoicesBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Commandes et factures</h2>
          </Col>
        </Row>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Rechercher des factures"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto">
                <Button variant="primary" href={"/portail-revendeur/commandes/ajouter"}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter une commande
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <InvoicesTable />
          </div>
        </AdvanceTableProvider>

        <ExportModal
          show={showExportModal}
          handleClose={toggleExportModal}
          handleCheckboxChange={handleCheckboxChange}
          handleExport={handleExport}
          exportOptions={exportOptions}
        />
        <InvoiceCreateModal
          show={showAddInvoiceModal}
          handleClose={toggleAddInvoiceModal}
        />
      </div>
    </>
  );
};

export default OrderInvoicesList;
