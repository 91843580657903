import {
  Col,
  Row,
  Container,
  Button,
  Badge,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";


interface ContactData {
  id: number;
  idClient: number;
  firstname: string;
  lastname: string;
  company: string;
  position: string;
  phone: string;
  mobile: string;
  email: string;
}

const ContactsDetails = () => {
  const { id } = useParams();
  const [contactData, setContactData] = useState<ContactData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTrunkData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token not found. Please log in.");
        return;
      }

      try {

        const contactResponse = await axios.get(
          `https://rct-backend.ipercom.io/api/Webrtc/contact/${id}`,
          {
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`
            }
          }
        );
        setContactData(contactResponse.data);
      } catch (err) {
        setError("Error fetching data.");
        console.error(err);
      }
    };

    fetchTrunkData();
  }, [id]);

  if (error)
    return (
      <Container>
        <p>{error}</p>
      </Container>
    );

  const renderTooltip = (text: string) => <Tooltip>{text}</Tooltip>;

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Détails du Trunk et du Contact</h2>
      </div>

      {!contactData ? (
        <p>Loading data...</p>
      ) : (
        <>
          {/* Affichage des informations du Trunk */}

          {/* Affichage des informations du Contact */}
          <Row className="mb-4">
            <Col lg={4}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-4">Informations du Contact</h5>
                <Row>
                  <Col md={6}>
                    <strong>Prénom:</strong>
                    <p>{contactData.firstname}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Nom:</strong>
                    <p>{contactData.lastname}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Entreprise:</strong>
                    <p>{contactData.company}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Position:</strong>
                    <p>{contactData.position}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Téléphone:</strong>
                    <p>{contactData.phone}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Mobile:</strong>
                    <p>{contactData.mobile}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Email:</strong>
                    <p>{contactData.email}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ContactsDetails;
