import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';

const SecurityConfig = () => {
  return (
    <>
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Anti-piratage</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Liste noire d'IP automatique globale Iperx
              </h3>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Permettez à Iperx de collecter n'importe quelle IP blacklistée
                  depuis cette instance, et en échange de cette contribution, la
                  liste globale des IP blacklistée du Programme de Défense Anti
                  Hacking de Iperx sera téléchargée et activée sur cette
                  installation.
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Protection contre les authentifications échouées
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Configurer le nombre d'authentifications échouées que Iperx
                acceptera. Si cette valeur est dépassée l'adresse IP source sera
                mise en liste noire
              </span>
              <FloatingLabel controlId="floatingInputAuthMax" label="Auth max">
                <Form.Control type="text" value="5" />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Requêtes de Challenge (407) échouées
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Les attaques DOS peuvent envoyer des requêtes REGISTER en rafale
                sans répondre aux Challenge (407). Configurez le nombre de
                "faux" enregistrements que Iperx acceptera par adresse IP avant
                mise en liste noire
              </span>
              <FloatingLabel
                controlId="floatingInputRequestMax"
                label="Requêtes max"
              >
                <Form.Control type="text" value="1000" />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Temps en liste noire
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Il s'agit du temps en secondes pendant lequel une adresse IP
                abusive restera en liste noire
              </span>
              <FloatingLabel
                controlId="floatingInput"
                label="Black list temp (sec)"
              >
                <Form.Control type="text" value="86400" />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Barrière de sécurité (Vert)
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Il s'agit d'un interval de temps en millisecondes pendant lequel
                le décompte commence mais aucune action n'est prise. Si vous
                voulez désactiver cette sécurité, réglez ce paramètre sur une
                valeur élevée.
              </span>
              <FloatingLabel
                controlId="floatingInput"
                label="interval de temps"
              >
                <Form.Control type="text" value="200" />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Barrière de sécurité (Ambre)
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Si ce nombre de paquets est dépassé, le PBX bloquera l'adresse
                IP source pour 5 secondes
              </span>
              <FloatingLabel
                controlId="floatingInput"
                label="nombre de paquets"
              >
                <Form.Control type="text" value="2000" />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Barrière de sécurité (Rouge)
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Il s'agit de la couche de protection finale. Si le nombre de
                paquets spécifié est dépassé, le PBX mettra en liste noire
                l'adresse IP. Cette IP restera en liste noire jusqu'à ce que le
                "Temps en liste noire" soit écoulé.
              </span>
              <FloatingLabel
                controlId="floatingInput"
                label="nombre de paquets"
              >
                <Form.Control type="text" value="4000" />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Protection des requêtes de Live Chat
              </h3>

              <span className="d-none d-sm-inline-block my-2">
                Nombre maximum de requêtes permises par adresse IP - les IP
                fautives seront blacklistées
              </span>
              <FloatingLabel
                controlId="floatingInput"
                label="Nombre maximum de requêtes"
              >
                <Form.Control type="text" value="20" />
              </FloatingLabel>

              <span className="d-none d-sm-inline-block my-2">
                Niveau de durée de la requête - Configurez la durée en secondes
                pendant lesquelles les requêtes ci-dessus sont comptées
              </span>
              <FloatingLabel
                controlId="floatingInput"
                label="Niveau de durée de la requête"
              >
                <Form.Control type="text" value="2" />
              </FloatingLabel>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Transport SSL/SecureSIP et chiffrements
              </h3>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Activer la conformité PCI SSL/SecureSIP Transport et
                  chiffrementsCiphers (Ceci laissera seulement TLSv1.2 activé et
                  peut empêcher les anciens téléphones et les anciennes
                  applications Iperx de se connecter à distance à votre
                  système). Nécessite le redémarrage du service SIP (Allez sur
                  Dashboard, Services, Sélectionner Iperx PhoneSystem 01 SIP
                  Server et redémarrez).
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-5 mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Activer la clé secrète de provisioning
              </h3>

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  Cela activera une clé secrète pour chaque utilisateur, que le
                  téléphone IP utilisera pour se provisionner.
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SecurityConfig;
