import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useScheduleData } from "data/iper-x/context/ScheduleContext";
import { useTrunkData } from "data/iper-x/context/TrunkContext";
import { RoutingData } from "hooks/api/ipbx/useRouting";
import React, { useState } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import RoutingForm from "./RoutingForm";
import AvatarGroup from "../../../../../components/common/ipbx/AvatarGroup";  // Importer le formulaire de routage

export const routingAPITableColumns: ColumnDef<RoutingData>[] = [
  {
    accessorKey: "nom",
    header: "Nom de la règle",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "users",
    header: "Utilisateurs",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => {
      const membres = React.useMemo(() => {
        if (typeof row.original.users === "string") {
          return row.original.users.split(",").map(val => val.trim());
        }
        return [];
      }, [row.original.users]);

      const isAll = React.useMemo(() => membres.includes("all"), [membres]);

      if (isAll) {
        return (
          <span className="badge bg-primary text-white" style={{ fontSize: "12px" }}>
          Tous les utilisateurs
        </span>
        );
      }

      const ids = React.useMemo(() => {
        return membres
          .filter(val => /^\d+$/.test(val) && val.length <= 6)
          .map(Number); // IDs internes
      }, [membres]);

      const [phoneUsers, setPhoneUsers] = React.useState<any[]>([]);
      const [loading, setLoading] = React.useState<boolean>(true);
      const [error, setError] = React.useState<string | null>(null);

      React.useEffect(() => {
        const fetchPhoneUsers = async () => {
          if (ids.length === 0) {
            setLoading(false);
            return;
          }

          setLoading(true);
          setError(null);

          try {
            const token = localStorage.getItem("token");
            if (!token) throw new Error("Token not found");

            const responses = await Promise.all(
              ids.map(async id => {
                const res = await fetch(
                  `https://rct-backend.ipercom.io/api/Webrtc/phoneuser/${id}`,
                  {
                    headers: {
                      Accept: "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  }
                );

                if (!res.ok) {
                  if (res.status === 404)
                    throw new Error(`Utilisateur avec l'ID ${id} non trouvé.`);
                  throw new Error(`Échec de la récupération de l'utilisateur avec l'ID ${id}`);
                }
                return res.json();
              })
            );

            setPhoneUsers(responses);
          } catch (err) {
            setError((err as Error).message);
            console.error(err);
          } finally {
            setLoading(false);
          }
        };

        fetchPhoneUsers();
      }, [ids]);

      const phoneUsersMap = React.useMemo(() => {
        const map = new Map<number, any>();
        phoneUsers.forEach(user => map.set(user.id, user));
        return map;
      }, [phoneUsers]);

      if (loading) return <span>Loading...</span>;
      if (error) return <span>Error: {error}</span>;

      return (
        <div className="d-flex align-items-center gap-2 flex-wrap">
          {ids.map(id => {
            const user = phoneUsersMap.get(id);
            if (user) {
              return <AvatarGroup key={id} users={[user]} />;
            }
            return null;
          })}
        </div>
      );
    }
  },



  {
    accessorKey: "prefixe",
    header: "Préfixe",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: 'text-900 fs-9' }
    }
  },
  {
    accessorFn: row => row.idSchedule,
    id: "idSchedule",
    header: "Horaires de bureau",
    cell: ({ row: { original } }) => {
      const scheduleData = useScheduleData();
      const { idSchedule } = original;
      const schedule = scheduleData.find(schedule => schedule.id === idSchedule);
      return schedule ? schedule.name : "Horaires de bureau non trouvé";
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorFn: row => row.idTrunk,
    id: "idTrunk",
    header: "Trunk",
    cell: ({ row: { original } }) => {
      const trunkData = useTrunkData();
      const { idTrunk } = original;
      const trunk = trunkData.find(trunk => trunk.id === idTrunk);
      return trunk ? trunk.name : "Trunk non trouvé";
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "action",
    header: "Action",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "displayNumber",
    header: "Numéro d'affichage",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "Activé",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedRouting, setSelectedRouting] = useState<RoutingData | null>(null);

      const handleDeleteClick = (routing: RoutingData) => {
        setSelectedRouting(routing);
        setShowDeleteModal(true);
      };

      const handleEditClick = (routing: RoutingData) => {
        setSelectedRouting(routing);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedRouting) {
          await handleDelete(selectedRouting.id);
          setShowDeleteModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const token = localStorage.getItem('token');
          if (!token) throw new Error("Token not found");

          const response = await fetch(
            `https://rct-backend.ipercom.io/api/Ipbx/routing/${id}`,
            {
              method: "DELETE",
              headers: {
                accept: "text/plain",
                Authorization: `Bearer ${token}`
              }
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de la règle de routage", error);
        }
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Modale de confirmation de suppression */}
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cette règle sortante ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Modale de modification avec RoutingForm */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier la règle sortante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <RoutingForm
                initialData={selectedRouting || undefined}  // Ensure `initialData` is `RoutingData` or `undefined`
                onClose={() => setShowEditModal(false)}
                tableData={[]}  // Add your state for table data
                setTableData={() => {
                }}  // Add your function to update the table
              />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

const RoutingTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RoutingTableAPI;
