import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  Form
} from "react-bootstrap";
import { UserData } from "../../../../../types/user";

export const userAPITableColumns: ColumnDef<UserData>[] = [
  {
    id: "name",
    header: "Nom & Prénom",
    cell: ({ row: { original } }) => {
      const { firstname, lastname } = original;
      const avatarUrl = `https://ui-avatars.com/api/?name=${firstname}+${lastname}`;
      return (
        <div className="d-flex align-items-center">
          <Avatar src={avatarUrl} size="m" className="me-3" />
          <span>
            {firstname} {lastname}
          </span>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9 d-flex align-items-center" }
    }
  },
  {
    accessorKey: "mail",
    header: "Email",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "phone",
    header: "Téléphone",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "userRole.name",
    header: "Rôle",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "company.name",
    header: "Société",
    meta: {
      headerProps: { style: { width: "15%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
      const [formData, setFormData] = useState<UserData | null>(null);

      const toggle = () => setDropdownOpen(!dropdownOpen);

      const handleDeleteClick = (user: UserData) => {
        setSelectedUser(user);
        setShowDeleteModal(true);
      };

      const handleEditClick = (user: UserData) => {
        setSelectedUser(user);
        setFormData(user);
        setShowEditModal(true);
      };

      const handleDeleteConfirm = async () => {
        if (selectedUser) {
          await handleDelete(selectedUser.idUsers);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const response = await fetch(
            `https://rct-backend.ipercom.io/api/User/${id}`,
            {
              method: "DELETE",
              headers: {
                accept: "text/plain"
              }
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la suppression de l'utilisateur",
            error
          );
        }
      };

      const handleEdit = async (data: UserData) => {
        try {
          const { idUsers, ...dataWithoutId } = data;
          const response = await fetch(
            "https://rct-backend.ipercom.io/api/User",
            {
              method: "PUT",
              headers: {
                accept: "text/plain",
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
            }
          );

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la mise à jour de l'utilisateur",
            error
          );
        }
      };

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData =>
          prevData
            ? {
              ...prevData,
              [name]: type === "checkbox" ? checked : value
            }
            : null
        );
      };

      return (
        <>
          <Dropdown
            show={dropdownOpen}
            onToggle={toggle}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <DropdownToggle
              as="div"
              onClick={toggle}
              style={{ cursor: "pointer" }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleEditClick(original)}>
                Modifier
              </DropdownItem>
              <DropdownItem onClick={() => handleDeleteClick(original)}>
                Supprimer
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer cet utilisateur ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'utilisateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {formData &&
                  Object.keys(formData).map(
                    key =>
                      key !== "idUsers" &&
                      key !== "userRole" &&
                      key !== "company" && (
                        <Form.Group key={key} controlId={`form${key}`}>
                          <Form.Label>{key}</Form.Label>
                          <Form.Control
                            type="text"
                            name={key}
                            value={formData[key as keyof UserData] as string}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )
                  )}
                {/*<Form.Group controlId="userRole">*/}
                {/*  <Form.Label>Rôle</Form.Label>*/}
                {/*  <Form.Control*/}
                {/*    type="text"*/}
                {/*    name="userRole"*/}
                {/*    value={formData?.userRole.name}*/}
                {/*    onChange={(e) => setFormData({*/}
                {/*      ...formData,*/}
                {/*      userRole: {*/}
                {/*        ...formData?.userRole,*/}
                {/*        name: e.target.value*/}
                {/*      }*/}
                {/*    } as UserData)}*/}
                {/*    required*/}
                {/*  />*/}
                {/*</Form.Group>*/}
                {/*<Form.Group controlId="company">*/}
                {/*  <Form.Label>Société</Form.Label>*/}
                {/*  <Form.Control*/}
                {/*    type="text"*/}
                {/*    name="company"*/}
                {/*    value={formData?.company.name}*/}
                {/*    onChange={(e) => setFormData({*/}
                {/*      ...formData,*/}
                {/*      company: {*/}
                {/*        ...formData?.company,*/}
                {/*        name: e.target.value*/}
                {/*      }*/}
                {/*    } as UserData)}*/}
                {/*    required*/}
                {/*  />*/}
                {/*</Form.Group>*/}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const UserTableAPI = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: "phoenix-table fs-9 text-center" }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UserTableAPI;
