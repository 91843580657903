import { faCirclePlay, faCircleStop, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { useClientData } from "data/iper-x/context/ClientContext";
import { useMsgVocalData } from "data/iper-x/context/MsgVocalContext";
import useMsgVocal from "hooks/api/ipbx/useMsgVocal";
import { VoicemailData } from "hooks/api/ipbx/useVoicemail";
import React, { useRef, useState } from "react";
import { Button, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";
import VoicemailForm from "./VoicemailForm";

// Password display component
const PasswordCell = ({ password }: { password: string }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  return (
    <div className="">
      <span className="me-2">
        {showPassword ? password : "••••••••"}
      </span>
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        onClick={toggleShowPassword}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

// Column definitions for the voicemail table
export const voicemailAPITableColumns: ColumnDef<VoicemailData>[] = [
  {
    accessorKey: "nameVoicemail",
    header: "Nom de la boîte vocale",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // {
  //   accessorFn: (row) => row.idClient,
  //   id: 'idClient',
  //   header: 'Client',
  //   cell: ({ row: { original } }) => {
  //     const clientData = useClientData();
  //     const { idClient } = original;
  //     const client = clientData.find(client => client.id === idClient);
  //     return client ? client.companyName : "Client non trouvé";
  //   },
  //   meta: {
  //     headerProps: { style: { width: '12%', fontSize: '11px' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   }
  // },
  {
    accessorKey: "emailUser",
    header: "Email",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorFn: (row) => row.idMsgVocal,
    id: "idMsgVocal",
    header: "Audio",
    cell: ({ row: { original } }) => {
      const msgVocalData = useMsgVocalData();
      const { idMsgVocal } = original;
      const msgVocal = msgVocalData.find(msgVocal => msgVocal.id === idMsgVocal);

      const [isPlaying, setIsPlaying] = useState(false);
      const audioRef = useRef<HTMLAudioElement | null>(null);

      const togglePlayback = () => {
        if (isPlaying) {
          audioRef.current?.pause();
        } else {
          if (msgVocal && msgVocal.url) {
            audioRef.current = new Audio(msgVocal.url);
            audioRef.current.play();
          }
        }
        setIsPlaying(!isPlaying);
      };

      return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {msgVocal ? (
            <>
              <button
                onClick={togglePlayback}
                style={{ background: "none", border: "none", cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={isPlaying ? faCircleStop : faCirclePlay}
                  style={{ fontSize: "24px" }}
                />
              </button>
              <audio src={msgVocal.url} ref={audioRef} onEnded={() => setIsPlaying(false)} />
              {msgVocal.name}
            </>
          ) : (
            "Audio non trouvé"
          )}
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "20%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "password",
    header: "Mot de passe",
    cell: ({ row: { original } }) => (
      <PasswordCell password={original.password} />
    ),
    meta: {
      headerProps: { style: { width: "20%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "voicemailNumber",
    header: "Numéro de la boîte vocale",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "state",
    header: "Activé",
    cell: ({ row: { original } }) => {
      const { state } = original;
      return (
        <Badge
          bg={state ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {state ? "Actif" : "Inactif"}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "20%", fontSize: "12px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { fontSize: '12px' } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [dropdownOpen, setDropdownOpen] = useState(false);
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [selectedVoicemail, setSelectedVoicemail] = useState<VoicemailData | null>(null);
      const { msgVocalData, loading: msgLoading } = useMsgVocal();
      const [formData, setFormData] = useState<VoicemailData | null>(null);
      const [passwordError, setPasswordError] = useState<string | null>(null);
      const [showPassword, setShowPassword] = useState(false);
      const [phonePasswordError, setPhonePasswordError] = useState<string | null>(null);
      // const [showAddModal, setShowAddModal] = useState(false);
      // const [showEditModal, setShowEditModal] = useState(false);
      // const [selectedVoicemail, setSelectedVoicemail] = useState<VoicemailData | null>(null);
      const [tableData, setTableData] = useState<VoicemailData[]>([]);

      const toggleShowPassword = () => {
        setShowPassword((prevShow) => !prevShow);
      };

      const validatePassword = (password: string) => {
        if (!/^\d{4}$/.test(password)) {
          setPasswordError("Ne respecte pas les recommandations requises");
          return false;
        }
        setPasswordError(null);
        return true;
      };

      const validateVoicemailNumber = (number: number) => {
        if (number < 0 || number > 99999) {
          setPhonePasswordError("Le numéro de la boîte vocale ne peut pas dépasser 5 chiffres.");
          return false;
        }
        setPhonePasswordError(null);
        return true;
      };

      const handleDeleteClick = (voicemail: VoicemailData) => {
        setSelectedVoicemail(voicemail);
        setShowDeleteModal(true);
      };

      const handleEditClick = (voicemail: VoicemailData) => {
        setSelectedVoicemail(voicemail);
        // setFormData(voicemail); // L'ID doit être présent dans `voicemail`
        setShowEditModal(true);
      };

      // Handle modal close
      const handleCloseModal = () => {
        // setShowAddModal(false);
        setShowEditModal(false);
      };


      const handleDeleteConfirm = async () => {
        if (selectedVoicemail) {
          await handleDelete(selectedVoicemail.id);
          setShowDeleteModal(false);
        }
      };
      const handleEditConfirm = async () => {
        if (formData) {
          // Vérifiez que formData contient bien l'ID
          console.log("ID à envoyer:", formData.idMsgVocal); // Ajoutez ceci pour vérifier l'ID
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };
      const handleDelete = async (id: number) => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/voicemail/${id}`, {
            method: 'DELETE',
            headers: {
              'accept': 'text/plain',
              "Authorization": `Bearer ${token}`
            },
          });


          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la suppression du répondeur numérique", error);
        }
      };
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;

        let parsedValue: string | number = value;

        if (name === "idClient" || name === "voicemailNumber") {
          parsedValue = value ? parseInt(value, 10) : 0;
        } else if (name === "idMsgVocal") {
          parsedValue = parseInt(value, 10);
        }


        if (name === "password") {
          validatePassword(value);
        } else if (name === "voicemailNumber") {
          validateVoicemailNumber(parsedValue as number);
        }

        setFormData((prevData) => prevData ? {
          ...prevData,
          [name]: type === "checkbox" ? checked : parsedValue
        } : null);
      };

      const handleEdit = async (data: VoicemailData) => {
        if (passwordError) {
          return;
        }
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            throw new Error("Token not found");
          }

          // Assurez-vous que les données contiennent bien toutes les propriétés nécessaires
          const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/voicemail`, {
            method: "PUT",
            headers: {
              "accept": "text/plain",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
              id: data.id,
              idClient: data.idClient,
              nameVoicemail: data.nameVoicemail,
              emailUser: data.emailUser,
              password: data.password,
              voicemailNumber: data.voicemailNumber,
              idMsgVocal: data.idMsgVocal,
              state: data.state
            })
          });

          if (response.ok) {
            window.location.reload();
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour du répondeur numérique", error);
        }
      };


      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditClick(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteClick(original)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Delete confirmation modal */}
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Êtes-vous sûr de vouloir supprimer ce répondeur numérique ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Edit modal */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le répondeur numérique</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedVoicemail && (
                <VoicemailForm
                  initialData={selectedVoicemail}
                  tableData={tableData}
                  setTableData={setTableData}
                  onClose={handleCloseModal}
                />
              )}
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

// Main component rendering the voicemail table
const VoicemailTableAPI = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9 text-center" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default VoicemailTableAPI;
