import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Button, Form, Alert, Container, Row, Col } from 'react-bootstrap';

interface Contact {
  id: string;
  firstname: string;
  lastname: string;
}

interface User {
  login: string;
  password: string;
}

const UserCreate: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<string>('');
  const [user, setUser] = useState<User>({
    login: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    // Fetch contacts data for the select dropdown
    const fetchContacts = async () => {
      try {
        const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
        const response = await fetch('https://erp.ipercom.io/api/index.php/contacts', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'DOLAPIKEY': DOLAPIKEY || '',
          },
        });
        const data = await response.json();
        setContacts(data.map((contact: any) => ({ id: contact.id, firstname: contact.firstname, lastname: contact.lastname })));
      } catch (error) {
        console.error("Erreur lors de la récupération des données contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  const handleUserChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSelectContact = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedContact(e.target.value);
  };

  const createUser = async (contactId: string) => {
    try {
      const DOLAPIKEY = localStorage.getItem('DOLAPIKEY');
      const response = await fetch(`https://erp.ipercom.io/api/index.php/contacts/${contactId}/createUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'DOLAPIKEY': DOLAPIKEY || '',
        },
        body: JSON.stringify(user),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Failed to create user: ${data.error?.message || 'Unknown error'}`);
      }

      return data.id;  // Return the newly created user ID
    } catch (error) {
      // throw new Error(`User creation failed: ${error.message}`);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      if (selectedContact) {
        const userId = await createUser(selectedContact); // Create the user for the selected contact
        setSuccess('Utilisateur créé avec succès.');
        setUser({
          login: '',
          password: '',
        });
      } else {
        throw new Error('Veuillez sélectionner un contact.');
      }
    } catch (error) {
      setError(`Erreur: ${error instanceof Error ? error.message : 'Inconnue'}`);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <h2>Créer un utilisateur</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={onSubmit}>
            {/* Select Contact */}
            <Form.Group controlId="contact">
              <Form.Label>Sélectionner un contact</Form.Label>
              <Form.Control as="select" value={selectedContact}
                            // onChange={handleSelectContact}
                            required>
                <option value="">Sélectionner un contact</option>
                {contacts.map(contact => (
                  <option key={contact.id} value={contact.id}>
                    {contact.firstname} {contact.lastname}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* User Information */}
            <Form.Group controlId="login">
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control
                type="text"
                name="login"
                value={user.login}
                onChange={handleUserChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={user.password}
                onChange={handleUserChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-4">
              Créer
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserCreate;
