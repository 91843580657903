import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { Contacts } from "../../../../../types/contacts";
import React, { useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export const contactsTableColumns: ColumnDef<Contacts>[] = [
  {
    accessorFn: ({ id, firstname, lastname }) => ({ id, firstname, lastname }),
    id: "nomPrenom",
    header: "Nom et Prénom",
    cell: ({ row: { original } }) => {
      const { id, firstname, lastname } = original;
      const avatar = `https://ui-avatars.com/api/?name=${encodeURIComponent(firstname)}+${encodeURIComponent(lastname)}&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center">
          <Avatar src={avatar} size="m" className="me-2" />
          <div className="d-flex flex-column ms-2">
            <Link to={`/ipbx/contacts/details/${id}`} className="text-dark">
              <p className="mb-0 fw-bold">{firstname} {lastname}</p>
            </Link>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "20%", minWidth: 250 } },
      cellProps: { className: "ps-3" }
    }
  },
  {
    accessorKey: "position",
    header: "Fonction",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "company",
    header: "Entreprise",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "phone",
    header: "Téléphone",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "email",
    header: "Email",
    meta: {
      headerProps: { style: { width: "20%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: "actions",
    header: "Actions",
    cell: ({ row: { original } }) => {
      const [showDeleteModal, setShowDeleteModal] = useState(false);
      const [showEditModal, setShowEditModal] = useState(false);
      const [formData, setFormData] = useState<Contacts | null>(original);

      const handleDeleteClick = () => {
        setShowDeleteModal(true);
      };

      const handleEditClick = () => {
        setShowEditModal(true);
        setFormData(original);
      };

      const handleDeleteConfirm = async () => {
        if (formData) {
          await handleDelete(formData.id);
          setShowDeleteModal(false);
        }
      };

      const handleEditConfirm = async () => {
        if (formData) {
          await handleEdit(formData);
          setShowEditModal(false);
        }
      };

      const handleDelete = async (id: number) => {
        try {
          const token = localStorage.getItem("token");
          const response = await fetch(`https://rct-backend.ipercom.io/api/Webrtc/contact${id}`, {
            method: "DELETE",
            headers: {
              "Authorization": `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error(`Failed to delete contact`);
          }
          window.location.reload();
        } catch (error) {
          console.error("Error deleting contact:", error);
        }
      };

      const handleEdit = async (data: Contacts) => {
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            throw new Error("Token not found");
          }
          const response = await fetch(`https://rct-backend.ipercom.io/api/Webrtc/contact`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
              id: data.id,
              idClient: data.idClient, // Ensure idClient is part of the data
              firstname: data.firstname,
              lastname: data.lastname,
              company: data.company,
              position: data.position,
              phone: data.phone,
              mobile: data.mobile,
              email: data.email
            })
          });

          if (!response.ok) {
            throw new Error(`Failed to update contact`);
          }
          window.location.reload();
        } catch (error) {
          console.error("Error updating contact:", error);
        }
      };


      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => prevData ? { ...prevData, [name]: value } : prevData);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Dropdown.Item onClick={handleEditClick}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={handleDeleteClick}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>Êtes-vous sûr de vouloir supprimer ce contact ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Annuler
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirm}>
                Supprimer
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier le contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="firstname">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={formData?.firstname || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="lastname">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={formData?.lastname || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="company">
                  <Form.Label>Entreprise</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    value={formData?.company || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="position">
                  <Form.Label>Position</Form.Label>
                  <Form.Control
                    type="text"
                    name="position"
                    value={formData?.position || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="phone">
                  <Form.Label>Téléphone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData?.phone || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="mobile">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    value={formData?.mobile || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formData?.email || ""}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleEditConfirm}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const ContactsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default ContactsTable;
