import {
  Col,
  Row,
  Container,
  Button,
  Badge,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import PasswordCell from "../../../../components/base/PasswordCell";

interface TrunkData {
  name: string;
  server: string;
  username: string;
  password: string;
  stateRegistration: string;
  nameInstance: string;
  subdomainInstance: string;
  ipInstance: string;
  caps: number;
  currentCall: number;
  displayNumber: string;
  displayName: string;
  prefixe: string;
  ringDuration: number;
  sdaRegistration: string;
  nbLiveCalls: number;
  withRegistration: boolean;
  state: boolean;
  stateRegister: boolean;
}

const TrunkDetails = () => {
  const { id } = useParams();
  const [trunkData, setTrunkData] = useState<TrunkData | null>(null);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTrunkData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token not found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(
          `https://rct-backend.ipercom.io/api/Ipbx/trunk/${id}`,
          {
            headers: {
              Accept: "text/plain",
              Authorization: `Bearer ${token}`
            }
          }
        );
        setTrunkData(response.data);
      } catch (err) {
        setError("Error fetching trunk data.");
        console.error(err);
      }
    };

    fetchTrunkData();
  }, [id]);

  if (error)
    return (
      <Container>
        <p>{error}</p>
      </Container>
    );

  const renderTooltip = (text: string) => <Tooltip>{text}</Tooltip>;

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        {/* Button for going back */}
        <Button variant="primary" onClick={() => navigate(-1)} className="mb-3">
          ← Retour
        </Button>
        <h2>Détails du Trunk</h2>
        <div>
          <Button variant="warning" className="me-2" disabled>
            <i className="fas fa-pen-to-square me-1"></i> Modifier
          </Button>
          <Button variant="danger" disabled>
            <i className="fas fa-trash me-1"></i> Supprimer
          </Button>
        </div>
      </div>

      {!trunkData ? (
        <p>Loading trunk data...</p>
      ) : (
        <>
          <Row className="mb-4">
            <Col lg={4}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-4">Informations Générales</h5>
                <Row>
                  <Col md={6}>
                    <strong>
                      Nom:
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(
                          "Nom unique du trunk avec instance et préfixe. Sensible à la casse."
                        )}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ cursor: "pointer", marginLeft: "4px" }}
                        />
                      </OverlayTrigger>
                    </strong>
                    <p>{trunkData.name}</p>
                  </Col>
                  <Col md={6}>
                    <strong>
                      Serveur:
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(
                          "Serveur opérateur, peut être IP ou domaine."
                        )}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ cursor: "pointer", marginLeft: "4px" }}
                        />
                      </OverlayTrigger>
                    </strong>
                    <p>{trunkData.server}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Username Trunk:</strong>
                    <p>{trunkData.username}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Password Trunk:</strong>
                    <PasswordCell password={trunkData.password} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Statut d'enregistrement:</strong>
                    <p>{trunkData.stateRegistration}</p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={4}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-4">Instance et Capacité</h5>
                <Row>
                  <Col md={6}>
                    <strong>Nom de l'instance:</strong>
                    <p>{trunkData.nameInstance}</p>
                  </Col>
                  <Col md={6}>
                    <strong>
                      Sous-domaine de l'instance:{" "}
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(
                          "Sous-domaine de cette instance Asterisk."
                        )}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ cursor: "pointer", marginLeft: "4px" }}
                        />
                      </OverlayTrigger>
                    </strong>
                    <p>{trunkData.subdomainInstance}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>IP de l'instance:</strong>
                    <p>{trunkData.ipInstance}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Capacités:</strong>
                    <p>{trunkData.caps}</p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={4}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-4">Appels et Affichage</h5>
                <Row>
                  <Col md={6}>
                    <strong>Appels en cours:</strong>
                    <p>{trunkData.currentCall}</p>
                  </Col>
                  <Col md={6}>
                    <strong>
                      Numéro d'affichage:
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(
                          "Numéro à afficher lors des appels émis avec ce trunk."
                        )}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ cursor: "pointer", marginLeft: "4px" }}
                        />
                      </OverlayTrigger>
                    </strong>
                    <p>{trunkData.displayNumber}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Nom d'affichage:</strong>
                    <p>{trunkData.displayName}</p>
                  </Col>
                  <Col md={6}>
                    <strong>
                      Préfixe:
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(
                          "Préfixe utilisé avant le numéro pour certains opérateurs."
                        )}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          style={{ cursor: "pointer", marginLeft: "4px" }}
                        />
                      </OverlayTrigger>
                    </strong>
                    <p>{trunkData.prefixe}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col lg={12}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-4">Enregistrement et Statut</h5>
                <Row>
                  <Col md={6}>
                    <strong>Durée de sonnerie:</strong>
                    <p>{trunkData.ringDuration} secondes</p>
                  </Col>
                  <Col md={6}>
                    <strong>Enregistrement SDA:</strong>
                    <p>{trunkData.sdaRegistration}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Nombre d'appels actifs:</strong>
                    <p>{trunkData.nbLiveCalls}</p>
                  </Col>
                  <Col md={6}>
                    <strong>Avec enregistrement:</strong>
                    <p>{trunkData.withRegistration ? "Oui" : "Non"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <strong>Statut:</strong>
                    <Badge bg={trunkData.state ? "success" : "secondary"}>
                      {trunkData.state ? "Actif" : "Inactif"}
                    </Badge>
                  </Col>
                  <Col md={6}>
                    <strong>Statut REGISTER:</strong>
                    <Badge
                      bg={trunkData.stateRegister ? "success" : "secondary"}
                    >
                      {trunkData.stateRegister ? "Actif" : "Inactif"}
                    </Badge>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={12}>
              <div className="bg-light p-4 rounded">
                <h5 className="mb-4">Les 10 derniers événements liés</h5>
                <p>Aucun événement disponible</p>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default TrunkDetails;
