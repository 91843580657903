import {
  faCircle,
  faClose,
  faFileExport,
  faFileImport,
  faPencil,
  faPeopleGroup,
  faPlus,
  faRefresh
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/others/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PhoneBookTable, { phoneBookColumns } from './PhoneBookTable';
import { phoneBookTableData } from '../../../../data/iper-x/ipbx/phoneBook';

const PhoneBookList = () => {
  const table = useAdvanceTable({
    data: phoneBookTableData,
    columns: phoneBookColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Annuaire</span>
          </h2>
          <Link
            className="btn btn-phoenix-secondary d-flex flex-center "
            to="/"
          >
            <FontAwesomeIcon icon={faPencil} className="me-2" />
            Modifier
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faClose} className="me-2" />
            Supprimer
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faPeopleGroup} className="me-2" />
            Groupes
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faFileImport} className="me-2" />
            Importer
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faFileExport} className="me-2" />
            Exporter
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon icon={faRefresh} className="me-2" />
            Regénérer
          </Link>
          <Link className="btn btn-white border border-2 px-2" to="/">
            <FontAwesomeIcon
              transform="shrink-1 down-3"
              icon={faCircle}
              className={`me-2 d-inline-block text-warning`}
            />
            Statut
          </Link>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un contact
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <PhoneBookTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default PhoneBookList;
