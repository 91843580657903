import { useState, useCallback, useEffect } from "react";
import { UserData } from "../../../../types/user";



const userTableData: UserData[] = [
];

const useEditorUser = () => {
  const [userData, setUserData] = useState<UserData[]>(userTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    console.log("Fetching new API data...");
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/User', {
        method: 'GET',
        headers: {
          accept: "text/plain",
          Authorization: `Bearer ${token}` // Add the token to the Authorization header
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setUserData(data);
      setError(null);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de l'API",
        error
      );
      setError('Impossible de récupérer les données.');
      setUserData(userTableData); // Fallback to default data
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    userData,
    setUserData,
    loading,
    error
  };
};

export default useEditorUser;
