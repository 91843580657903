import { useState, useCallback, useEffect } from "react";

export interface FinalClientData {
  id: number;
  idRevendeur: number;
  createdBy: number;
  idInstance: number;
  firstName: string;
  lastName: string;
  position: string | null;
  email: string | null;
  address: string | null;
  dateCreated: string | null;
  phoneNumber: string | null;
  companyName: string | null;
  status: string | null;
  lastUpdated: string | null;
  licences: any[];
  siret: string | null;
}

// Fallback data au cas où il y a une erreur de récupération des données
export const fallBackClientData: FinalClientData[] = [
  {
    id: 1,
    idRevendeur: 10,
    createdBy: 6,
    idInstance: 1,
    firstName: "John",
    lastName: "Doe",
    position: "CEO",
    email: "john.doe@example.com",
    address: "123 Main Street",
    dateCreated: null,
    phoneNumber: null,
    companyName: null,
    status: null,
    lastUpdated: null,
    licences: [],
    siret: null
  },
];

// Données initiales vides
const initialClientData: FinalClientData[] = [];

/**
 * **useFinalClient**:
 * Un hook personnalisé pour gérer les données des clients finaux, l'état de chargement et les erreurs.
 * Il récupère les données clients depuis une API externe et gère l'authentification via un token stocké dans le localStorage.
 *
 * @returns {Object} - Les données des clients finaux, l'état de chargement, le message d'erreur et le setter des données client.
 *
 * @property {FinalClientData[]} clientData - Données des clients finaux récupérées via l'API ou données de secours.
 * @property {Function} setClientData - La fonction pour définir manuellement les données des clients finaux.
 * @property {boolean} loading - Indique si les données sont en cours de récupération.
 * @property {string | null} error - Le message d'erreur ou null s'il n'y a pas d'erreur.
 *
 * @example
 * const { clientData, loading, error } = useFinalClient();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ClientList data={clientData} />;
 */
const useFinalClient = () => {
  const [clientData, setClientData] = useState<FinalClientData[]>(initialClientData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not available');
      }

      const response = await fetch('https://rct-backend.ipercom.io/api/ipbx/clientfinal', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setClientData(data);

      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données.');
      setClientData(fallBackClientData);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    clientData,
    setClientData,
    loading,
    error
  };
};

export default useFinalClient;
