import { useState, useEffect, useCallback } from "react";

// Define the structure for TrunkData
export interface TrunkData {
  id: number;
  name: string;
  idClient: number;
  server: string;
  username: string;
  password: string;
  // nameInstance: string;
  // subdomainInstance: string;
  // ipInstance: string;
  caps: number;
  currentCall: number;
  displayName: string;
  displayNumber: string;
  prefixe: string;
  ringDuration: number;
  withRegistration: boolean;
  sdaRegistration: string;
  stateRegister: boolean;
  state: boolean;
  nbLiveCalls: number;
}

/**
 * **useTrunkDataID**:
 * A custom hook for managing trunk data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch trunk data for.
 * @returns {Object} - The trunk data, loading state, errors, and a setter for the data.
 *
 * @property {TrunkData[]} trunkData - The data retrieved from the API or a fallback value.
 * @property {Function} setTrunkData - Function to manually set trunk data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { trunkData, loading, error } = useTrunkDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <TrunkList data={trunkData} />;
 */
const useTrunkDataID = (id: number) => {
  const [trunkData, setTrunkData] = useState<TrunkData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTrunkData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/trunk/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setTrunkData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données trunk data", error);
      setError("Impossible de récupérer les données trunk data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchTrunkData();
  }, [fetchTrunkData]);

  return {
    trunkData,
    setTrunkData,
    loading,
    error
  };
};

export default useTrunkDataID;
