import { Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import PageBreadcrumb, {
  PageBreadcrumbItem
} from "components/common/PageBreadcrumb";
import React from 'react';

export const optionsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: "Avancés",
    url: '/apps/ipbx/parametres?selected=advance'
  },
  {
    label: 'Langue et d’heures de bureau',
    url: '/apps/ipbx/parametres/language-office-hours'
  }
];

const LanguageOfficeHoursConfig = () => {
  return (
    <>
      <PageBreadcrumb items={optionsBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">
            Paramètres de langue et d’heures de bureau
          </span>
        </h2>
      </div>

      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Langue du système</h3>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Langue du système</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>Français</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">Fuseau horaire</h3>
              <Form.Group className="mb-3" controlId="formFile">
                <Form.Label>Choisir le fuseau horaire</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Region">
                  <Form.Select aria-label="Floating label select example">
                    <option>+1:00 Spain (Madrid, Palma)</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default LanguageOfficeHoursConfig;
