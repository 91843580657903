import { useState, useCallback, useEffect } from 'react';

// Interfaces for the Lead Data structure
export interface SourceLead {
  id: number;
  name: string;
  slug: string;
}

export interface CompanyClientType {
  id: number;
  name: string;
  slug: string;
}

export interface CommonCity {
  id: number;
  name: string;
  slug: string;
  zipCode: string;
}

export interface UserAssignedRetailer {
  id: number;
  firstname: string;
  lastname: string;
  avatar: string;
}

export interface LeadData {
  idLeads: number;
  name: string;
  ceoName: string;
  siret: string;
  address: string;
  mail: string;
  website: string;
  phone: string;
  numbersEmployees: number;
  annualIncome: number;
  nafCode: string;
  sourceLead: SourceLead;
  companyClientType: CompanyClientType;
  commonCity: CommonCity;
  devicesNumber: number;
  accessNumber: number;
  progressMin: number;
  progressMax: number;
  statusLabel: string;
  statusType: string;
  userAssignedRetailer: UserAssignedRetailer;
}

// Fallback data for LeadData in case API fails
const fallBackLeadData: LeadData[] = [
  {
    idLeads: 1,
    name: 'TechCorp Solutions',
    ceoName: 'Jane Smith',
    siret: '12345678901234',
    address: '10 Rue de la Paix, Paris',
    mail: 'contact@techcorp.com',
    website: 'www.techcorp.com',
    phone: '+33123456789',
    numbersEmployees: 100,
    annualIncome: 5000000,
    nafCode: '6202A',
    sourceLead: { id: 1, name: 'Web Referral', slug: 'web-referral' },
    companyClientType: { id: 2, name: 'Enterprise', slug: 'enterprise' },
    commonCity: { id: 3, name: 'Paris', slug: 'paris', zipCode: '75001' },
    devicesNumber: 150,
    accessNumber: 120,
    progressMin: 80,
    progressMax: 100,
    statusLabel: 'In Progress',
    statusType: 'warning',
    userAssignedRetailer: {
      id: 4,
      firstname: 'Mark',
      lastname: 'Johnson',
      avatar: 'https://ui-avatars.com/api/?name=Mark+Johnson'
    }
  },
  {
    idLeads: 2,
    name: 'EcoPower Energy',
    ceoName: 'Marie Dupont',
    siret: '98765432109876',
    address: '25 Av des Champs-Élysées, Paris',
    mail: 'info@ecopower.com',
    website: 'www.ecopower.com',
    phone: '+33198765432',
    numbersEmployees: 50,
    annualIncome: 2000000,
    nafCode: '3511Z',
    sourceLead: { id: 2, name: 'Direct Contact', slug: 'direct-contact' },
    companyClientType: { id: 3, name: 'SME', slug: 'sme' },
    commonCity: { id: 4, name: 'Paris', slug: 'paris', zipCode: '75008' },
    devicesNumber: 60,
    accessNumber: 50,
    progressMin: 50,
    progressMax: 80,
    statusLabel: 'Completed',
    statusType: 'success',
    userAssignedRetailer: {
      id: 5,
      firstname: 'Elena',
      lastname: 'Martinez',
      avatar: 'https://ui-avatars.com/api/?name=Elena+Martinez'
    }
  }
];

// Default empty lead data
const leadTableData: LeadData[] = [];

/**
 * **Leads Revendeur**:
 * A custom hook that manages lead data for retailers, including loading and error states.
 * It fetches lead data (company details, CEO, contact info, and assigned user) from an external API, using token-based authentication stored in localStorage.
 *
 * @returns {Object} - The lead data, loading state, error message, and a setter for lead data.
 *
 * @property {LeadData[]} leadData - Lead data fetched from the API or fallback data in case of errors.
 * @property {Function} setLeadData - Function to manually set the lead data.
 * @property {boolean} loading - Indicates whether the lead data is being fetched.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * // Example usage of useRetailerLead in a component
 * const { leadData, loading, error } = useRetailerLead();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <LeadList data={leadData} />;
 */
const useRetailerLead = () => {
  const [leadData, setLeadData] = useState<LeadData[]>(leadTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }

      // Make the API request with the token in the headers
      const response = await fetch(
        'https://rct-backend.ipercom.io/api/Lead/user/me',
        {
          method: 'GET',
          headers: {
            accept: 'text/plain',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setLeadData(data);
      setError(null); // Clear error if data fetch is successful
    } catch (error) {
      console.error("Erreur lors de la récupération des données data from API", error);
      setError('Impossible de récupérer les données.');
      // setLeadData(fallBackLeadData); // Fallback to default lead data in case of an error
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    leadData,
    setLeadData,
    loading,
    error
  };
};

export default useRetailerLead;
