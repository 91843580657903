import React, { useState } from 'react';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { faFileExport, faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import LeadsFilterModal from "components/modals/LeadsFilterModal";
import EditorLeadsTable, { editorLeadsTableColumns } from "pages/apps/editor/Leads/EditorLeadsTable";
import { editorLeadsTableData } from "data/iper-x/editor/editorLeadsTableData";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import DatePicker from "react-flatpickr";

const LeadsInEditor = () => {
  const table = useAdvanceTable({
    data: editorLeadsTableData,
    columns: editorLeadsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showAddLeadModal, setShowAddLeadModal] = useState(false);
  const [lead, setLead] = useState({
    PROSPECT: '',
    'REVENDEUR/CONTACT': '',
    'NOMBRE DE POSTE': '',
    'NOMBRE D’ACCÈS': '',
    'DATE DE CRÉATION': ''
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLead(prevLead => ({
      ...prevLead,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Logique de soumission du formulaire ici
    setShowAddLeadModal(false); // Fermer la modal après soumission
  };

  return (
    <AdvanceTableProvider {...table}>
      <div>
        <h2 className="mb-4">Mes leads</h2>
        <Row className="g-3 justify-content-between mb-4">
          <Col xs="auto">
            <Button variant="primary" className="me-4" onClick={() => setShowAddLeadModal(true)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Ajouter un Lead
            </Button>
            <Button variant="link" className="text-900 px-0">
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Export
            </Button>
          </Col>
          <Col xs="auto">
            <div className="d-flex">
              <SearchBox
                placeholder="Rechercher par nom"
                className="me-2"
                onChange={handleSearchInputChange}
              />
              <DatePicker defaultValue="Mar 1, 2022" />
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
            </div>
            <LeadsFilterModal
              show={openFilterModal}
              handleClose={() => setOpenFilterModal(false)}
            />
          </Col>
        </Row>
        <EditorLeadsTable />

        <Modal show={showAddLeadModal} onHide={() => setShowAddLeadModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter un lead</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit} style={{ width: '100%' }}>

              <Form.Group controlId="PROSPECT">
                <Form.Label>PROSPECT</Form.Label>
                <Form.Control type="text" name="PROSPECT" value={lead.PROSPECT} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="REVENDEUR/CONTACT">
                <Form.Label>REVENDEUR/CONTACT</Form.Label>
                <Form.Control type="text" name="REVENDEUR/CONTACT" value={lead['REVENDEUR/CONTACT']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="NOMBRE DE POSTE">
                <Form.Label>NOMBRE DE POSTE</Form.Label>
                <Form.Control type="text" name="NOMBRE DE POSTE" value={lead['NOMBRE DE POSTE']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="NOMBRE D’ACCÈS">
                <Form.Label>NOMBRE D’ACCÈS</Form.Label>
                <Form.Control type="text" name="NOMBRE D’ACCÈS" value={lead['NOMBRE D’ACCÈS']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="DATE DE CRÉATION">
                <Form.Label>DATE DE CRÉATION</Form.Label>
                <Form.Control type="text" name="DATE DE CRÉATION" value={lead['DATE DE CRÉATION']} onChange={handleChange} required />
              </Form.Group>

              <Button variant="primary" type="submit" className='mt-4 mb-4'>
                Ajouter
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </AdvanceTableProvider>
  );
};

export default LeadsInEditor;
