// SignInHard.tsx
import React, { useState } from 'react';
import AuthSimpleLayout from 'layouts/old/AuthSimpleLayout';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { useAuthContext } from '../../../providers/AuthProvider';
import AuthSocialButtons from '../../../components/common/AuthSocialButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login(username, password);
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <AuthSimpleLayout>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username">
          <div className="text-center mb-7">
            <h3 className="text-1000">Connexion</h3>
            <p className="text-700">Accédez à votre compte</p>
          </div>
          {/* <AuthSocialButtons title="Connections" /> */}
          {/* <div className="position-relative">
            <hr className="bg-200 mt-5 mb-4" />
            <div className="divider-content-center">ou utilisez l’email</div>
          </div> */}
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="email">EMAIL</Form.Label>
            <div className="form-icon-container">
              <Form.Control
                id="email"
                type="email"
                className="form-icon-input"
                placeholder="EMAIL"
                value={username}
                onChange={e => setUsername(e.target.value)}
                required
              />
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-900 fs-9 form-icon"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="password">Mot de passe</Form.Label>
            <div className="form-icon-container">
              <Form.Control
                id="password"
                type="password"
                className="form-icon-input"
                placeholder="Mot de passe"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
              <FontAwesomeIcon
                icon={faKey}
                className="text-900 fs-9 form-icon"
              />
            </div>
          </Form.Group>
          <Row className="flex-between-center mb-7">
            <Col xs="auto">
              <Form.Check type="checkbox" className="mb-0">
                <Form.Check.Input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  defaultChecked
                />
                <Form.Check.Label htmlFor="remember-me" className="mb-0">
                  Souviens-toi de moi
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col xs="auto">
              <Link
                to={`/pages/authentication/forgot-password`}
                className="fs-9 fw-semi-bold"
              >
                Mot de passe oublié ?
              </Link>
            </Col>
          </Row>
        </Form.Group>
        <Button variant="primary" className="w-100 mb-3" type="submit">
          Se connecter
        </Button>
      </Form>
    </AuthSimpleLayout>
  );
};

export default SignIn;
