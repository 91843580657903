import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import React from "react";

import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";
import DevicesForm from "./DevicesForm";

interface DevicesTableProps {
  onEdit: (device: PhoneDevicesData) => void;
  onDelete: (deviceId: number) => void;
}

export const devicesTableColumns: ColumnDef<PhoneDevicesData>[] = [
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "10%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => {
      const {
        name = "Nom non défini",
        privateIpAddress,
        publicIpAddress,
        deviceStatus,
        id
      } = row.original;

      // Determine status color and text based on deviceStatus
      // Fonction pour déterminer la couleur en fonction du statut
      const getStatusColor = (status: string) => {
        switch (status) {
          case "Online":
            return "green";
          case "Offline":
            return "red";
          case "In Progress":
            return "orange";
          default:
            return "gray"; // Couleur par défaut
        }
      };

      // Appelle la fonction pour obtenir la couleur
      const statusColor = getStatusColor(deviceStatus || "Statut inconnu");
      const statusText = deviceStatus || "Statut inconnu";

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Indicator with Tooltip for device status */}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{statusText}</Tooltip>}
          >
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: statusColor,
                marginRight: "8px",
                cursor: "pointer"
              }}
            ></span>
          </OverlayTrigger>

          {/* Name with Tooltip for IPs and Provisioning Status */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <div>
                  <strong>IP Privée:</strong>{" "}
                  {privateIpAddress || "Non définie"}
                  <br />
                  <strong>IP Publique:</strong>{" "}
                  {publicIpAddress || "Non définie"}
                </div>
              </Tooltip>
            }
          >
            <a
              href={`/ipbx/téléphones/details/${id}`}
              className="text-primary"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              {name}
            </a>
          </OverlayTrigger>
        </div>
      );
    }
  },

  {
    accessorKey: "brandModel",
    header: "Constructeur / Modèle",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <>
        <div>Constructeur: {row.original.brand}</div>
        <div>Modèle: {row.original.deviceModel}</div>
        <div>Version du firmware: {row.original.firmwareVersion}</div>
      </>
    )
  },

  {
    accessorKey: "macAddressMachineId",
    header: "MAC / Machine ID",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <>
        <div>{row.original.macAddress}</div>
        <div>{row.original.machineId}</div>
      </>
    )
  },

  {
    accessorKey: "location",
    header: "Location",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "deviceType",
    header: "Type de dispositif",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "userProvisioning",
    header: "Utilisateurs & Statuts",
    cell: ({ row: { original } }) => {
      const { id, provisioningStatus1, provisioningStatus2 } = original;

      // Determine avatar border colors based on provisioning statuses
      const borderColor1 =
        provisioningStatus1 === "Registered"
          ? "green"
          : provisioningStatus1 === "failed"
            ? "red"
            : "gray"; // Default for other statuses

      const borderColor2 =
        provisioningStatus2 === "Registered"
          ? "green"
          : provisioningStatus2 === "failed"
            ? "red"
            : "gray"; // Default for other statuses

      // Generate avatar URLs
      const avatarUrl1 = `https://ui-avatars.com/api/?name=test1&rounded=true&size=32`;
      const avatarUrl2 = `https://ui-avatars.com/api/?name=test2&rounded=true&size=32`;

      return (
        <div className="d-flex flex-column gap-2">
          {/* First Account */}
          <div className="d-flex align-items-center">
            {/* Avatar */}
            <img
              src={avatarUrl1}
              alt="Account 1 Avatar"
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                marginRight: "10px",
                border: `2px solid ${borderColor1}`
              }}
            />

            {/* User Details */}
            <div className="d-flex flex-column">
              <a
                href={`utilisateurs/details/${id}/account1`}
                className="fw-bold text-dark text-decoration-none"
              >
                Account 1
              </a>

              {/* Provisioning Status */}
              <small
                className={`fw-bold ${
                  provisioningStatus1 === "Registered"
                    ? "text-success"
                    : provisioningStatus1 === "failed"
                      ? "text-danger"
                      : "text-muted"
                }`}
              >
                {provisioningStatus1 || "Statut inconnu"}
              </small>
            </div>
          </div>

          {/* Second Account */}
          <div className="d-flex align-items-center">
            {/* Avatar */}
            <img
              src={avatarUrl2}
              alt="Account 2 Avatar"
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                marginRight: "10px",
                border: `2px solid ${borderColor2}`
              }}
            />

            {/* User Details */}
            <div className="d-flex flex-column">
              <a
                href={`utilisateurs/details/${id}/account2`}
                className="fw-bold text-dark text-decoration-none"
              >
                Account 2
              </a>

              {/* Provisioning Status */}
              <small
                className={`fw-bold ${
                  provisioningStatus2 === "Registered"
                    ? "text-success"
                    : provisioningStatus2 === "failed"
                      ? "text-danger"
                      : "text-muted"
                }`}
              >
                {provisioningStatus2 || "Statut inconnu"}
              </small>
            </div>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "12%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

  {
    id: 'leadDropdown',
    header: "Actions",
    meta: {
      headerProps: { style: { width: "12%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row: { original } }) => {
      const [openFormModal, setOpenFormModal] = React.useState(false);
      const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
      const [currentDevice, setCurrentDevice] = React.useState<
        PhoneDevicesData | undefined
      >(undefined);

      // Handlers for editing and deleting
      const handleEditDevice = (device: PhoneDevicesData) => {
        setCurrentDevice(device);
        setOpenFormModal(true);
      };

      const handleDeleteDevice = (deviceId: number) => {
        // Ajoutez la logique de suppression ici
        setCurrentDevice(undefined);
        setOpenDeleteModal(true);
      };

      const handleCloseForm = () => {
        setOpenFormModal(false);
        setCurrentDevice(undefined);
      };

      const handleCloseDelete = () => {
        setOpenDeleteModal(false);
        setCurrentDevice(undefined);
      };

      const handleSaveDevice = (updatedDevice: PhoneDevicesData) => {
        // console.log("Device saved:", updatedDevice);
        // Add logic to update the table or re-fetch the data
        handleCloseForm();
      };

      return (
        <>
          {/* Dropdown with actions */}
          <RevealDropdownTrigger>
            <RevealDropdown className="fs-9">
              <Dropdown.Item onClick={() => handleEditDevice(original)}>
                Modifier
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDeleteDevice(original.id)}>
                Supprimer
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          {/* Modal for Editing */}
          <Modal show={openFormModal} onHide={handleCloseForm} centered>
            <Modal.Header closeButton>
              <Modal.Title>Modifier l'Appareil</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DevicesForm
                currentDevice={currentDevice}
                mode="edit"
                onSave={handleSaveDevice}
                onClose={handleCloseForm}
              />
            </Modal.Body>
          </Modal>

          {/* Modal for Deletion */}
          <Modal show={openDeleteModal} onHide={handleCloseDelete} centered>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation de Suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DevicesForm
                currentDevice={currentDevice}
                mode="delete"
                onSave={handleSaveDevice}
                onDelete={handleDeleteDevice}
                onClose={handleCloseDelete}
              />
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }
];

const DevicesTable: React.FC<DevicesTableProps> = ({ onEdit, onDelete }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default DevicesTable;
