import ScrollSpy from "components/base/ScrollSpy";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { editorUserRightsBreadcrumbItems } from "data/breadcrumbItem";
import { dealDetailsInfoData } from "data/crm/dealDetailsInfo";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { leadsBreadcrumbItems } from "./EditorDetails";
import AboutRetailerCard from "./AboutRetailerCard";
import DealsPrintingDimensionsCard from "./DealsPrintingDimensionsCard";
import FinalClientInDetailEditor from "./FinalClientInDetailEditor";
import LeadAddressCard from "./LeadAddressCard";
import LeadDetailsOffcanvas from "./LeadDetailsOffcanvas";
import RetailerProfileCard from "./RetailerProfileCard";
import UserDetailsInfo from "./UserDetailsInfo";
import { stats } from "data/crm/dealDetailsInfo";


const DetailsRetailerDashboardInEditor = () => {
  const { name } = useParams();

  const [openOffcanvas, setOpenOffcanvas] = useState(false);

  const updatedBreadcrumbItems = editorUserRightsBreadcrumbItems.map((item, index) => {
    if (index === editorUserRightsBreadcrumbItems.length - 1) {
      return {
        ...item,
        label: `Détails revendeur`
      };
    }
    return item;
  });

  return (
    <div>
      <PageBreadcrumb items={leadsBreadcrumbItems} />
      <div className="pb-9">
        <Row className="align-items-center justify-content-between g-3 mb-3">
          <Col xs={12} md="auto">
            <h2 className="mb-0">Détail Revendeur</h2>
          </Col>
        </Row>

        <Row className="g-0 g-md-4 g-xl-6">
          <Col md={5} xl={4} className="d-none d-md-block">
            <div className="sticky-leads-sidebar">
              <div className="lead-details-offcanvas bg-soft scrollbar">
                <div className="d-flex justify-content-between align-items-center mb-2 d-md-none">
                  <h3 className="mb-0">Détails du lead</h3>
                </div>
                <RetailerProfileCard className="mb-3" />
                <LeadAddressCard />



              </div>
            </div>
          </Col>
          <Col md={7} xl={8}>
            <div className="lead-details-container">
              <AboutRetailerCard className="mb-3" />
              <DealsPrintingDimensionsCard stats={stats} className="mb-9" />
              <UserDetailsInfo data={dealDetailsInfoData} className="mb-7" />
              <ScrollSpy>
                <ScrollSpy.Content
                  id="leads"
                  className="lead-details-scrollspy mb-8"
                  minTopValue={150}
                >
                  <FinalClientInDetailEditor />
                </ScrollSpy.Content>
                <ScrollSpy.Content
                  id="emails"
                  className="lead-details-scrollspy mb-8"
                >
                </ScrollSpy.Content>
              </ScrollSpy>
            </div>
          </Col>
        </Row>
      </div>
      <LeadDetailsOffcanvas open={openOffcanvas} setOpen={setOpenOffcanvas} />
    </div>
  );
};


export default DetailsRetailerDashboardInEditor;
