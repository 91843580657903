import React, { ChangeEvent, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import { retailerTicketsBreadcrumbItems } from "data/breadcrumbItem";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import TicketForm from "./TicketForm";
import useTicketsData from "../../../../hooks/dolibarr/useTicketsData";
import TicketsTable, { TicketsTableColumns } from "../../editor/ticket/TicketsTable";

export interface Assignee {
  id: number;
  name: string;
  avatar?: string;
}

export interface Ticket {
  id: number;
  author: string;
  subject: string;
  type: string;
  severity: string;
  priority: string;
  company: string;
  assignee: string;
  creationDate: string;
  status: { label: string; type?: string };
  assigness: Assignee[];
  progress?: { min: number; max: number };
  task: number;
  bg: string;
  budget: number;
}

export interface User {
  assigne: string;
  startDate: string;
  endDate: string;
  type_code: string;
  category_code: string;
  severity_code: string;
  socid: string;
  projectid: string;
  subject: string;
  message: string;
}

const TicketsList: React.FC = () => {
  const { data, error, refetch } = useTicketsData(); // Added refetch to reload data
  const table = useAdvanceTable({
    data,
    columns: TicketsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  // Handle submission and refetch tickets after new ticket is added
  const handleSubmit = (user: User) => {
    console.log('Ticket ajouté:', user);
    refetch(); // Refetch the ticket data to update the table
  };

  return (
    <div className="mb-9">
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <PageBreadcrumb items={retailerTicketsBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Vos tickets</h2>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="primary" onClick={handleShowModal}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un ticket
              </Button>
            </Col>
          </Row>
        </div>
        {error && <Alert variant="danger">{error}</Alert>}

        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <TicketsTable />
        </div>
      </AdvanceTableProvider>

      <TicketForm
        show={showModal}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmit} // Pass the refetch handler to the form
      />
    </div>
  );
};

export default TicketsList;
