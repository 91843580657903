import { useState, useEffect, useCallback } from "react";

// Define the structure for ContactData
export interface ContactData {
  id: number;
  idClient: number;
  firstname: string;
  lastname: string;
  company: string;
  position: string;
  phone: string;
  mobile: string;
  email: string;
}

/**
 * **useContactDataID**:
 * A custom hook for managing contact data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch contact data for.
 * @returns {Object} - The contact data, loading state, errors, and a setter for the data.
 *
 * @property {ContactData[]} contactData - The data retrieved from the API or a fallback value.
 * @property {Function} setContactData - Function to manually set contact data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { contactData, loading, error } = useContactDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ContactList data={contactData} />;
 */
const useContactDataID = (id: number) => {
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchContactData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Webrtc/contact/client${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setContactData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données contact data", error);
      setError("Impossible de récupérer les données contact data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchContactData();
  }, [fetchContactData]);

  return {
    contactData,
    setContactData,
    loading,
    error
  };
};

export default useContactDataID;
