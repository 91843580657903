import React, { useState, ChangeEvent } from "react";
import { Modal, Form, Button } from "react-bootstrap";

interface AddContactModalProps {
  show: boolean;
  onHide: () => void;
  clientFinalId: number | null;
  onAddContact: (contact: any) => void;
}

interface FormData {
  id: number;
  idClient: number | null;
  firstname: string;
  lastname: string;
  company: string;
  position: string;
  phone: string;
  mobile: string;
  email: string;
}

interface Errors {
  firstname?: string;
  lastname?: string;
  company?: string;
  phone?: string;
  mobile?: string;
  email?: string;
}

const AddContactModal: React.FC<AddContactModalProps> = ({ show, onHide, clientFinalId, onAddContact }) => {
  const [formData, setFormData] = useState<FormData>({
    id: 0,
    idClient: clientFinalId,
    firstname: "",
    lastname: "",
    company: "",
    position: "",
    phone: "",
    mobile: "",
    email: ""
  });
  const [errors, setErrors] = useState<Errors>({});
  const [clientPosition, setClientPosition] = useState("");

  const validateFields = () => {
    const newErrors: Errors = {};
    if (!formData.firstname) newErrors.firstname = "Le prénom est requis.";
    if (!formData.lastname) newErrors.lastname = "Le nom est requis.";
    if (!formData.company) newErrors.lastname = "Le nom de l'entreprise est requis.";
    if (!formData.mobile || !/^\d+$/.test(formData.mobile)) {
      newErrors.mobile = "Le numéro mobile est requis et doit contenir uniquement des chiffres.";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Un email valide est requis.";
    }
    if (formData.phone && !/^\d*$/.test(formData.phone)) {
      newErrors.phone = "Le numéro de téléphone doit contenir uniquement des chiffres.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`https://rct-backend.ipercom.io/api/Webrtc/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ ...formData, position: clientPosition })
      });

      if (!response.ok) {
        throw new Error(`Failed to add contact: ${response.statusText}`);
      }

      const newContact = await response.json();
      onAddContact(newContact);
      onHide();
    } catch (error) {
      console.error("Failed to add contact:", error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId="firstname">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleFormChange}
              isInvalid={!!errors.firstname}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstname}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="lastname">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleFormChange}
              isInvalid={!!errors.lastname}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastname}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="company">
            <Form.Label>Entreprise</Form.Label>
            <Form.Control
              type="text"
              name="company"
              value={formData?.company || ""}
              onChange={handleFormChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.company}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="clientPosition">
            <Form.Label>Fonction</Form.Label>
            <Form.Control
              as="select"
              value={clientPosition}
              onChange={e => setClientPosition(e.target.value)}
              required
            >
              <option value="">Sélectionner une fonction</option>
              <option value="PDG">PDG (Président-Directeur Général)</option>
              <option value="DG">DG (Directeur Général)</option>
              <option value="CTO">CTO (Directeur Technique)</option>
              <option value="CFO">CFO (Directeur Financier)</option>
              <option value="COO">COO (Directeur des Opérations)</option>
              <option value="Responsable">Responsable</option>
              <option value="Chef d'Équipe">Chef d'Équipe</option>
              <option value="ADV">ADV (Administration des Ventes)</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="phone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleFormChange}
              isInvalid={!!errors.phone}
              required
            />
            <Form.Text className="text-muted">Format : chiffres uniquement</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="mobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleFormChange}
              isInvalid={!!errors.mobile}
              required
            />
            <Form.Text className="text-muted">Format : chiffres uniquement</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.mobile}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleFormChange}
              isInvalid={!!errors.email}
              required
            />
            <Form.Text className="text-muted">Format : example@domaine.com</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-4">
            Ajouter
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddContactModal;
