import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Badge
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

interface DeviceData {
  id: number;
  name: string;
  brand: string;
  deviceModel: string;
  firmwareVersion: string;
  macAddress: string;
  machineId: string;
  privateIpAddress: string;
  publicIpAddress: string;
  location: string;
  deviceType: string;
  provisioningStatus: string;
  deviceStatus: string;
  createdAt: string;
}

const DeviceDetails: React.FC = () => {
  const { id } = useParams(); // Get device ID from URL params
  const navigate = useNavigate();
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDeviceData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Token not found. Please log in.");
        return;
      }

      try {
        const response = await axios.get(
          `https://rct-backend.ipercom.io/api/Ipbx/device/${id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`
            }
          }
        );
        setDeviceData(response.data);
      } catch (err) {
        setError("Error fetching device details.");
        console.error(err);
      }
    };

    fetchDeviceData();
  }, [id]);

  if (error) {
    return (
      <Container>
        <p>{error}</p>
      </Container>
    );
  }

  if (!deviceData) {
    return (
      <Container>
        <p>Loading device details...</p>
      </Container>
    );
  }

  const renderTooltip = (text: string) => <Tooltip>{text}</Tooltip>;

  return (
    <Container className="my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Button variant="primary" onClick={() => navigate(-1)}>
          ← Retour
        </Button>
        <h2>Détails de l'Appareil</h2>
        <div>
          <Button variant="warning" className="me-2">
            Modifier
          </Button>
          <Button variant="danger">Supprimer</Button>
        </div>
      </div>

      <Row>
        <Col lg={6}>
          <div className="bg-light p-4 rounded mb-4">
            <h5 className="mb-4">Informations Générales</h5>
            <Row>
              <Col md={6}>
                <strong>
                  Nom:
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Nom de l'appareil")}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2 text-info"
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                </strong>
                <p>{deviceData.name || "Non défini"}</p>
              </Col>
              <Col md={6}>
                <strong>
                  Constructeur:
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Constructeur de l'appareil")}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2 text-info"
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                </strong>
                <p>{deviceData.brand}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <strong>Modèle:</strong>
                <p>{deviceData.deviceModel || "Non défini"}</p>
              </Col>
              <Col md={6}>
                <strong>Version Firmware:</strong>
                <p>{deviceData.firmwareVersion || "Non défini"}</p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col lg={6}>
          <div className="bg-light p-4 rounded mb-4">
            <h5 className="mb-4">Réseau</h5>
            <Row>
              <Col md={6}>
                <strong>Adresse IP Privée:</strong>
                <p>{deviceData.privateIpAddress || "Non définie"}</p>
              </Col>
              <Col md={6}>
                <strong>Adresse IP Publique:</strong>
                <p>{deviceData.publicIpAddress || "Non définie"}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <strong>Adresse MAC:</strong>
                <p>{deviceData.macAddress}</p>
              </Col>
              <Col md={6}>
                <strong>ID Machine:</strong>
                <p>{deviceData.machineId || "Non défini"}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <div className="bg-light p-4 rounded mb-4">
            <h5 className="mb-4">Statut</h5>
            <Row>
              <Col md={6}>
                <strong>Type d'Appareil:</strong>
                <p>{deviceData.deviceType}</p>
              </Col>
              <Col md={6}>
                <strong>Statut de Provisioning:</strong>
                <Badge
                  bg={
                    deviceData.provisioningStatus === "Registered"
                      ? "success"
                      : "secondary"
                  }
                >
                  {deviceData.provisioningStatus}
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <strong>Statut de l'Appareil:</strong>
                <Badge
                  bg={
                    deviceData.deviceStatus === "Actif"
                      ? "success"
                      : "secondary"
                  }
                >
                  {deviceData.deviceStatus}
                </Badge>
              </Col>
              <Col md={6}>
                <strong>Date de Création:</strong>
                <p>{new Date(deviceData.createdAt).toLocaleDateString()}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DeviceDetails;
