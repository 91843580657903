import { faFileImport, faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState, useMemo } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import useVoicemail from "hooks/api/ipbx/useVoicemail";
import VoicemailTableAPI, { voicemailAPITableColumns } from "./VoicemailTableAPI";
import LeadsFilterModal from "components/modals/LeadsFilterModal";
import VoicemailForm from "./VoicemailForm";
import { ClientProvider } from "data/iper-x/context/ClientContext";
import useFinalClient from "hooks/api/ipbx/useFinalClient";
import useMsgVocal from "hooks/api/ipbx/useMsgVocal";
import { MsgVocalProvider } from "data/iper-x/context/MsgVocalContext";
import useVoicemailDataID from "hooks/api/ipbx/useVoicemailDataID";

const VoicemailListAPI = () => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId = ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;
  const { voicemailData, setVoicemailData, loading, error } = useVoicemailDataID(clientFinalId);
  const { clientData, loading: clientLoading, error: clientError } = useFinalClient();
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const { msgVocalData, loading: msgVocalLoading, error: msgVocalError } = useMsgVocal();
  const [openFilterModal, setOpenFilterModal] = useState(false);

  // Initialize advance table hook with filtered voicemail data
  const table = useAdvanceTable({
    data: voicemailData,
    columns: voicemailAPITableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <MsgVocalProvider msgVocalData={msgVocalData}>
      <ClientProvider clientData={clientData}>
        <div>
          <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
            <h2 className="mb-0">
              <span className="me-3">Répondeurs numériques</span>
            </h2>
          </div>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  className="w-300"
                  placeholder="Rechercher"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
              <Col xs="auto">
                <Button variant="primary" onClick={() => setShowAddClientModal(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter un répondeur numérique
                </Button>
                <LeadsFilterModal
                  show={openFilterModal}
                  handleClose={() => setOpenFilterModal(false)}
                />
              </Col>
            </Row>
          </div>
          {loading && <p>Chargement des données...</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
          {!loading && (
            <AdvanceTableProvider {...table}>
              <div
                className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                <VoicemailTableAPI />
              </div>
            </AdvanceTableProvider>
          )}
          <Modal show={showAddClientModal} onHide={() => setShowAddClientModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Ajouter un répondeur numérique</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <VoicemailForm
                tableData={voicemailData}
                setTableData={setVoicemailData}
                onClose={() => setShowAddClientModal(false)}
              />
            </Modal.Body>
          </Modal>
        </div>
      </ClientProvider>
    </MsgVocalProvider>
  );
};

export default VoicemailListAPI;
