import { faFileImport, faFileExport, faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/others/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import useRetailerLead from "hooks/api/retailer/useRetailerLead";
import LeadTableAPI, { leadAPITableColumns } from "./LeadTableAPI";
import LeadsFilterModal from "components/modals/LeadsFilterModal";
import AddLeadForm from "./AddLeadForm";

const LeadListAPI = () => {
  const { leadData, setLeadData, loading, error } = useRetailerLead();
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const table = useAdvanceTable({
    data: leadData,
    columns: leadAPITableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false,
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Leads</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
            {/*  <Button variant="link" className="text-900 me-4 px-0">*/}
            {/*    <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />*/}
            {/*    Importer*/}
            {/*  </Button>*/}
            {/*  <Button variant="link" className="text-900 me-4 px-0">*/}
            {/*    <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />*/}
            {/*    Export*/}
            {/*  </Button>*/}
            {/*  <Button variant="primary" onClick={() =>*/}
            {/*  setShowAddClientModal(true)}>*/}
            {/*  <FontAwesomeIcon icon={faPlus} className="me-2" />*/}
            {/*  Ajouter un lead*/}
            {/*</Button>*/}
            <LeadsFilterModal
              show={openFilterModal}
              handleClose={() => setOpenFilterModal(false)}
            />
            </Col>
          </Row>
        </div>
        {loading && <p>Chargement des données...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!loading && (
        <AdvanceTableProvider {...table}>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <LeadTableAPI />
          </div>
        </AdvanceTableProvider>
      )}
      <Modal show={showAddClientModal} onHide={() => setShowAddClientModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddLeadForm
            tableData={leadData}
            setTableData={setLeadData}
            onClose={() => setShowAddClientModal(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LeadListAPI;
