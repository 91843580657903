import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

interface Order {
  socid: string;
  date: string;
  items: OrderLine[];
}

interface OrderLine {
  fk_product: string;
  product_name: string;
  qty: string;
  subprice: string;
  tva_tx: string;
  licenses: LicenseClient[];
}

interface LicenseClient {
  id_license: string;
  license_key: string;
  client_final: ClientFinal | null;
}


interface ClientFinal {
  id: string;
  name: string;
  position: string;
  email: string;
  address: string;
}

interface Product {
  id: string;
  label: string;
  price: string;
  type: "product" | "service";
}

interface Category {
  id: string;
  label: string;
}

const OrderCreateClient: React.FC = () => {
  const navigate = useNavigate();
  const DOLID = localStorage.getItem("DOLID") || "";
  const companyID = localStorage.getItem("companyID") || "23";
  const userId = localStorage.getItem("userId") || "26";
  const token = localStorage.getItem("token") || "";

  const [order, setOrder] = useState<Order>({
    socid: DOLID,
    date: new Date().toISOString().split("T")[0],
    items: []
  });
  const [currentItem, setCurrentItem] = useState<OrderLine>({
    fk_product: "",
    product_name: "",
    qty: "1",
    subprice: "",
    tva_tx: "20",
    licenses: []
  });

  const [modalShow, setModalShow] = useState(false);
  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [family, setFamily] = useState<string>("IPERX");
  const [simultaneousCalls, setSimultaneousCalls] = useState<string>("4AS");
  const [years, setYears] = useState<string>("5");
  const [itemType, setItemType] = useState<"product" | "service">("service");
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  // State for clients
  const [clients, setClients] = useState<ClientFinal[]>([]);
  const [newClient, setNewClient] = useState<ClientFinal>({
    id: "",
    name: "",
    position: "",
    email: "",
    address: ""
  });
  const [creatingClient, setCreatingClient] = useState(false);

  const DOLAPIKEY = localStorage.getItem("DOLAPIKEY");

  useEffect(() => {
    fetchCategories();
    fetchClients(); // Fetch clients when component mounts
  }, []);

  useEffect(() => {
    filterItems();
  }, [family, simultaneousCalls, years, categories, itemType]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://erp.ipercom.io/api/index.php/categories",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          }
        }
      );
      const data = await response.json();
      setCategories(data.map((category: any) => ({ id: category.id, label: category.label })));
    } catch (error) {
      console.error("Erreur lors de la récupération des données categories:", error);
    }
  };

  const fetchProducts = async (categoryId: string) => {
    try {
      const response = await fetch(
        `https://erp.ipercom.io/api/index.php/products?sortfield=t.ref&sortorder=ASC&limit=100&mode=2&category=${categoryId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          }
        }
      );
      const data = await response.json();
      const fetchedProducts = data.map((product: any) => ({
        id: product.id,
        label: product.label,
        price: product.price,
        type: product.type
      }));
      setFilteredProducts(fetchedProducts);

      if (fetchedProducts.length === 1) {
        setCurrentItem((prevItem) => ({
          ...prevItem,
          fk_product: fetchedProducts[0].id,
          product_name: fetchedProducts[0].label,
          subprice: fetchedProducts[0].price
        }));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données products:", error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await fetch(
        "https://rct-backend.ipercom.io/api/Ipbx/clientfinal",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "text/plain"
          }
        }
      );
      const data = await response.json();
      setClients(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données clients:", error);
    }
  };

  const createClient = async () => {
    try {
      const response = await fetch(
        "https://rct-backend.ipercom.io/api/Ipbx/clientfinal",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "text/plain"
          },
          body: JSON.stringify({
            idRevendeur: 6,
            createdBy: userId,
            idInstance: 1,
            name: newClient.name,
            position: newClient.position,
            email: newClient.email,
            address: newClient.address
          })
        }
      );

      if (response.ok) {
        const createdClient = await response.json();
        setClients((prevClients) => [...prevClients, createdClient]);
        setCreatingClient(false); // Close the modal after creating client
        setNewClient({ id: "", name: "", position: "", email: "", address: "" });
      } else {
        console.error("Failed to create client");
      }
    } catch (error) {
      console.error("Error creating client:", error);
    }
  };

  const filterItems = () => {
    const categoryFilter = `${family}${simultaneousCalls}${years}`;
    const category = categories.find((cat) => cat.label.includes(categoryFilter));
    if (category) {
      fetchProducts(category.id);
    } else {
      setFilteredProducts([]);
      setCurrentItem((prevItem) => ({
        ...prevItem,
        fk_product: "",
        product_name: "",
        subprice: ""
      }));
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const { name, value } = e.target;
    if (index === undefined) {
      setOrder((prevOrder) => ({ ...prevOrder, [name]: value }));
    } else {
      const updatedItems = [...order.items];
      updatedItems[index] = { ...updatedItems[index], [name]: value };
      setOrder((prevOrder) => ({ ...prevOrder, items: updatedItems }));
    }

    if (name === "fk_product") {
      const selectedProduct = filteredProducts.find((product) => product.id === value);
      if (selectedProduct) {
        setCurrentItem((prevItem) => ({
          ...prevItem,
          fk_product: selectedProduct.id,
          product_name: selectedProduct.label,
          subprice: selectedProduct.price
        }));
      }
    } else {
      setCurrentItem((prevItem) => ({ ...prevItem, [name]: value }));
    }
  };

  const extractMaxUsers = (label: string): number => {
    const match = label.match(/(\d+)\s*utilisateurs/);
    return match ? parseInt(match[1], 10) : 0;
  };

  const generateLicenses = async (quantity: number) => {
    const maxUsers = extractMaxUsers(currentItem.product_name);

    const body = {
      createdBy: userId,
      idCompanies: companyID,
      idLicencesTypesHost: 1,
      erpThiersRef: DOLID,
      erpOrderRef: 0,
      erpProductRef: currentItem.fk_product,
      maxUsers: maxUsers,
      simultaneousCalls: parseInt(simultaneousCalls.replace("AS", "")),
      subDuration: parseInt(years),
      quantity: quantity
    };

    try {
      const response = await fetch("https://rct-backend.ipercom.io/api/Licence/gen_license", {
        method: "POST",
        headers: {
          Accept: "text/plain",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        setError("Erreur lors de la génération des licences.");
        return [];
      }

      const data = await response.json();

      // Initialize `client_final` for each license
      const licenses = data.map((license: any) => ({
        id_license: license.idLicences,
        license_key: license.key,
        client_final: { id: "", name: "", position: "", email: "", address: "" } // Initialize client_final
      }));

      return licenses;
    } catch (error) {
      console.error("Error generating licenses:", error);
      setError("Une erreur s'est produite lors de la génération des licences.");
      return [];
    }
  };

  const handleSaveClientData = () => {
    if (selectedLicenseIndex !== null && selectedLicenseIndex < currentItem.licenses.length) {
      // Créez une copie du tableau des licences
      const updatedLicenses = [...currentItem.licenses];

      // Vérifiez que la licence à l'index sélectionné existe
      if (!updatedLicenses[selectedLicenseIndex]) return;

      // Créez une copie de `client_final` de la licence pour la modifier
      const updatedClientFinal = {
        ...updatedLicenses[selectedLicenseIndex].client_final,
        ...newClient // Ici, vous pouvez ajouter les nouvelles infos du client final
      };

      // Mettez à jour la licence avec le nouveau client_final
      updatedLicenses[selectedLicenseIndex] = {
        ...updatedLicenses[selectedLicenseIndex],
        client_final: updatedClientFinal
      };

      // Mettez à jour l'élément courant avec les licences mises à jour
      setCurrentItem((prevItem) => ({
        ...prevItem,
        licenses: updatedLicenses
      }));

      // Mettez à jour la commande avec les éléments modifiés
      setOrder((prevOrder) => {
        const updatedItems = prevOrder.items.map((item) =>
          item.fk_product === currentItem.fk_product ? { ...currentItem, licenses: updatedLicenses } : item
        );
        return { ...prevOrder, items: updatedItems };
      });

      setSuccess("Client modifié avec succès.");
      handleCloseModal();
    } else {
      setError("L'index de licence sélectionné est invalide.");
    }
  };


  // const handleSaveClientData = async () => {
  //   console.log("handleSaveClientData triggered");
  //
  //   if (creatingClient) {
  //     console.log("Creating a new client...");
  //     // Create a new client using the API
  //     try {
  //       const response = await fetch(
  //         "https://rct-backend.ipercom.io/api/Ipbx/clientfinal",
  //         {
  //           method: "POST",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //             Accept: "text/plain",
  //           },
  //           body: JSON.stringify({
  //             idRevendeur: 6,
  //             createdBy: userId,
  //             idInstance: 1,
  //             name: newClient.name,
  //             position: newClient.position,
  //             email: newClient.email,
  //             address: newClient.address,
  //           }),
  //         }
  //       );
  //
  //       if (response.ok) {
  //         console.log("Client created successfully");
  //         const createdClient = await response.json();
  //         setClients((prevClients) => [...prevClients, createdClient]);
  //         setCreatingClient(false);
  //         setNewClient({ id: "", name: "", position: "", email: "", address: "" });
  //         setSuccess("Client créé avec succès.");
  //       } else {
  //         console.error("Failed to create client: ", response.statusText);
  //         setError("Échec de la création du client.");
  //       }
  //     } catch (error) {
  //       console.error("Error creating client:", error);
  //       setError("Erreur lors de la création du client.");
  //     }
  //   } else if (selectedLicenseIndex !== null && currentItem.licenses[selectedLicenseIndex]) {
  //     console.log("Modifying existing client...");
  //
  //     // Modify an existing client in a license
  //     const updatedLicenses = [...currentItem.licenses];
  //     updatedLicenses[selectedLicenseIndex] = {
  //       ...updatedLicenses[selectedLicenseIndex],
  //       client_final: {
  //         ...updatedLicenses[selectedLicenseIndex].client_final,
  //         name: newClient.name,
  //         position: newClient.position,
  //         email: newClient.email,
  //         address: newClient.address,
  //       },
  //     };
  //
  //     console.log("Updated licenses: ", updatedLicenses);
  //
  //     // Update current item with modified licenses
  //     const updatedCurrentItem = { ...currentItem, licenses: updatedLicenses };
  //     setCurrentItem(updatedCurrentItem);
  //
  //     // Update the order state to reflect changes in the license
  //     setOrder((prevOrder) => {
  //       const updatedItems = prevOrder.items.map((item) => {
  //         if (item.fk_product === updatedCurrentItem.fk_product) {
  //           return updatedCurrentItem;
  //         }
  //         return item;
  //       });
  //       return { ...prevOrder, items: updatedItems };
  //     });
  //
  //     console.log("Client data updated in order");
  //     setSuccess("Données du client modifiées avec succès.");
  //     handleCloseModal();
  //   } else {
  //     console.error("No valid client or license selected");
  //   }
  // };

  const handleClientFinalChange = (index: number, field: keyof ClientFinal, value: string) => {
    setCurrentItem((prevItem) => {
      // Create a copy of the current licenses
      const updatedLicenses = [...prevItem.licenses];

      // Check if the license exists at the given index
      if (!updatedLicenses[index]) return prevItem;

      // Create an updated license with fallback values for client_final properties
      const updatedLicense = {
        ...updatedLicenses[index],
        client_final: {
          ...updatedLicenses[index].client_final,
          [field]: value || "", // Fallback to an empty string if undefined
          id: updatedLicenses[index].client_final?.id || "", // Ensure id is always a string
          name: updatedLicenses[index].client_final?.name || "", // Ensure name is always a string
          position: updatedLicenses[index].client_final?.position || "", // Ensure position is always a string
          email: updatedLicenses[index].client_final?.email || "", // Ensure email is always a string
          address: updatedLicenses[index].client_final?.address || "" // Ensure address is always a string
        }
      };

      // Replace the modified license in the array
      updatedLicenses[index] = updatedLicense;

      // Return the updated item with modified licenses
      return {
        ...prevItem,
        licenses: updatedLicenses
      };
    });
  };


  const handleClientSelection = (licenseIndex: number, clientId: string) => {
    const selectedClient = clients.find((client) => client.id === clientId);
    if (selectedClient) {
      const updatedLicenses = [...currentItem.licenses];
      updatedLicenses[licenseIndex].client_final = selectedClient;
      setCurrentItem((prevItem) => ({ ...prevItem, licenses: updatedLicenses }));
    }
  };

  const addItemToOrder = async () => {

    // Initialize licenses if they are undefined
    if (!currentItem.licenses) {
      currentItem.licenses = [];
    }

    if (!currentItem.fk_product) {
      setError("Vous devez sélectionner un Produit/Service avant de l’ajouter.");
      return;
    }

    if (parseInt(currentItem.qty) <= 0) {
      setError("La quantité doit être supérieure à 0 pour ajouter un élément.");
      return;
    }

    setLoading(true);

    // Check if the product already exists in the order
    const existingProductIndex = order.items.findIndex((item) => item.fk_product === currentItem.fk_product);

    if (existingProductIndex !== -1) {
      // Product exists, increment the quantity and generate additional licenses if needed
      const existingProduct = order.items[existingProductIndex];
      const newQuantity = parseInt(existingProduct.qty) + parseInt(currentItem.qty);

      // Generate additional licenses for the new quantity
      const additionalLicenses = await generateLicenses(parseInt(currentItem.qty));
      const updatedLicenses = [...existingProduct.licenses, ...additionalLicenses];

      // Update the existing product in the order
      const updatedItems = [...order.items];
      updatedItems[existingProductIndex] = {
        ...existingProduct,
        qty: newQuantity.toString(),
        licenses: updatedLicenses
      };

      // Update the order state with the modified product
      setOrder((prevOrder) => ({
        ...prevOrder,
        items: updatedItems
      }));

      setSuccess(`Quantité mise à jour pour le produit ${existingProduct.product_name}.`);
    } else {
      // Product doesn't exist, add it to the order with its licenses
      const licenses = await generateLicenses(parseInt(currentItem.qty));

      if (licenses.length > 0) {
        setCurrentItem((prevItem) => ({
          ...prevItem,
          licenses: [...licenses]
        }));

        setOrder((prevOrder) => ({
          ...prevOrder,
          items: [...prevOrder.items, { ...currentItem, licenses }]
        }));

        setError(null);
        setSuccess("Service ou produit ajouté avec succès.");
      }
    }

    setLoading(false);
  };

  const removeItem = (index: number) => {
    setOrder((prevOrder) => {
      const updatedItems = prevOrder.items.filter((_, i) => i !== index);
      return {
        ...prevOrder,
        items: updatedItems
      };
    });
  };

  const removeLicense = (licenseIndex: number, productIndex: number) => {
    const updatedItems = [...order.items];

    // Get the current product's licenses
    const licenses = [...updatedItems[productIndex].licenses];

    // Remove the license at the given index
    licenses.splice(licenseIndex, 1);

    // If there are no licenses left, remove the product, otherwise update the product's licenses
    if (licenses.length === 0) {
      updatedItems.splice(productIndex, 1); // Remove the product if no licenses are left
    } else {
      updatedItems[productIndex] = {
        ...updatedItems[productIndex],
        licenses,
        qty: licenses.length.toString() // Update the quantity based on remaining licenses
      };
    }

    // Update the order with the modified licenses
    setOrder({ ...order, items: updatedItems });
  };


  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!acceptedTerms) {
      setError("Vous devez accepter les conditions générales de vente.");
      return;
    }

    if (order.items.length === 0) {
      setError("Vous devez ajouter au moins un élément avant de valider la commande.");
      return;
    }

    setLoading(true);

    setLoading(false);
    setSuccess("Commande créée avec succès.");
  };

  const handleShowModal = (index: number) => {
    if (index !== null && index < currentItem.licenses.length) {
      setSelectedLicenseIndex(index);
      setModalShow(true);
    } else {
      setError("Index de licence invalide.");
    }
  };


  const handleCloseModal = () => {
    setModalShow(false);
    setSelectedLicenseIndex(null); // Reset index when closing
  };

  return (
    <Container className="mt-5">
      <h2>Ajouter une commande</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={onSubmit}>

        <Card className="my-3">
          <Card.Header>
            <h5>Ajouter un élément</h5>
          </Card.Header>
          <Card.Body>
            <Form.Group controlId="itemType">
              <Form.Label>Type d'élément</Form.Label>
              <Form.Control
                as="select"
                name="itemType"
                value={itemType}
                onChange={(e) => setItemType(e.target.value as "product" | "service")}
              >
                <option value="service">Service</option>
                <option value="product">Produit</option>
              </Form.Control>
            </Form.Group>

            {itemType === "service" && (
              <>
                <Row>
                  <Col md={4}>
                    <Form.Group controlId="family">
                      <Form.Label>Famille</Form.Label>
                      <Form.Control
                        as="select"
                        name="family"
                        value={family}
                        onChange={(e) => setFamily(e.target.value)}
                      >
                        <option value="IPERX">IPERX</option>
                        <option value="IPERCLOUD" disabled>IPERCLOUD</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="simultaneousCalls">
                      <Form.Label>Appels Simultanés</Form.Label>
                      <Form.Control
                        as="select"
                        name="simultaneousCalls"
                        value={simultaneousCalls}
                        onChange={(e) => setSimultaneousCalls(e.target.value)}
                      >
                        {["4", "8", "16", "24", "32", "40", "48", "50", "100"].map((call) => (
                          <option key={call} value={`${call}AS`}>
                            {call} accès simultanés
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="years">
                      <Form.Label>Années</Form.Label>
                      <Form.Control
                        as="select"
                        name="years"
                        value={years}
                        onChange={(e) => setYears(e.target.value)}
                      >
                        <option value="1">1 an</option>
                        <option value="2">2 ans</option>
                        <option value="3">3 ans</option>
                        <option value="4">4 ans</option>
                        <option value="5">5 ans</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            <Form.Group controlId="fk_product">
              <Form.Label>Produit/Service</Form.Label>
              <Form.Control
                as="select"
                name="fk_product"
                value={currentItem.fk_product}
                onChange={(e) => handleChange(e)}
                required
                disabled
              >
                <option value="">Sélectionner un produit/service</option>
                {filteredProducts.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Row>
              <Col md={4}>
                <Form.Group controlId="qty">
                  <Form.Label>Quantité</Form.Label>
                  <Form.Control
                    type="number"
                    name="qty"
                    value={currentItem.qty}
                    onChange={(e) => {
                      const value = Math.max(0, Number(e.target.value));
                      setCurrentItem((prevItem) => ({ ...prevItem, qty: value.toString() }));
                    }}
                    min="0"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="subprice">
                  <Form.Label>Prix unitaire</Form.Label>
                  <Form.Control
                    type="number"
                    name="subprice"
                    value={parseFloat(currentItem.subprice).toFixed(2)}
                    onChange={(e) => handleChange(e)}
                    required
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="tva_tx">
                  <Form.Label>TVA (%)</Form.Label>
                  <Form.Control
                    type="number"
                    name="tva_tx"
                    value={currentItem.tva_tx}
                    onChange={(e) => handleChange(e)}
                    required
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="success" onClick={addItemToOrder} className="mt-3">
              Ajouter un service ou produit
            </Button>
          </Card.Body>
        </Card>

        {/* Product/Service Table */}
        <Card className="mb-3">
          <Card.Header>
            <h5>Services ou Produits ajoutés</h5>

          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
              <tr>
                <th>Ref</th>
                <th>Produit/Service</th>
                <th>Quantité</th>
                <th>Prix unitaire</th>
                <th>TVA (%)</th>
                <th>Total HT</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {order.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.fk_product}</td>
                  <td>{item.product_name}</td>
                  <td>{item.qty}</td>
                  <td>{parseFloat(item.subprice).toFixed(2)}</td>
                  <td>{item.tva_tx}</td>
                  <td>{(parseFloat(item.subprice) * parseFloat(item.qty)).toFixed(2)}</td>
                  <td>
                    <Button variant="danger" onClick={() => removeItem(index)}>
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        {/* License Table */}
        <Card className="mb-3">
          <Card.Header>
            <h5>Récapitulatif de vos Licences et attribution Clients </h5>
            {/*<span>*Les licences seront activées une fois la facturation effectuée.</span>*/}
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
              <tr>
                <th>Ref</th>
                <th>Licence</th>
                <th>Nom</th>
                <th>Role</th>
                <th>Email</th>
                <th>Adresse</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {order.items.map((item, productIndex) =>
                item.licenses?.map((license, licenseIndex) => (
                  <tr key={license.id_license}>
                    <td>{license.id_license}</td>
                    <td>{license.license_key || "N/A"}</td>
                    <td>{license.client_final?.name || "N/A"}</td>
                    <td>{license.client_final?.position || "N/A"}</td>
                    <td>{license.client_final?.email || "N/A"}</td>
                    <td>{license.client_final?.address || "N/A"}</td>
                    <td>
                      <Button variant="primary" onClick={() => handleShowModal(licenseIndex)}>
                        Attribuer un client
                      </Button>
                      <Button variant="danger" onClick={() => removeLicense(licenseIndex, productIndex)}>
                        Supprimer
                      </Button>
                    </td>
                  </tr>
                ))
              )}
              </tbody>


            </Table>
          </Card.Body>
        </Card>

        {/* Modal for creating/updating client */}
        <Modal show={modalShow} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {creatingClient ? "Créer un nouveau client final" : "Modifier le client final"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedLicenseIndex !== null && (
              <>
                {/* Client Name */}
                <Form.Group>
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentItem.licenses[selectedLicenseIndex]?.client_final?.name || ""}
                    onChange={(e) => handleClientFinalChange(selectedLicenseIndex, "name", e.target.value)}
                  />
                </Form.Group>

                {/* Client Position */}
                <Form.Group>
                  <Form.Label>Position</Form.Label>
                  <Form.Control
                    as="select"
                    value={currentItem.licenses[selectedLicenseIndex]?.client_final?.position || ""}
                    onChange={(e) => handleClientFinalChange(selectedLicenseIndex, "position", e.target.value)}
                  >
                    <option value="">Sélectionner un poste</option>
                    <option value="PDG">PDG (Président-Directeur Général)</option>
                    <option value="DG">DG (Directeur Général)</option>
                    <option value="CTO">CTO (Directeur Technique)</option>
                    <option value="CFO">CFO (Directeur Financier)</option>
                    <option value="COO">COO (Directeur des Opérations)</option>
                    <option value="Responsable">Responsable (Gestionnaire d'équipe ou de département)</option>
                    <option value="Chef d'Équipe">Chef d'Équipe (Superviseur d'équipe)</option>
                    <option value="ADV">ADV (Administration des Ventes)</option>
                  </Form.Control>
                </Form.Group>

                {/* Client Email */}
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={currentItem.licenses[selectedLicenseIndex]?.client_final?.email || ""}
                    onChange={(e) => handleClientFinalChange(selectedLicenseIndex, "email", e.target.value)}
                  />
                </Form.Group>

                {/* Client Address */}
                <Form.Group>
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentItem.licenses[selectedLicenseIndex]?.client_final?.address || ""}
                    onChange={(e) => handleClientFinalChange(selectedLicenseIndex, "address", e.target.value)}
                  />
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fermer
            </Button>
            <Button variant="primary" onClick={handleSaveClientData}>
              Enregistrer les modifications
            </Button>
          </Modal.Footer>
        </Modal>


        <Form.Group controlId="acceptedTerms">
          <Form.Check
            type="checkbox"
            label="Accepter les conditions générales de vente (Obligatoire)"
            checked={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.target.checked)}
            required
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="my-4"
          disabled={!acceptedTerms || loading || order.items.length === 0}
        >
          Valider
        </Button>
      </Form>
    </Container>
  );
};

export default OrderCreateClient;
