import alex from "assets/img/audio/alex.jpg";
import anne from "assets/img/audio/anne.png";
import kevin from "assets/img/audio/kevin.png";
import claudia from "assets/img/audio/claudia.png";
import colin from "assets/img/audio/colin.png";
import emma from "assets/img/audio/emma.png";
import francois from "assets/img/audio/francois.png";
import guy from "assets/img/audio/guy.png";
import jeff from "assets/img/audio/jeff.png";
import karina from "assets/img/audio/karina.png";
import katia from "assets/img/audio/katia.png";
import laurence from "assets/img/audio/laurence.png";
import margaret from "assets/img/audio/margaret.png";
import michelle from "assets/img/audio/michelle.png";
import sabine from "assets/img/audio/sabine.png";
import sarah from "assets/img/audio/sarah.png";
import valerie from "assets/img/audio/valerie.png";

interface AudioFile {
  name: string;
  languages?: string;
  type?: string;
  genre: string;
  src?: string;
  id: number;
  avatar: string;
  check: boolean;
}

export const audioFiles: AudioFile[] = [

  {
    name: "Pop rock 1",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock01.mp3",
    id: 1,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 2",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock02.mp3",
    id: 2,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 3",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock03.mp3",
    id: 3,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 4",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock04.mp3",
    id: 4,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 5",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock05.mp3",
    id: 5,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 6",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock06.mp3",
    id: 6,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 7",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock07.mp3",
    id: 7,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 8",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock08.mp3",
    id: 8,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 9",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock09.mp3",
    id: 9,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 10",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock10.mp3",
    id: 10,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 11",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock11.mp3",
    id: 11,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 12",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock12.mp3",
    id: 12,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 13",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock13.mp3",
    id: 13,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 14",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock14.mp3",
    id: 14,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 15",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock15.mp3",
    id: 15,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 16",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock16.mp3",
    id: 16,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 17",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock17.mp3",
    id: 17,
    avatar: "poprock1.jpg",
    check: false
  },
  {
    name: "Pop rock 18",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock18.mp3",
    id: 18,
    avatar: "poprock2.jpg",
    check: false
  },
  {
    name: "Pop rock 19",
    genre: "music",
    type: "Pop rock",
    src: "/audio/audioSong/poprock/poprock19.mp3",
    id: 19,
    avatar: "poprock2.jpg",
    check: false
  },

  {
    name: "Electro 1",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg01.mp3",
    id: 20,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 2",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg02.mp3",
    id: 21,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 3",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg03.mp3",
    id: 22,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 4",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg04.mp3",
    id: 23,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 5",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg05.mp3",
    id: 24,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 6",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg06.mp3",
    id: 25,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 7",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg07.mp3",
    id: 26,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 8",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg08.mp3",
    id: 27,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 9",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg09.mp3",
    id: 28,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 10",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg10.mp3",
    id: 29,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 11",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg11.mp3",
    id: 30,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 12",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg12.mp3",
    id: 31,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 13",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg13.mp3",
    id: 32,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 14",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg14.mp3",
    id: 33,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 15",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg15.mp3",
    id: 34,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 16",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg16.mp3",
    id: 35,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 17",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg17.mp3",
    id: 36,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 18",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg18.mp3",
    id: 37,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 19",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg19.mp3",
    id: 38,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 20",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg20.mp3",
    id: 39,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 21",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg21.mp3",
    id: 40,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 22",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg22.mp3",
    id: 41,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 23",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg23.mp3",
    id: 42,
    avatar: "electro1.jpg",
    check: false
  },
  {
    name: "Electro 24",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg24.mp3",
    id: 43,
    avatar: "electro2.jpg",
    check: false
  },
  {
    name: "Electro 25",
    genre: "music",
    type: "Electro",
    src: "/audio/audioSong/electro/leg25.mp3",
    id: 44,
    avatar: "electro2.jpg",
    check: false
  },

  {
    name: "Soft 1",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft01.mp3",
    id: 45,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 2",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft02.mp3",
    id: 46,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 3",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft03.mp3",
    id: 47,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 4",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft04.mp3",
    id: 48,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 5",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft05.mp3",
    id: 49,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 6",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft06.mp3",
    id: 50,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 7",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft07.mp3",
    id: 51,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 8",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft08.mp3",
    id: 52,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 9",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft09.mp3",
    id: 53,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 10",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft10.mp3",
    id: 54,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 11",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft11.mp3",
    id: 55,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 12",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft12.mp3",
    id: 56,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 13",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft13.mp3",
    id: 57,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 14",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft14.mp3",
    id: 58,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 15",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft15.mp3",
    id: 59,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 16",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft16.mp3",
    id: 60,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 17",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft17.mp3",
    id: 61,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 18",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft18.mp3",
    id: 62,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Soft 19",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft19.mp3",
    id: 63,
    avatar: "soft1.jpg",
    check: false
  },
  {
    name: "Soft 20",
    genre: "music",
    type: "Soft",
    src: "/audio/audioSong/soft/soft20.mp3",
    id: 64,
    avatar: "soft2.jpg",
    check: false
  },
  {
    name: "Alex",
    genre: "voice",
    src: "/audio/audioVoice/alex-espagne.mp3",
    id: 65,
    avatar: alex,
    languages: "espagnole",
    check: false
  },
  {
    name: "Anne",
    genre: "voice",
    src: "/audio/audioVoice/anne-france.mp3",
    id: 66,
    avatar: anne,
    languages: "française",
    check: false
  },
  {
    name: "Colin",
    genre: "voice",
    src: "/audio/audioVoice/colin-anglais.mp3",
    id: 67,
    avatar: colin,
    languages: "anglaise",
    check: false
  },
  {
    name: "Emma",
    genre: "voice",
    src: "/audio/audioVoice/emma-anglais.mp3",
    id: 68,
    avatar: emma,
    languages: "anglaise",
    check: false
  },
  {
    name: "Guy",
    genre: "voice",
    src: "/audio/audioVoice/guy-france.mp3",
    id: 69,
    avatar: guy,
    languages: "française",
    check: false
  },
  {
    name: "Jeff",
    genre: "voice",
    src: "/audio/audioVoice/jeff-france.mp3",
    id: 70,
    avatar: jeff,
    languages: "française",
    check: false
  },
  {
    name: "Karina",
    genre: "voice",
    src: "/audio/audioVoice/karina-italie.mp3",
    id: 71,
    avatar: karina,
    languages: "italienne",
    check: false
  },
  {
    name: "Katia",
    genre: "voice",
    src: "/audio/audioVoice/katia-france.mp3",
    id: 72,
    avatar: katia,
    languages: "française",
    check: false
  },
  {
    name: "Laurence",
    genre: "voice",
    src: "/audio/audioVoice/laurence-france.mp3",
    id: 73,
    avatar: laurence,
    languages: "française",
    check: false
  },
  {
    name: "Margaret",
    genre: "voice",
    src: "/audio/audioVoice/margaret-allemagne.mp3",
    id: 74,
    avatar: margaret,
    languages: "allemande",
    check: false
  },
  {
    name: "Michelle",
    genre: "voice",
    src: "/audio/audioVoice/michelle-anglais.mp3",
    id: 75,
    avatar: michelle,
    languages: "anglaise",
    check: false
  },
  {
    name: "Sabine",
    genre: "voice",
    src: "/audio/audioVoice/sabine-france.mp3",
    id: 76,
    avatar: sabine,
    languages: "française",
    check: false
  },
  {
    name: "Sarah",
    genre: "voice",
    src: "/audio/audioVoice/sarah-france.mp3",
    id: 78,
    avatar: sarah,
    languages: "française",
    check: false
  },
  {
    name: "Valerie",
    genre: "voice",
    src: "/audio/audioVoice/valerie-france.mp3",
    id: 79,
    avatar: valerie,
    languages: "française",
    check: false
  },
  {
    name: "Francois",
    genre: "voice",
    src: "/audio/audioVoice/francois-france.mp3",
    id: 80,
    avatar: francois,
    languages: "française",
    check: false
  },
  {
    name: "Kevin",
    genre: "voice",
    src: "/audio/audioVoice/christophe-france.mp3",
    id: 81,
    avatar: kevin,
    languages: "française",
    check: false
  },
  {
    name: "Claudia",
    genre: "voice",
    src: "/audio/audioVoice/claudia-francais-anglais.mp3",
    id: 82,
    avatar: claudia,
    languages: "anglaise",
    check: false
  }
];


export const getMessageExample = (messageType: string) => {
  switch (messageType) {
    case "Répondeur":
      return `Bonjour et merci de contacter [Nom de l’Entreprise].
                Vous êtes actuellement en train de laisser un message sur notre répondeur.
                Nous vous prions de bien vouloir laisser vos coordonnées complètes ainsi qu'une brève description de votre demande.
                Nous vous rappellerons dès que possible pendant nos horaires d’ouverture, du lundi au vendredi, de [heure d’ouverture] à [heure de fermeture].
                Merci et à bientôt chez [Nom de l’Entreprise].`;

    case "SVI":
      return `Bonjour et bienvenue chez [Nom de l’Entreprise]. Nous sommes ravis de vous accueillir.
                Pour être mis en relation avec notre service commercial, appuyez sur 1.
                Pour toute demande technique, appuyez sur 2.
                Si vous avez une question concernant la facturation ou les paiements, appuyez sur 3.
                Si vous souhaitez parler avec le service administratif, appuyez sur 4.
                Pour toute autre demande, appuyez sur 5 ou restez en ligne pour parler à un conseiller.
                Si vous appelez en dehors de nos heures d’ouverture, vous pouvez nous laisser un message avec vos coordonnées, et nous vous recontacterons dès que possible.
                Merci de votre appel et à très bientôt.`;

    case "Prédécroché":
      return `Bonjour, vous êtes bien en ligne avec [Nom de l’Entreprise].
                Un conseiller va bientôt prendre votre appel.
                Merci de patienter quelques instants pendant que nous vous connectons à un de nos experts.
                Votre appel est très important pour nous.`;

    case "Message d'attente":
      return `Merci de patienter, un de nos conseillers prendra votre appel dans les plus brefs délais.
                Nous vous remercions de votre patience et de votre compréhension.
                Pendant que vous attendez, nous vous invitons à découvrir nos services en visitant notre site web à l'adresse [URL du site web].`;

    default:
      return "Tapez votre message ici...";
  }
};
  