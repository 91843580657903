import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { SdaData } from "hooks/api/ipbx/useSda";
import usePhoneUserDataID from "hooks/api/ipbx/usePhoneUserDataID";
import useScheduleDataID from "hooks/api/ipbx/useScheduleDataID";
import useTrunkDataID from "hooks/api/ipbx/useTrunkDataID";
import useVoicemailDataID from "hooks/api/ipbx/useVoicemailDataID";
import useMsgVocalDataID from "hooks/api/ipbx/useMsgVocalDataID";
import useGroupDataID from "hooks/api/ipbx/useGroupDataID";
import useQueuedataID from "hooks/api/ipbx/useQueuedataID";

type SdaFormProps = {
  initialData?: SdaData;
  tableData: SdaData[];
  setTableData: React.Dispatch<React.SetStateAction<SdaData[]>>;
  onClose: () => void;
};

const SdaForm: React.FC<SdaFormProps> = ({
                                           initialData,
                                           tableData,
                                           setTableData,
                                           onClose
                                         }) => {
  const ipbxAccess = JSON.parse(localStorage.getItem("ipbx_access") || "[]");
  const clientFinalId =
    ipbxAccess.length > 0 ? ipbxAccess[0].id_client_final : null;

  // Chargement des données API pour les sélecteurs
  const { phoneUserData } = usePhoneUserDataID(clientFinalId);
  const { scheduleData } = useScheduleDataID(clientFinalId);
  const { trunkData } = useTrunkDataID(clientFinalId);
  const { voicemailData } = useVoicemailDataID(clientFinalId);
  const { msgVocalData } = useMsgVocalDataID(clientFinalId);
  const { groupData } = useGroupDataID(clientFinalId);
  const { queueData } = useQueuedataID(clientFinalId);

  // Initialisation de newSda avec initialData ou valeurs par défaut
  const [newSda, setNewSda] = useState<SdaData>(
    initialData || {
      id: 0,
      idClient: clientFinalId || 0,
      idSchedule: 0,
      name: "",
      sda: "",
      destination: "",
      destinationData: "",
      ringDuration: 20,
      idTrunkTransfert: 0,
      nrDestination: "",
      nrDestinationData: "",
      idNrTrunkTransfert: 0,
      state: true,
      priority: 1
    }
  );

  useEffect(() => {
    if (initialData) {
      setNewSda(initialData);
    }
  }, [initialData]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const isCheckbox = (e.target as HTMLInputElement).type === "checkbox";
    setNewSda(prevSda => ({
      ...prevSda,
      [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value
    }));
  };

  const calculateDurationInSeconds = (ringDuration: number) => {
    return (ringDuration || 0) * 0.75; // each ring represents ~0.75 seconds
  };

  const getSdaRegistrationArray = (
    registration: string | string[] | undefined
  ) => {
    if (typeof registration === "string") {
      return registration.split(",").map(item => item.trim());
    }
    return Array.isArray(registration) ? registration : [];
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found");
      return;
    }

    const method = initialData ? "PUT" : "POST";
    const url = `https://rct-backend.ipercom.io/api/Ipbx/sda`;

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(newSda)
      });

      if (response.status === 204) {
        // No content in the response, just update the table state
        setTableData(prevData => {
          if (initialData) {
            // Edit operation: update the existing item
            return prevData.map(item =>
              item.id === newSda.id ? { ...newSda } : item
            );
          } else {
            // Add operation: append the new item
            return [...prevData, newSda];
          }
        });
      } else if (response.ok) {
        const data: SdaData = await response.json();
        setTableData(prevData => {
          if (initialData) {
            return prevData.map(item => (item.id === data.id ? data : item));
          } else {
            return [...prevData, data];
          }
        });
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onClose(); // Close the form on successful submission
      window.location.reload();
    } catch (error) {
      console.error("Error submitting SDA form", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {/* Section 1: General Settings */}
      <h5>Paramètres Généraux</h5>
      <Row>
        <Col>
          <Form.Group controlId="state" className="align-items-center mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>Définit l'état actif ou inactif de la règle.</Tooltip>
              }
            >
              <Form.Label>
                État de la Règle{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <div className="d-flex align-items-center">
              <Form.Switch
                name="state"
                checked={newSda.state}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewSda({
                    ...newSda,
                    state: e.target.checked
                  })
                }
                className="me-2"
              />
              <span>{newSda.state ? "Actif" : "Inactif"}</span>
            </div>
          </Form.Group>

        </Col>
        <Col>

          <Form.Group controlId="priority" className="mb-3">
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip>
                  La priorité détermine l'ordre dans lequel les règles SDA sont appliquées.
                </Tooltip>
              }
            >
              <Form.Label>
                Priorité{" "}
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="number"
              name="priority"
              value={newSda.priority}
              min={1}
              onChange={handleChange}
              required
            />
            <Form.Text className="text-muted">
              Plus la priorité est basse, plus la règle sera appliquée en premier.
            </Form.Text>
          </Form.Group>

        </Col>
      </Row>

      <Form.Group controlId="name" className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Nom unique pour identifier la règle SDA, ex: "Standard
              entreprise".
            </Tooltip>
          }
        >
          <Form.Label>
            Nom de la Règle{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          type="text"
          name="name"
          value={newSda.name}
          onChange={
            handleChange as React.ChangeEventHandler<
              HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
            >
          }
          required
        />
      </Form.Group>

      {/* Section 2: SDA Selection */}
      <h5>Sélection du numéro SDA</h5>
      <Form.Group controlId="sda" className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Numéro SDA au format international (ex: 33518222200)
            </Tooltip>
          }
        >
          <Form.Label>
            Numéro SDA attribué à l'utilisateur{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          as="select"
          name="sda"
          value={newSda.sda}
          onChange={
            handleChange as React.ChangeEventHandler<
              HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
            >
          }
          required
          className="form-select selectpicker"
        >
          <option value="">Sélectionner un SDA</option>
          {trunkData.map(sda => (
            <optgroup key={sda.id} label={sda.name}>
              {getSdaRegistrationArray(sda.sdaRegistration).map(
                (registration, index) => (
                  <option key={`${sda.id}-${index}`} value={registration}>
                    {registration}
                  </option>
                )
              )}
            </optgroup>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Section 3: Schedule and Call Destinations */}
      <h5>Configuration des horaires et destination d'appels</h5>
      <Form.Group controlId="idSchedule" className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Sélectionner l'horaire pour appliquer cette règle.
            </Tooltip>
          }
        >
          <Form.Label>
            Horaires de Bureau{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          as="select"
          name="idSchedule"
          className="form-select"
          value={newSda.idSchedule}
          onChange={
            handleChange as React.ChangeEventHandler<
              HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
            >
          }
          required
        >
          <option value="">Sélectionner un horaire</option>
          {scheduleData.map(schedule => (
            <option key={schedule.id} value={schedule.id}>
              {schedule.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="destination" className="mb-3">
        <Form.Label>
          Destination Principale
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-destination">
                <ul style={{ paddingLeft: "1rem", margin: 0 }}>
                  <li>
                    <strong>Poste interne</strong> : Sélectionne un poste
                    interne de l'entreprise.
                  </li>
                  <li>
                    <strong>File d'attente</strong> : Dirige vers une file
                    d'attente pour gérer les appels en attente.
                  </li>
                  <li>
                    <strong>Répondeur numérique</strong> : Envoie l'appel au
                    répondeur pour laisser un message vocal.
                  </li>
                  <li>
                    <strong>Messagerie</strong> : Joue un message vocal sans
                    possibilité de laisser un message.
                  </li>
                  <li>
                    <strong>Transfert exterieur</strong> : Transfère l'appel
                    vers un numéro externe.
                  </li>
                  <li>
                    <strong>Groupe d'appels</strong> : Dirige l'appel vers un
                    groupe d'appels spécifique.
                  </li>
                </ul>
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          as="select"
          className="form-select"
          name="destination"
          value={newSda.destination}
          onChange={handleChange}
          required
        >
          <option value="">Sélectionner une destination principale</option>
          <option value="post_interne">Poste interne</option>
          <option value="queue">File d'attente</option>
          <option value="voicemail">
            Répondeur numérique (messages vocaux autorisés)
          </option>
          <option value="messageVocal">
            Messagerie (messages vocaux non autorisés)
          </option>
          <option value="transfer_exterieur">Transfert exterieur</option>
          <option value="groupe">Groupe d'appels</option>
        </Form.Control>
      </Form.Group>

      {/* Destination Detail Selection */}
      {newSda.destination && (
        <Form.Group controlId="destinationData" className="mb-3">
          <Form.Label>
            {(() => {
              switch (newSda.destination) {
                case "post_interne":
                  return "Extension et Nom du poste interne";
                case "voicemail":
                  return "Nom du répondeur numérique";
                case "queue":
                  return "Nom de la file d'attente";
                case "messageVocal":
                  return "Nom de l'audio";
                case "groupe":
                  return "Nom du groupe d'appels";
                case "transfer_exterieur":
                  return "Numéro externe";
                default:
                  return "Destination inconnue";
              }
            })()}
          </Form.Label>

          {newSda.destination === "transfer_exterieur" ? (
            <>
              <Form.Label>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      Numéro externe au format international (EX : 33518222200)
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ cursor: "pointer", marginLeft: "4px" }}
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                name="destinationData"
                value={newSda.destinationData}
                onChange={handleChange}
              />
            </>
          ) : (
            <Form.Control
              as="select"
              className="form-select mb-3"
              name="destinationData"
              value={newSda.destinationData}
              // value={newSda.destinationData || (msgVocalData.length > 0 ? msgVocalData[0].id : "14")}
              onChange={handleChange}
            >
              {(() => {
                switch (newSda.destination) {
                  case "post_interne":
                    return (
                      <>
                        <option value="0">Sélectionner un poste interne</option>
                        {phoneUserData.length > 0 ? (
                          phoneUserData.map(user => (
                            <option key={user.id} value={user.extension}>
                              {user.extension} ({user.firstname} {user.lastname}
                              )
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "voicemail":
                    return (
                      <>
                        <option value="0">Sélectionner une boîte vocale</option>
                        {voicemailData.length > 0 ? (
                          voicemailData.map(voicemail => (
                            <option key={voicemail.id} value={voicemail.id}>
                              {voicemail.nameVoicemail}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "queue":
                    return (
                      <>
                        <option value="0">
                          Sélectionner une file d'attente
                        </option>
                        {queueData.length > 0 ? (
                          queueData.map(queue => (
                            <option key={queue.id} value={queue.id}>
                              {queue.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "messageVocal":
                    return (
                      <>
                        <option value="0">Sélectionner un message vocal</option>
                        {msgVocalData.length > 0 ? (
                          msgVocalData.map(msg => (
                            <option key={msg.id} value={msg.id}>
                              {msg.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "groupe":
                    return (
                      <>
                        <option value="0">Sélectionner un groupe</option>
                        {groupData.length > 0 ? (
                          groupData.map(group => (
                            <option key={group.id} value={group.id}>
                              {group.nameGroup}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              })()}
            </Form.Control>
          )}
        </Form.Group>
      )}

      {newSda.destination === "transfer_exterieur" && (
        <Form.Group controlId="idTrunkTransfert" className="mb-3">
          <Form.Label>Trunk de transfert</Form.Label>
          {trunkData.length === 1 ? (
            <Form.Control
              type="text"
              name="idTrunkTransfert"
              value={trunkData[0].id}
              readOnly
            />
          ) : (
            <Form.Control
              as="select"
              className="form-select mb-3"
              name="idTrunkTransfert"
              value={newSda.idTrunkTransfert}
              onChange={handleChange}
            >
              <option value="">Sélectionner un trunk de transfert</option>
              {trunkData.length > 0 ? (
                trunkData.map(trunk => (
                  <option key={trunk.id} value={trunk.id}>
                    {trunk.name}
                  </option>
                ))
              ) : (
                <option disabled>Aucune donnée</option>
              )}
            </Form.Control>
          )}
        </Form.Group>
      )}

      {/* Section 4: Secondary Call Destination */}
      <h5>Destination Secondaire</h5>
      <Form.Group controlId="nrDestination" className="mb-3">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>
              Sélectionner une destination de secours en cas de non-réponse.
            </Tooltip>
          }
        >
          <Form.Label>
            Destination sur Non-Réponse{" "}
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </Form.Label>
        </OverlayTrigger>
        <Form.Control
          as="select"
          className="form-select"
          name="nrDestination"
          value={newSda.nrDestination}
          onChange={handleChange}
          // required
        >
          <option value="">Sélectionner une destination sur non réponse</option>
          <option value="post_interne">Poste interne</option>
          <option value="queue">File d'attente</option>
          <option value="voicemail">
            Répondeur numérique (messages vocaux autorisés)
          </option>
          <option value="messageVocal">
            Messagerie (messages vocaux non autorisés)
          </option>
          <option value="transfer_exterieur">Transfert exterieur</option>
          <option value="groupe">Groupe d'appels</option>
        </Form.Control>
      </Form.Group>

      {newSda.nrDestination && (
        <Form.Group controlId="nrDestinationData">
          <Form.Label>
            {(() => {
              switch (newSda.nrDestination) {
                case "post_interne":
                  return "Extension et Nom du poste interne";
                case "voicemail":
                  return "Nom du répondeur numérique";
                case "queue":
                  return "Nom de la file d'attente";
                case "messageVocal":
                  return "Nom de l'audio";
                case "groupe":
                  return "Nom du groupe d'appels";
                case "transfer_exterieur":
                  return "Numéro externe";
                default:
                  return "Destination inconnue";
              }
            })()}
          </Form.Label>
          {newSda.nrDestination === "transfer_exterieur" ? (
            <>
              <Form.Label>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      Numéro externe au format international (EX : 33518222200)
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ cursor: "pointer", marginLeft: "4px" }}
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                name="nrDestinationData"
                value={newSda.nrDestinationData}
                onChange={handleChange}
              />
            </>
          ) : (
            <Form.Control
              as="select"
              className="form-select"
              name="nrDestinationData"
              value={newSda.nrDestinationData}
              onChange={handleChange}
            >
              {(() => {
                switch (newSda.nrDestination) {
                  case "post_interne":
                    return (
                      <>
                        <option value="0">Sélectionner un poste interne</option>
                        {phoneUserData.length > 0 ? (
                          phoneUserData.map(user => (
                            <option key={user.id} value={user.extension}>
                              {user.extension} ({user.firstname} {user.lastname}
                              )
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "voicemail":
                    return (
                      <>
                        <option value="0">Sélectionner une boîte vocale</option>
                        {voicemailData.length > 0 ? (
                          voicemailData.map(voicemail => (
                            <option key={voicemail.id} value={voicemail.id}>
                              {voicemail.nameVoicemail}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "queue":
                    return (
                      <>
                        <option value="0">
                          Sélectionner une file d'attente
                        </option>
                        {queueData.length > 0 ? (
                          queueData.map(queue => (
                            <option key={queue.id} value={queue.id}>
                              {queue.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "messageVocal":
                    return (
                      <>
                        <option value="0">Sélectionner un message vocal</option>
                        {msgVocalData.length > 0 ? (
                          msgVocalData.map(msg => (
                            <option key={msg.id} value={msg.id}>
                              {msg.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  case "groupe":
                    return (
                      <>
                        <option value="0">Sélectionner un groupe</option>
                        {groupData.length > 0 ? (
                          groupData.map(group => (
                            <option key={group.id} value={group.id}>
                              {group.nameGroup}
                            </option>
                          ))
                        ) : (
                          <option disabled>Aucune donnée</option>
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              })()}
            </Form.Control>
          )}
        </Form.Group>
      )}
      {newSda.nrDestination === "transfer_exterieur" && (
        <Form.Group controlId="idNrTrunkTransfert">
          <Form.Label>Trunk de transfert sur non réponse</Form.Label>
          {trunkData.length === 1 ? (
            <Form.Control
              type="text"
              name="idNrTrunkTransfert"
              value={trunkData[0].id}
              readOnly
            />
          ) : (
            <Form.Control
              as="select"
              className="form-select"
              name="idNrTrunkTransfert"
              value={newSda.idNrTrunkTransfert}
              onChange={handleChange}
            >
              <option value="">
                Sélectionner un trunk de transfert sur non réponse
              </option>
              {trunkData.length > 0 ? (
                trunkData.map(trunk => (
                  <option key={trunk.id} value={trunk.id}>
                    {trunk.name}
                  </option>
                ))
              ) : (
                <option disabled>Aucune donnée</option>
              )}
            </Form.Control>
          )}
        </Form.Group>
      )}

      <Form.Group controlId="ringDuration">
        <Form.Label>
          Nombre de sonneries
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                Entrez le nombre de sonneries (1 sonnerie correspond à environ
                3/4 secondes)
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              icon={faCircleInfo}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            />
          </OverlayTrigger>
        </Form.Label>

        <Form.Control
          type="number"
          name="ringDuration"
          value={newSda.ringDuration}
          onChange={handleChange}
          min={3}
        />

        <Form.Text className="text-muted">
          Durée estimée: {calculateDurationInSeconds(newSda.ringDuration)}{" "}
          secondes
        </Form.Text>
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-2">
        {initialData ? "Enregistrer les modifications" : "Ajouter"}
      </Button>
    </Form>
  );
};

export default SdaForm;
