import { useState, useEffect, useCallback } from "react";

export interface ServerConfigData {
  id: number;
  name: string;
  publicIp: string;
  privateIp: string;
  subdomain: string;
  servDbAsterisk: string;
  nameDbAsterisk: string;
  usernameDbAsterisk: string;
  passwordDbAsterisk: string;
  ariUsername: string;
  ariPassword: string;
  portSip: number;
  portWebrtc: number;
  displayNumber: string;
  emailCertificat: string;
  installation: number;
  assigne: number;
}


/**
 * **useServerConfig**:
 * A custom hook for managing server configuration data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @returns {Object} - The server configuration data, loading state, errors, and a setter for the data.
 *
 * @property {ServerConfigData[]} serverConfigData - The data retrieved from the API or a fallback value.
 * @property {Function} setServerConfigData - Function to manually set server configuration data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { serverConfigData, loading, error } = useServerConfig();
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <ServerConfigList data={serverConfigData} />;
 */

const useServerConfig = () => {
  const [serverConfigData, setServerConfigData] = useState<ServerConfigData[]>([]);
  const [serverConfigLoading, setServerConfigLoading] = useState(true);
  const [serverConfigError, setServerConfigError] = useState<string | null>(null);

  const fetchServerConfigs = useCallback(async () => {
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Token not found");
      }

      // Fetch data from the API
      const response = await fetch("https://rct-backend.ipercom.io/api/Ipbx/server", {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}` // Add the token to the headers
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setServerConfigData(data);
      setServerConfigError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données API data", error);
      setServerConfigError("Impossible de récupérer les données data.");
    } finally {
      setServerConfigLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchServerConfigs();
  }, [fetchServerConfigs]);

  return {
    serverConfigData,
    setServerConfigData,
    serverConfigLoading,
    serverConfigError
  };
};

export default useServerConfig;
