import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const DevelopmentPage = () => {
  return (
    <div>
      <div className="px-3">
        <Row className="min-vh-100 flex-center p-5">
          <Col xs={12} xl={10} xxl={8}>
            <Row className="justify-content-center align-items-center g-5">
              <Col xs={12} className="text-center">
                <h2 className="text-800 fw-bolder mb-3">
                  En Cours de Développement !
                </h2>
                <p className="text-900 mb-5">
                  Cette page est actuellement en cours de développement.
                  <br />
                  Merci de votre patience.
                </p>
                <Link to="/" className="btn btn-primary">
                  Retour à l'accueil
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DevelopmentPage;
