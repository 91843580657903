import React from 'react';
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

const ParamEmailTemplatesConfig = () => {
  return (
    <>
      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Sélectionner un modèle à modifier
              </h3>

              <Form.Group className="mb-3" controlId="formServeurSMTP">
                <Form.Label>Sélectionner un modèle à modifier</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Serveur SMTP">
                  <Form.Select aria-label="Floating label select example">
                    <option>Antihacking IP Blacklisted</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formServeurSMTP">
                <Form.Label>De (Nom affiché)</Form.Label>
                <FloatingLabel controlId="floatingSelect" label="Serveur SMTP">
                  <Form.Select aria-label="Floating label select example">
                    <option>--</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Label>Modèle Email</Form.Label>
              <Col xs={12} className="gy-6 mb-5">
                <FloatingLabel
                  controlId="floatingProjectOverview"
                  label="Modèle"
                >
                  <Form.Control
                    as="textarea"
                    value="
                    L'IP %%IPADDRESS%% sur le PBX %%SIPDOMAIN%% a été blacklistée et expirera le : %%EXPIREDATE%%
Module affecté : %%SOURCE%%
Agent utilisateur : %%USERAGENT%%

Raison : Le taux de requêtes est trop haut !

Cette adresse IP %%IPADDRESS%% a fait de nombreuses tentatives d'authentification à Iper-x avec des indentifiants invalides. Par conséquent, Iper-x a créé une règle de liste noire empêchant cette IP de continuer à envoyer des requêtes.

Vous n'avez rien besoin de faire.

Si vous souhaitez vérifier, modifier ou supprimer la règle, vous pouvez le faire depuis la console d'administration Iper-x > Dashboard > IPs en liste noire.

Pour plus d'informations : https://www.Iper-x.com/docs/allow-deny-ip-addresses/
"
                    style={{ height: '240px' }}
                  />
                </FloatingLabel>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ParamEmailTemplatesConfig;
