import { useState, useEffect, useCallback } from "react";

// Define the structure for SdaData
export interface SdaData {
  id: number;
  idClient: number;
  name: string;
  sda: string;
  idSchedule: number;
  destination: string;
  destinationData: string;
  ringDuration: number;
  idTrunkTransfert: number;
  nrDestination: string;
  nrDestinationData: string;
  idNrTrunkTransfert: number;
  state: boolean;
}

/**
 * **useSdaDataID**:
 * A custom hook for managing SDA data.
 * It handles loading and error states and retrieves data from an external API.
 *
 * @param {number} id - The client ID to fetch SDA data for.
 * @returns {Object} - The SDA data, loading state, errors, and a setter for the data.
 *
 * @property {SdaData[]} sdaData - The data retrieved from the API or a fallback value.
 * @property {Function} setSdaData - Function to manually set SDA data.
 * @property {boolean} loading - Indicates if the data is loading.
 * @property {string | null} error - The error message, or null if no error occurred.
 *
 * @example
 * const { sdaData, loading, error } = useSdaDataID(1);
 * if (loading) {
 *   return <p>Loading...</p>;
 * }
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * return <SdaList data={sdaData} />;
 */
const useSdaDataID = (id: number) => {
  const [sdaData, setSdaData] = useState<SdaData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSdaData = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await fetch(`https://rct-backend.ipercom.io/api/Ipbx/sda/client/${id}`, {
        method: "GET",
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP Error! status: ${response.status}`);
      }

      const data = await response.json();
      setSdaData(data);
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la récupération des données SDA data", error);
      setError("Impossible de récupérer les données SDA data.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchSdaData();
  }, [fetchSdaData]);

  return {
    sdaData,
    setSdaData,
    loading,
    error
  };
};

export default useSdaDataID;
