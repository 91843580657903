import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import Badge from "components/base/Badge";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { UilDesktopAlt, UilRocket } from "@iconscout/react-unicons";
import Unicon from "../../../../components/base/Unicon";
import { Card, Dropdown, Form, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/base/Button";
import { EditorLeadDataType } from "data/iper-x/editor/editorLeadsTableData";

const isRTL = "";

export const editorLeadsTableColumns: ColumnDef<EditorLeadDataType>[] = [
  {
    accessorKey: "prospect",
    header: "Prospect",
    cell: ({ row: { original } }) => {
      const {
        name,
        date,
        designation,

      } = original.prospect;
      return (
        <div className="d-flex align-items-center">

          <div>
            <Link to={`/portail-editeur/leads/${name}`} className="text-decoration-none fw-bold fs-8">
              {name}
            </Link>
            <div className="d-flex align-items-center">
              <p className="mb-0 text-1000 fw-semi-bold fs-9 me-2">
                {designation} {date}
              </p>

            </div>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "20%" }, className: "ps-4 pe-5 border-end" },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },

  {
    accessorKey: "contact",
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-warning-100 rounded me-2">
            <FeatherIcon
              icon="user"
              size={16}
              className="text-warning-600 dark__text-warning-300"
            />
          </div>
          <span>Revendeur/Contact</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const {
        avatar,
        name
      } = original.contact;
      return (
        <>
          <div className="d-flex align-items-center">
            {avatar && (
              <Link to={`/portail-editeur/revendeur/${name}`}>
                <Avatar src={avatar} size="xl" className="me-3" />
              </Link>
            )}
            <div>
              {name ? (
                <>
                  <span className="">GEKO</span> <br />
                  <Link to={`/portail-editeur/revendeur/${name}`} className="fs-8">
                    {name}
                  </Link>
                </>
              ) : (
                <>
                  <Dropdown align={isRTL ? "end" : "start"}>
                    <Dropdown.Toggle
                      variant="link"
                      className="text-800 text-decoration-none dropdown-caret-none p-0 fs-8 fw-normal"
                    >

                      <div className="d-flex align-items-center">
                        <Link to={`/portail-editeur/revendeur/${name}`}>
                          <Avatar src={avatar} size="xl" className="me-3" />
                        </Link>

                        <span className="badge-phoenix badge-phoenix-primary badge">
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="primary dark__text-400 me-1"
                            transform="grow-4 down-1"
                          />
                          Assigner
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="text-800 fs-9 ms-2"
                          />
                        </span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="shadow-sm"
                      style={{ minWidth: "20rem" }}
                    >
                      <Card className="position-relative border-0">
                        <Card.Body className="p-0">
                          <div className="mx-3">
                            <h4 className="mb-3 fw-bold">Selection du contact</h4>

                            <Form.Select className="mb-3">
                              <option value="">Select</option>
                              <option value="1">Jerry Seinfield</option>
                              <option value="2">Anthoney Michael</option>
                              <option value="3">Ansolo Lazinatov</option>
                            </Form.Select>
                            <div className="text-end">
                              <Button variant="link" className="text-danger">
                                Cancel
                              </Button>
                              <Button
                                size="sm"
                                variant="primary"
                                className="px-5"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </>
      );

    },
    meta: {
      headerProps: { style: { width: "20%" }, className: "ps-4 pe-5 border-end" },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },

  {
    accessorKey: "devices_number",
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <Unicon
              icon={UilDesktopAlt}
              className="text-primary-600 dark__text-primary-300"
              size={16}
            />
          </div>
          <span>Nombre de poste</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => original.devices_number,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },

  {
    accessorKey: "access_number",
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <Unicon
              icon={UilRocket}
              className="text-primary-600 dark__text-primary-300"
              size={16}
            />

          </div>
          <span>Nombre d’accès</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => original.access_number,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },

  {
    id: "progress",
    header: "Progress",
    cell: ({ row: { original } }) => {
      const { progress } = original;

      return (
        <>
          <p className="text-800 fs-10 mb-0">
            {progress.min} / {progress.max}
          </p>
          <ProgressBar
            now={(progress.min / progress.max) * 100}
            style={{ height: 3 }}
            variant="success"
          />
        </>
      );
    },
    meta: {
      cellProps: { className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000" },
      headerProps: { style: { width: "5%" }, className: "ps-4 pe-5 border-end" }
    }
  },

  {
    id: "statuss",
    header: "Status",
    cell: ({ row: { original } }) => {
      const { status } = original;

      return (
        <Badge variant="phoenix" bg={status.type}>
          {status.label}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000" },
      headerProps: { style: { width: "5%" }, className: "ps-4 pe-5 border-end" }
    }
  },

  {
    accessorKey: 'date',
    header: "Date de création",
    cell: ({ row: { original } }) => original.date,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 text-700'
      }
    }
  },

  {
    id: 'leadDropdown',
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  },

];

const RetailerLeadsTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default RetailerLeadsTable;
