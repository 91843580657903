import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { Devices } from "../../../../data/iper-x/ipbx/devices";
import ActionDropdownItems from "../../../../components/common/ActionDropdownItems";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import DevicesDropdownItems from "../../../../components/common/DevicesDropdownItems";
import RevealDropdown, {
  RevealDropdownTrigger
} from "components/base/RevealDropdown";
import React from "react";

import { subMinutes } from "date-fns";
import { PhoneDevicesData } from "../../../../hooks/api/ipbx/usePhoneDevicesDataID";

export const devicesTableColumns: ColumnDef<PhoneDevicesData>[] = [

  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "6%", fontSize: "11px" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => {
      const {
        name = "Nom non défini",
        privateIpAddress,
        publicIpAddress,
        deviceStatus,
        id
      } = row.original;

      // Determine status color and text based on deviceStatus

      const statusText = deviceStatus || "Statut inconnu";

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Indicator with Tooltip for device status */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {statusText}
              </Tooltip>
            }
          >
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              // backgroundColor: statusColor,
              marginRight: "8px",
              cursor: "pointer"
            }}
          ></span>
          </OverlayTrigger>

          {/* Name with Tooltip for IPs and Provisioning Status */}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <div>
                  <strong>IP Privée:</strong> {privateIpAddress || "Non définie"}
                  <br />
                  <strong>IP Publique:</strong> {publicIpAddress || "Non définie"}
                </div>
              </Tooltip>
            }
          >
            <a
              href={`/ipbx/téléphones/details/${id}`}
              className="text-primary"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              {name}
            </a>
          </OverlayTrigger>
        </div>
      );
    }
  }


  ,


  {
    accessorKey: "brandModel",
    header: "Constructeur / Modèle",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <>
        <div>Constructeur: {row.original.brand}</div>
        <div>Modèle: {row.original.deviceModel}</div>
        <div>Version du firmware: {row.original.firmwareVersion}</div>
      </>
    )
  },

  {
    accessorKey: "macAddressMachineId",
    header: "MAC / Machine ID",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    },
    cell: ({ row }) => (
      <>
        <div>{row.original.macAddress}</div>
        <div>{row.original.machineId}</div>
      </>
    )
  },

  {
    accessorKey: "location",
    header: "Location",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "deviceType",
    header: "Type de dispositif",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "userProvisioning",
    header: "Utilisateur & Statut",
    cell: ({ row: { original } }) => {
      const { id, provisioningStatus } = original;

      // Determine avatar border color based on provisioning status
      const borderColor =
        provisioningStatus === "Registered"
          ? "green"
          : provisioningStatus === "failed"
            ? "red"
            : "gray"; // Default for other statuses

      // Generate avatar URL
      const avatarUrl = `https://ui-avatars.com/api/?name=test&rounded=true&size=32`;

      return (
        <div className="d-flex align-items-center">
          {/* Avatar */}
          <img
            src={avatarUrl}
            // alt={`${firstname} ${lastname}`}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              marginRight: "10px",
              border: `2px solid ${borderColor}`
            }}
          />

          {/* User Details */}
          <div className="d-flex flex-column">
            <a
              href={`utilisateurs/details/${id}`}
              className="fw-bold text-dark text-decoration-none"
            >
              {/*{firstname} {lastname}*/}
            </a>

            {/* Provisioning Status */}
            <small
              className={`fw-bold ${
                provisioningStatus === "Registered"
                  ? "text-success"
                  : provisioningStatus === "failed"
                    ? "text-danger"
                    : "text-muted"
              }`}
            >
              {provisioningStatus || "Statut inconnu"}
            </small>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: "12%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  }
  ,

  // {
  //   accessorKey: 'createdAt',
  //   header: 'Créé le',
  //   meta: {
  //     headerProps: { style: { width: '10%' } },
  //     cellProps: { className: 'text-900 fs-9' }
  //   },
  //   cell: ({ row }) => new Date(row.getValue('createdAt')).toLocaleDateString()
  // },
  {
    id: 'leadDropdown',
    header: "Gérer",
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: "5%" } },
      cellProps: { className: "text-end pe-0 ps-4" }
    }
  }
];

const DevicesTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default DevicesTable;
