import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

type SortableItemProps = {
  id: string;
  name: string;
  onDelete: (id: string) => void;
};

const SortableItem: React.FC<SortableItemProps> = ({ id, name, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(id);
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="list-group-item d-flex justify-content-between align-items-center"
    >
      {name}
      <FontAwesomeIcon
        icon={faTrash}
        onMouseDown={handleDelete}
        style={{ cursor: "pointer" }}
      />
    </li>
  );
};

export default SortableItem;

