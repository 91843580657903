import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Form, Modal, ProgressBar } from "react-bootstrap";
import { ColumnDef } from "@tanstack/react-table";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import Badge, { BadgeBg } from "components/base/Badge";
import { Ticket } from "../../../../hooks/dolibarr/useTicketsData";
import AdvanceTable from "../../../../components/base/AdvanceTable";

interface TicketsCellProps {
  name: string;
  ticket: Ticket;
}

const TicketsCell: React.FC<TicketsCellProps> = ({ name, ticket }) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(ticket);
  const DOLAPIKEY = localStorage.getItem("DOLAPIKEY"); // Get token from local storage

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch(
        `https://erp.ipercom.io/api/index.php/tickets/${formData.id}`,
        {
          method: "PUT",
          headers: {
            DOLAPIKEY: `${DOLAPIKEY}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formData)
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log("Ticket updated successfully");
      handleCloseModal();
    } catch (error) {
      console.error("Error updating ticket:", error);
    }
  };

  return (
    <>
      <Link
        to={``}
        className="text-decoration-none fw-bold fs-9"
        onClick={handleShowModal}
      >
        {name}
      </Link>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Détails du Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicRef">
              <Form.Label>Référence</Form.Label>
              <Form.Control type="text" value={formData.ref} readOnly />
            </Form.Group>

            <Form.Group controlId="formBasicSubject">
              <Form.Label>Sujet</Form.Label>
              <Form.Control
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicType">
              <Form.Label>Type</Form.Label>
              <Form.Control type="text" value={formData.type_code} readOnly />
            </Form.Group>

            <Form.Group controlId="formBasicCategory">
              <Form.Label>Catégorie</Form.Label>
              <Form.Control
                type="text"
                value={formData.category_code}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formBasicSeverity">
              <Form.Label>Gravité</Form.Label>
              <Form.Control
                type="text"
                value={formData.severity_code}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formBasicStatus">
              <Form.Label>Statut</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="0">Non lu</option>
                <option value="1">Lu</option>
                <option value="2">Assigné</option>
                <option value="3">En cours</option>
                <option value="4">Fermé</option>

                <option value="5">En attente de retour</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicPriority">
              <Form.Label>Priorité</Form.Label>
              <Form.Control
                as="select"
                name="priority"
                value={formData.priority}
                onChange={handleChange}
              >
                <option value="Low">Faible</option>
                <option value="Medium">Moyen</option>
                <option value="High">Élevé</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicCreationDate">
              <Form.Label>Date de création</Form.Label>
              <Form.Control
                type="text"
                value={new Date(formData.date_creation).toLocaleString()}
                readOnly
                disabled
              />
            </Form.Group>

            <Form.Group controlId="formBasicModificationDate">
              <Form.Label>Date de modification</Form.Label>
              <Form.Control
                type="text"
                value={new Date(formData.date_modification).toLocaleString()}
                readOnly
                disabled
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Enregistrer les modifications
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const TicketsTableColumns: ColumnDef<Ticket>[] = [
  {
    accessorKey: "ref_subject",
    header: "Référence & Sujet",
    cell: ({ row }) => (
      <div>
        <strong>{row.original.ref}</strong> {/* Display reference in bold */}
        <div>
          Sujet :{" "}
          <Link to={`/portail-revendeur/vos-tickets/${row.original.id}`}>
            {row.original.subject}
          </Link>
          {/*<TicketsCell name={row.original.subject} ticket={row.original} />*/}
        </div>
      </div>
    )
  },

  {
    accessorKey: "type_code",
    header: "Type"
  },
  {
    accessorKey: "category_code",
    header: "Catégorie"
  },
  {
    accessorKey: "severity_code",
    header: "Gravité"
  },
  {
    accessorKey: "date_creation",
    header: "Date de création",
    cell: ({ getValue }) => {
      const value = getValue<string>();
      return new Date(value).toLocaleDateString();
    }
  },
  {
    accessorKey: "date_modification",
    header: "Date de modification",
    cell: ({ getValue }) => {
      const value = getValue<string>();
      return new Date(value).toLocaleDateString();
    }
  },
  {
    accessorKey: "fk_statut",
    header: "Status",
    cell: ({ row: { original } }) => {
      let statusLabel;
      let variant;

      // Map the status code to labels and badge colors
      switch (original.status) {
        case "0":
          statusLabel = "Non lu";
          variant = "secondary"; // gray for unread
          break;
        case "1":
          statusLabel = "Lu";
          variant = "info"; // blue for read
          break;
        case "2":
          statusLabel = "Assigné";
          variant = "warning"; // yellow for assigned
          break;
        case "3":
          statusLabel = "En cours";
          variant = "success"; // green for in progress
          break;
        case "5":
          statusLabel = "En attente de retour";
          variant = "info"; // green for in progress
          break;
        default:
          statusLabel = "Inconnu"; // default in case of an unknown status
          variant = "dark";
      }

      return (
        <Badge variant="phoenix" bg={variant as BadgeBg}>
          {statusLabel}
        </Badge>

      );
    }
  },

  {
    accessorKey: 'progress',
    header: "Progress",
    cell: ({ row: { original } }) => {
      const { progress } = original;

      // If progress is a single percentage value (0-100)
      return (
        <>
          <p className="text-800 fs-10 mb-0">
            {progress}% {/* Display the progress as percentage */}
          </p>
          <ProgressBar now={Number(progress)} style={{ height: 3 }} variant="success" />

        </>
      );

      // Return null or a placeholder if no progress data is available
      return <p className="text-muted">No progress</p>;
    }
  }
];

const TicketsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default TicketsTable;
