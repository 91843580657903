import { useState, useEffect } from 'react';

export interface Assignee {
  id: number;
  name: string;
  avatar?: string;
}

export interface Ticket {
  id: number;
  author: string;
  subject: string;
  type: string;
  severity: string;
  priority: string;
  company: string;
  assignee: string;
  creationDate: string;
  status: string;
  fk_status: string;
  task: number;
  bg: string;
  progress: string;
  budget: number;
  ref: string;
  type_code: string;
  category_code: string;
  severity_code: string;
  message: string;
  date_creation: string;
  date_modification: string;
  date_cloture: string | null;
}

const useTicketsData = () => {
  const [data, setData] = useState<Ticket[]>([]);
  const [error, setError] = useState<string | null>(null);

  const DOLAPIKEY = localStorage.getItem("DOLAPIKEY");

  const fetchTickets = async () => {
    try {
      const response = await fetch(
        "https://erp.ipercom.io/api/index.php/tickets?sortfield=t.rowid&sortorder=ASC&limit=100",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            DOLAPIKEY: DOLAPIKEY || ""
          }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (!Array.isArray(result)) {
        throw new Error("Invalid data format from API");
      }

      const ticketsWithDetails: Ticket[] = result.map((ticket: any) => ({
        id: Number(ticket.id),
        author: ticket.author || "Unknown Author",
        subject: ticket.subject || "Unnamed Ticket",
        ref: ticket.ref || "No Reference",
        type: ticket.type_label || "Unknown Type",
        type_code: ticket.type_code || "Unknown Type Code",
        category_code: ticket.category_code || "Unknown Category Code",
        severity_code: ticket.severity_code || "Unknown Severity Code",
        severity: ticket.severity_label || "Unknown Severity",
        priority: ticket.priority_label || "Unknown Priority",
        company: ticket.company || "Unknown Company",
        assignee: ticket.assignee || "Unknown Assignee",
        creationDate: new Date(ticket.date_creation * 1000).toISOString().split("T")[0],
        fk_status: ticket.fk_status || "Unknown Status",
        status: ticket.status || "Unknown Status",
        progress: ticket.progress || "Unknown Progress",
        date_creation: new Date(ticket.date_creation * 1000).toISOString().split("T")[0],
        date_modification: new Date(ticket.date_modification * 1000).toISOString().split("T")[0],
        date_cloture: ticket.date_cloture
          ? new Date(ticket.date_cloture * 1000).toISOString().split("T")[0]
          : null,
        message: ticket.message || "No Message",
        task: 123, // this should be dynamically assigned based on your logic
        bg: "bg55", // this should be dynamically assigned based on your logic
        budget: 1000 // this should be dynamically assigned based on your logic
      }));

      setData(ticketsWithDetails);
    } catch (error) {
      if (error instanceof Error) {
        setError(`Error fetching ticket data: ${error.message}`);
      } else {
        setError("Unexpected error during ticket data retrieval.");
      }
    }
  };

  // Fetch the data on the first render
  useEffect(() => {
    fetchTickets();
  }, []);

  // Return data, error, and a refetch function to manually reload tickets
  return { data, error, refetch: fetchTickets };
};

export default useTicketsData;
