import React from "react";

interface ProgressBarProps {
  step: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ step, totalSteps }) => {
  const steps = Array.from({ length: totalSteps }, (_, index) => index + 1);

  const styles = {
    progressBarContainer: {
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      width: "100%" // Elargir la barre de progression
    } as React.CSSProperties,
    steps: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "5px",
      position: "relative" as "relative"
    } as React.CSSProperties,
    step: {
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      textAlign: "center",
      position: "relative" as "relative",
      width: `${100 / totalSteps}%`
    } as React.CSSProperties,
    stepNumber: {
      backgroundColor: "#3874FF",
      borderRadius: "50%",
      width: "25px",
      height: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold" as "bold",
      color: "#fff"
    } as React.CSSProperties,
    progressBar: {
      width: "100%",
      height: "2px", // Elargir la barre de progression
      backgroundColor: "#e0e0e0",
      position: "relative" as "relative"
    } as React.CSSProperties,
    progressFill: {
      height: "100%",
      backgroundColor: "#3874FF" // Couleur de la barre de progression
    } as React.CSSProperties
  };

  return (
    <div style={styles.progressBarContainer}>
      <div style={styles.steps}>
        {steps.map((currentStep) => (
          <div key={currentStep} style={styles.step}>
            <span style={styles.stepNumber}>{currentStep}</span>
          </div>
        ))}
      </div>
      <div style={styles.progressBar}>
        <div
          style={{
            ...styles.progressFill,
            width: `${(step / totalSteps) * 100}%` // Remplir la barre en fonction de l'étape
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
